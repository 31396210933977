import { v4 } from "uuid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import {
  Issue,
  ProductChemicalInput,
  ProductChemicalOverrideInput,
} from "generated-graphql/graphql";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SaveButton } from "components/SaveButton";
import { useCallback, useMemo } from "react";
import { cloneDeep } from "lodash";
import { useProductChemicalInputValidation } from "../../Report/validationHooks/useProductChemicalInputValidation";
import { useValidatingForm } from "hooks/useValidatingForm";
import { FormTextField } from "components/Forms/FormTextField";
import { SearchSelect } from "components/SearchSelect";
import { StatePicker } from "components/StatePicker";
import {
  STORAGE_TYPE_DESCRIPTIONS,
  StorageType,
} from "encamp-shared/src/constants/tierii";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";

export type ProductChemicalOverrideDialogProps = {
  productChemical: ProductChemicalInput;
  index: number;
  initialIssues: Issue[];
  onSave: (newValue: ProductChemicalOverrideInput) => void;
  onClose: () => void;
  open: boolean;
  mode: "add" | "edit";
};

export function ProductChemicalOverrideDialog({
  productChemical,
  index,
  initialIssues,
  onSave,
  onClose,
  open,
  mode,
}: ProductChemicalOverrideDialogProps) {
  const theme = useTheme();

  const id = useMemo(
    () => (mode === "edit" ? productChemical?.overrides?.[index]?.id : v4()),
    [index, mode, productChemical]
  );

  const mappedInitialIssues = useMemo(() => {
    if (mode === "add") {
      return [];
    }
    return initialIssues
      .filter(
        (i) =>
          i.schemaPath &&
          i.schemaPath.includes(`ProductChemicalOverride<${id}>`)
      )
      .map((i) => ({
        ...i,
        key: i.key?.replace(`overrides.${index}.`, ""),
      }));
  }, [id, mode, initialIssues, index]);

  const initialValues = useMemo(() => {
    const existingValues = cloneDeep(productChemical);
    if (mode === "edit") {
      return existingValues;
    }
    const newOverride: ProductChemicalOverrideInput = {
      id: id,
      jurisdiction: "",
      storageType: "",
    };
    return {
      ...existingValues,
      overrides: [...(existingValues.overrides || []), newOverride],
    };
  }, [id, mode, productChemical]);

  const { control, getValues } = useValidatingForm(
    initialValues,
    mappedInitialIssues,
    useProductChemicalInputValidation()
  );

  const watchStorageType = useWatch({
    control: control,
    name: `overrides.${index}.storageType`,
  });

  const handleSave = useCallback(() => {
    const override = getValues(`overrides.${index}`);
    onSave(override);
  }, [index, onSave, getValues]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{mode == "add" ? "Add" : "Edit"} State Override</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Grid container padding={theme.spacing(2)} spacing={theme.spacing(2)}>
          <Grid item xs={12}>
            <StatePicker
              // @ts-ignore TODO: figure out why this type is not compatible
              control={control}
              name={`overrides.${index}.jurisdiction`}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={`overrides.${index}.storageType`}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <SearchSelect
                    {...field}
                    {...fieldState}
                    label="Storage Type"
                    options={Object.values(STORAGE_TYPE_DESCRIPTIONS)}
                    getOptionLabel={(opt: any) => prettyPrintEnumValue(opt)}
                    required
                  />
                </FormControl>
              )}
            />
          </Grid>

          {watchStorageType === StorageType.Other ? (
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name={`overrides.${index}.storageTypeDescription`}
                label="Storage Type Description"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pb: theme.spacing(1.5), pr: theme.spacing(1.5) }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={theme.spacing(1.5)}
        >
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <SaveButton
            loading={false}
            saveText="Save Override"
            onClick={handleSave}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
