import { useMutation } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import Refresh from "@mui/icons-material/Refresh";
import { Box, Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { Permission, UserStatus, UsersQuery } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddUserDialog } from "./AddUserDialog";
import { EditUserDialog } from "./EditUserDialog";

export type UserRow = UsersQuery["users"]["items"][number];

export const prettyPrintUserStatus = (userStatus: UserStatus) => {
  switch (userStatus) {
    case UserStatus.Pending:
      return "Invite Sent";
    case UserStatus.Disabled:
      return "Deactivated";
    default:
      return prettyPrintEnumValue(userStatus);
  }
};

const USERS = gql(`
  query Users($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    users(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        email
        status
        facilities {
            id
        }
        person {
          first
          last
        }
        UserTenant {
          tenantId
            role {
                name
                permissions
            }
        }
      }
      count
    }
  }
`);

const RESEND_INVITE = gql(/* GraphQL */ `
  mutation ResendInvite($email: String!) {
    resendInvitationToUser(email: $email)
  }
`);

export function Users() {
  const { tenantId, tenant } = useTenant();
  const { hasPermissions } = useAuthorization();
  const { isStaff } = useCurrentUser();

  const canViewUsersList = hasPermissions([Permission.ReadAllUserTenant]);
  const navigate = useNavigate();
  if (!canViewUsersList) {
    navigate("/");
  }

  const [addUser, setAddUser] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<UserRow | null>(null);

  useBreadcrumb([
    { label: tenant?.name ?? "Unknown Tenant" },
    { label: "Account" },
    { label: "Users" },
  ]);
  const alerts = useAlerts();
  const [resendInvite] = useMutation(RESEND_INVITE, {
    onError: (error) => alerts.error(error.message),
    onCompleted: (result) => {
      if (result.resendInvitationToUser) {
        alerts.success("Invite sent successfully.");
      }
    },
  });

  const columns: OmnisearchGridColDef<UserRow>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      sortable: true,
      valueGetter: (params) =>
        `${params.row.person?.first ?? ""} ${
          params.row.person?.last ?? ""
        }`.trim(),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      sortable: true,
    },
    {
      field: "numFacilities",
      headerName: "Facilities",
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        const role = params.row.UserTenant.find(
          (ut) => ut.tenantId === tenantId
        )?.role;

        if (
          role?.permissions?.includes(Permission.ReadAllFacility) ||
          role?.permissions?.includes(Permission.WriteAllFacility)
        )
          return "all";

        return params.row.facilities.length;
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      sortable: false,
      valueGetter: (params) =>
        params.row.UserTenant?.map((userTenant) => userTenant.role?.name).join(
          ", "
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      sortable: true,
      valueGetter: (params) => prettyPrintUserStatus(params.row.status),
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 80,
      flex: 0.5,
      align: "right",
      getActions: ({ row }) => {
        const actions = [];

        if (isStaff && row.status === UserStatus.Pending) {
          actions.push(
            <Tooltip title="Resend Invite" key={1}>
              <GridActionsCellItem
                onClick={() =>
                  resendInvite({ variables: { email: row.email } })
                }
                label="Resend Invite"
                icon={<Refresh />}
              />
            </Tooltip>
          );
        }
        if (hasPermissions([Permission.WriteAllUserTenant])) {
          actions.push(
            <Tooltip title="Edit User" key={2}>
              <GridActionsCellItem
                onClick={() => setEditUser(row)}
                label="Edit User"
                icon={<Edit />}
              />
            </Tooltip>
          );
        }

        return actions;
      },
    },
  ];

  return (
    <Box sx={{ pt: 3 }}>
      {addUser && (
        <AddUserDialog
          open={addUser}
          onClose={() => setAddUser(false)}
          tenantId={tenantId ?? ""}
        />
      )}
      {editUser && (
        <EditUserDialog
          open={!!editUser}
          initialUserId={editUser.id}
          onClose={() => setEditUser(null)}
          tenantId={tenantId ?? ""}
        />
      )}

      <OmnisearchDataGrid
        columns={columns}
        dataQuery={USERS}
        // Excluding status and role for now
        excludeFilterColumns={["numFacilities", "role", "status", "name"]}
        onRowClick={(params) => setEditUser(params.row)}
        additionalFilterColumns={[
          {
            key: "first",
            header: "First Name",
          },
          {
            key: "last",
            header: "Last Name",
          },
        ]}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.users.items}
        getCount={(data) => data.users.count}
        noDataMessage="No users have been added to this organization yet."
        initialPageSize={50}
        commandButtons={
          hasPermissions([Permission.CreateUserTenant])
            ? [
                <Box key="add">
                  <Button variant="contained" onClick={() => setAddUser(true)}>
                    Add User
                  </Button>
                </Box>,
              ]
            : []
        }
      />
    </Box>
  );
}
