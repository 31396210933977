import Clear from "@mui/icons-material/Clear";
import StarOutline from "@mui/icons-material/StarOutline";
import Tune from "@mui/icons-material/Tune";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterKey } from "hooks/useOmnisearchDatagridSettings";
import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { useQueryParams } from "../../hooks/useQueryParams";
import { FavoritesPopover } from "./FavoritesPopover";
import { FilterPopover } from "./FilterPopover";

export type Filter = {
  omnisearch?: string;
};

export type OmnisearchProps = {
  columnFilterKeys?: FilterKey[];
  showFavorites?: boolean;
  sx?: SxProps;
  /**
   * If true, the omnisearch will use the query string to store the search value.
   */
  useQueryString?: boolean;
  // DEPRECATED: instead do this: const { omnisearch } = useOmnisearchDatagrid();
  onSearchChanged?: (search: string) => void;
};

export function Omnisearch({
  columnFilterKeys,
  showFavorites = true,
  sx = {},
  useQueryString = true,
  onSearchChanged,
}: OmnisearchProps) {
  const { filters, setFilters } = useQueryParams<Filter>();
  const [internalSearch, setInternalSearch] = useState("");

  const setSearch = useCallback(
    (v: string) => {
      if (useQueryString) {
        setFilters((f) => {
          f.omnisearch = v;
        });
      } else {
        setInternalSearch(v);
      }
      if (onSearchChanged) onSearchChanged(v);
    },
    [useQueryString, setFilters, onSearchChanged]
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const [filterButtonAnchor, setFilterButtonAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleFavoritesIconClick =
    () => (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopoverAnchor(event.currentTarget);
      setIsPopoverOpen(true);
    };

  const onFilterKeyClick = useCallback(
    (newOmnisearch: string) => {
      setSearch(newOmnisearch);
    },
    [setSearch]
  );

  const toggleFilterKeysPopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) =>
      setFilterButtonAnchor(event.currentTarget),
    []
  );

  const handleCloseFilter = useCallback(() => {
    setFilterButtonAnchor(null);
  }, []);

  const openFilter = useMemo(
    () => Boolean(filterButtonAnchor),
    [filterButtonAnchor]
  );

  const value = useMemo(
    () => (useQueryString ? filters.omnisearch : internalSearch),
    [useQueryString, filters.omnisearch, internalSearch]
  );

  return (
    <Stack
      direction="row"
      spacing={2.5}
      sx={{
        width: {
          sm: "80%",
          xs: "100%",
        },
        ...sx,
      }}
    >
      <TextField
        sx={{ flexGrow: 1 }}
        name="search"
        value={value ?? ""}
        onChange={(e) => setSearch(e.target.value)}
        label="Search"
        InputProps={{
          endAdornment: (
            <Box sx={{ display: "flex" }}>
              <IconButton
                sx={{
                  display: value?.length ? "default" : "none",
                }}
                onClick={() => {
                  setSearch("");
                }}
                aria-label="clear input"
              >
                <Clear fontSize="small" />
              </IconButton>
              {columnFilterKeys && (
                <Tooltip title="Filters">
                  <IconButton
                    onClick={toggleFilterKeysPopover}
                    aria-label="show filters"
                  >
                    <Tune fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ),
        }}
      />

      {columnFilterKeys && (
        <FilterPopover
          isOpen={openFilter}
          filterButtonAnchor={filterButtonAnchor}
          handleCloseFilter={handleCloseFilter}
          columnFilterKeys={columnFilterKeys}
          onFilterKeyClick={onFilterKeyClick}
          filters={filters}
        />
      )}

      {showFavorites && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Favorites">
            <IconButton
              sx={{ p: 0 }}
              onClick={handleFavoritesIconClick()}
              aria-label="open favorites"
            >
              <StarOutline />
            </IconButton>
          </Tooltip>

          <FavoritesPopover
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            popoverAnchor={popoverAnchor}
            setPopoverAnchor={setPopoverAnchor}
          />
        </Box>
      )}
    </Stack>
  );
}
