import { FacilityProductStorageLocationInput } from "generated-graphql/graphql";
import { v4 as uuid } from "uuid";
import { transform as transformStorageLocation } from "./transformStorageLocation";

export function transform(
  storageLocation: FacilityProductStorageLocationInput
) {
  return {
    id: storageLocation.id === "NEW" ? uuid() : storageLocation.id,
    storageLocation: transformStorageLocation(storageLocation.storageLocation),
    maxQuantity: storageLocation.maxQuantity
      ? Number(storageLocation.maxQuantity)
      : null,
  };
}
