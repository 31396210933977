import { ProductChemicalInput } from "generated-graphql/graphql";
import { omit } from "lodash";

export const transform = (input: ProductChemicalInput) => {
  return {
    ...omit(input, "issues", "id", "chemical"),
    amount: input.amount ? Number(input.amount) : undefined,
    overrides: input.overrides.map((o) => omit(o, "issues")),
  };
};
