import { useSuspenseQuery } from "@apollo/client";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { gql } from "generated-graphql";
import { useTenant } from "hooks/useTenant";
import { FilterSelections } from "../FilterSelections";
import { useOverviewState } from "../OverviewContext";
import {
  allowedNonComplianceBarChartTypes,
  NonComplianceBarChart,
  NonComplianceBarChartType,
} from "./NonComplianceBarChart";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useQueryParams } from "hooks/useQueryParams";

const GET_INSIGHTS_NON_COMPLIANCE = gql(/* GraphQL */ `
  query GetInsightsNonCompliance($tenantId: String!, $filter: InsightsFilter) {
    insightsNonCompliance(tenantId: $tenantId, filter: $filter) {
      byProgramArea {
        count
        key
        name
      }
      quarterlyBySignificance {
        quarter
        group
        count
      }
      quarterlyByProgramArea {
        quarter
        group
        count
      }
      violationsByFacility {
        count
        key
        name
      }
    }
  }
`);

const graphVariations: Record<
  NonComplianceBarChartType,
  { label: string; selectLabel: string }
> = {
  programAreaTotals: {
    label: "Program area totals",
    selectLabel: "Program area totals",
  },
  quarterlyNonComplianceTotals: {
    label: "Quarterly non-compliance totals",
    selectLabel: "Quarterly totals",
  },
  quarterlyNonComplianceByProgramArea: {
    label: "Quarterly non-compliance by program area",
    selectLabel: "Quarterly by program area",
  },
  leastCompliantFacilities: {
    label: "Least-compliant facilities",
    selectLabel: "Least-compliant facilities",
  },
};

type QueryParamFilters = {
  noncomplianceGraphType: NonComplianceBarChartType;
};

export function NonComplianceTile() {
  return (
    <DashboardTile xs={12}>
      <NonComplianceTileContent />
    </DashboardTile>
  );
}

function NonComplianceTileContent() {
  const theme = useTheme();
  const { filters, setFilters } = useQueryParams<QueryParamFilters>();
  const complianceGraphType = allowedNonComplianceBarChartTypes.includes(
    filters.noncomplianceGraphType as any
  )
    ? (filters.noncomplianceGraphType as NonComplianceBarChartType)
    : ("programAreaTotals" as NonComplianceBarChartType);
  const { tenantId } = useTenant();
  const {
    overviewState: { programArea, naicsCode, naicsType },
  } = useOverviewState();

  const { data } = useSuspenseQuery(GET_INSIGHTS_NON_COMPLIANCE, {
    variables: {
      tenantId: tenantId ?? "",
      filter: { programArea, naicsCode: naicsCode?.toString() },
    },
    skip: !tenantId,
  });

  return (
    <>
      <DashboardTile.Header
        title="Non-compliance"
        infoTooltip={<NonComplianceTooltip timePeriod="3 years" />}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FilterSelections
            facilityType={naicsType}
            programArea={programArea}
          />
          <FormControl sx={{ width: theme.spacing(32.5) }}>
            <InputLabel id="view-by">View by</InputLabel>
            <Select
              sx={{ height: theme.spacing(5) }}
              fullWidth
              labelId="view-by"
              label="View by"
              value={complianceGraphType}
              onChange={(event) => {
                setFilters((f) => {
                  f.noncomplianceGraphType = event.target
                    .value as NonComplianceBarChartType;
                });
              }}
            >
              {Object.entries(graphVariations).map(([key, { selectLabel }]) => (
                <MenuItem key={key} value={key}>
                  {selectLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DashboardTile.Header>
      <DashboardTile.ContentArea>
        <Stack direction="row" alignItems="center">
          <Typography>{graphVariations[complianceGraphType].label}</Typography>
          <Tooltip
            title={
              <NonComplianceGraphTooltip
                label={graphVariations[complianceGraphType].label}
                timePeriod="3 years"
              />
            }
          >
            <IconButton size="small">
              <InfoOutlined color="disabled" />
            </IconButton>
          </Tooltip>
        </Stack>

        <Box height={theme.spacing(34)}>
          <NonComplianceBarChart
            graphType={complianceGraphType}
            data={data?.insightsNonCompliance}
          />
        </Box>
      </DashboardTile.ContentArea>
    </>
  );
}

function NonComplianceTooltip({ timePeriod }: { timePeriod: string }) {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Program area totals
        </Typography>{" "}
        represents the total number of facilities who have been in
        non-compliance for at least 1 quarter over the last {timePeriod}, broken
        down by program area.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly totals
        </Typography>{" "}
        represents the total number of facilities who have been in
        non-compliance during each quarter over the last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly by program area
        </Typography>{" "}
        represents the number of facilities who have been in non-compliance
        during each quarter over the last {timePeriod}, broken down by program
        area.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Least-compliant facilities
        </Typography>{" "}
        represents the facilities who have spent the most quarters in
        non-compliance across all program areas over the last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        *You can view the facility details that correspond to each chart by
        clicking on it.
      </Typography>
    </Stack>
  );
}

function NonComplianceGraphTooltip({
  label,
  timePeriod,
}: {
  label: string;
  timePeriod: string;
}) {
  switch (label) {
    case "Program area totals":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Program area totals
            </Typography>{" "}
            represents the total number of facilities that have been in
            non-compliance for at least 1 quarter over the last {timePeriod},
            broken down by program area.
          </Typography>
          <Typography variant="caption">
            If there is a facility in non-compliance in multiple program areas,
            you'll see that facility counted against each corresponding program
            area total (e.g. the non-compliant facility would be counted against
            both air and water totals).
          </Typography>
        </Stack>
      );
    case "Quarterly non-compliance totals":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly totals
            </Typography>{" "}
            represents the total number of facilities that have been in
            non-compliance during each quarter over the last {timePeriod},
            regardless of their program area.
          </Typography>
        </Stack>
      );
    case "Quarterly non-compliance by program area":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly by program area
            </Typography>{" "}
            represents the number of facilities that have been in non-compliance
            during each quarter over the last {timePeriod}, broken down by
            program area.
          </Typography>
          <Typography variant="caption">
            If a facility is non-compliant in multiple program areas, it will be
            counted in the 'multiple programs' section of the chart.
          </Typography>
        </Stack>
      );
    case "Least-compliant facilities":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Least-compliant facilities
            </Typography>{" "}
            represents the facilities that have spent the most quarters in
            non-compliance across all program areas over the last {timePeriod}.
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
}
