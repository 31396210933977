import { FacilityChemicalInput } from "generated-graphql/graphql";
import { omit } from "lodash";
import { FacilityChemicalDetailFormType } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemicalAddEditDialog";
import { transform as transformFacilityChemicalStorageLocations } from "./transformFacilityChemicalStorageLocation";
import { transform as transformStateField } from "./transformStateField";

export function transform(
  input: FacilityChemicalDetailFormType
): FacilityChemicalInput {
  return {
    ...omit(input, "__typename", "issues", "facility"),
    maxAmount: input.maxAmount ? Number(input.maxAmount) : null,
    averageAmount: input.averageAmount ? Number(input.averageAmount) : null,
    maxAmountCode:
      typeof input.maxAmountCode === "number" || input.maxAmountCode
        ? Number(input.maxAmountCode)
        : null,
    averageAmountCode:
      typeof input.averageAmountCode === "number" || input.averageAmountCode
        ? Number(input.averageAmountCode)
        : null,
    maxAmountLargestContainer: input.maxAmountLargestContainer
      ? Number(input.maxAmountLargestContainer)
      : null,
    daysOnSite: input.daysOnSite ? Number(input.daysOnSite) : null,
    stateFields: input.stateFields?.map(transformStateField),
    storageLocations: input.storageLocations?.map(
      transformFacilityChemicalStorageLocations
    ),
  };
}
