import { TierIiReportKind } from "generated-graphql/graphql";
import { useNavigateWithOmnisearch } from "hooks/useNavigateWithOmnisearch";
import { useQueryParams } from "hooks/useQueryParams";
import {
  createContext,
  useCallback,
  useContext,
  useDeferredValue,
  useMemo,
  useTransition,
} from "react";

type Filter = {
  assignedToMe: boolean;
  tagNames: string[];
  filingStatusReportKind: TierIiReportKind;
};

export type ChemicalOverviewState = {
  immediateFilters: Partial<Filter>;
  deferredFilters: Partial<Filter>;
  isPending: boolean;
};

type ChemicalOverviewContextType = {
  chemicalOverviewState: ChemicalOverviewState;
  setChemicalOverviewState: (cb: (state: Partial<Filter>) => void) => void;
  drillDownToFacilities: (
    fn: (filters?: {
      assignedToMe?: boolean;
      tagNames?: string[];
    }) => Record<string, any>
  ) => void;
};

const ChemicalOverviewStateContext = createContext<
  ChemicalOverviewContextType | undefined
>(undefined);

export function useChemicalOverviewState() {
  const context = useContext(ChemicalOverviewStateContext);
  if (!context) {
    throw new Error(
      "useChemicalOverviewState must be used within a ChemicalOverviewStateProvider"
    );
  }
  return context;
}

export const ChemicalOverviewStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { filters, setFilters } = useQueryParams<Filter>();

  const deferredFilters = useDeferredValue(filters);

  const [isPending, startTransition] = useTransition();

  const chemicalOverviewState = useMemo(
    () => ({ deferredFilters, immediateFilters: filters, isPending }),
    [deferredFilters, filters, isPending]
  );

  const setChemicalOverviewState = useCallback(
    (cb: (state: Partial<Filter>) => void) => {
      startTransition(() => {
        setFilters((f) => {
          cb(f);
        });
      });
    },
    [setFilters]
  );

  const drillDownToFacilities = useNavigateWithOmnisearch("../reports", {
    assignedToMe: deferredFilters.assignedToMe,
    tagNames: deferredFilters.tagNames,
  });

  const value = useMemo(
    () => ({
      chemicalOverviewState,
      setChemicalOverviewState,
      drillDownToFacilities,
    }),
    [chemicalOverviewState, setChemicalOverviewState, drillDownToFacilities]
  );

  return (
    <ChemicalOverviewStateContext.Provider value={value}>
      {children}
    </ChemicalOverviewStateContext.Provider>
  );
};
