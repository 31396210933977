import { Skeleton, Stack, useTheme } from "@mui/material";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";
import { Suspense } from "react";
import { getChemicalsBreadcrumb } from "util/breadcrumb";
import { ChemicalOverviewStateProvider } from "./ChemicalOverviewContext";
import { EncampFilingStatusTile } from "./Tiles/EncampFilingStatusTile";
import { EncampStatsTile } from "./Tiles/EncampStatsTile";
import { IssuesAndAnomaliesTile } from "./Tiles/IssuesAndAnomaliesTile";
import { MyFacilitiesTile } from "./Tiles/MyFacilitiesTile";
import { MyProgressTile } from "./Tiles/MyProgressTile";
import { MyReportStatusTile } from "./Tiles/MyReportStatusTile";
import { OutstandingReviewsTile } from "./Tiles/OutstandingReviewsTile";

export function Overview() {
  const { tenantId } = useTenant();
  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Overview",
    },
  ]);

  return (
    <ChemicalOverviewStateProvider>
      <DashboardLayout>
        <Suspense
          fallback={
            <>
              <SkeletonTile />
              <SkeletonTile />
              <SkeletonTile />
            </>
          }
        >
          <MyFacilitiesTile xs={12} md={6} xl={5} />
          <OutstandingReviewsTile xs={12} md={6} xl={5} />
          <EncampStatsTile xs={12} xl={2} />
          <MyProgressTile xs={12} sm={5} md={4} lg={3} xl={2} />
          <MyReportStatusTile xs={12} sm={7} md={8} lg={5} xl={5} />
          <EncampFilingStatusTile xs={12} lg={4} xl={5} />
          <IssuesAndAnomaliesTile xs={12} />
        </Suspense>
      </DashboardLayout>
    </ChemicalOverviewStateProvider>
  );
}

function SkeletonTile() {
  const theme = useTheme();
  return (
    <DashboardTile xs={12}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={theme.spacing(4)} />
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Skeleton
            variant="rectangular"
            height={theme.spacing(40)}
            sx={{ flexGrow: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={theme.spacing(40)}
            sx={{ flexGrow: 1 }}
          />
        </Stack>
        <Skeleton variant="rectangular" height={theme.spacing(6)} />
      </Stack>
    </DashboardTile>
  );
}
