import { v4 as uuidV4 } from "uuid";
import Add from "@mui/icons-material/Add";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Delete from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Grid,
  Link,
  Skeleton,
  Stack,
  useTheme,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useAlerts } from "components/Alerts/AlertProvider";
import { MailingAddress } from "components/Facility/MailingAddress";
import { PhysicalAddress } from "components/Facility/PhysicalAddress";
import { FacilityPicker } from "components/FacilityPicker";
import { FireDepartmentPicker } from "components/FireDepartmentPicker";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { CheckboxField } from "components/Forms/CheckboxField";
import { DateField } from "components/Forms/DateField";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SelectWithFreeSolo } from "components/Forms/SelectWithFreeSolo";
import { LepcPicker } from "components/LepcPicker";
import { NaicsPicker } from "components/NaicsPicker";
import { SubSection, SubSectionHeader } from "components/SubSection";
import { TagPicker } from "components/TagPicker";
import { LabeledText } from "components/Typography/LabeledText";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import { alternateIdTypes } from "encamp-shared/src/constants/alternateIdTypes";
import {
  DynamicField,
  FacilityInput,
  FacilityStatus,
  FireDepartmentPickerFragment,
  LepcPickerFragment,
  ProgramArea,
  TagType,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacility";
import { useAuthorization } from "hooks/useAuthorization";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { regulatoryFieldsExceptFacilityAlternateIds } from "routes/Customer/Chemicals/Report/useReport";
import { useFacilityInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useFacilityInputValidation";
import invariant from "tiny-invariant";
import { hasCriticalIssues } from "util/forms";
import { FacilityStateFieldsForm } from "./FacilityStateFieldsForm";
import { Scroller } from "./Scroller";
import {
  useFacility,
  useFacilityProfileIssues,
  useUpdateFacilityProfile,
} from "./useFacility";
import { TIER_II_MANAGER_STATES } from "encamp-shared/src/constants/states";

export type FacilityDetailsInput = Omit<FacilityInput, "stateFields"> & {
  stateFields?: (DynamicField & { type: ProgramArea })[];
};
export const FacilityProfile: React.FC = () => {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenant } = useTenant();
  const { data, loading, error } = useFacility();
  const { facilityId } = useParams<{ facilityId: string }>();

  invariant(facilityId);

  const { hasWritePermissionForFacility } = useAuthorization();

  const readOnly = !hasWritePermissionForFacility(facilityId);

  if (error) {
    throw error;
  }

  const defaultValues = useMemo<FacilityDetailsInput>(
    () => ({
      name: data?.facility?.name ?? "",
      customerFacilityId: data?.facility?.customerFacilityId ?? "",
      companyName: data?.facility?.companyName ?? null,
      businessUnit: data?.facility?.businessUnit ?? null,
      department: data?.facility?.department ?? null,
      dateOpened: data?.facility?.dateOpened ?? null,
      dateClosed: data?.facility?.dateClosed ?? null,
      dateInactive: data?.facility?.dateInactive ?? null,
      isMailingAddressSameAsFacility: data?.facility?.isMailingAddressEncamp
        ? "encamp"
        : data?.facility?.isMailingAddressSameAsFacility
        ? true
        : false,
      isUnmanned: data?.facility?.isUnmanned ? true : false,
      isColocated: data?.facility?.colocationReportingFacility ? true : false,
      isSubjectToChemicalAccidentPrevention:
        data?.facility?.isSubjectToChemicalAccidentPrevention,
      isSubjectToEmergencyPlanning:
        data?.facility?.isSubjectToEmergencyPlanning,
      isSubjectToToxicsReleaseInventoryReporting:
        data?.facility?.isSubjectToToxicsReleaseInventoryReporting,
      colocationReportingFacilityId:
        data?.facility?.colocationReportingFacility?.id ?? null,
      facilityType: data?.facility?.facilityType,
      status: data?.facility?.status,
      statusReason: data?.facility?.statusReason,
      facilityAlternateIds: data?.facility?.facilityAlternateIds ?? [],
      maxNumOccupants: data?.facility?.maxNumOccupants ?? null,
      fireDepartmentId: data?.facility?.fireDepartment?.id ?? null,
      lepcId: data?.facility?.lepc?.id ?? null,
      dbNumber: data?.facility?.dbNumber ?? null,
      naicsCode: data?.facility?.naicsCode ?? null,
      longitude: data?.facility?.longitude ?? null,
      latitude: data?.facility?.latitude ?? null,
      municipality: data?.facility?.municipality ?? null,
      phone: data?.facility?.phone ?? null,
      phone24Hour: data?.facility?.phone24Hour ?? null,
      county: data?.facility?.county ?? null,
      streetAddress1: data?.facility?.streetAddress1 ?? null,
      streetAddress2: data?.facility?.streetAddress2 ?? null,
      city: data?.facility?.city ?? null,
      state: data?.facility?.state ?? null,
      zip: data?.facility?.zip ?? null,
      country: data?.facility?.country ?? null,

      mailingStreetAddress1: data?.facility?.mailingStreetAddress1 ?? null,
      mailingStreetAddress2: data?.facility?.mailingStreetAddress2 ?? null,
      mailingCity: data?.facility?.mailingCity ?? null,
      mailingState: data?.facility?.mailingState ?? null,
      mailingZip: data?.facility?.mailingZip ?? null,
      mailingCountry: data?.facility?.mailingCountry ?? null,
      stateFields:
        data?.facility?.stateFields.map((sf) => ({
          ...sf,
          value: sf.value ?? null,
          type: ProgramArea.Epcra,
        })) ??
        [] ??
        null,
      tags: data?.facility?.tags ?? [],
    }),
    [data?.facility]
  );

  useBreadcrumb([
    {
      label: "Facilities",
      to: `/o/${tenant?.id}/facilities`,
    },
    {
      label: data?.facility?.name ?? "",
    },
  ]);

  const form = useValidatingForm<FacilityDetailsInput>(
    defaultValues,
    useFacilityProfileIssues(),
    useFacilityInputValidation({
      facilityId: data?.facility?.id ?? "",
      reportId: undefined,
      omit: [
        "contacts",
        "facilityDocuments",
        ...regulatoryFieldsExceptFacilityAlternateIds,
      ],
    })
  );

  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    issues,
    watch,
  } = form;

  const state = watch("state");
  const stateFields = watch("stateFields");
  const fdId = watch("fireDepartmentId");
  const lepcId = watch("lepcId");

  const currentIssues = useMemo(() => {
    return isDirty ? issues : data?.facility?.issues;
  }, [data, issues, isDirty]);

  const saveDisabled = useMemo(
    () => readOnly || loading || hasCriticalIssues(currentIssues ?? []),
    [currentIssues, loading, readOnly]
  );

  const [updateFacility, { loading: submitting }] = useUpdateFacilityProfile();

  const onSubmit: SubmitHandler<FacilityDetailsInput> = useCallback(
    async (saveData: FacilityDetailsInput) => {
      if (!data?.facility) return;

      const input = transform(saveData);

      try {
        await updateFacility({
          variables: {
            id: data.facility.id,
            facility: input,
          },
        });
      } catch (err) {
        alerts.error("Error saving facility information.");
        console.info("Error saving facility information.", err);
        return;
      }

      alerts.success("Facility information saved successfully.");
    },
    [alerts, data, updateFacility]
  );

  const scrollerSections = useMemo(() => {
    return [
      {
        id: "basicInfo",
        title: "Basic Information",
        content: loading ? (
          <BasicInfoSkelly />
        ) : (
          <BasicInfo readOnly={readOnly} />
        ),
      },
      {
        id: "localAgencies",
        title: "Local Agencies",
        content: loading ? (
          <LocalAgenciesSkelly />
        ) : (
          <LocalAgencies readOnly={readOnly} />
        ),
      },
      {
        id: "stateInfo",
        title: `State Information`,
        content: data?.facility && (
          <FacilityStateFieldsForm
            jurisdictions={[state, lepcId, fdId].filter(Boolean) as string[]}
            context="facility"
            fields={stateFields ?? []}
            namePrefix="stateFields"
            readOnly={readOnly}
            sx={{ p: theme.spacing(3) }}
          />
        ),
      },
      // todo re-enable when waste is ready
      // {
      //   id: "waste",
      //   title: "Waste",
      //   content: <Waste />,
      // },
    ];
  }, [
    theme,
    loading,
    stateFields,
    data?.facility,
    state,
    readOnly,
    lepcId,
    fdId,
  ]);

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Stack>
            <Scroller
              sections={scrollerSections}
              sx={{
                pt: theme.spacing(3),
              }}
            />
            <FloatingSaveBar
              onSaveClick={handleSubmit(onSubmit)}
              saving={submitting}
              saveText={"Save"}
              saveDisabled={saveDisabled}
              saveStartIcon={<SaveIcon />}
              issues={form.issues ?? data?.facility?.issues ?? []}
              sx={{ paddingRight: theme.spacing(1.5), marginX: 0 }}
            />
          </Stack>
        </form>
      </FormProvider>
      <UnfinishedChangesPrompt
        when={submitting || isSubmitSuccessful ? false : isDirty}
      />
    </>
  );
};

function BasicInfo({ readOnly }: { readOnly: boolean }) {
  const theme = useTheme();
  const { control } = useFormContext();
  return (
    <>
      <SubSection>
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <FormTextField
              name="name"
              label="Facility Name*"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="customerFacilityId"
              label="Facility ID"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="companyName"
              label="Company Name"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormTextField
              name="department"
              label="Department"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormTextField
              name="businessUnit"
              label="Business Unit"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormTextField
              name="dbNumber"
              label="Dun & Bradstreet Number"
              control={control}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Controller
              name="naicsCode"
              control={control}
              render={({ field, fieldState }) => (
                <NaicsPicker {...field} {...fieldState} disabled={readOnly} />
              )}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormTextField
              name="phone"
              label="Phone Number"
              control={control}
              disabled={readOnly}
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <FormTextField
              name="phone24Hour"
              label="24-hour Phone Number"
              control={control}
              disabled={readOnly}
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <Controller
              name="tags"
              control={control}
              render={({ field, fieldState }) => (
                <TagPicker
                  {...field}
                  {...fieldState}
                  disabled={readOnly}
                  onChange={field.onChange}
                  label="Facility Tags"
                  tagType={TagType.Facility}
                />
              )}
            />
          </Grid>
        </Grid>
      </SubSection>
      <OperationalStatus readOnly={readOnly} />
      <SubSection title="Physical Address">
        <PhysicalAddress showMap readOnly={readOnly} />
      </SubSection>
      <SubSection title="Mailing Address">
        <MailingAddress readOnly={readOnly} />
      </SubSection>
      <GovernmentIds readOnly={readOnly} />
    </>
  );
}

function BasicInfoSkelly() {
  const theme = useTheme();
  return (
    <>
      <SubSection>
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
        </Grid>
      </SubSection>
      <OperationalStatusSkelly />
      <PhysicalAddressSkelly />
      <MailingAddressSkelly />
      <GovernmentIdsSkelly />
    </>
  );
}

function OperationalStatus({ readOnly }: { readOnly: boolean }) {
  const theme = useTheme();
  const { control, watch, getValues, setValue } = useFormContext();
  const isColocated = watch("isColocated");
  const { data } = useFacility();
  const { tenant } = useTenant();
  const [colocationReportingFacility, setColocationReportingFacility] =
    useState(data?.facility?.colocationReportingFacility);

  return (
    <SubSection title="Operational Status">
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
      >
        <Grid item xs={12}>
          <FormTextField
            name="facilityType"
            label="Facility Type"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <FormSelect
            label="Facility Status"
            selectItems={[
              FacilityStatus.Open,
              FacilityStatus.Closed,
              FacilityStatus.Inactive,
              FacilityStatus.Planned,
            ].map((val) => ({
              value: val,
              display: val,
            }))}
            name="status"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <FormTextField
            name="statusReason"
            label="Facility Status Reason"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <DateField
            name="dateOpened"
            label="Date Opened"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <DateField
            name="dateClosed"
            label="Date Closed"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <DateField
            name="dateInactive"
            label="Date Inactive"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6} md={3} alignSelf={"center"}>
          <CheckboxField
            name="isUnmanned"
            label="Unmanned"
            control={control}
            checkboxProps={{
              onChange: (_, checked) =>
                checked && setValue("maxNumOccupants", 0),
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormTextField
            intOnly
            name="maxNumOccupants"
            label="Max Occupancy"
            control={control}
            disabled={getValues("isUnmanned") || readOnly}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CheckboxField
            name="isColocated"
            label="Co-located"
            control={control}
            disabled={readOnly}
          />
        </Grid>
        {isColocated && (
          <Grid item xs={12}>
            <Controller
              name="colocationReportingFacilityId"
              control={control}
              render={({ field, fieldState }) => (
                <FacilityPicker
                  {...field}
                  {...fieldState}
                  value={colocationReportingFacility}
                  defaultSearchTerm={`tenantId:${tenant?.id}`}
                  label="Colocation Reporting Facility"
                  disabledFacilitiesById={[data?.facility?.id ?? ""]}
                  onChange={(fac) => {
                    setColocationReportingFacility(fac);
                    field.onChange(fac?.id ?? null);
                  }}
                  disabled={readOnly}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </SubSection>
  );
}

function OperationalStatusSkelly() {
  const theme = useTheme();

  return (
    <SubSection title="Operational Status">
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
      >
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
      </Grid>
    </SubSection>
  );
}

function PhysicalAddressSkelly() {
  const theme = useTheme();
  return (
    <SubSection title="Physical Address">
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
        marginTop={theme.spacing(2)}
      >
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>

        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
      </Grid>
    </SubSection>
  );
}

function MailingAddressSkelly() {
  const theme = useTheme();

  return (
    <Box
      marginTop={theme.spacing(3)}
      marginLeft={theme.spacing(3)}
      paddingBottom={theme.spacing(3)}
    >
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
      >
        <Grid item sm={12} md={6} sx={{ paddingTop: 0 }}>
          <SubSectionHeader title="Mailing Address" />
        </Grid>
        <Grid item sm={12} md={6} sx={{ paddingTop: 0 }}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" height={theme.spacing(10)} />
        </Grid>
      </Grid>
    </Box>
  );
}

function GovernmentIds({ readOnly }: { readOnly: boolean }) {
  const theme = useTheme();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "facilityAlternateIds",
  });
  const fieldState = control.getFieldState("facilityAlternateIds");
  const watchedFields = useWatch({
    control,
    name: "facilityAlternateIds",
  });
  const pastExpiresAt = useCallback((date: Date | string | undefined) => {
    if (!date) {
      return false;
    }
    const now = new Date();
    const isPastExp = new Date(date) < now;
    return isPastExp;
  }, []);
  return (
    <SubSection title="Government IDs">
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
        marginTop={theme.spacing(2)}
      >
        {fields.map((alternateId, index) => {
          const hasNoId = watchedFields[index]?.hasNoId || false;
          return (
            <Fragment key={alternateId.id}>
              <Grid item xs={3}>
                <SelectWithFreeSolo
                  name={`facilityAlternateIds[${index}].type`}
                  control={control}
                  options={alternateIdTypes}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={3}>
                {hasNoId ? (
                  <>
                    <TextField
                      sx={{ display: "flex" }}
                      label="ID"
                      disabled={true}
                    />
                    {/* This error display is here to maintain the layout */}
                    <ErrorDisplay />
                  </>
                ) : (
                  <Controller
                    name={`facilityAlternateIds[${index}].value`}
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        {...field}
                        label="ID *"
                        // It's not clear but I believe we disable this field if
                        // it has expired so we don't lose what the old ID was?
                        disabled={
                          readOnly ||
                          pastExpiresAt(watchedFields[index]?.expiresAt)
                        }
                        InputProps={{
                          style:
                            hasNoId === true ? { color: "transparent" } : {},
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                {hasNoId ? (
                  <DatePicker label={"Expires"} disabled={true} />
                ) : (
                  <DateField
                    name={`facilityAlternateIds[${index}].expiresAt`}
                    label="Expires"
                    control={control}
                    disabled={readOnly}
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                <CheckboxField
                  sx={{ mt: theme.spacing(0.75) }}
                  name={`facilityAlternateIds[${index}].hasNoId`}
                  label="No Government ID"
                  control={control}
                  disabled={readOnly}
                />
              </Grid>
              {!readOnly && (
                <Grid item xs={0.5}>
                  <Link
                    component="button"
                    type="button"
                    variant="body2"
                    disabled={readOnly}
                    height={theme.spacing(7)}
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Delete color="action" />
                    </Box>
                  </Link>
                </Grid>
              )}
            </Fragment>
          );
        })}
        {fieldState.error && (
          <Grid item xs={12}>
            <ErrorDisplay error={fieldState.error} />
          </Grid>
        )}
        {!readOnly && (
          <Grid item xs={12}>
            <Link
              component="button"
              type="button"
              variant="body2"
              disabled={readOnly}
              onClick={(e) => {
                e.preventDefault();
                append({
                  id: uuidV4(),
                  type: "",
                  value: "",
                  hasNoId: false,
                });
              }}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
              underline="none"
            >
              <Add color="primary" />
              ADD ID
            </Link>
          </Grid>
        )}
      </Grid>
    </SubSection>
  );
}

function GovernmentIdsSkelly() {
  const theme = useTheme();
  return (
    <SubSection title="Government IDs">
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
        marginTop={theme.spacing(2)}
      >
        <Grid item xs={12}>
          <Link
            component="button"
            type="button"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <AddCircleOutline color="primary" />
            ADD ID
          </Link>
        </Grid>
      </Grid>
    </SubSection>
  );
}

function LocalAgencies({ readOnly }: { readOnly: boolean }) {
  const theme = useTheme();
  const { control } = useFormContext<FacilityDetailsInput>();
  const { data } = useFacility();

  const [state, county] = useWatch<FacilityDetailsInput>({
    name: ["state", "county"],
    control,
  });

  const [lepc, setLepc] = useState<LepcPickerFragment | null>(
    data?.facility?.lepc ?? null
  );
  const [fireDepartment, setFireDepartment] =
    useState<FireDepartmentPickerFragment | null>(
      data?.facility?.fireDepartment ?? null
    );

  const formattedCounty = county?.replace(/\s+County$/, "");

  return (
    <>
      <SubSection
        title="Local Emergency Planning Committee (LEPC)"
        tooltip={
          TIER_II_MANAGER_STATES.includes(state) && county
            ? `Available selections are restricted to those in ${formattedCounty} County`
            : undefined
        }
      >
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <Controller
              name="lepcId"
              control={control}
              render={({ field, fieldState }) => (
                <LepcPicker
                  {...field}
                  {...fieldState}
                  facilityState={state}
                  facilityCounty={county}
                  disabled={readOnly}
                  onChange={(lepc) => {
                    setLepc(lepc);
                    field.onChange(lepc?.id ?? null);
                  }}
                  value={lepc ? lepc : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LabeledText label="Street Address" value={lepc?.mailingAddress} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="City" value={lepc?.city} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="State" value={lepc?.state} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="Zip" value={lepc?.zip} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText
              label="County"
              value={lepc?.county ? lepc?.county : " "}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Phone" value={lepc?.phone} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Email" value={lepc?.email} />
          </Grid>
        </Grid>
      </SubSection>
      <SubSection
        title="Fire District"
        tooltip={
          TIER_II_MANAGER_STATES.includes(state) && county
            ? `Available selections are restricted to those in ${formattedCounty} County`
            : undefined
        }
      >
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <Controller
              name="fireDepartmentId"
              control={control}
              render={({ field, fieldState }) => (
                <FireDepartmentPicker
                  {...field}
                  {...fieldState}
                  facilityState={state}
                  facilityCounty={county}
                  onChange={(fireDepartment) => {
                    setFireDepartment(fireDepartment);
                    field.onChange(fireDepartment?.id ?? null);
                  }}
                  value={fireDepartment ? fireDepartment : null}
                  disabled={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LabeledText
              label="Street Address"
              value={fireDepartment?.mailingAddress}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="City" value={fireDepartment?.city} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="State" value={fireDepartment?.state} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="Zip" value={fireDepartment?.zip} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText
              label="County"
              value={fireDepartment?.county ? fireDepartment?.county : " "}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Phone" value={fireDepartment?.phone} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Email" value={fireDepartment?.email} />
          </Grid>
        </Grid>
      </SubSection>
    </>
  );
}

function LocalAgenciesSkelly() {
  const theme = useTheme();

  return (
    <>
      <SubSection title="Local Emergency Planning Committee (LEPC)">
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12} md={2}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
        </Grid>
      </SubSection>
      <SubSection title="Fire District">
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)} // 8px, there is 24px set aside for the error message below each component
        >
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12} md={2}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Skeleton variant="rectangular" height={theme.spacing(10)} />
          </Grid>
        </Grid>
      </SubSection>
    </>
  );
}
