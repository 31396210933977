import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Issue,
  ProductChemicalFormFragment,
  ProductChemicalInput,
  ProductChemicalOverrideInput,
} from "generated-graphql/graphql";
import { DataGrid } from "components/DataGrid";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { IssueCount } from "components/IssueCount";
import {
  Stack,
  Box,
  Typography,
  Button,
  useTheme,
  Tooltip,
} from "@mui/material";
import { NoRowsOverlay } from "../../Inventory/Facility/NoRowsOverlay";
import { useCallback, useMemo, useState } from "react";
import {
  Control,
  useFieldArray,
  UseFormGetValues,
  useWatch,
} from "react-hook-form";
import { ProductChemicalOverrideDialog } from "./ProductChemicalOverrideDialog";
import { ConfirmDialog } from "components/ConfirmDialog";

interface ProductChemicalOverrideDataGridProps {
  issues: Issue[];
  control: Control<ProductChemicalInput, any>;
  getValues: UseFormGetValues<ProductChemicalInput>;
}

export type ProductChemicalOverrideRow = Exclude<
  ProductChemicalFormFragment["overrides"],
  null | undefined
>[number] & {
  issues: Issue[];
};

const ProductChemicalOverrideDataGrid: React.FC<
  ProductChemicalOverrideDataGridProps
> = ({ issues, control, getValues }) => {
  const theme = useTheme();

  // Stores relevant data for adding/editing, determines whether override dialog
  // shows
  const [editOverrideData, setEditOverrideData] = useState<{
    override: Partial<ProductChemicalOverrideInput>;
    mode: "add" | "edit";
    index: number;
  }>();

  // Stores relevant data for deleting a component, determines whether delete modal shows
  const [deleteConfirmData, setDeleteConfirmData] = useState<{
    overrideId?: string;
    jurisdiction?: string;
  }>({});

  const { append, remove, update } = useFieldArray({
    control,
    name: "overrides",
  });

  const watchedOverrides = useWatch({
    control,
    name: "overrides",
    defaultValue: getValues().overrides,
  });

  const handleOpenDeleteDialog = (
    row: Partial<ProductChemicalOverrideInput>
  ) => {
    setDeleteConfirmData({
      overrideId: row.id,
      jurisdiction: row.jurisdiction ?? "",
    });
  };
  const handleCloseConfirmDialog = useCallback(() => {
    setDeleteConfirmData({});
  }, []);

  const handleDeleteOverride = useCallback(
    (id: string) => {
      const index = watchedOverrides.findIndex((c) => c.id === id);
      remove(index);
      handleCloseConfirmDialog();
    },
    [handleCloseConfirmDialog, remove, watchedOverrides]
  );

  const handleEditOverride = useCallback(
    (row: Partial<ProductChemicalOverrideInput>) => {
      const index = watchedOverrides.findIndex((c) => c.id === row.id) ?? -1;
      setEditOverrideData({ override: row, mode: "edit", index });
    },
    [watchedOverrides]
  );

  const handleAddOverride = useCallback(() => {
    setEditOverrideData({
      override: {},
      mode: "add",
      index: watchedOverrides.length || 0,
    });
  }, [watchedOverrides]);

  const overridesWithIssues = useMemo(() => {
    return watchedOverrides.map((o) => ({
      ...o,
      issues: issues.filter((i) => i.modelId === o.id),
    }));
  }, [watchedOverrides, issues]);

  const columns = useMemo<GridColDef<ProductChemicalOverrideRow>[]>(
    () => [
      {
        field: "jurisdiction",
        headerName: "State",
        flex: 1,
      },
      {
        field: "storageType",
        headerName: "Storage Type",
        flex: 1,
        renderCell: (params) => prettyPrintEnumValue(params.row.storageType),
      },
      {
        field: "issues",
        headerName: "Issues",
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell(params) {
          return <IssueCount issueCount={params.row.issues?.length} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params) => [
          <Tooltip title="Edit" key={1}>
            <GridActionsCellItem
              onClick={() => handleEditOverride(params.row)}
              label="Edit Chemical"
              icon={<EditIcon />}
            />
          </Tooltip>,
          <Tooltip title="Delete" key={2}>
            <GridActionsCellItem
              onClick={() => handleOpenDeleteDialog(params.row)}
              label="Delete Chemical"
              icon={<DeleteIcon />}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleEditOverride]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
            State Overrides
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" size="small" onClick={handleAddOverride}>
            Add State Override
          </Button>
        </Box>
      </Stack>
      <DataGrid
        autoHeight
        rows={overridesWithIssues}
        columns={columns}
        slots={{
          noRowsOverlay: (props) => (
            <NoRowsOverlay
              {...props}
              onButtonClick={handleAddOverride}
              message="There are no state level overrides for product storage fields"
              buttonText="Add state override"
            />
          ),
        }}
      />
      <ProductChemicalOverrideDialog
        key={editOverrideData?.override.id ?? ""}
        mode={editOverrideData?.mode ?? "add"}
        initialIssues={issues}
        index={editOverrideData?.index ?? -1}
        open={!!editOverrideData}
        productChemical={getValues()}
        onClose={() => setEditOverrideData(undefined)}
        onSave={(override) => {
          console.log({ override });
          if (editOverrideData?.mode === "add") {
            append(override, {
              shouldFocus: true,
            });
          } else {
            if ((editOverrideData?.index ?? -1) >= 0) {
              update(editOverrideData?.index ?? -1, override);
            }
          }
          setEditOverrideData(undefined);
        }}
      />
      <ConfirmDialog
        open={!!deleteConfirmData.overrideId}
        onClose={handleCloseConfirmDialog}
        onConfirm={() =>
          handleDeleteOverride(deleteConfirmData.overrideId ?? "")
        }
        msg={`Are you sure you want to delete ${
          deleteConfirmData.jurisdiction
            ? `the override for ${deleteConfirmData.jurisdiction}`
            : "this"
        } ?`}
      />
    </>
  );
};

export default ProductChemicalOverrideDataGrid;
