import { ApolloError } from "@apollo/client/errors";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactsIcon from "@mui/icons-material/Contacts";
import DeleteIcon from "@mui/icons-material/Delete";
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HelpIcon from "@mui/icons-material/Help";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { FrontChat } from "components/FrontChat";
import { LeftNavDivider } from "components/LeftNavDivider";
import { Permission } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Appcues } from "../../components/Appcues";
import { Layout } from "../../components/Layout";
import { NavItem } from "../../components/LeftNavDrawer";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useStaffNavItems } from "../Staff";

export function Customer() {
  return (
    <>
      <Appcues />
      <FrontChat />
      <CustomerLayout />
    </>
  );
}

// We need to pass the error out of this hook because we do
// different things with it based on context:
// * When we display these nav items in context of a 404/500 page,
//   we don't want to throw an error (infinite loops result)
// * When we display these nav items in context of the customer
//   layout, we do want to throw an error (to show an error page)
export function useCustomerNavItems(): {
  error: ApolloError | undefined;
  navItems: NavItem[];
} {
  const { isStaff, isPartner } = useCurrentUser();
  const { hasPermissions } = useAuthorization();
  const { error, tenant } = useTenant();

  const { featureFlags: flags } = useFeatureFlags();
  const hasWaste = flags?.["module-rcra"];
  const staffNavItems = useStaffNavItems();
  const navItems = useMemo<NavItem[]>(() => {
    const navItems: NavItem[] = [];

    if (isPartner) {
      navItems.push({
        kind: "Standard",
        text: "Organizations",
        Icon: GroupsRoundedIcon,
        path: `o/${tenant?.id}/organizations`,
      });
    }

    navItems.push(
      ...(hasPermissions([Permission.RouteFacilities])
        ? [
            {
              kind: "Standard" as const,
              text: "Facilities",
              Icon: FactoryIcon,
              path: `o/${tenant?.id}/facilities`,
            },
          ]
        : []),
      ...(hasPermissions([Permission.RouteContacts])
        ? [
            {
              kind: "Standard" as const,
              text: "Contacts",
              Icon: ContactsIcon,
              path: `o/${tenant?.id}/contacts`,
            },
          ]
        : []),
      ...(hasPermissions([Permission.RouteDocuments])
        ? [
            {
              kind: "Standard" as const,
              text: "Documents",
              Icon: ArticleIcon,
              path: `o/${tenant?.id}/documents`,
            },
          ]
        : []),
      ...(hasPermissions([Permission.RouteEpcraCredentials])
        ? [
            {
              kind: "Standard" as const,
              text: "Credentials",
              Icon: BadgeIcon,
              path: `o/${tenant?.id}/credentials`,
            },
          ]
        : []),
      {
        kind: "Standard",
        text: "Tasks",
        Icon: AssignmentTurnedInIcon,
        path: `o/${tenant?.id}/tasks`,
      }
    );

    navItems.push({
      kind: "CustomElement",
      customElement: <LeftNavDivider />,
    });

    navItems.push(
      ...(hasPermissions([Permission.RouteInsights])
        ? [
            {
              kind: "Standard" as const,
              text: "Insights",
              Icon: MapIcon,
              path: `o/${tenant?.id}/insights`,
            },
          ]
        : []),
      {
        kind: "Standard",
        text: "Chemicals",
        path: `o/${tenant?.id}/chemicals`,
        Icon: WarehouseIcon,
      }
    );

    navItems.push({
      kind: "Standard",
      text: "Waste",
      path: `o/${tenant?.id}/waste`,
      Icon: DeleteIcon,
    });

    navItems.push({
      kind: "CustomElement",
      customElement: <LeftNavDivider />,
    });

    if (hasPermissions([Permission.WriteAllUserTenant])) {
      navItems.push({
        kind: "Standard",
        text: "Account",
        Icon: SettingsIcon,
        path: `o/${tenant?.id}/account/users`,
        isDisabled: false,
      });
    }

    if (flags?.["amazon-notion-training"]) {
      navItems.push({
        kind: "Standard",
        text: "Help",
        Icon: HelpIcon,
        path: "",
        isDisabled: true,
      });
    }

    if (isStaff) {
      navItems.push(...staffNavItems);
    }

    return navItems;
  }, [
    flags,
    hasPermissions,
    hasWaste,
    isPartner,
    isStaff,
    staffNavItems,
    tenant?.id,
  ]);

  return { error, navItems };
}

export function CustomerLayout() {
  const { error, navItems } = useCustomerNavItems();

  if (error) {
    throw error;
  }

  return (
    <Layout navItems={navItems}>
      <Outlet />
    </Layout>
  );
}
