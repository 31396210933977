import { useCallback } from "react";
import { useQueryParams } from "./useQueryParams";

/**
 * A custom React hook that provides navigation functionality with Omnisearch support.
 *
 * @template T - A type extending Record<string, any> to represent the default filters object.
 * @param {string} to - The destination path to navigate to.
 * @param {T} filters - An object containing the default filter values.
 * @returns {(fn: (filters?: T) => { [key: string]: any }) => void} A function that, when called,
 *          will navigate to the specified path with updated Omnisearch parameters.
 *
 * @example
 * // Usage example:
 * const navigate = useNavigateWithOmnisearch('/echo', { programArea: 'Air' });
 *
 * // Navigate with default filters
 * navigate((f) => f); // /echo?omnisearch=programArea:Air
 *
 * // Navigate with custom filters
 * navigate((f) => ({
 *   ...f,
 *   registryId: '1234567890',
 * }));
 *
 * // This will result in a URL like:
 * // /echo?omnisearch=programArea:Air%20registryId:1234567890
 *
 * // Navigate with no default filters
 * navigate(() => ({
 *   registryId: '1234567890',
 * })); // /echo?omnisearch=registryId:1234567890
 */
export function useNavigateWithOmnisearch<T extends Record<string, any>>(
  to: string,
  filters: T
): (fn: (filters?: T) => { [key: string]: any }) => void {
  const { navigateWithFilters } = useQueryParams();

  return useCallback(
    /**
     * @param {(filters?: T) => { [key: string]: any }} fn - A function that takes the current
     *        default filters (optional) and returns an object representing the omnisearch query.
     */
    (fn: (filters?: T) => { [key: string]: any }) => {
      const newFilters = fn(filters);
      navigateWithFilters(to, (f) => {
        // Convert the newFilters object into an Omnisearch string
        f.omnisearch = Object.entries(newFilters)
          .filter(([_, value]) => value !== undefined)
          .map(([key, value]) => {
            return `${key}:${value}`;
          })
          .join(" ");
      });
    },
    [filters, navigateWithFilters, to]
  );
}
