import { UnitType } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { getAmountCodes } from "encamp-shared/src/constants/storageAmounts";

pluralize.addSingularRule(/feet$/i, "foot");
pluralize.addPluralRule(/feet$/i, "feet");

/**
 * Formats a quantity with its unit for display.
 * @param amount - The numeric quantity
 * @param unit - The unit type
 * @param abbreviateUnit - Whether to use abbreviated unit labels
 * @param includeLabel - Whether to include the unit label in the output
 * @returns Formatted string representation of the quantity
 */
export function prettyPrintQuantity(
  amount?: number | null,
  unit?: UnitType | null,
  abbreviateUnit = false,
  includeLabel = true
): string {
  if (!amount && amount !== 0) return "Not set";

  if (!includeLabel) {
    return `${amount.toLocaleString()}`;
  }

  const unitLabel = getUnitLabel({ unit, amount, abbreviateUnit });

  return `${amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })} ${unitLabel}`;
}

/**
 * Formats a large quantity with its unit for display.
 * @param amount - The numeric quantity
 * @param unit - The unit type
 * @param abbreviateUnit - Whether to use abbreviated unit labels
 * @param includeLabel - Whether to include the unit label in the output
 * @returns Formatted string representation of the quantity
 */
export function prettyPrintLargeQuantity(
  amount?: number | null,
  unit?: UnitType | null,
  abbreviateUnit = false,
  includeLabel = true
): string {
  if (!amount && amount !== 0) return "Not set";

  const abbreviations = [
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
    { value: 1, symbol: "" },
  ];

  let formattedAmount = "";
  for (const { value, symbol } of abbreviations) {
    if (Math.abs(amount) >= value) {
      formattedAmount = (amount / value).toFixed(2);

      // Ensure we have no more than 3 numeric characters
      if (Math.abs(parseFloat(formattedAmount)) >= 100) {
        formattedAmount = Math.round(parseFloat(formattedAmount)).toString();
      } else if (Math.abs(parseFloat(formattedAmount)) >= 10) {
        formattedAmount = parseFloat(formattedAmount).toFixed(1);
      }

      // Remove trailing zeros and decimal point if necessary
      formattedAmount = formattedAmount.replace(/\.0+$/, "");
      if (formattedAmount.includes(".")) {
        formattedAmount = formattedAmount.replace(/0+$/, "");
      }

      // Add a space before the symbol if it's not empty
      formattedAmount += symbol ? ` ${symbol}` : "";
      break;
    }
  }

  if (!formattedAmount) {
    formattedAmount = amount.toString();
  }

  if (!includeLabel) {
    return formattedAmount;
  }

  const unitLabel = getUnitLabel({ unit, amount, abbreviateUnit });

  return `${formattedAmount} ${unitLabel}`;
}

/**
 * Formats an amount code with its unit for display.
 * @param state - The state of the amount code
 * @param rawAmountCode - The raw amount code
 * @param unit - The unit type
 * @param abbreviateUnit - Whether to use abbreviated unit labels
 * @param includeLabel - Whether to include the unit label in the output
 * @returns Formatted string representation of the amount code
 */
export function prettyPrintAmountCode(
  state?: string | null,
  rawAmountCode?: number | null,
  unit?: UnitType | null,
  abbreviateUnit = false,
  includeLabel = true
) {
  if (!rawAmountCode) return "Not set";

  const codes = getAmountCodes(state ?? "");
  const amountCode = codes[rawAmountCode.toString()] ?? "";

  if (!includeLabel) {
    return `${amountCode}`;
  }

  const unitLabel = getUnitLabel({ unit, abbreviateUnit });

  return `${amountCode} ${unitLabel}`;
}

/**
 * Gets the unit label based on the unit type and amount.
 * @param unit - The unit type
 * @param amount - The numeric quantity
 * @param abbreviateUnit - Whether to use abbreviated unit labels
 * @returns The unit label
 */
function getUnitLabel({
  unit,
  amount,
  abbreviateUnit = false,
}: {
  unit?: UnitType | null;
  amount?: number | null;
  abbreviateUnit?: boolean;
}) {
  if (abbreviateUnit) {
    return unit ? translateUnitToLabel(unit) : "(No unit)";
  }

  return unit
    ? pluralize(prettyPrintEnumValue(unit), amount ?? undefined)
    : "(No unit)";
}

/**
 * Translates a UnitType to its abbreviated label.
 * @param unit - The unit type to translate
 * @returns The abbreviated label for the given unit
 * @throws Error if an unknown unit type is provided
 */
export function translateUnitToLabel(unit: UnitType): string {
  switch (unit) {
    case UnitType.Barrels:
      return "bbls";
    case UnitType.CubicFeet:
      return "cu. ft";
    case UnitType.Gallons:
      return "gals";
    case UnitType.Kilograms:
      return "kg";
    case UnitType.Liters:
      return "L";
    case UnitType.MetricTons:
      return "MT";
    case UnitType.Pounds:
      return "lbs";
    case UnitType.Tons:
      return "T";
    default:
      throw new Error(`Unknown unit type: ${unit}`);
  }
}
