import { useQuery } from "@apollo/client";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Grid,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { gql } from "generated-graphql";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFacility } from "../../Facility/useFacility";
import { DiffTable } from "../DiffTable";
import { HasNoInsightsId } from "../HasNoInsightsId";
import { LinkedInsightsNotFound } from "../LinkedInsightsNotFound";

const GET_RCRA_INFO_HANDLER = gql(`
  query GetRcraInfoHandler($handlerId: String!, $tenantId: String!) {
    rcraInfoHdHandler(handlerId: $handlerId, tenantId: $tenantId) {
      handlerId
      activityLocation
      seqNumber
      handlerName
      receiveDate
      sourceType
      publicNotes
      fedWasteGenerator
      locationStreetNo
      locationStreet1
      locationStreet2
      locationCity
      locationState
      locationZip
      locationCountry
      locationLatitude
      locationLongitude
      fedWasteGenerator
      currentRecord
      mailStreetNo
      mailStreet1
      mailStreet2
      mailCity
      mailState
      mailZip
      mailCountry
      contactFirstName
      contactLastName
      contactTitle
      contactEmailAddress
      contactPhone
      contactStreetNo
      contactStreet1
      contactStreet2
      contactCity
      contactState
      contactZip
      contactCountry
      subpartPHealthcare
      hdOwnerOperators {
        ownerOperatorName
        ownerOperatorIndicator
        dateBecameCurrent
        email
        phone
        streetNo
        street1
        street2
        city
        state
        zip
        country
      }
    }
  }
`);

interface RcraInfoDetailProps {
  id?: string;
}

const SectionHeader = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Box
      // marginTop={theme.spacing(3)}
      marginBottom={theme.spacing(2.5)}
      marginLeft={theme.spacing(1)}
    >
      <Typography variant="body1" fontWeight={"bold"} height={theme.spacing(3)}>
        {title}
      </Typography>
    </Box>
  );
};

const rowsPerPage = 4;
export const RcraInfoDetail: React.FC<RcraInfoDetailProps> = ({ id }) => {
  const { handlerId: handlerIdFromUrl, tenantId } = useParams<{
    handlerId: string;
    tenantId: string;
  }>();
  const handlerId = id || handlerIdFromUrl;
  const theme = useTheme();
  const { data: facility } = useFacility();

  const [diffMode, setDiffMode] = useState<"updated" | "nodiff">("nodiff");
  const [activeRecordId, setActiveRecordId] = useState<string>("");
  const [page, setPage] = useState(0);

  const epaId = findAlternateId(
    facility?.facility?.facilityAlternateIds ?? [],
    ["EPA"],
    true
  );

  const makeID = (record: any) => {
    const { handlerId, activityLocation, sourceType, seqNumber } = record;
    return `${handlerId}${activityLocation}${sourceType}${seqNumber}`;
  };

  const { loading, error, data } = useQuery(GET_RCRA_INFO_HANDLER, {
    variables: { handlerId: handlerId ?? "", tenantId: tenantId ?? "" },
    onCompleted: () => {
      if (data?.rcraInfoHdHandler.length) {
        setActiveRecordId(makeID(data.rcraInfoHdHandler[0]));
      }
    },
    skip: !handlerId,
  });

  if (epaId?.hasNoId === true) {
    return <HasNoInsightsId type={"EPA ID"} />;
  }

  if (error) {
    return (
      <LinkedInsightsNotFound
        alternateId={handlerId ?? "[No ID set]"}
        type="RCRA Info"
      />
    );
  }

  const activeRecordIndex = data?.rcraInfoHdHandler.findIndex(
    (record: any) => makeID(record) === activeRecordId
  );
  const activeRecord =
    typeof activeRecordIndex === "number"
      ? data?.rcraInfoHdHandler[activeRecordIndex]
      : null;
  const previousRecord =
    activeRecordIndex &&
    activeRecordIndex < (data?.rcraInfoHdHandler?.length ?? 0) - 1
      ? data?.rcraInfoHdHandler[activeRecordIndex + 1]
      : null;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function getOwner(record: any) {
    return (
      record?.hdOwnerOperators?.find(
        (oo: any) => oo.ownerOperatorIndicator === "CURRENT_OWNER"
      ) || {}
    );
  }

  function getOperator(record: any) {
    return (
      record?.hdOwnerOperators?.find(
        (oo: any) => oo.ownerOperatorIndicator === "CURRENT_OPERATOR"
      ) || {}
    );
  }

  //   Diff Table constructors

  const siteDetailsConstructors = [
    {
      fieldLabel: "Facility Name",
      constructor: (record: any) => record?.handlerName || "",
    },
    {
      fieldLabel: "Street Address",
      constructor: (record: any) =>
        `${record?.locationStreetNo || ""} ${record?.locationStreet1 || ""}, ${
          record?.locationCity || ""
        }, ${record?.locationState || ""} ${record?.locationZip || ""}, ${
          record?.locationCountry || ""
        }`,
    },
    {
      fieldLabel: "Mailing Address",
      constructor: (record: any) =>
        `${record?.mailStreetNo || ""} ${record?.mailStreet1 || ""}, ${
          record?.mailCity || ""
        }, ${record?.mailState || ""} ${record?.mailZip || ""}, ${
          record?.mailCountry || ""
        }`,
    },
    {
      fieldLabel: "County",
      constructor: (record: any) => record?.countyCode || "",
    },
    {
      fieldLabel: "Lat / Long",
      constructor: (record: any) =>
        `${record?.locationLatitude || ""}, ${record?.locationLongitude || ""}`,
    },
  ];

  const regulatoryInfoConstructors = [
    {
      fieldLabel: "Federal Generator Status",
      constructor: (record: any) =>
        record?.fedWasteGenerator
          .replace(/_/g, " ")
          .replace(/\b\w/g, (letter: string) => letter.toUpperCase()) || "",
    },
    {
      fieldLabel: "Subpart P Healthcare Facility",
      constructor: (record: any) =>
        record?.subpartPHealthcare === true ? "True" : "False",
    },
  ];

  const siteContactConstructors = [
    {
      fieldLabel: "Name",
      constructor: (record: any) =>
        `${record?.contactFirstName || ""} ${record?.contactLastName || ""}`,
    },
    {
      fieldLabel: "Title",
      constructor: (record: any) => record?.contactTitle || "",
    },
    {
      fieldLabel: "Email",
      constructor: (record: any) => record?.contactEmailAddress || "",
    },
    {
      fieldLabel: "Phone",
      constructor: (record: any) => record?.contactPhone || "",
    },
    {
      fieldLabel: "Address",
      constructor: (record: any) =>
        `${record?.contactStreetNo || ""} ${record?.contactStreet1 || ""}, ${
          record?.contactCity || ""
        }, ${record?.contactState || ""} ${record?.contactZip || ""}, ${
          record?.contactCountry || ""
        }`,
    },
  ];

  const ownerDataConstructors = [
    {
      fieldLabel: "Name",
      constructor: (record: any) => record?.ownerOperatorName || "",
    },
    {
      fieldLabel: "Email",
      constructor: (record: any) => record?.email || "",
    },
    {
      fieldLabel: "Phone",
      constructor: (record: any) => record?.phone || "",
    },
    {
      fieldLabel: "Address",
      constructor: (record: any) =>
        `${record?.streetNo || ""} ${record?.street1 || ""}, ${
          record?.city || ""
        }, ${record?.state || ""} ${record?.zip || ""}, ${
          record?.country || ""
        }`,
    },
    {
      fieldLabel: "Date Became Current",
      constructor: (record: any) =>
        record?.dateBecameCurrent
          ? new Date(record.dateBecameCurrent).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
  ];

  const operatorDataConstructors = [
    {
      fieldLabel: "Name",
      constructor: (record: any) => record?.ownerOperatorName || "",
    },
    {
      fieldLabel: "Email",
      constructor: (record: any) => record?.email || "",
    },
    {
      fieldLabel: "Phone",
      constructor: (record: any) => record?.phone || "",
    },
    {
      fieldLabel: "Address",
      constructor: (record: any) =>
        `${record?.streetNo || ""} ${record?.street1 || ""}, ${
          record?.city || ""
        }, ${record?.state || ""} ${record?.zip || ""}, ${
          record?.country || ""
        }`,
    },
    {
      fieldLabel: "Date Became Current",
      constructor: (record: any) =>
        record?.dateBecameCurrent
          ? new Date(record.dateBecameCurrent).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
  ];

  return (
    <Box
      marginTop={theme.spacing(3)}
      paddingBottom={theme.spacing(3)}
      marginLeft={theme.spacing(3)}
      marginRight={theme.spacing(3)}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box>
          <TableContainer sx={{ border: `${theme.palette.divider} solid 1px` }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Notification Date</TableCell>
                  <TableCell>Reason for Submittal</TableCell>
                  <TableCell>Public Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.rcraInfoHdHandler
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((record: any) => (
                    <TableRow
                      key={record.receiveDate}
                      selected={makeID(record) === activeRecordId}
                      onClick={() => setActiveRecordId(makeID(record))}
                    >
                      <TableCell>
                        {new Date(record.receiveDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>{record.sourceType}</TableCell>
                      <TableCell>{record.publicNotes}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data?.rcraInfoHdHandler.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
          <Box
            marginTop={theme.spacing(4)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h6" align="right">
                {activeRecord &&
                  new Date(activeRecord.receiveDate).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    }
                  )}{" "}
                - {activeRecord?.sourceType}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" marginRight={theme.spacing(1)}>
                Compare with previous notification
              </Typography>
              <Switch
                checked={diffMode === "updated"}
                onChange={(_, checked) =>
                  setDiffMode(checked ? "updated" : "nodiff")
                }
              />
            </Box>
          </Box>
        </Box>
      )}

      {loading ? (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={6}>
            <SectionHeader title="Site Details" />
            <Skeleton variant="rectangular" height={theme.spacing(20)} />
          </Grid>
          <Grid item xs={6}>
            <SectionHeader title="Regulatory Info" />
            <Skeleton variant="rectangular" height={theme.spacing(20)} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={6}>
            <SectionHeader title="Site Details" />
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontWeight: "bold",
                        border: `1px solid ${theme.palette.divider}`,
                        borderRight: `1px solid ${theme.palette.divider}`,
                        borderBottom: `0px`,
                        width: "20%",
                      }}
                    >
                      EPA ID
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        borderBottom: `0px`,
                      }}
                    >
                      {activeRecord?.handlerId}
                      <Tooltip title="View EPA Detail Page in new tab">
                        <IconButton
                          component="a"
                          href={`https://enviro.epa.gov/envirofacts/rcrainfo/facility?handlerId=${activeRecord?.handlerId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <DiffTable
              baseRecord={activeRecord}
              comparisonRecord={previousRecord}
              fieldConstructors={siteDetailsConstructors}
              diffMode={diffMode}
              firstCellWidth="20%"
            />
          </Grid>
          <Grid item xs={6}>
            <SectionHeader title="Regulatory Info" />
            <DiffTable
              baseRecord={activeRecord}
              comparisonRecord={previousRecord}
              fieldConstructors={regulatoryInfoConstructors}
              diffMode={diffMode}
            />
          </Grid>
        </Grid>
      )}

      {loading ? (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          {[...Array(4)].map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton variant="rectangular" height={theme.spacing(20)} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={4}>
            <SectionHeader title="Site Contact" />
            <DiffTable
              baseRecord={activeRecord}
              comparisonRecord={previousRecord}
              fieldConstructors={siteContactConstructors}
              diffMode={diffMode}
              firstCellWidth="25%"
            />
          </Grid>
          <Grid item xs={4}>
            <SectionHeader title="Owner" />
            <DiffTable
              baseRecord={getOwner(activeRecord)}
              comparisonRecord={getOwner(previousRecord)}
              fieldConstructors={ownerDataConstructors}
              diffMode={diffMode}
              firstCellWidth="25%"
            />
          </Grid>
          <Grid item xs={4}>
            <SectionHeader title="Operator" />
            <DiffTable
              baseRecord={getOperator(activeRecord)}
              comparisonRecord={getOperator(previousRecord)}
              fieldConstructors={operatorDataConstructors}
              diffMode={diffMode}
              firstCellWidth="25%"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
