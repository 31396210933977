import { Stack } from "@mui/material";
import { teal } from "@mui/material/colors";
import { BarChart } from "components/Dashboard/BarChart";
import ChartLegend from "components/Dashboard/ChartLegend";
import { InsightPenaltiesQuery } from "generated-graphql/graphql";
import { useMemo } from "react";
import formatCurrency from "util/formatCurrency";
import {
  PriorityChartColors,
  ProgramAreaColors,
  generateQuarters,
  getPriorityChartColorByIndex,
} from "util/insights";
import { useOverviewState } from "../OverviewContext";

export const allowedPenaltiesBarChartTypes = [
  "byProgramArea",
  "byCostFacility",
  "byQuarter",
  "byQuarterProgramArea",
] as const;

export type PenaltiesBarChartType =
  (typeof allowedPenaltiesBarChartTypes)[number];

export type PenaltiesBarChartProps = {
  graphType: PenaltiesBarChartType;
  data: InsightPenaltiesQuery["insightsPenalties"] | undefined;
};

export const PenaltiesBarChart = ({
  graphType,
  data,
}: PenaltiesBarChartProps) => {
  if (!data) return null;

  switch (graphType) {
    case "byProgramArea":
      return <ByProgramArea programAreas={data?.byProgramArea} />;
    case "byCostFacility":
      return <ByCostFacility facilities={data?.byFacility} />;
    case "byQuarter":
      return <ByQuarter quarters={data?.byQuarter} />;
    case "byQuarterProgramArea":
      return <ByQuarterProgramArea quarters={data?.byQuarterByProgramArea} />;
    default:
      return null;
  }
};

const ByProgramArea = ({
  programAreas = [],
}: {
  programAreas: InsightPenaltiesQuery["insightsPenalties"]["byProgramArea"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  return (
    <BarChart
      onItemClick={(event, item) => {
        const fine = programAreas[item.dataIndex];
        drillDownToEcho((f) => ({
          ...f,
          programAreaPenalty: fine.label,
        }));
      }}
      dataset={
        programAreas.length ? programAreas : [{ cost: 0, label: "No data" }]
      }
      series={[
        {
          type: "bar",
          dataKey: "cost",
          valueFormatter: (val) => (val ? `$${val.toLocaleString()}` : "$0"),
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "label",
          colorMap: {
            type: "ordinal",
            values: Object.keys(ProgramAreaColors),
            colors: Object.values(ProgramAreaColors),
          },
          categoryGapRatio: 0.65,
        },
      ]}
      yAxis={[
        { scaleType: "sqrt", valueFormatter: (val) => formatCurrency(val) },
      ]}
      barLabel={({ value }) => (value ? formatCurrency(value) : "$0")}
      barLabelColor="white"
    />
  );
};

const ByCostFacility = ({
  facilities = [],
  limit = 5,
}: {
  facilities: InsightPenaltiesQuery["insightsPenalties"]["byFacility"];
  limit?: number;
}) => {
  const { drillDownToEcho } = useOverviewState();
  const dataset = useMemo(
    () =>
      facilities
        // Do not show facilities with a cost of 0
        .filter((f) => f.cost > 0)
        .slice(0, limit),
    [facilities, limit]
  );
  return (
    <Stack display="flex" direction="row" height="100%">
      <BarChart
        onItemClick={(event, item) => {
          const fine = facilities[item.dataIndex];
          drillDownToEcho(() => ({
            registryId: fine.frsId,
          }));
        }}
        dataset={dataset.length ? dataset : [{ cost: 0, label: "No data" }]}
        series={[
          {
            type: "bar",
            dataKey: "cost",
            valueFormatter: (val) => (val ? `$${val.toLocaleString()}` : "$0"),
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            categoryGapRatio: 0.4,
            colorMap: {
              type: "ordinal",
              colors: PriorityChartColors,
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "sqrt",
            valueFormatter: (val) => formatCurrency(val),
          },
        ]}
        barLabel={({ value }) => (value ? formatCurrency(value) : "$0")}
        barLabelColor="white"
        indexAxisLabelProperty={dataset.length ? "name" : undefined}
        sx={{ flex: 1 }}
      />
      <ChartLegend
        items={dataset.map((d, index) => ({
          color: getPriorityChartColorByIndex(index),
          value: `${index + 1}. ${d.label}`,
        }))}
        sx={{ flex: 1, maxWidth: "35%" }}
      />
    </Stack>
  );
};

const ByQuarter = ({
  quarters,
}: {
  quarters: InsightPenaltiesQuery["insightsPenalties"]["byQuarter"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  const processedData = generateQuarters().map((label) => {
    const data = quarters.find((q) => q.label === label);
    return data || { label, cost: 0 };
  });

  return (
    <BarChart
      onItemClick={(event, barItemIdentifier) => {
        const clickedQuarter = processedData[barItemIdentifier.dataIndex];
        const originalQuarter = quarters.find(
          (q) => q.label === clickedQuarter.label
        );
        drillDownToEcho((f) => ({
          ...f,
          hasPenalties: true,
        }));
      }}
      dataset={processedData}
      series={[
        {
          type: "bar",
          dataKey: "cost",
          color: teal[200],
          valueFormatter: (val) => formatCurrency(val ?? undefined),
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "label",
          categoryGapRatio: 0.2,
          valueFormatter(value, context) {
            if (context.location === "tick") {
              return value.split(" ")[0] === "Q1"
                ? value.split(" ").join("\n")
                : value.split(" ")[0];
            }
            return value;
          },
        },
      ]}
      yAxis={[
        {
          scaleType: "sqrt",
          valueFormatter: formatCurrency,
        },
      ]}
    />
  );
};

const ByQuarterProgramArea = ({
  quarters,
}: {
  quarters: InsightPenaltiesQuery["insightsPenalties"]["byQuarterByProgramArea"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  const programAreas = Array.from(
    new Set(quarters.flatMap((q) => q.programAreas.flatMap((pa) => pa.label)))
  ).sort();

  const processedData = generateQuarters().map((quarter) => {
    const programAreas = Object.keys(ProgramAreaColors).reduce((acc, pa) => {
      const thisQuarter = quarters
        .find((q) => q.quarter === quarter)
        ?.programAreas.find((p) => p.label === pa);
      acc[pa.toLowerCase()] = thisQuarter?.cost ?? null;
      return acc;
    }, {} as Record<string, number | null>);

    return {
      ...programAreas,
      quarter: quarter,
    };
  });

  return (
    <>
      <ChartLegend
        config={{ direction: "row", position: "above" }}
        items={programAreas.map((pa) => ({
          value: pa,
          color: ProgramAreaColors[pa],
        }))}
        sx={{ paddingLeft: 6, position: "absolute" }}
      />
      <BarChart
        onItemClick={(event, barItemIdentifier) => {
          const clickedQuarter = processedData[barItemIdentifier.dataIndex];
          if (clickedQuarter) {
            drillDownToEcho((f) => ({
              ...f,
              programAreaPenalty:
                barItemIdentifier.seriesId !== "Multiple Programs"
                  ? barItemIdentifier.seriesId
                  : undefined,
            }));
          }
        }}
        dataset={processedData}
        series={Object.keys(ProgramAreaColors).map((programArea) => ({
          type: "bar",
          stack: "total",
          id: programArea,
          dataKey: programArea.toLowerCase(),
          color: ProgramAreaColors[programArea],
          valueFormatter: (value) =>
            programArea === "Multiple Programs"
              ? `Multi-program facilities (${formatCurrency(
                  value ?? undefined
                )})`
              : `${programArea} facilities (${formatCurrency(
                  value ?? undefined
                )})`,
        }))}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "quarter",
            categoryGapRatio: 0.2,
            valueFormatter(value, context) {
              if (context.location === "tick") {
                return value.split(" ")[0] === "Q1"
                  ? value.split(" ").join("\n")
                  : value.split(" ")[0];
              }
              return value;
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "sqrt",
            valueFormatter: formatCurrency,
          },
        ]}
        stackedTooltipTotalFormatter={(total) =>
          `Total fines: ${formatCurrency(total)}`
        }
      />
    </>
  );
};
