import {
  FacilityRegulatoryStatus,
  WasteManifestSource,
} from "generated-graphql/graphql";
import { wasteGeneratorStatusToLabel } from "util/constants";

export function wasteManifestSourceToLabel(
  source?: WasteManifestSource | null
) {
  switch (source) {
    case WasteManifestSource.CleanEarth:
      return "Clean Earth";
    case WasteManifestSource.UsEcology:
      return "US Ecology";
    case WasteManifestSource.Republic:
      return "Republic";
    case WasteManifestSource.CleanHarbors:
      return "Clean Harbors";
    case WasteManifestSource.Wm:
      return "Waste Management";
    case WasteManifestSource.EManifest:
      return "e-Manifest";
    default:
      return "";
  }
}

export function getRegulatoryStatusDiscrepancies(
  regulatoryStatus: FacilityRegulatoryStatus
) {
  const { rcraInfo, eManifest, vendor } = regulatoryStatus;

  const discrepancies: string[] = [];

  if (rcraInfo !== eManifest) {
    discrepancies.push(
      `There is a discrepancy between the last reported generator status in RCRAInfo and the eManifest calculated generator status.\n    RCRAInfo: ${wasteGeneratorStatusToLabel(
        rcraInfo
      )}\n    eManifest: ${wasteGeneratorStatusToLabel(eManifest)}`
    );
  }

  if (rcraInfo !== vendor) {
    discrepancies.push(
      `There is a discrepancy between the last reported generator status in RCRAInfo and the vendor calculated generator status.\n    RCRAInfo: ${wasteGeneratorStatusToLabel(
        rcraInfo
      )}\n    Vendor: ${wasteGeneratorStatusToLabel(vendor)}`
    );
  }

  if (eManifest !== vendor) {
    discrepancies.push(
      `There is a discrepancy between the eManifest and vendor calculated generator statuses.\n    eManifest: ${wasteGeneratorStatusToLabel(
        eManifest
      )}\n    Vendor: ${wasteGeneratorStatusToLabel(vendor)}`
    );
  }

  return discrepancies;
}
