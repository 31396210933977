import { Component, ErrorInfo } from "react";

import { GridProps, Stack } from "@mui/material";
import { ReactNode } from "react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { grey } from "@mui/material/colors";
interface ErrorTileProps extends GridProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export class ErrorTile extends Component<ErrorTileProps, ErrorBoundaryState> {
  constructor(props: ErrorTileProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
          minHeight="12rem"
        >
          <BrokenImageIcon fontSize="large" sx={{ color: grey[400] }} />
        </Stack>
      );
    }

    return this.props.children;
  }
}
