import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Grid,
  GridProps,
  IconButton,
  Paper,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";
import { BigTooltip } from "components/BigTooltip";
import { ErrorTile } from "./ErrorTile";
import { PropsWithChildren, ReactNode } from "react";

export function DashboardTile(props: PropsWithChildren<GridProps>) {
  const theme = useTheme();
  return (
    <Grid item {...props}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
        component={Stack}
        paddingX={theme.spacing(3)}
        paddingY={theme.spacing(2)}
      >
        <ErrorTile>
          <Stack
            spacing={2}
            sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            {props.children}
          </Stack>
        </ErrorTile>
      </Paper>
    </Grid>
  );
}

const Header = ({
  title,
  infoTooltip,
  children,
}: PropsWithChildren<{
  title: string;
  infoTooltip?: ReactNode;
}>) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      gap={theme.spacing(2)}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" sx={{ whiteSpace: "nowrap" }}>
          {title}
        </Typography>
        {infoTooltip && (
          <BigTooltip title={infoTooltip}>
            <IconButton size="small">
              <InfoOutlined color="disabled" />
            </IconButton>
          </BigTooltip>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

DashboardTile.Header = Header;

const ContentArea = (props: PropsWithChildren<StackProps>) => {
  return (
    <Stack
      {...props}
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        ...props.sx,
      }}
    >
      {props.children}
    </Stack>
  );
};

DashboardTile.ContentArea = ContentArea;

const Footer = (props: PropsWithChildren<StackProps>) => {
  return (
    <Stack
      sx={{
        marginTop: "auto",
        paddingTop: (theme) => theme.spacing(2),
      }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};

DashboardTile.Footer = Footer;
