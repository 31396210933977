import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ChemicalPicker } from "components/ChemicalPicker";
import { Dialog } from "components/Dialog";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { CatalogLink } from "components/CatalogLink";
import { PressurePicker } from "components/PressurePicker";
import { SearchSelect } from "components/SearchSelect";
import {
  STORAGE_TYPE_DESCRIPTIONS,
  StorageType,
} from "encamp-shared/src/constants/tierii";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  ChemicalPickerFragment,
  Pressure,
  ProductChemicalInput,
  Temperature,
  UnitType,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";
import { useProductChemicalInputValidation } from "../../Report/validationHooks/useProductChemicalInputValidation";
import { ProductChemicalRow } from "./ProductChemicalDataGrid";
import ProductChemicalOverrideDataGrid from "./ProductChemicalOverrideDataGrid";

gql(`
  fragment ProductChemicalForm on ProductChemical {
    id
    amount
    chemicalId
    pressure
    productId
    storageType
    storageTypeDescription
    temperature
    unit
    overrides {
      id
      jurisdiction
      storageType
      storageTypeDescription
      temperature
      pressure
    }
    chemical {
      ...ChemicalPicker
    }
  }
`);

type ProductChemicalFormProps = {
  productName: string;
  productId: string;
  productChemical?: ProductChemicalRow;
  usedChemicalIds: string[];
  onClose: () => void;
  onProductChemicalSaved: (productChemical: ProductChemicalRow) => void;
  open: boolean;
  creatingNewProduct: boolean;
};

const ProductChemicalForm: React.FC<ProductChemicalFormProps> = (props) => {
  const {
    productId,
    productName,
    productChemical,
    usedChemicalIds,
    onClose,
    onProductChemicalSaved,
  } = props;
  const alerts = useAlerts();
  const { tenantId } = useParams();
  const theme = useTheme();

  const [selectedChemical, setSelectedChemical] =
    useState<ChemicalPickerFragment | null>(
      props.productChemical?.chemical ?? null
    );

  const defaultValues: ProductChemicalInput = useMemo(
    () => ({
      storageType: props.productChemical?.storageType ?? "",
      storageTypeDescription:
        props.productChemical?.storageTypeDescription ?? "",
      amount: props.productChemical?.amount ?? null,
      chemicalId: props.productChemical?.chemicalId ?? null,
      productId,
      unit: props.productChemical?.unit ?? UnitType.Pounds,
      pressure: props.productChemical?.pressure ?? Pressure.AmbientPressure,
      temperature:
        props.productChemical?.temperature ?? Temperature.AmbientTemperature,
      overrides: props.productChemical?.overrides ?? [],
    }),
    [productId, props.productChemical]
  );

  const form = useValidatingForm<ProductChemicalInput>(
    defaultValues,
    productChemical?.issues ?? [],
    useProductChemicalInputValidation()
  );

  const { watch, control, handleSubmit, issues, getValues } = form;

  const storageType = watch("storageType");

  useEffect(() => {
    setSelectedChemical(productChemical?.chemical ?? null);
  }, [productChemical?.chemical]);

  const onSubmit: SubmitHandler<ProductChemicalInput> = useCallback(
    async (data) => {
      if (selectedChemical == null) return;

      const { amount, pressure, storageType, temperature, unit, overrides } =
        data;

      if (amount == null || unit == null) return;

      const amountNum = Number(amount);
      if (Number.isNaN(amountNum)) {
        alerts.error(`Amount ${amount} is not a valid number`);
        return;
      }

      onProductChemicalSaved({
        id: productChemical?.id ?? uuid(),
        amount: amountNum,
        pressure,
        chemicalId: selectedChemical.id,
        productId,
        storageType,
        storageTypeDescription:
          data.storageType === StorageType.Other
            ? data.storageTypeDescription
            : null,
        temperature,
        unit,
        chemical: selectedChemical,
        overrides,
        issues: form.issues,
      });
      onClose();
    },
    [
      selectedChemical,
      onProductChemicalSaved,
      productChemical,
      productId,
      form.issues,
      onClose,
      alerts,
    ]
  );

  return (
    <Dialog open={props.open} onClose={onClose} fullWidth maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {productChemical ? "Edit" : "Add"} Chemical{" "}
            {productChemical ? "for" : "to"} {productName}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={theme.spacing(2)}>
              <Grid item xs={12} marginTop={theme.spacing(1)}>
                <Controller
                  name="chemicalId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ChemicalPicker
                      {...field}
                      {...fieldState}
                      value={productChemical?.chemical}
                      defaultSearchTerm={`tenantId:${tenantId}`}
                      onChange={(chem) => {
                        field.onChange(chem?.id ?? null);
                        setSelectedChemical(chem);
                      }}
                      disabledChemicalsById={usedChemicalIds}
                      disabledOptionLabel="Already assigned to this product"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name={`amount`}
                  control={control}
                  label="Amount"
                  type="number"
                  textFieldProps={{ type: "numeric", required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  control={control}
                  name="unit"
                  label="Unit"
                  selectItems={Object.values(UnitType).map((unit) => ({
                    display: prettyPrintEnumValue(unit),
                    value: unit,
                  }))}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
                  Storage
                </Typography>
                <Grid item xs={12} spacing={theme.spacing(2)}>
                  <Controller
                    name={`storageType`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <SearchSelect
                          {...field}
                          {...fieldState}
                          label="Storage Type"
                          options={Object.values(STORAGE_TYPE_DESCRIPTIONS)}
                          getOptionLabel={(opt: any) =>
                            prettyPrintEnumValue(opt)
                          }
                          required
                        />
                      </FormControl>
                    )}
                  />
                </Grid>

                {storageType === StorageType.Other ? (
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name={`storageTypeDescription`}
                      label="Storage Type Description"
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12}>
                  <PressurePicker />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={`temperature`}
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id={`${field.name}-label`}>
                          Temperature
                        </InputLabel>
                        <Select
                          {...field}
                          label="Temperature"
                          labelId={`${field.name}-label`}
                        >
                          {Object.values(Temperature).map((temperature) => (
                            <MenuItem key={temperature} value={temperature}>
                              {prettyPrintEnumValue(temperature)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <ProductChemicalOverrideDataGrid
                  getValues={getValues}
                  issues={issues}
                  control={control}
                />
              </Grid>
            </Grid>
            {selectedChemical && (
              <Grid item xs={12}>
                <CatalogLink
                  type="Chemical"
                  id={selectedChemical?.id}
                  name={selectedChemical?.name}
                  issues={form.issues}
                />
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <IssueListButton issues={issues} />
            <FormControl>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={hasCriticalIssues(issues)}
              >
                {productChemical == null ? "Add" : "Update"}
              </Button>
            </FormControl>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ProductChemicalForm;
