import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { programAreaToStatuteCodes } from "encamp-shared/src/constants/echo";
import { gql } from "generated-graphql";
import {
  FacilityStatus,
  InsightsEchoFacilitiesQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import invariant from "invariant";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface EchoProps {
  handleRowClick?: (params: GridRowParams) => void;
  initialPageSize?: number;
  filterTenant?: boolean;
}

export type Row =
  InsightsEchoFacilitiesQuery["insightsEchoFacilities"]["items"][number];

const ECHO_EXPORTERS = gql(`
  query InsightsEchoFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!, $filterTenantId: String) {
    insightsEchoFacilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId, filterTenantId: $filterTenantId) {
      items {
        registryId
        id: facilityId
        customerFacilityId
        facilityName
        facilityStreet
        facilityCity
        facilityState
        facilityZip
        facilityCounty
        nonComplianceCountLast12Qrtrs
        violationCountLast12Qrtrs
        penaltiesLast12Qrtrs
      }
      count
    }
  }
`);

export const Echo: React.FC<EchoProps> = ({
  handleRowClick,
  initialPageSize = 25,
  filterTenant = true,
}) => {
  useBreadcrumb({ label: "ECHO" });
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");

  const [showMyFacilities, setShowMyFacilities] = useState(filterTenant);

  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: Row = params.row;
      navigate(`/o/${tenantId}/insights/echo/${row.registryId}`);
    }
  };

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      { field: "registryId", headerName: "Registry ID", flex: 0.6 },
      {
        field: "facilityName",
        headerName: "Facility Name",
        filterKeyType: "facility",
        flex: 1.2,
        valueGetter: (params) => {
          const { facilityName, customerFacilityId } = params.row;
          if (customerFacilityId) {
            return `${facilityName} (${customerFacilityId})`;
          }
          return facilityName;
        },
      },
      {
        field: "facilityAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const { facilityStreet, facilityCity, facilityState, facilityZip } =
            params.row;
          return [facilityStreet, facilityCity, facilityState, facilityZip]
            .filter(Boolean)
            .join(", ");
        },
      },
      {
        field: "penaltiesLast12Qrtrs",
        headerName: "Penalties (last 12 quarters)",
        renderHeader: (params) => (
          <Tooltip title="Penalties (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Penalties
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.penaltiesLast12Qrtrs == 0
            ? null
            : `$${params.row.penaltiesLast12Qrtrs?.toLocaleString()}`,
      },
      {
        field: "violationCountLast12Qrtrs",
        headerName: "Violations (last 12 quarters)",
        renderHeader: (params) => (
          <Tooltip title="Violations (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Violations
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.violationCountLast12Qrtrs == 0
            ? null
            : params.row.violationCountLast12Qrtrs?.toLocaleString(),
      },
      {
        field: "nonComplianceCountLast12Qrtrs",
        headerName: "Quarters in Non-compliance (last 12 quarters)",
        renderHeader: (params) => (
          <Tooltip title="Quarters in Non-compliance (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Quarters in Non-compliance
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.nonComplianceCountLast12Qrtrs == 0
            ? null
            : params.row.nonComplianceCountLast12Qrtrs?.toLocaleString(),
      },
    ],
    []
  );

  const sortedProgramAreas = Array.from(programAreaToStatuteCodes.keys()).sort(
    (a, b) => a.localeCompare(b)
  );
  return (
    <Grid item>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showMyFacilities}
              onChange={() => setShowMyFacilities(!showMyFacilities)}
              name="showMyFacilities"
              color="primary"
            />
          }
          label="Only show my facilities"
        />
      </Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={ECHO_EXPORTERS}
        excludeFilterColumns={[
          "nonComplianceCountLast12Qrtrs",
          "violationCountLast12Qrtrs",
          "penaltiesLast12Qrtrs",
        ]}
        additionalFilterColumns={[
          {
            key: "customerFacilityId",
            header: "Customer Facility ID",
            filterKeyType: "text",
          },
          {
            key: "naicsCode",
            header: "NAICS Code",
            filterKeyType: "text",
          },
          {
            key: "programArea",
            header: "Facility Program Area",
            filterKeyType: "enum",
            enumValues: sortedProgramAreas.filter(
              (programArea) => programArea !== "Chemicals"
            ),
            enumPresentationFunction: (programArea: string) => programArea,
          },
          {
            key: "programAreaPenalty",
            header: "Penalty Program Area",
            filterKeyType: "enum",
            enumValues: sortedProgramAreas,
            enumPresentationFunction: (programArea: string) => programArea,
          },
          {
            key: "programAreaViolation",
            header: "Violation Program Area",
            filterKeyType: "enum",
            enumValues: sortedProgramAreas,
            enumPresentationFunction: (programArea: string) => programArea,
          },
          {
            key: "programAreaNonCompliance",
            header: "Non-compliance Program Area",
            filterKeyType: "enum",
            enumValues: sortedProgramAreas,
            enumPresentationFunction: (programArea: string) => programArea,
          },
          {
            key: "hasViolations",
            header: "Has Violations",
            filterKeyType: "boolean",
          },
          {
            key: "hasPenalties",
            header: "Has Penalties",
            filterKeyType: "boolean",
          },
          {
            key: "hasCurrentNonCompliance",
            header: "Has Current Non-compliance",
            filterKeyType: "boolean",
          },
          {
            key: "hasHistoricalNonCompliance",
            header: "Has Historical Non-compliance",
            filterKeyType: "boolean",
          },
        ]}
        getItems={(data: any) => data?.insightsEchoFacilities?.items ?? []}
        getCount={(data: any) => data?.insightsEchoFacilities?.count ?? 0}
        noDataMessage="No ECHO Exporters found."
        initialPageSize={initialPageSize}
        onRowClick={onRowClick}
        defaultSearch={showMyFacilities ? `status:${FacilityStatus.Open}` : ""}
        dataQueryVariables={{
          filterTenantId: showMyFacilities ? tenantId : null,
          tenantId,
        }}
      />
    </Grid>
  );
};
