import { useSuspenseQuery } from "@apollo/client";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, deepOrange, deepPurple, lime, pink } from "@mui/material/colors";
import { BigTooltip } from "components/BigTooltip";
import ChartLegend from "components/Dashboard/ChartLegend";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { gql } from "generated-graphql";
import { UnitType } from "generated-graphql/graphql";
import { useQueryParams } from "hooks/useQueryParams";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { prettyPrintLargeQuantity } from "util/unit";
import {
  WasteShipmentsGraphs,
  WasteShipmentsGraphsProps,
  allowedGraphTypes,
} from "./WasteShipmentGraph";

const WASTE_SHIPMENTS = gql(`
  query WasteShipments($facilityId: ID!) {
    wasteShipmentsByFacility(facilityId: $facilityId) {
      hazardousWasteShipments {
        totalAmount
        highestMonth
        byMonth {
          acutelyHazardous
          hazardous
          month
        }
      }
      wasteShipmentsByMonth {
        totalNonHazardousAmount
        totalHazardousAmount
        totalUniversalAmount
        byMonth {
          month
          nonHazardous
          hazardous
          universal
        }
      }
      highestWasteStreamShipments {
        wasteStream {
          id
          dotDescription
        }
        amount
      }
    }
  }
`);

const graphVariations: Record<
  WasteShipmentsGraphsProps["graphType"],
  { label: string; selectLabel: string; tooltip: ReactNode }
> = {
  hazardousWaste: {
    label: "Hazardous waste shipments in lbs",
    selectLabel: "Hazardous waste shipments",
    tooltip: (
      <Typography variant="caption">
        Based on manifest data ingested by Encamp, see how your historical RCRA
        hazardous waste shipments relate to federal generator status thresholds.
        If a threshold is exceeded in a calendar month, you may need to file an
        update notification with the EPA.
      </Typography>
    ),
  },
  hazardousWasteByMonth: {
    label: "Waste shipments by month in lbs",
    selectLabel: "Waste shipments by month",
    tooltip: (
      <Typography variant="caption">
        Based on manifest data ingested by Encamp, see how your historical waste
        shipments are broken down by classification.
      </Typography>
    ),
  },
  highestGeneratingWasteStreams: {
    label: "Highest waste stream shipments in lbs",
    selectLabel: "Highest waste stream shipments",
    tooltip: (
      <Typography variant="caption">
        Based on manifest data ingested by Encamp, see the waste streams at your
        facility that contribute the most to your waste shipments.
      </Typography>
    ),
  },
};

export const WasteShipmentsTile = () => {
  return (
    <DashboardTile xs={12}>
      <WasteShipmentsContent />
    </DashboardTile>
  );
};

function WasteShipmentsContent() {
  const theme = useTheme();

  const { facilityId } = useParams<{ facilityId: string }>();
  const { filters, setFilters } = useQueryParams<{
    graphType: WasteShipmentsGraphsProps["graphType"];
  }>();
  const graphType =
    filters.graphType && allowedGraphTypes.includes(filters.graphType)
      ? filters.graphType
      : "hazardousWaste";

  const { data } = useSuspenseQuery(WASTE_SHIPMENTS, {
    variables: { facilityId: facilityId ?? "" },
  });

  return (
    <>
      <DashboardTile.Header
        title="Waste Shipments"
        infoTooltip={
          <Typography variant="caption">
            Based on manifest data ingested by Encamp, use the dropdown on the
            right to view historical information about this facility's waste
            shipments.
          </Typography>
        }
      >
        <FormControl sx={{ width: theme.spacing(32.5) }}>
          <InputLabel id="view-by">View by</InputLabel>
          <Select
            sx={{ height: theme.spacing(5) }}
            fullWidth
            labelId="view-by"
            label="View by"
            value={graphType}
            onChange={(event) =>
              setFilters((f) => ({
                ...f,
                graphType: event.target
                  .value as WasteShipmentsGraphsProps["graphType"],
              }))
            }
          >
            {Object.entries(graphVariations).map(([key, { selectLabel }]) => (
              <MenuItem key={key} value={key}>
                {selectLabel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DashboardTile.Header>

      <Stack direction={{ xs: "column", lg: "row" }} spacing={8}>
        {graphType === "hazardousWaste" && (
          <DashboardTile.ContentArea>
            <Stack
              pl={{ xs: 0, lg: 2 }}
              gap={2}
              direction={{ xs: "row", lg: "column" }}
            >
              <StatDisplay
                label="Total waste"
                value={prettyPrintLargeQuantity(
                  data?.wasteShipmentsByFacility.hazardousWasteShipments
                    ?.totalAmount ?? 0,
                  UnitType.Pounds,
                  true
                )}
              />
              <StatDisplay
                label="Highest month"
                value={
                  data?.wasteShipmentsByFacility.hazardousWasteShipments
                    ?.highestMonth
                }
              />
            </Stack>
          </DashboardTile.ContentArea>
        )}

        {graphType === "hazardousWasteByMonth" && (
          <DashboardTile.ContentArea
            pl={{ xs: 0, lg: 2 }}
            spacing={4}
            direction={{ xs: "row", lg: "column" }}
          >
            <StatDisplay
              label="Total non-hazardous waste"
              value={prettyPrintLargeQuantity(
                data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                  ?.totalNonHazardousAmount ?? 0,
                UnitType.Pounds,
                true
              )}
            />
            <StatDisplay
              label="Total hazardous waste"
              value={prettyPrintLargeQuantity(
                data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                  ?.totalHazardousAmount ?? 0,
                UnitType.Pounds,
                true
              )}
            />
            <StatDisplay
              label="Total universal waste"
              value={prettyPrintLargeQuantity(
                data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                  ?.totalUniversalAmount ?? 0,
                UnitType.Pounds,
                true
              )}
            />
          </DashboardTile.ContentArea>
        )}

        <DashboardTile.ContentArea sx={{ flex: { xs: "1", lg: "1 1 auto" } }}>
          <Stack direction="row" alignItems="center">
            <Typography>{graphVariations[graphType].label}</Typography>
            <BigTooltip title={graphVariations[graphType].tooltip}>
              <IconButton size="small">
                <InfoOutlined color="disabled" />
              </IconButton>
            </BigTooltip>
          </Stack>
          <Box height={theme.spacing(34)}>
            {graphType === "hazardousWaste" && <HazardousWasteLegend />}
            {graphType === "hazardousWasteByMonth" && (
              <HazardousWasteByMonthLegend />
            )}
            <WasteShipmentsGraphs graphType={graphType} data={data} />
          </Box>
        </DashboardTile.ContentArea>
      </Stack>
    </>
  );
}

function HazardousWasteLegend() {
  return (
    <ChartLegend
      config={{ direction: "row", position: "above" }}
      items={[
        {
          color: deepOrange[900],
          value: `Acutely Hazardous Waste`,
          tooltip:
            "Waste that has a specific code (P, or one of the F-codes marked with an H)",
        },
        {
          color: deepOrange[300],
          value: `Hazardous Waste`,
          tooltip:
            "Waste that is flammable, corrosive, reactive, or toxic (D-codes), or is listed on one of the EPA's four lists of hazardous waste (F, K, U, or P codes)",
        },
        {
          color: pink[300],
          value: `Federal LQG acute threshold`,
          tooltip:
            "Shipped more than 2.2 lbs of acutely hazardous waste per month",
          type: "dashed-line",
        },
        {
          color: blue[900],
          value: `Federal LQG threshold`,
          tooltip:
            "Shipped more than 2,200 lbs of hazardous waste per month [or combo of hazardous + acutely hazardous]",
          type: "dashed-line",
        },
      ]}
      onItemClick={(item) => console.log(item)}
      sx={{ paddingLeft: 6, position: "absolute" }}
    />
  );
}

function HazardousWasteByMonthLegend() {
  return (
    <ChartLegend
      config={{ direction: "row", position: "above" }}
      items={[
        {
          color: lime[800],
          value: `Non-hazardous Waste`,
          tooltip: "Waste that causes no harm to human or environmental health",
        },
        {
          color: deepOrange[300],
          value: `Hazardous Waste`,
          tooltip:
            "Waste that is flammable, corrosive, reactive, or toxic (D-codes), or is listed on one of the EPA's four lists of hazardous waste (F, K, U, or P codes)",
        },
        {
          color: deepPurple[300],
          value: `Universal Waste`,
          tooltip:
            "Hazardous wastes shipped across various industries (batteries, pesticides, mercury-containing equipment, lamps, and aerosol cans) and are allowed to be handled under less stringent rules than other hazardous wastes.",
        },
      ]}
      onItemClick={(item) => console.log(item)}
      sx={{ paddingLeft: 6, position: "absolute" }}
    />
  );
}
