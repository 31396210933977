import LocationOn from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import BasicMap from "./BasicMap";
import { FormTextField } from "./Forms/FormTextField";

interface StorageLocationLatLongFormData {
  storageLocation: {
    latitude: number | null;
    longitude: number | null;
  };
}

const latLongStates = ["IL", "KY", "ND"];

export const StorageLocationLatLong = ({
  facilityState,
}: {
  facilityState: string | null | undefined;
}) => {
  const [isMapEnabled, setIsMapEnabled] = useState(false);
  const { setValue, control, trigger } =
    useFormContext<StorageLocationLatLongFormData>();

  const lat = useWatch({
    control,
    name: "storageLocation.latitude",
  });

  const lng = useWatch({
    control,
    name: "storageLocation.longitude",
  });

  const showMap = lat && lng;
  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const newLat = e.latLng.lat();
      const newLng = e.latLng.lng();
      setValue("storageLocation.latitude", newLat, {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("storageLocation.longitude", newLng, {
        shouldTouch: true,
        shouldDirty: true,
      });
      trigger(["storageLocation.longitude", "storageLocation.latitude"]);
    }
  };

  if (!facilityState || !latLongStates.includes(facilityState)) {
    return null;
  }

  return (
    <Stack gap={3}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item xs={5}>
          <FormTextField
            name="storageLocation.latitude"
            label="Latitude"
            control={control}
          />
        </Grid>
        <Grid item xs={5}>
          <FormTextField
            name="storageLocation.longitude"
            label="Longitude"
            control={control}
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            startIcon={<LocationOn />}
            disabled={isMapEnabled || !showMap}
            onClick={() => setIsMapEnabled(true)}
          >
            Move Pin
          </Button>
        </Grid>
      </Grid>
      {showMap ? (
        <Box height="200px" position="relative">
          <BasicMap
            latitude={lat}
            longitude={lng}
            static={!isMapEnabled}
            onMarkerDragEnd={handleMarkerDragEnd}
          />
          {isMapEnabled && (
            <>
              <Box
                top={10}
                left={10}
                position="absolute"
                px={1}
                borderRadius={1}
                sx={{ backgroundColor: "white" }}
              >
                <Typography variant="caption">
                  Move the marker to set the correct location
                </Typography>
              </Box>

              <Box top={10} right={10} position="absolute">
                <Button
                  variant="contained"
                  onClick={() => setIsMapEnabled(false)}
                >
                  Done
                </Button>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Card variant="outlined" sx={{ height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Map will display here once latitude and longitude are entered.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};
