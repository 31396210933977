import { useSuspenseQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { Typography, Alert, Box, GridProps } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { useTheme } from "@mui/material/styles";
import InsightsIcon from "@mui/icons-material/Insights";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";
import { useParams } from "react-router-dom";
import { OutstandingReviewer } from "generated-graphql/graphql";
import { useCallback } from "react";

const OUTSTANDING_REVIEWS_QUERY = gql(`
  query OutstandingReviews($tenantId: ID!, $filter: ChemicalOverviewFilter) {
    outstandingReviews(tenantId: $tenantId, filter: $filter) {
      reviewers {
        userId
        first
        last
        email
        reportCount
      }
      reportsWithoutReviewerCount
    }
  }
`);

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => `${params.row.first} ${params.row.last}`,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "reportCount",
    headerName: "Number of Reports",
    type: "number",
    flex: 1,
  },
];

export function OutstandingReviewsTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <OutstandingReviewsContent />
    </DashboardTile>
  );
}

function OutstandingReviewsContent() {
  const theme = useTheme();
  const { tenantId } = useParams();
  const { chemicalOverviewState, drillDownToFacilities } =
    useChemicalOverviewState();

  const { data } = useSuspenseQuery(OUTSTANDING_REVIEWS_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: {
        assignedToMe: chemicalOverviewState.deferredFilters.assignedToMe,
        tagNames: chemicalOverviewState.deferredFilters.tagNames,
      },
    },
  });

  const drillDownToFacilitiesWithReviewer = useCallback(
    (search: string) => {
      drillDownToFacilities((f) => ({
        ...f,
        reviewer: search,
      }));
    },
    [drillDownToFacilities]
  );

  const { reviewers, reportsWithoutReviewerCount } =
    data?.outstandingReviews ?? {
      reviewers: [],
      reportsWithoutReviewerCount: 0,
    };

  return (
    <>
      <DashboardTile.Header
        title="Outstanding Reviews"
        infoTooltip={
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Outstanding Reviews
            </Typography>{" "}
            displays all incomplete reviews for the current reporting year,
            grouped by reviewer.
          </Typography>
        }
      />
      <DashboardTile.ContentArea>
        <DataGrid
          rows={reviewers}
          columns={columns}
          getRowId={(row) => row.userId}
          pagination={true}
          pageSizeOptions={[5]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [
                { field: "reportCount", sort: "desc" },
                { field: "name", sort: "asc" },
              ],
            },
          }}
          autoHeight
          onRowClick={(item: GridRowParams<OutstandingReviewer>) => {
            drillDownToFacilitiesWithReviewer(item.row.email);
          }}
        />
      </DashboardTile.ContentArea>
      {reportsWithoutReviewerCount > 0 && (
        <>
          <DashboardTile.Footer>
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": {
                  "& .MuiAlert-icon": {
                    color: theme.palette.info.main,
                  },
                  "& .MuiTypography-root": {
                    color: theme.palette.info.main,
                  },
                },
              }}
              onClick={() => drillDownToFacilitiesWithReviewer("false")}
            >
              <Alert severity="info" icon={<InsightsIcon />}>
                <Typography>
                  <Typography component="span" fontWeight="800">
                    {reportsWithoutReviewerCount}
                  </Typography>{" "}
                  of your reports{" "}
                  {reportsWithoutReviewerCount === 1 ? "has" : "have"} no
                  reviewer assigned.
                </Typography>
              </Alert>
            </Box>
          </DashboardTile.Footer>
        </>
      )}
    </>
  );
}
