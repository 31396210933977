import { useQuery } from "@apollo/client";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "generated-graphql";
import { StorageLocationPickerFragment } from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useMemo, useState } from "react";
import { FieldError } from "react-hook-form";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { v4 } from "uuid";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

gql(`
  fragment StorageLocationPicker on StorageLocation {
    id
    description
    facilityId
    CA_mapNumber
    CA_gridNumber
    OR_insideOutside
    OR_storageArea
    OR_storageBuilding
    OR_storageFloor
    OR_storageQuadrant
    OR_storageRoom
    latitude
    longitude
  }
`);

const objectIsValidStorageLocation = (
  obj: any
): obj is { id: string; facilityId: string; description: string } =>
  typeof obj === "object" &&
  "id" in obj &&
  "facilityId" in obj &&
  "description" in obj;

const STORAGE_LOCATIONS_QUERY = gql(`
  query StorageLocations($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    storageLocations(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...StorageLocationPicker
      }
      count
    }
  }
`);

type OptionType = StorageLocationPickerFragment & {
  inputValue?: string;
};

interface StorageLocationPickerProps {
  facilityId: string;
  onSelectLocation: (location: StorageLocationPickerFragment) => void;
  defaultSearchTerm?: string;
  disabledLocationsById?: string[];
  disabledOptionLabel?: string;
  description?: StorageLocationPickerFragment["description"];
  error?: FieldError;
  disabled?: boolean;
  disableClearable?: boolean;
  required?: boolean;
  tooltip?: React.ReactNode;
}

const initNewStorageLocation = ({
  facilityId,
  description,
  id,
  facilityLat,
  facilityLng,
}: {
  facilityId: string;
  description: string;
  id: string;
  facilityLat: number | null | undefined;
  facilityLng: number | null | undefined;
}): StorageLocationPickerFragment => ({
  id,
  facilityId,
  description: description.trim(),
  OR_insideOutside: null,
  OR_storageArea: "",
  OR_storageBuilding: "",
  OR_storageFloor: "",
  OR_storageQuadrant: null,
  OR_storageRoom: "",
  latitude: facilityLat ?? null,
  longitude: facilityLng ?? null,
});

export const StorageLocationPicker: React.FC<StorageLocationPickerProps> = ({
  facilityId,
  onSelectLocation,
  defaultSearchTerm = "",
  description,
  error,
  disabled,
  required,
  disableClearable,
  tooltip,
}) => {
  const { data: facilityData } = useFacility(facilityId);
  const { latitude: facilityLat, longitude: facilityLng } =
    facilityData?.facility || {};
  const theme = useTheme();
  const [search, setSearch] = useState(description ?? "");
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [options, setOptions] = useState<StorageLocationPickerFragment[]>([]);
  const [debouncedSearch] = useDebounce(
    `facilityId:${facilityId} ${defaultSearchTerm} ${search}`,
    200
  );
  const [newStorageLocation, setNewStorageLocation] =
    useState<StorageLocationPickerFragment | null>(null);

  const newLocationId = useMemo(() => v4(), [searchIsOpen]);

  const { data, loading, previousData } = useQuery(STORAGE_LOCATIONS_QUERY, {
    variables: {
      search: debouncedSearch,
      page: 0,
      pageSize: 5,
    },
    skip: !searchIsOpen,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setSearch(description ?? "");
  }, [description]);

  useEffect(() => {
    if (data?.storageLocations.items) setOptions(data?.storageLocations.items);
    else if (previousData?.storageLocations.items)
      setOptions(previousData?.storageLocations.items);
  }, [
    setOptions,
    data?.storageLocations.items,
    previousData?.storageLocations.items,
  ]);

  // The key that's pressed isn't passed to the onChange event, so we need to track it
  // in a way that's accessible to the onChange event.
  const [tabbed, setTabbed] = useState(false);

  return (
    <>
      <Autocomplete<OptionType, false, typeof disableClearable, true>
        loading={loading}
        autoHighlight={true}
        autoSelect={true}
        disableClearable={disableClearable}
        disabled={disabled}
        value={description}
        isOptionEqualToValue={(o, v) => o.description === v.description}
        onBlur={(e) => {
          setTabbed(false);
          const description = (e.target as HTMLInputElement).value;
          if (!description.length) {
            onSelectLocation(
              initNewStorageLocation({
                facilityId,
                description: "",
                id: "",
                facilityLat,
                facilityLng,
              })
            );
            return;
          }

          let existingStorageLocation = options.find(
            (o) =>
              o.description?.toLowerCase().trim() ===
              description.toLowerCase().trim()
          );

          const selectedNewStorageLocation =
            description === newStorageLocation?.description;

          if (!(selectedNewStorageLocation || existingStorageLocation)) {
            setSearch("");
            return;
          }

          if (existingStorageLocation) {
            onSelectLocation({
              ...existingStorageLocation,
              latitude: existingStorageLocation.latitude ?? facilityLat,
              longitude: existingStorageLocation.longitude ?? facilityLng,
            });
            setSearch(existingStorageLocation.description ?? "");
            return;
          }

          if (selectedNewStorageLocation) {
            onSelectLocation({
              ...newStorageLocation,
              latitude: newStorageLocation.latitude ?? facilityLat,
              longitude: newStorageLocation.longitude ?? facilityLng,
            });
            setSearch(newStorageLocation.description ?? "");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            // Capture the tab key press so we can use it in the onChange event
            setTabbed(true);
          }
        }}
        onChange={(_, newValue, reason) => {
          if (reason === "clear") {
            onSelectLocation(
              initNewStorageLocation({
                facilityId,
                description: "",
                id: "",
                facilityLat,
                facilityLng,
              })
            );
            return;
          }

          if (
            (reason === "selectOption" || (reason == "blur" && tabbed)) &&
            objectIsValidStorageLocation(newValue)
          ) {
            const existingStorageLocation = options.find(
              (o) => o.description === newValue?.description
            );

            onSelectLocation(
              existingStorageLocation
                ? {
                    ...existingStorageLocation,
                    latitude: existingStorageLocation.latitude ?? facilityLat,
                    longitude: existingStorageLocation.longitude ?? facilityLng,
                  }
                : {
                    latitude: facilityLat,
                    longitude: facilityLng,
                    description: newValue.description.trim(),
                    id: newValue.id,
                    facilityId: newValue.facilityId,
                  }
            );

            if (!existingStorageLocation) {
              setNewStorageLocation(
                initNewStorageLocation({
                  facilityId: newValue.facilityId,
                  description: newValue.description.trim(),
                  id: newValue.id,
                  facilityLat,
                  facilityLng,
                })
              );
            }
          }
        }}
        inputValue={search}
        onInputChange={(_, newInputValue, reason) => {
          if (reason !== "reset") {
            setSearch(newInputValue);
          }
        }}
        options={options}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const filtered = options.filter(
            (o) =>
              (o.description ?? "")
                .toLowerCase()
                .trim()
                .indexOf(inputValue.toLowerCase().trim()) >= 0
          );
          const isExisting = filtered.some(
            (option) =>
              inputValue.toLowerCase().trim() ===
              option.description?.toLowerCase().trim()
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              facilityId,
              id: newLocationId,
              description: inputValue,
              inputValue: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onOpen={() => setSearchIsOpen(true)}
        onClose={() => setSearchIsOpen(false)}
        noOptionsText="Search for a storage location"
        getOptionLabel={(option: OptionType | string) => {
          if (typeof option === "string") {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return option.description ?? "";
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}`}>
            {option.inputValue ?? option.description}
          </li>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={!!error}
              label="Storage Location"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {tooltip && (
                      <Tooltip title={tooltip}>
                        <IconButton
                          style={{
                            color: theme.palette.info.dark,
                            opacity: 0.8,
                          }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              required={required}
            />
          );
        }}
      />
      <ErrorDisplay error={error} />
    </>
  );
};
