import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";

import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
  useWatch,
} from "react-hook-form";

import ChemicalComponentFormDialog from "./ChemicalComponentForm";
import { ChemicalDetailInputs, ComponentInputType } from "./index";
import { Issue } from "generated-graphql/graphql";

interface ChemicalComponentTableProps {
  issues: Issue[];
  errors: FieldErrors<ChemicalDetailInputs>;
  control: Control<ChemicalDetailInputs, any>;
  getValues: UseFormGetValues<ChemicalDetailInputs>;
}

export const ChemicalComponentTable: React.FC<ChemicalComponentTableProps> = ({
  issues,
  errors,
  control,
  getValues,
}) => {
  const columns: GridColDef<Partial<ComponentInputType>>[] = [
    {
      headerName: "Component Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Component Percentage",
      field: "componentPercentage",
      flex: 0.5,
    },
    {
      headerName: "By Weight or Volume",
      field: "weightOrVolume",
      flex: 0.5,
    },
    { headerName: "CAS", field: "casNumber" },
    { headerName: "EHS Form", field: "ehsForm" },
    {
      headerName: "EHS",
      field: "isEHS",
      renderCell(params) {
        const isEhs = params.row.isEhs;
        if (!isEhs) return <></>;
        return <Check />;
      },
    },
    {
      field: "errors",
      headerName: "",
      sortable: false,
      width: 50,
      valueGetter: (params) => {
        // Lookup the row index of the row to determine if there are errors
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.id);
        return !!errors.components?.[rowIndex];
      },
      renderCell: ({ value }) =>
        value && (
          <Box
            sx={{
              color: theme.palette.error.main,
              display: "flex",
              alignItems: "end",
            }}
          >
            <WarningAmberRounded />
          </Box>
        ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <Tooltip title="Edit" key={1}>
          <GridActionsCellItem
            onClick={() => handleEditComponent(params.row)}
            label="Edit Component"
            icon={<Edit />}
          />
        </Tooltip>,
        <Tooltip title="Delete" key={2}>
          <GridActionsCellItem
            onClick={() => {
              handleOpenDeleteDialog(params.row);
            }}
            label="Delete Component"
            icon={<Delete />}
          />
        </Tooltip>,
      ],
    },
  ];

  const theme = useTheme();

  // Stores relevant data for adding/editing, determines whether ChemicalComponentFormDialog shows
  const [editComponentData, setEditComponentData] = useState<{
    component: Partial<ComponentInputType>;
    mode: "add" | "edit";
    index: number;
  }>();
  // Stores relevant data for deleting a component, determines whether delete modal shows
  const [deleteConfirmData, setDeleteConfirmData] = useState<{
    componentId?: string;
    title?: string;
  }>({});

  const { append, remove, update } = useFieldArray({
    control,
    name: "components",
  });

  const watchedComponents = useWatch({
    control,
    name: "components",
    defaultValue: getValues().components,
  });

  const handleOpenDeleteDialog = (row: Partial<ComponentInputType>) => {
    setDeleteConfirmData({
      componentId: row.id,
      title: row.name ?? "",
    });
  };
  const handleCloseConfirmDialog = useCallback(() => {
    setDeleteConfirmData({});
  }, []);
  const handleDeleteComponent = (id: string) => {
    const index = watchedComponents.findIndex((c) => c.id === id);
    remove(index);
    handleCloseConfirmDialog();
  };
  function handleEditComponent(row: Partial<ComponentInputType>) {
    const index = watchedComponents.findIndex((c) => c.id === row.id);
    setEditComponentData({ component: row, mode: "edit", index });
  }
  function handleAddComponent() {
    setEditComponentData({
      component: {},
      mode: "add",
      index: watchedComponents.length,
    });
  }

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ marginTop: theme.spacing(2) }}>
          <Stack direction={"column"}>
            <Controller
              name="components"
              control={control}
              render={({ fieldState: { error } }) => (
                <FormControl>
                  <Stack
                    width={"100%"}
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Stack
                      justifyContent="start"
                      direction="row"
                      alignItems="center"
                    >
                      <FormLabel>Components</FormLabel>
                      <ErrorDisplay
                        error={error}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 0,
                        }}
                      />
                    </Stack>
                    <Button
                      key="add"
                      variant="contained"
                      size="small"
                      onClick={handleAddComponent}
                    >
                      Add Component
                    </Button>
                  </Stack>
                </FormControl>
              )}
            />
          </Stack>
          {watchedComponents?.length > 0 && (
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <DataGrid
                editMode="row"
                rows={watchedComponents.filter((item) => item.name)}
                isRowSelectable={() => false}
                onRowClick={({ row }) => handleEditComponent(row)}
                columns={columns}
              />
            </Box>
          )}

          <ChemicalComponentFormDialog
            key={editComponentData?.component.id ?? ""}
            mode={editComponentData?.mode ?? "add"}
            initialIssues={issues}
            index={editComponentData?.index ?? -1}
            open={!!editComponentData}
            chemical={getValues()}
            onClose={() => {
              setEditComponentData(undefined);
            }}
            onSave={(component) => {
              if (editComponentData?.mode === "add") {
                append(component, {
                  shouldFocus: true,
                });
              } else {
                if ((editComponentData?.index ?? -1) >= 0) {
                  update(editComponentData?.index ?? -1, component);
                }
              }
              setEditComponentData(undefined);
            }}
          />
        </Box>
      </Grid>
      <ConfirmDialog
        open={!!deleteConfirmData.componentId}
        onClose={handleCloseConfirmDialog}
        onConfirm={() =>
          handleDeleteComponent(deleteConfirmData.componentId ?? "")
        }
        msg={`Are you sure you want to delete ${
          deleteConfirmData.title ? `the ${deleteConfirmData.title}` : "this"
        } component?`}
      />
    </>
  );
};
