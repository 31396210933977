import { StorageLocationInput } from "generated-graphql/graphql";

export function transform(
  storageLocation: StorageLocationInput
): StorageLocationInput {
  return {
    ...storageLocation,
    latitude: storageLocation.latitude
      ? Number(storageLocation.latitude)
      : null,
    longitude: storageLocation.longitude
      ? Number(storageLocation.longitude)
      : null,
  };
}
