import { FormControl, Grid, useTheme } from "@mui/material";
import { StorageLocationLatLong } from "components/StorageLocationLatLong";
import { StateAbbreviation } from "encamp-shared/src/constants/states";
import {
  STORAGE_TYPE_DESCRIPTIONS,
  StorageType,
  getStorageTypeDropdownOptionsForState,
} from "encamp-shared/src/constants/tierii";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  FacilityChemicalStorageLocationInput,
  Temperature,
  UnitType,
} from "generated-graphql/graphql";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useReport } from "routes/Customer/Chemicals/Report/useReport";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { ErrorDisplay } from "../../../../../components/Forms/ErrorDisplay";
import { FormSelect } from "../../../../../components/Forms/FormSelect";
import { FormTextField } from "../../../../../components/Forms/FormTextField";
import { PressurePicker } from "../../../../../components/PressurePicker";
import { SearchSelect } from "../../../../../components/SearchSelect";

gql(`
  fragment ReportingFacilityChemicalStorageLocationForm on ReportingFacilityChemicalStorageLocation {
    id
    maxAmount
    storageLocationId
    pressure
    otherPressureValue
    storageType
    storageTypeDescription
    temperature
    unit
    storageLocation {
      ...StorageLocationPicker
    }
  }
`);

gql(`
  fragment FacilityChemicalStorageLocationForm on FacilityChemicalStorageLocation {
    id
    maxAmount
    storageLocationId
    pressure
    otherPressureValue
    storageType
    storageTypeDescription
    temperature
    unit
    storageLocation {
      ...StorageLocationPicker
    }
  }
`);

type StorageLocationFormProps = {
  disabled?: boolean;
};

export const FacilityChemicalStorageLocationForm: React.FC<
  StorageLocationFormProps
> = ({ disabled }) => {
  const theme = useTheme();
  const { data: reportData } = useReport();
  const { data: facilityData } = useFacility();
  const facilityState =
    facilityData?.facility?.state ?? reportData?.tierIIReport.facility.state;

  const form = useFormContext<FacilityChemicalStorageLocationInput>();

  const {
    control,
    formState: { errors },
    watch,
  } = form;

  const storageType = watch("storageType");
  const storageTypeDescriptions = facilityState
    ? getStorageTypeDropdownOptionsForState(
        facilityState as StateAbbreviation
      ).map((st) => st.label)
    : STORAGE_TYPE_DESCRIPTIONS;

  return (
    <Grid
      container
      spacing={theme.spacing(2)}
      sx={{ marginTop: theme.spacing(1) }}
    >
      <Grid item xs={8}>
        <FormTextField
          name="maxAmount"
          label="Max Amount"
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>

      <Grid item xs={4}>
        <FormSelect
          selectItems={Object.values(UnitType).map((unit) => ({
            display: prettyPrintEnumValue(unit),
            value: unit,
          }))}
          control={control}
          name="unit"
          label="Unit Type"
          disabled={disabled}
          hideErrors
        />
      </Grid>

      <Grid item xs={12} paddingLeft={theme.spacing(2)}>
        <ErrorDisplay errors={errors} fields={["maxAmount", "unit"]} />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name={`storageType`}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <SearchSelect
                {...field}
                {...fieldState}
                label="Storage Type"
                required
                options={Object.values(storageTypeDescriptions)}
                getOptionLabel={(opt: any) => prettyPrintEnumValue(opt)}
                disabled={disabled}
              />
            </FormControl>
          )}
        />
      </Grid>

      {storageType === StorageType.Other ? (
        <Grid item xs={12}>
          <FormTextField
            name="storageTypeDescription"
            label="Storage Type Description"
            control={control}
            disabled={disabled}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12}>
        <PressurePicker facilityState={facilityState} disabled={disabled} />
      </Grid>

      <Grid item xs={12}>
        <FormSelect
          name="temperature"
          label="Temperature"
          selectItems={Object.values(Temperature).map((temp) => ({
            display: prettyPrintEnumValue(temp),
            value: temp,
          }))}
          control={control}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <StorageLocationLatLong facilityState={facilityState} />
      </Grid>
    </Grid>
  );
};
