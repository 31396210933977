import { useMutation } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import { useQueryParams } from "hooks/useQueryParams";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatChemicalName } from "util/chemicalName";
import { prettyPrintAmountCode, prettyPrintQuantity } from "util/unit";
import { ChemicalsTableProps } from "./ChemicalsTable";
import { FacilityChemicalMeasurementForm } from "./FacilityChemicalMeasurementForm";
import {
  ChemicalsWithMeasurementsQuery,
  ChemicalsWithMeasurementsQueryVariables,
} from "generated-graphql/graphql";
import { GET_REPORT } from "./Report/useReport";
import { DeleteChemicalMeasurementsConfirmDialog } from "./DeleteChemicalMeasurementsConfirmDialog";
import { useFacility } from "../Facility/useFacility";

export const CHEMICALS_WITH_MEASUREMENTS = gql(`
  query ChemicalsWithMeasurements($facilityId: ID!, $reportingYear: Int!, $search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilityChemicalMeasurementsForReport(facilityId: $facilityId, reportingYear: $reportingYear, search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        pureOrMixture
        casNumber
        isEhs
        components {
          name
          casNumber
          componentPercentage
          isEhs
        }
        alternateId
        maxAmount {
          amount
          amountCode
          unit
        }
        totalMeasurements
        hasCarryOver
        issues {
          ...issue
        }
      }
      count
    }
  }
`);

const DELETE_FACILITY_CHEMICAL_MEASUREMENTS_BY_CHEMICAL_AND_YEAR = gql(`
  mutation DeleteFacilityChemicalMeasurementsByChemicalAndYear($facilityId: ID!, $chemicalId: ID!, $reportingYear: Int!, $addZeroMeasurement: Boolean) {
    deleteFacilityChemicalMeasurementsByChemicalAndYear(facilityId: $facilityId, chemicalId: $chemicalId, reportingYear: $reportingYear, addZeroMeasurement: $addZeroMeasurement) {
      facilityId
      chemicalId
      reportingYear
    }
  }
`);

export function ChemicalsWithMeasurementsTable({
  facilityId,
  reportId,
}: ChemicalsTableProps) {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const { navigateWithFilters } = useQueryParams<{
    facilityId: string;
    omnisearch?: string;
  }>();
  const reportingYear: number = useSelectedReportingYear();
  const { data: facilityQuery, loading: facilityLoading } = useFacility();

  const defaultSearch = useMemo(
    () => `facilityId:${facilityId} reportingYear:${reportingYear}`,
    [facilityId, reportingYear]
  );

  const [showAddChemical, setShowAddChemical] = useState(false);
  const [deletingChemicalMeasurements, setDeletingChemicalMeasurements] =
    useState<
      | ChemicalsWithMeasurementsQuery["facilityChemicalMeasurementsForReport"]["items"][number]
      | undefined
    >();

  const location = useLocation();
  const usedInInventory = location.pathname.includes("inventory");

  const navigateToChemicalMeasurements = useCallback(
    (chemicalId: string) => {
      const destination = `measurements/${chemicalId}`;
      usedInInventory
        ? navigateWithFilters(destination, (filters) => {
            // This will carry over the RY and facilityId query params but remove the omnisearch filtering
            // so we don't inadvertently filter out measurements
            delete filters.omnisearch;
            filters.facilityId = facilityId;
          })
        : navigate(destination);
    },
    [facilityId, navigate, navigateWithFilters, usedInInventory]
  );

  const [
    deleteFacilityChemicalMeasurementsByChemicalAndYear,
    { loading: isDeleting },
  ] = useMutation(DELETE_FACILITY_CHEMICAL_MEASUREMENTS_BY_CHEMICAL_AND_YEAR, {
    onCompleted() {
      alerts.success("Successfully deleted the measurements");
      setDeletingChemicalMeasurements(undefined);
    },
    onError(err) {
      alerts.error("An error occurred deleting the measurements", err);
      setDeletingChemicalMeasurements(undefined);
    },
    refetchQueries: [CHEMICALS_WITH_MEASUREMENTS, GET_REPORT],
  });

  const handleDelete = useCallback(
    async (chemicalId: string, addZeroMeasurement?: boolean) => {
      if (!deletingChemicalMeasurements) return;
      await deleteFacilityChemicalMeasurementsByChemicalAndYear({
        variables: {
          facilityId,
          chemicalId: chemicalId,
          reportingYear,
          addZeroMeasurement,
        },
      });
    },
    [
      deleteFacilityChemicalMeasurementsByChemicalAndYear,
      deletingChemicalMeasurements,
      facilityId,
      reportingYear,
    ]
  );

  const handleAddChemical = () => {
    setShowAddChemical(true);
  };

  return (
    <>
      <OmnisearchDataGrid<
        ChemicalsWithMeasurementsQuery,
        ChemicalsWithMeasurementsQueryVariables,
        ChemicalsWithMeasurementsQuery["facilityChemicalMeasurementsForReport"]["items"][number]
      >
        columns={[
          {
            field: "name",
            headerName: "Name",
            flex: 1.5,
            renderCell: ({ row }) => (
              <Tooltip title={formatChemicalName(row)}>
                <Typography
                  variant="body2"
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                >
                  {row.name}
                </Typography>
              </Tooltip>
            ),
          },
          {
            field: "casNumber",
            headerName: "CAS #",
            flex: 1,
          },
          {
            field: "alternateId",
            headerName: "Alt ID",
            flex: 1,
          },
          {
            field: "maxAmount",
            headerName: "Max Amt",
            valueGetter: ({ row }) => {
              if (row.maxAmount?.amount) {
                return prettyPrintQuantity(
                  row.maxAmount?.amount,
                  row.maxAmount?.unit,
                  true
                );
              } else if (row.maxAmount?.amountCode) {
                return prettyPrintAmountCode(
                  facilityQuery?.facility?.state,
                  row.maxAmount?.amountCode,
                  row.maxAmount?.unit,
                  true
                );
              }
              return "";
            },
            flex: 1.3,
            sortable: false,
          },
          {
            field: "facilityChemicalMeasurementCount",
            headerName: "Measurements",
            valueGetter: ({ row }) => row.totalMeasurements,
            flex: 0.8,
          },
          {
            field: "issues",
            headerName: "Issues",
            flex: 0.5,
            renderCell: ({ row: { issues } }) => (
              <IssueCount issueCount={issues?.length} />
            ),
            sortable: false,
          },
          {
            field: "actions",
            type: "actions",
            flex: 0.6,
            align: "right",
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  key="edit"
                  label="Edit"
                  icon={<Edit />}
                  onClick={() => navigateToChemicalMeasurements(row.id)}
                />,
                <GridActionsCellItem
                  key="delete"
                  label="Delete"
                  icon={<Delete />}
                  onClick={() => setDeletingChemicalMeasurements(row)}
                />,
              ];
            },
          },
        ]}
        dataQuery={CHEMICALS_WITH_MEASUREMENTS}
        defaultSearch={defaultSearch}
        dataQueryVariables={{
          facilityId,
          reportingYear: Number(reportingYear),
        }}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        excludeFilterColumns={[
          "issues",
          "facilityChemicalMeasurementCount",
          "maxAmount",
        ]}
        getCount={(data) => data.facilityChemicalMeasurementsForReport.count}
        getItems={(data) => data.facilityChemicalMeasurementsForReport.items}
        withPadding={false}
        commandButtons={[
          !reportId ? (
            <TierIIYearPicker
              key="yearPicker"
              selectProps={{
                size: "small",
              }}
            />
          ) : undefined,
          <Button
            key="add"
            variant="contained"
            size="small"
            onClick={handleAddChemical}
          >
            Add Chemical
          </Button>,
        ]}
        noDataMessage="No chemical information has been added to this facility yet."
        noDataButtonText="Add Chemical"
        noDataOnButtonClick={handleAddChemical}
        onRowClick={(params) => navigateToChemicalMeasurements(params.row.id)}
      />
      <DeleteChemicalMeasurementsConfirmDialog
        measurementToDelete={deletingChemicalMeasurements}
        setDeletingChemicalMeasurements={setDeletingChemicalMeasurements}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
        reportingYear={reportingYear}
      />
      {showAddChemical && (
        <FacilityChemicalMeasurementForm
          facilityId={facilityId}
          onClose={() => setShowAddChemical(false)}
          open={showAddChemical}
        />
      )}
    </>
  );
}
