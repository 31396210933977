import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { gql } from "generated-graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useTenant } from "hooks/useTenant";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InsightsUpsell } from "../../../components/UpsellLandingPages/InsightsUpsell";
import { LoadingState } from "../Insights/LoadingState";

interface InsightsLinkerProps {
  componentIfLinked: React.ComponentType<any>;
  componentIfUnlinked: React.ComponentType<any>;
  facilityId: string;
  type: "EPA" | "FRS" | "NPDES";
  comparisonMapper: (rowData: any) => ComparisonFields;
}

export interface ComparisonFields {
  name: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  id: string;
}

const REFERENCE_FACILITY = gql(`
  query FacilityBasicWithAltids($facilityId: ID!) {
    facility(id: $facilityId) {
      id
      name
      streetAddress1
      streetAddress2
      city
      state
      zip
      facilityAlternateIds {
        id
        type
        value
        expiresAt
      }
    }
  }
`);

const ADD_ALTERNATE_ID = gql(`
  mutation AddAlternateId($facilityId: ID!, $alternateIds: [AlternateIdInput!]!) {
    addFacilityAlternateIds(facilityId: $facilityId, alternateIds: $alternateIds)
  }
`);

export const InsightsLinker: React.FC<InsightsLinkerProps> = ({
  componentIfLinked: ComponentIfLinked,
  componentIfUnlinked: ComponentIfUnlinked,
  facilityId,
  type,
  comparisonMapper,
}) => {
  const { data, loading, error } = useQuery(REFERENCE_FACILITY, {
    variables: { facilityId },
  });
  const [addAlternateId] = useMutation(ADD_ALTERNATE_ID, {
    refetchQueries: [{ query: REFERENCE_FACILITY, variables: { facilityId } }],
  });

  const [selectedRecord, setSelectedRecord] = useState<ComparisonFields | null>(
    null
  );

  const { featureFlags: flags, loading: featureFlagsLoading } =
    useFeatureFlags();
  const hasInsights = flags?.["insights-ui"];

  const [isLinking, setIsLinking] = useState(false);
  const { tenant } = useTenant();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const isModalOpen = searchParams.get("modal") === "open";

  useBreadcrumb([
    {
      label: "Facilities",
      to: `/o/${tenant?.id}/facilities`,
    },
    {
      label: data?.facility?.name ?? "",
      to: `/o/${tenant?.id}/facilities/${facilityId}/profile`,
    },
    {
      label:
        type === "FRS"
          ? "ECHO Insights"
          : type === "EPA"
          ? "RCRA Insights"
          : `Insights`,
    },
  ]);

  const linkFacility = () => {
    if (selectedRecord) {
      setIsLinking(true);
      addAlternateId({
        variables: {
          facilityId,
          alternateIds: [{ type: type, value: selectedRecord.id }],
        },
      }).then(() => {
        closeModal();
        setIsLinking(false);
        // Redirect to the detail route with the newly linked RCRA/ECHO detail
      });
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    const rowData = params.row;
    setSelectedRecord(comparisonMapper(rowData));
  };

  const openModal = () => {
    searchParams.set("modal", "open");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const closeModal = () => {
    searchParams.delete("modal");
    searchParams.delete("omnisearch");
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setSelectedRecord(null);
  };

  if (loading || featureFlagsLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const facility = data?.facility;
  const hasAlternateId = facility?.facilityAlternateIds?.some(
    (alternateId: any) => alternateId.type === type
  );
  const alternateId = findAlternateId(facility?.facilityAlternateIds ?? [], [
    type,
  ]);
  const renderFacilityDetails = (facilityData: any) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField label="Name" value={facilityData.name} fullWidth disabled />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Street Address"
          value={facilityData.streetAddress1}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField label="City" value={facilityData.city} fullWidth disabled />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="State"
          value={facilityData.state}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField label="ZIP" value={facilityData.zip} fullWidth disabled />
      </Grid>
    </Grid>
  );

  const renderSelectedRecordDetails = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Name"
          value={selectedRecord?.name}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Street Address"
          value={selectedRecord?.streetAddress}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="City"
          value={selectedRecord?.city}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="State"
          value={selectedRecord?.state}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField label="ZIP" value={selectedRecord?.zip} fullWidth disabled />
      </Grid>
    </Grid>
  );

  if (!hasInsights) {
    return <InsightsUpsell />;
  }

  if (hasAlternateId) {
    return <ComponentIfLinked id={alternateId?.value} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: (theme) => theme.spacing(3),
      }}
    >
      <Card sx={{ maxWidth: 400, textAlign: "center", p: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            No {type} ID Linked
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            This facility does not have a {type} ID, which means that there is
            no linked data from the federal database to display.
          </Typography>
          <Button variant="contained" color="primary" onClick={openModal}>
            Link {type} Facility
          </Button>
        </CardContent>
      </Card>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            p: 4,
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <ComponentIfUnlinked
                handleRowClick={handleRowClick}
                initialPageSize={10}
                filterTenant={false}
              />
            </Grid>
            <Grid item xs={4}>
              <Paper sx={{ p: 3, boxShadow: 0 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Facility Comparison
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Encamp Facility
                    </Typography>
                    {renderFacilityDetails(facility)}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {type} Facility
                    </Typography>
                    {selectedRecord
                      ? renderSelectedRecordDetails()
                      : "No facility selected"}
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2, textAlign: "right" }}>
                  <Button variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={linkFacility}
                    disabled={!selectedRecord}
                    loading={isLinking}
                    sx={{ ml: 1 }}
                  >
                    Link Facility
                  </LoadingButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};
