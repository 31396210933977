import { RoleTypeEnum } from "../generated-graphql";
import { Permission } from "../prisma/generated/schema";

/** @deprecated */
export const PERMISSION_KEYS = [
  "RouteEPCRA",
  "RouteRCRA",
  "RouteFacilities",
  "RouteCalendar",
  "RouteContacts",
  "RouteDocuments",
  "RouteEPCRAOverview",
  "RouteEPCRATierII",
  "RouteEPCRAReports",
  "RouteEPCRAProducts",
  "RouteEPCRACredentials",
  "RouteInsights",

  "ReadOrgEventSequences",
  "WriteOrgEventSequences",

  "ReadAllFacility",
  "ReadAllFacilityMinimal",
  "WriteAllFacility",
  "CreateFacility",
  "EditOwnFacilityPermissions",
  "EditOwnFacilityPermissionsUnlimited",

  "ReadAllUserTenant", // AKA see full user list
  "WriteAllUserTenant", // AKA modify someone's permissions, disable them, etc
  "CreateUserTenant", // AKA invite a user to the org

  "ReadUserTenantRole",
  "WriteUserTenantRole",

  "ReadRole",
  "WriteRole",

  "ReadPerson",
  "WritePerson",

  "ReadFacilityContact",
  "WriteFacilityContact",

  "ReadSystemAuditLog",
  "ReadUserActivityLog",

  "CreateDataCollectionTask",
  "WriteDataCollectionTask", // write all fields (e.g. for Super Admin)
  "FulfillDataCollectionTask", // Write person, inventory and create/initialize new Tier II reports
  "WriteDataCollectionTaskTemplate",

  "WriteAllCalendarEvent",

  "VerifyTierIIReport",
] as const;

/** @deprecated use Permission from `@prisma/client` instead */
export type PermissionKey = typeof PERMISSION_KEYS[number];
export type PermissionsLookupType = Record<Permission, number>;

export const PermissionsLookup: PermissionsLookupType = {
  ReadAllFacility: 2,
  WriteAllFacility: 3,
  CreateFacility: 4,
  ReadAllUserTenant: 5,
  WriteAllUserTenant: 6,
  CreateUserTenant: 7,
  ReadUserTenantRole: 8,
  WriteUserTenantRole: 9,
  ReadRole: 10,
  WriteRole: 11,
  ReadPerson: 12,
  WritePerson: 13,
  ReadFacilityContact: 14,
  WriteFacilityContact: 15,
  ReadSystemAuditLog: 16,
  ReadUserActivityLog: 17,
  CreateDataCollectionTask: 18,
  WriteDataCollectionTask: 19,
  FulfillDataCollectionTask: 20,
  WriteDataCollectionTaskTemplate: 21,
  WriteAllCalendarEvent: 31,
  ReadOrgEventSequences: 32,
  WriteOrgEventSequences: 33,
  EditOwnFacilityPermissions: 34,
  ReadAllFacilityMinimal: 35,
  EditOwnFacilityPermissionsUnlimited: 36,
  VerifyTierIIReport: 37,

  // Route Permissions
  RouteEPCRA: 0,
  RouteRCRA: 1,
  RouteFacilities: 22,
  RouteCalendar: 23,
  RouteContacts: 24,
  RouteDocuments: 25,
  RouteEPCRAOverview: 26,
  RouteEPCRATierII: 27,
  RouteEPCRAReports: 28,
  RouteEPCRAProducts: 29,
  RouteEPCRACredentials: 30,
  RouteInsights: 38,
};

export const FacilityPermissionsRequiringWrite: Permission[] = [
  Permission.WriteFacilityContact,
  Permission.VerifyTierIIReport,
];

export enum RoleNamesEnum {
  superAdmin = "Super Admin",
  encampAllFacilitiesUser = "Encamp All Facilities User",
  encampFacilityUser = "Encamp Facility User",
  encampEHSFieldUser = "Encamp EHS Field User",
}

export type EncampRoleSeedsType = {
  [key: string]: {
    name: RoleNamesEnum;
    rolePermissions: Permission[];
    roleType: RoleTypeEnum;
  };
};

/**
 * Document that describes what each permission is used for
 * https://docs.google.com/spreadsheets/d/1dOGVSkSbrr_uYNC4B0qj1_iaY9dHB-l7QJUL9N7yaj8/edit?pli=1&gid=0#gid=0
 */
export const EncampRoleSeeds: EncampRoleSeedsType = {
  SuperAdmin: {
    name: RoleNamesEnum.superAdmin,
    rolePermissions: [...Object.values(Permission)],
    roleType: RoleTypeEnum.ENCAMP_ASSIGNABLE_BY_STAFF,
  },
  EncampAllFacilitiesUser: {
    name: RoleNamesEnum.encampAllFacilitiesUser,
    rolePermissions: [
      Permission.RouteEPCRA,
      Permission.RouteRCRA,
      Permission.RouteFacilities,
      Permission.RouteCalendar,
      Permission.RouteContacts,
      Permission.RouteDocuments,
      Permission.RouteEPCRAOverview,
      Permission.RouteEPCRATierII,
      Permission.RouteEPCRAReports,
      Permission.RouteEPCRAProducts,
      Permission.RouteEPCRACredentials,
      Permission.RouteInsights,
      Permission.ReadAllFacility,
      Permission.WriteAllFacility,
      Permission.ReadAllUserTenant,
      Permission.ReadUserTenantRole,
      Permission.ReadRole,
      Permission.ReadPerson,
      Permission.WritePerson,
      Permission.ReadFacilityContact,
      Permission.WriteFacilityContact,
      Permission.ReadSystemAuditLog,
      Permission.FulfillDataCollectionTask,
      Permission.WriteAllCalendarEvent,
      Permission.ReadOrgEventSequences,
      Permission.WriteOrgEventSequences,
      Permission.VerifyTierIIReport,
    ],
    roleType: RoleTypeEnum.ENCAMP_ASSIGNABLE_BY_USER,
  },
  EncampFacilityUser: {
    name: RoleNamesEnum.encampFacilityUser,
    rolePermissions: [
      Permission.RouteEPCRA,
      Permission.RouteRCRA,
      Permission.RouteFacilities,
      Permission.RouteCalendar,
      Permission.RouteContacts,
      Permission.RouteDocuments,
      Permission.RouteEPCRAOverview,
      Permission.RouteEPCRATierII,
      Permission.RouteEPCRAReports,
      Permission.RouteEPCRAProducts,
      Permission.RouteEPCRACredentials,
      Permission.RouteInsights,
      Permission.ReadAllUserTenant,
      Permission.ReadUserTenantRole,
      Permission.ReadRole,
      Permission.ReadPerson,
      Permission.WritePerson,
      Permission.ReadFacilityContact,
      Permission.WriteFacilityContact,
      Permission.ReadSystemAuditLog,
      Permission.FulfillDataCollectionTask,
      Permission.WriteAllCalendarEvent,
      Permission.VerifyTierIIReport,
    ],
    roleType: RoleTypeEnum.ENCAMP_ASSIGNABLE_BY_USER,
  },
  EncampEHSFieldUser: {
    name: RoleNamesEnum.encampEHSFieldUser,
    rolePermissions: [
      Permission.RouteEPCRA,
      Permission.RouteEPCRAReports,
      Permission.RouteCalendar,
      Permission.ReadAllUserTenant,
      Permission.ReadUserTenantRole,
      Permission.ReadRole,
      Permission.ReadPerson,
      Permission.WritePerson,
      Permission.ReadFacilityContact,
      Permission.ReadSystemAuditLog,
      Permission.FulfillDataCollectionTask,
    ],
    roleType: RoleTypeEnum.ENCAMP_ASSIGNABLE_BY_USER,
  },
};
