export function formatNumber(value: number | null | undefined): string {
  if (typeof value !== "number") return "";

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
}

// Abbreviates numbers with K, M, B, maximum of 2 numbers after the decimal point
export function formatNumberWithAbbreviation(
  amount: number | null | undefined
): string {
  if (typeof amount !== "number") return "";

  const abbreviations = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  for (const { value, symbol } of abbreviations) {
    if (amount >= value) {
      return `${formatter.format(amount / value)} ${symbol}`;
    }
  }

  return formatter.format(amount);
}
