import { useSuspenseQuery } from "@apollo/client";
import { Grid, GridProps, Typography } from "@mui/material";
import { amber, green, grey, red } from "@mui/material/colors";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import DonutChart from "components/Dashboard/DonutChart";
import { gql } from "generated-graphql";
import { useTenant } from "hooks/useTenant";
import { DateTime } from "luxon";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";

const MY_PROGRESS_QUERY = gql(`
  query MyProgress($tenantId: ID!, $filter: ChemicalOverviewFilter) {
    chemicalsOverviewMyProgress(tenantId: $tenantId, filter: $filter) {
      reportsRemaining
      reportsTotal
      dueDate
    }
  }
`);

export function MyProgressTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <MyProgressContent />
    </DashboardTile>
  );
}

function MyProgressContent() {
  const { tenantId } = useTenant();
  const {
    chemicalOverviewState: {
      deferredFilters: { assignedToMe, tagNames },
    },
  } = useChemicalOverviewState();

  const { data } = useSuspenseQuery(MY_PROGRESS_QUERY, {
    variables: { tenantId: tenantId ?? "", filter: { assignedToMe, tagNames } },
    fetchPolicy: "network-only",
  });

  const myProgress = data?.chemicalsOverviewMyProgress;

  const dueDate = myProgress ? DateTime.fromISO(myProgress.dueDate) : null;
  const daysRemaining = dueDate
    ? Math.ceil(dueDate.diffNow("days").days + 1) // + 1 for the current day
    : null;
  const now = DateTime.now();
  const daysLapsed =
    // Do not begin the countdown until January 1st of the due year
    now.year !== dueDate?.year
      ? 0
      : now.diff(DateTime.local(now.year, 1, 1), "days").days;

  return (
    <>
      <DashboardTile.Header
        title="My Progress"
        infoTooltip={<MyProgressTooltip />}
      ></DashboardTile.Header>
      {myProgress && (
        <DashboardTile.ContentArea>
          <ReportsRemainingDonutChart
            reportsRemaining={myProgress.reportsRemaining}
            reportsTotal={myProgress.reportsTotal}
          />
        </DashboardTile.ContentArea>
      )}
      {typeof daysRemaining === "number" &&
        typeof daysLapsed === "number" &&
        dueDate && (
          <DashboardTile.ContentArea>
            <DaysRemainingDonutChart
              daysRemaining={daysRemaining}
              daysLapsed={daysLapsed}
              dueDate={dueDate}
              now={now}
            />
          </DashboardTile.ContentArea>
        )}
    </>
  );
}

function MyProgressTooltip() {
  return (
    <Typography variant="caption">
      <Typography component="span" variant="caption" fontWeight={800}>
        My Progress
      </Typography>{" "}
      displays how many reports you still need to verify and submit to Encamp,
      along with how many days are left until the submission deadline.
    </Typography>
  );
}

function ReportsRemainingDonutChart({
  reportsRemaining,
  reportsTotal,
}: {
  reportsRemaining: number;
  reportsTotal: number;
}) {
  const { drillDownToFacilities } = useChemicalOverviewState();

  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography component="p" variant="caption">
        Reports Remaining to
      </Typography>
      <Typography component="p" variant="caption">
        Submit to Encamp
      </Typography>
      <DonutChart
        seriesData={[
          {
            id: "remaining",
            color: amber[200],
            value: reportsRemaining,
          },
          {
            id: "verified",
            color: grey[200],
            value: reportsTotal - reportsRemaining,
          },
        ]}
        seriesOverrides={{
          innerRadius: 40,
          outerRadius: 70,
          highlightScope: { highlight: "none" },
        }}
        label={`${reportsRemaining}`}
        subLabel={`of ${reportsTotal.toLocaleString()}`}
        containerHeight="10rem"
        showLegend={false}
        showTooltip={false}
        onItemClick={(event, pieItemIdentifier, item) => {
          drillDownToFacilities((f) => ({
            ...f,
            organizationStatus:
              item.id === "verified" ? "VERIFIED" : "!VERIFIED|!NOT_REPORTING",
          }));
        }}
      />
    </Grid>
  );
}

function DaysRemainingDonutChart({
  daysRemaining,
  daysLapsed,
  dueDate,
  now,
}: {
  daysRemaining: number;
  daysLapsed: number;
  dueDate: DateTime;
  now: DateTime;
}) {
  const remainingValue =
    now.year === dueDate.year
      ? daysRemaining
      : dueDate.diff(DateTime.local(dueDate.year, 1, 1), "days").days;

  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="p" variant="caption">
        Days Remaining to
      </Typography>
      <Typography component="p" variant="caption">
        Submit to Encamp
      </Typography>
      <DonutChart
        seriesData={[
          {
            id: "remaining",
            color:
              remainingValue <= 10
                ? red[400]
                : remainingValue <= 30
                ? amber[200]
                : green[300],
            value: remainingValue,
          },
          {
            id: "lapsed",
            color: grey[200],
            value: daysLapsed,
          },
        ]}
        seriesOverrides={{
          innerRadius: 40,
          outerRadius: 70,
          highlightScope: { highlight: "none" },
        }}
        label={`${daysRemaining}`}
        subLabel="days"
        aboveLabel={dueDate.toFormat("LLL d")}
        containerHeight="10rem"
        showLegend={false}
        showTooltip={false}
      />
    </Grid>
  );
}
