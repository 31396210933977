import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { EditContactDialog } from "components/Contacts/EditContactDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { getFullName } from "encamp-shared/src/utils/name";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { ContactsQuery, PhoneType } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useMemo, useState } from "react";
import { DeleteContactDialog } from "./DeleteContactDialog";
import { StaffBulkActionsMenu } from "./StaffBulkActionsMenu";

function printPhoneType(type: PhoneType) {
  switch (type) {
    case PhoneType.Hrs_24:
      return "24-hour";
    default:
      return prettyPrintEnumValue(type);
  }
}

export type ContactGridProps = {
  tenantId: string;
  commandButtons?: React.ReactNode[];
};

export type Row = ContactsQuery["people"]["items"][number];

export function ContactGrid({ tenantId, commandButtons }: ContactGridProps) {
  const theme = useTheme();
  const { isStaff } = useCurrentUser();
  const [dialogState, setDialogState] = useState<"edit" | "delete" | null>(
    null
  );
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([]);

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 0.2,
        renderCell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                py: theme.spacing(1),
                display: "flex",
                alignItems: "start",
                height: "100%",
              }}
            >
              {getFullName(row)}
            </Typography>
          );
        },
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 0.2,
        sortable: false,
        renderCell: (params) => (
          <Box
            sx={{
              py: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              height: "100%",
            }}
          >
            {params.row.phones?.map((p, index) => (
              <Typography key={index} variant="body2">
                {p.number} ({printPhoneType(p.type)})
              </Typography>
            ))}
          </Box>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        flex: 0.2,
        renderCell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                py: theme.spacing(1),
                display: "flex",
                alignItems: "start",
                height: "100%",
              }}
            >
              {row.email}
            </Typography>
          );
        },
      },
      {
        field: "numFacilities",
        headerName: "Facilities",
        flex: 0.1,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                py: theme.spacing(1),
                display: "flex",
                alignItems: "start",
                height: "100%",
              }}
            >
              {row.facilityContacts?.length || ""}
            </Typography>
          );
        },
      },
      {
        field: "issues",
        headerName: "Issues",
        sortable: false,
        flex: 0.1,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => {
          // We aggregate all of the facility contact issues onto the `person`
          const personIssues = row.issues?.length ?? 0;
          return (
            <Box
              sx={{
                py: theme.spacing(1),
                display: "flex",
                alignItems: "start",
                height: "100%",
              }}
            >
              <IssueCount issueCount={personIssues} />
            </Box>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.1,
        getActions: (params) => [
          <Tooltip title="Edit contact" key={1}>
            <GridActionsCellItem
              onClick={() => {
                setSelectedPersonId(params.row.id);
                setDialogState("edit");
              }}
              label="Edit contact"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Delete contact" key={1}>
            <GridActionsCellItem
              onClick={() => {
                setSelectedPersonId(params.row.id);
                setDialogState("delete");
              }}
              label="Delete contact"
              icon={<Delete />}
            />
          </Tooltip>,
        ],
      },
    ],
    [theme]
  );

  const peopleQuery = gql(/* GraphQL */ `
    query Contacts(
      $search: String
      $page: Int
      $pageSize: Int
      $sort: [SortModel!]
    ) {
      people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          first
          last
          email
          user {
            id
          }
          phones {
            type
            number
          }
          facilityContacts {
            id
            reportingRoles
            facility {
              id
              name
              streetAddress1
              streetAddress2
              city
              state
              zip
              country
            }
          }
          issues {
            message
          }
        }
        count
      }
    }
  `);

  const buttons = useMemo(() => {
    const btns = [
      ...(commandButtons ?? []),
      <Button
        key="add"
        variant="contained"
        onClick={() => {
          setSelectedPersonId(null);
          setDialogState("edit");
        }}
      >
        Add Contact
      </Button>,
    ];

    if (isStaff) {
      btns.push(
        <StaffBulkActionsMenu key="staff" contactIds={selectedContactIds} />
      );
    }

    return btns;
  }, [commandButtons, isStaff, selectedContactIds]);

  return (
    <>
      <OmnisearchDataGrid
        columns={columns}
        showFavorites={true}
        dataQuery={peopleQuery}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        defaultSearch={"tenantId:" + tenantId}
        commandButtons={buttons}
        excludeFilterColumns={["phone", "numFacilities", "issues"]}
        additionalFilterColumns={[
          {
            key: "facilityName",
            header: "Facility Name",
            filterKeyType: "facility",
          },
        ]}
        noDataMessage="Organization has no contacts."
        getItems={(data) => data.people.items}
        getCount={(data) => data.people.count}
        rowHeight={"auto"}
        onRowClick={(props) => {
          setSelectedPersonId(props.row.id);
          setDialogState("edit");
        }}
        onSelectedIdsChanged={
          isStaff ? (ids) => setSelectedContactIds(ids as string[]) : undefined
        }
        isRowSelectable={(params) => {
          return !params.row.user?.id;
        }}
        disableRowSelectionOnClick={true}
      />

      {dialogState === "delete" && (
        <DeleteContactDialog
          open
          onClose={() => {
            setSelectedPersonId(null);
            setDialogState(null);
          }}
          personId={selectedPersonId ?? ""}
        />
      )}

      {dialogState === "edit" && (
        <EditContactDialog
          open
          onClose={() => {
            setSelectedPersonId(null);
            setDialogState(null);
          }}
          onSave={async (person) => {
            if (!selectedPersonId && person?.id) {
              setSelectedPersonId(person?.id ?? undefined);
            } else {
              setSelectedPersonId(null);
              setDialogState(null);
            }
          }}
          personId={selectedPersonId ?? undefined}
          refetchQueries={["Contacts"]}
        />
      )}
    </>
  );
}
