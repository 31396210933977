export interface Audit {
  auditId: number;
  recordId: string;
  operation: string;
  timestamp: Date;
  user?: string;
  session?: string;
  tableOid: number;
  tableSchema: string;
  tableName: string;
  record?: any;
  oldRecord?: any;
}

export interface AuditedTable {
  id: string;
  tableName: string;
  shouldAudit: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Chemical {
  id: string;
  needsReview: boolean;
  name: string;
  casNumber?: string;
  noCasNumber?: boolean;
  alternateId?: string;
  isEhs: boolean;
  pureOrMixture?: PureOrMixture;
  stateOfMatter?: StateOfMatter[];
  isTradeSecret: boolean;
  density?: number;
  densityUnits?: DensityUnits;
  manufacturerSupplier?: string;
  recommendedUse?: string;
  version?: string;
  revisionDate?: Date;
  physicalHazards?: PhysicalHazard[];
  healthHazards?: HealthHazard[];
  oregonHazards?: OregonHazard[];
  ifcHazards?: InternationalFireCodeHazard[];
  noHazardsNotReporting?: boolean;
  ehsForm?: EhsForm;
  createdAtViaMerge?: Date;
  productChemical?: ProductChemical[];
  components?: ChemicalComponent[];
  stateFields?: ChemicalStateField[];
  chemicalTenants?: ChemicalTenant[];
  documents?: ChemicalDocument[];
  facilityChemicals?: FacilityChemical[];
  facilityChemicalMeasurements?: FacilityChemicalMeasurement[];
  reportingFacilityChemicals?: ReportingFacilityChemical[];
  facilityChemicalStateFields?: FacilityChemicalStateField[];
  verifiedAt?: Date;
  verifiedBy?: string;
  verifier?: User;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ChemicalComponent {
  id: string;
  chemicalId: string;
  name: string;
  casNumber?: string;
  noCasNumber?: boolean;
  isEhs: boolean;
  componentPercentage?: number;
  weightOrVolume?: WeightOrVolume;
  ehsForm?: EhsForm;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  chemical: Chemical;
}

export interface ChemicalDocument {
  id: string;
  key: string;
  name: string;
  documentId: string;
  document: Document;
  chemicalId: string;
  chemical: Chemical;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ChemicalMap {
  id: string;
  state?: string;
  chemicalId?: string;
  encampName: string;
  encampCasNumber?: string;
  mappedName: string;
  mappedCasNumber?: string;
  mappedEhs?: boolean;
  tenantName?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ChemicalStateField {
  id: string;
  chemicalId: string;
  key: string;
  value: any;
  type: ProgramArea;
  jurisdiction: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  chemical: Chemical;
}

export interface ChemicalTenant {
  id: string;
  chemicalId: string;
  tenantId: string;
  chemical: Chemical;
  tenant: Tenant;
}

export interface Comment {
  id: string;
  reportId?: string;
  userId?: string;
  workflowId?: string;
  activityId?: string;
  comment: string;
  user?: User;
  report?: TierIIReport;
  workflow?: Workflow;
  activity?: Activity;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ComplianceEngineCache {
  id: string;
  rootModel: CacheRootModel;
  programArea: ProgramArea;
  tenantId?: string;
  partnerTenantId?: string;
  cacheVersion: number;
  complianceEngineVersionId: string;
  version: ComplianceEngineVersion;
  issues?: ComplianceEngineCacheIssue[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export interface ComplianceEngineCacheEvictionLog {
  id: string;
  cacheId: string;
  rootModel: CacheRootModel;
  sourceId: string;
  complianceEngineVersionId: string;
  tgOp: string;
  tgTableName: string;
  tgName: string;
  tgWhen: string;
  createdAt: Date;
}

export interface ComplianceEngineCacheIssue {
  id: string;
  cacheId: string;
  programArea: ProgramArea;
  cache: ComplianceEngineCache;
  tenantId?: string;
  modelId: string;
  modelName: string;
  key: string;
  message: string;
  jurisdiction?: string;
  createdAt: Date;
  createdBy: string;
}

export interface ComplianceEngineVersion {
  id: string;
  version: number;
  rulesHash: string;
  commitHash: string;
  caches?: ComplianceEngineCache[];
  createdAt: Date;
}

export interface ComplianceQuarter {
  quarter: string;
  cwaIndex?: number;
  caaIndex?: number;
  rcraIndex?: number;
  overallIndex?: number;
}

export interface AlternateIdCredential {
  id: string;
  credentialId: string;
  alternateIdValue: string;
  alternateIdType: string;
  alternateIdMetadata?: any;
  credential: Credential;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Credential {
  id: string;
  tenantId?: string;
  isEncampOwned: boolean;
  name?: string;
  username: string;
  password: string;
  kind: CredentialKind;
  securityQuestions: any;
  verificationStatus: CredentialVerificationStatus;
  lastVerificationSuccessAt?: Date;
  lastVerificationCheckAt?: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  tenant?: Tenant;
  facilityCredentials?: FacilityCredential[];
  alternateIdCredentials?: AlternateIdCredential[];
  CredentialUsageLog?: CredentialUsageLog[];
}

export interface CredentialUsageLog {
  id: string;
  credentialId: string;
  jobId?: string;
  verificationStatus: CredentialVerificationStatus;
  errorMessage?: string;
  credential: Credential;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Document {
  id: string;
  tenantId?: string;
  tenant?: Tenant;
  title: string;
  description?: string;
  fileExtension: string;
  state?: string;
  area?: string;
  documentType: DocumentType;
  storageLink: string;
  lobId?: string;
  reportId?: string;
  report?: TierIIReport;
  chemicalDocument?: ChemicalDocument[];
  payments?: Payment[];
  paymentInvoices?: PaymentInvoice[];
  isArchived?: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  activities?: ActivityDocument[];
  facilityDocuments?: FacilityDocument[];
  tasks?: Task[];
  documentTags?: DocumentTag[];
  job?: Job[];
}

export interface DocumentTag {
  id: string;
  tagId: string;
  documentId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  tag: Tag;
  document: Document;
}

export interface EmulatedRole {
  id: string;
  sessionId: string;
  userId: string;
  user: User;
  tenantId: string;
  tenant: Tenant;
  permissions?: Permission[];
  createdAt: Date;
  updatedAt: Date;
  expiresAt: Date;
}

export interface CalculatedRegulatoryStatus {
  id: string;
  facilityId: string;
  facility: Facility;
  periodStart: Date;
  periodInterval: PeriodInterval;
  type: RegulatoryType;
  source: string;
  status: RegulatoryStatus;
  determinationFactors: any;
  createdAt: Date;
  createdBy?: string;
  updatedAt: Date;
  updatedBy?: string;
}

export interface CalculationOperation {
  id: string;
  reportingFacilityChemicalId: string;
  reportingFacilityChemical: ReportingFacilityChemical;
  operation: any;
  createdAt: Date;
}

export interface Facility {
  id: string;
  tenantId: string;
  name: string;
  status: FacilityStatus;
  statusReason?: string;
  epaGeneratorStatus?: string;
  epaGeneratorStatusUpdatedAt?: Date;
  epaGeneratorStatusUpdatedBy?: string;
  stateGeneratorStatus?: string;
  stateGeneratorStatusUpdatedAt?: Date;
  stateGeneratorStatusUpdatedBy?: string;
  businessUnit?: string;
  customerFacilityId?: string;
  dbNumber?: string;
  facilityType?: string;
  naicsCode?: number;
  maxNumOccupants?: number;
  isUnmanned?: boolean;
  phone?: string;
  phone24Hour?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  mailingStreetAddress1?: string;
  mailingStreetAddress2?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZip?: string;
  mailingCountry?: string;
  isMailingAddressSameAsFacility?: boolean;
  isMailingAddressEncamp?: boolean;
  county?: string;
  municipality?: string;
  latitude?: number;
  longitude?: number;
  isAutoGeocoded?: boolean;
  dateOpened?: Date;
  dateClosed?: Date;
  dateInactive?: Date;
  searchableProgramAreas?: string;
  searchableStatus?: string;
  searchableCity?: string;
  lastProductMeasuredAt?: Date;
  streetAddressVerifiedAt?: Date;
  isSubjectToEmergencyPlanning?: boolean;
  isSubjectToEmergencyPlanningInternal?: boolean;
  isSubjectToChemicalAccidentPrevention?: boolean;
  isSubjectToToxicsReleaseInventoryReporting?: boolean;
  collectionMode?: CollectionMode;
  companyName?: string;
  department?: string;
  isInactive?: boolean;
  isClosed?: boolean;
  colocationReportingFacilityId?: string;
  isNotReporting?: boolean;
  isNotReportingThroughEncamp?: boolean;
  reportsManually?: boolean;
  colocatedByFacilties?: Facility[];
  colocationReportingFacility?: Facility;
  tierIIReports?: TierIIReport[];
  facilityDocuments?: FacilityDocument[];
  stateFields?: FacilityStateField[];
  contacts?: FacilityContact[];
  users?: UserFacility[];
  facilityCredentials?: FacilityCredential[];
  facilityAlternateIds?: FacilityAlternateId[];
  storageLocations?: StorageLocation[];
  facilityChemicals?: FacilityChemical[];
  facilityChemicalMeasurements?: FacilityChemicalMeasurement[];
  processedManifest?: ProcessedManifest[];
  facilityProducts?: FacilityProduct[];
  facilityProductMeasurements?: FacilityProductMeasurement[];
  facilityChemicalStateFields?: FacilityChemicalStateField[];
  facilityTags?: FacilityTag[];
  reportingFacilityChemicalThresholdResults?: ReportingFacilityChemicalThresholdResult[];
  wasteStreams?: FacilityWasteStream[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  tenant: Tenant;
  reportingFacilityChemicals?: ReportingFacilityChemical[];
  payments?: FacilityPayment[];
  jobs?: Job[];
  fireDepartmentId?: string;
  lepcId?: string;
  fireDepartment?: FireDepartment;
  lepc?: Lepc;
  tasks?: Task[];
  echoFacilityMatchCandidates?: EchoFacilityMatchCandidate[];
  fireDepartmentName?: string;
  lepcName?: string;
  facilityEchoExporter?: undefined;
  CalculatedRegulatoryStatus?: CalculatedRegulatoryStatus[];
  facilityModelIssues?: FacilityModelIssue[];
}

export interface FacilityAlternateId {
  id: string;
  facilityId: string;
  value: string;
  hasNoId: boolean;
  type: string;
  expiresAt?: Date;
  tenantId: string;
  tenant: Tenant;
  facility: Facility;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityChemical {
  id: string;
  facilityId: string;
  chemicalId: string;
  reportingYear: number;
  maxAmount?: number;
  maxAmountCode?: number;
  averageAmount?: number;
  averageAmountCode?: number;
  maxAmountLargestContainer?: number;
  daysOnSite?: number;
  hasConfidentialStorageLocations?: boolean;
  unit?: UnitType;
  isAlwaysReported?: boolean;
  facility: Facility;
  chemical: Chemical;
  storageLocations?: FacilityChemicalStorageLocation[];
  reportingFacilityChemical?: ReportingFacilityChemicalOrigin;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityChemicalMeasurement {
  id: string;
  facilityId: string;
  chemicalId: string;
  storageLocationId: string;
  amount: number;
  unit: UnitType;
  measuredAtUtc: Date;
  storageType?: string;
  storageTypeDescription?: string;
  pressure?: Pressure;
  temperature?: Temperature;
  otherPressureValue?: string;
  facility: Facility;
  chemical: Chemical;
  storageLocation: StorageLocation;
  reportingFacilityChemicals?: ReportingFacilityChemicalOrigin[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityChemicalStateField {
  id: string;
  facilityId: string;
  facility: Facility;
  chemicalId: string;
  chemical: Chemical;
  reportingYear: number;
  key: string;
  value?: any;
  type: ProgramArea;
  jurisdiction: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityChemicalStorageLocation {
  id: string;
  facilityChemicalId: string;
  facilityChemical: FacilityChemical;
  storageLocationId: string;
  storageLocation: StorageLocation;
  storageType?: string;
  storageTypeDescription?: string;
  pressure?: Pressure;
  temperature?: Temperature;
  maxAmount?: number;
  unit?: UnitType;
  otherPressureValue?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityContact {
  id: string;
  facilityId: string;
  facility: Facility;
  personId: string;
  person: Person;
  reportingRoles?: ContactReportRole[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityCredential {
  id: string;
  tenantId: string;
  facilityId: string;
  credentialId: string;
  isEncampOwned: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  credential: Credential;
  facility: Facility;
}

export interface FacilityDocument {
  id: string;
  documentId: string;
  facilityId: string;
  document: Document;
  facility: Facility;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityPayment {
  payment: Payment;
  paymentId: string;
  facility: Facility;
  facilityId: string;
  amount?: number;
}

export interface FacilityProduct {
  id: string;
  facilityId: string;
  productId: string;
  reportingYear: number;
  maxQuantity?: number;
  averageQuantity?: number;
  daysOnSite?: number;
  hasConfidentialStorageLocations?: boolean;
  facility: Facility;
  product: Product;
  storageLocations?: FacilityProductStorageLocation[];
  reportingFacilityChemicals?: ReportingFacilityChemicalOrigin[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityProductMeasurement {
  id: string;
  facilityId: string;
  productId: string;
  storageLocationId: string;
  measuredAtUtc: Date;
  quantity: number;
  facility: Facility;
  product: Product;
  storageLocation: StorageLocation;
  reportingFacilityChemicals?: ReportingFacilityChemicalOrigin[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityProductStorageLocation {
  id: string;
  facilityProductId: string;
  facilityProduct: FacilityProduct;
  storageLocationId: string;
  storageLocation: StorageLocation;
  maxQuantity?: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityStateField {
  id: string;
  tenantId: string;
  facilityId: string;
  key: string;
  value: any;
  type: ProgramArea;
  jurisdiction: string;
  facility: Facility;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityTag {
  id: string;
  tagId: string;
  facilityId: string;
  tag: Tag;
  facility: Facility;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ProcessedManifest {
  id: string;
  tenantId: string;
  facilityId?: string;
  manifestTrackingNumber: string;
  eManifestId?: string;
  vendorManifestId?: string;
  shippedDate?: Date;
  epaId?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  facility?: Facility;
  tenant: Tenant;
  eManifest?: WasteManifest;
  vendorManifest?: WasteManifest;
}

export interface ReportingFacilityChemical {
  id: string;
  facilityId: string;
  facility: Facility;
  chemicalId: string;
  chemical: Chemical;
  reportingYear: number;
  maxAmount?: number;
  maxAmountCode?: number;
  averageAmount?: number;
  averageAmountCode?: number;
  maxAmountLargestContainer?: number;
  daysOnSite?: number;
  hasConfidentialStorageLocations?: boolean;
  unit?: UnitType;
  isAlwaysReported?: boolean;
  isOverThreshold?: boolean;
  thresholdReason?: ThresholdReason;
  manuallyEdited: boolean;
  aggregationErrorMessage?: string;
  aggregationError?: any;
  storageLocations?: ReportingFacilityChemicalStorageLocation[];
  origin?: ReportingFacilityChemicalOrigin;
  calculationOperations?: CalculationOperation[];
  thresholdResults?: ReportingFacilityChemicalThresholdResult[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ReportingFacilityChemicalOrigin {
  id: string;
  reportingFacilityChemicalId?: string;
  reportingFacilityChemical?: ReportingFacilityChemical;
  facilityProducts?: FacilityProduct[];
  facilityProductMeasurements?: FacilityProductMeasurement[];
  facilityChemicalMeasurements?: FacilityChemicalMeasurement[];
  facilityChemicalId?: string;
  facilityChemical?: FacilityChemical;
}

export interface ReportingFacilityChemicalStorageLocation {
  id: string;
  reportingFacilityChemicalId: string;
  reportingFacilityChemical: ReportingFacilityChemical;
  storageLocationId: string;
  storageLocation: StorageLocation;
  maxAmount?: number;
  unit?: UnitType;
  storageType?: string;
  storageTypeDescription?: string;
  pressure?: Pressure;
  temperature?: Temperature;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ReportingFacilityChemicalThresholdResult {
  id: string;
  reportingFacilityChemicalId: string;
  reportingFacilityChemical: ReportingFacilityChemical;
  thresholdResultId: string;
  thresholdResult: ThresholdResult;
  facilityId?: string;
  facility?: Facility;
  updatedAt: Date;
  createdAt: Date;
}

export interface ThresholdResult {
  id: string;
  threshold: number;
  unit: UnitType;
  jurisdiction: ThresholdResultJurisdictionType;
  description: string;
  overThreshold: boolean;
  isSection312: boolean;
  isSection302: boolean;
  type: ThresholdResultType;
  casNumber: string;
  reportingFacilityChemicals?: ReportingFacilityChemicalThresholdResult[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FireDepartment {
  id: string;
  name: string;
  state: string;
  city: string;
  county?: string;
  zip: string;
  mailingAddress: string;
  mailingAddressLine2?: string;
  phone?: string;
  phoneExt?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  latitude?: number;
  longitude?: number;
  additionalComments?: string;
  stateSubmitsToFireDept: boolean;
  needsT2sFile: boolean;
  isMailed: boolean;
  isEmailed: boolean;
  portalId?: string;
  portalName?: string;
  preferUncertified: boolean;
  isSubmittedViaPortal: boolean;
  facilities?: Facility[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface EchoAirFacility {
  pgmSysId: string;
  registryId?: string;
  facilityName: string;
  streetAddress?: string;
  city?: string;
  countyName?: string;
  state?: string;
  zipCode?: string;
  epaRegion?: string;
  sicCodes?: string[];
  naicsCodes?: string[];
  facilityTypeCode?: string;
  airPollutantClassCode?: string;
  airPollutantClassDesc?: string;
  airOperatingStatusCode?: string;
  airOperatingStatusDesc?: string;
  currentHpv?: string;
  localControlRegionCode?: string;
  localControlRegionName?: string;
  echoAirViolationHistory?: EchoAirViolationHistory[];
}

export interface EchoAirViolationHistory {
  pgmSysId: string;
  activityId: string;
  agencyTypeDesc?: string;
  stateCode?: string;
  airLconCode?: string;
  compDeterminationUid?: string;
  enfResponsePolicyCode?: string;
  programCodes?: string;
  programDescs?: string;
  pollutantCodes?: string;
  pollutantDescs?: string;
  earliestFrvDetermDate?: Date;
  hpvDayzeroDate?: Date;
  hpvResolvedDate?: Date;
  dscvPathwayDate?: Date;
  nftcPathwayDate?: Date;
  echoAirFacility: EchoAirFacility;
}

export interface CaseEnforcements {
  activityId: string;
  activityName?: string;
  stateCode?: string;
  regionCode?: string;
  fiscalYear?: number;
  caseNumber?: string;
  caseName?: string;
  activityTypeCode?: string;
  activityTypeDesc?: string;
  activityStatusCode?: string;
  activityStatusDesc?: string;
  activityStatusDate?: Date;
  lead?: string;
  caseStatusDate?: Date;
  dojDocketNmbr?: string;
  enfOutcomeCode?: string;
  enfOutcomeDesc?: string;
  totalPenaltyAssessedAmt?: number;
  totalCostRecoveryAmt?: number;
  totalCompActionAmt?: number;
  hqDivision?: string;
  branch?: string;
  voluntarySelfDisclosureFlag?: boolean;
  multimediaFlag?: boolean;
  enfSummaryText?: string;
  casePrograms?: CasePrograms[];
  caseEnforcementConclusions?: CaseEnforcementConclusions[];
  caseEnforcementType?: CaseEnforcementType[];
  casePriorities?: CasePriorities[];
  casePollutants?: CasePollutants[];
  caseLawSections?: CaseLawSections[];
  caseViolations?: CaseViolations[];
  casePenalties?: CasePenalties[];
  caseFacilities?: CaseFacilities[];
  caseDefendants?: CaseDefendants[];
  caseRelatedActivities?: CaseRelatedActivities[];
  caseRegionalDockets?: CaseRegionalDockets[];
  caseReliefSought?: CaseReliefSought[];
  caseMilestones?: CaseMilestones[];
}

export interface CasePrograms {
  activityId: string;
  caseNumber?: string;
  programCode: string;
  programDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseEnforcementConclusions {
  enfConclusionId: string;
  activityId: string;
  caseNumber?: string;
  enfConclusionNmbr: string;
  enfConclusionActionCode?: string;
  enfConclusionName?: string;
  settlementLodgedDate?: Date;
  settlementEnteredDate?: Date;
  settlementFy?: number;
  primaryLaw?: string;
  regionCode?: string;
  activityTypeCode?: string;
  fedPenaltyAssessedAmt?: number;
  stateLocalPenaltyAmt?: number;
  sepAmt?: number;
  complianceActionCost?: number;
  costRecoveryAwardedAmt?: number;
  caseEnforcement: CaseEnforcements;
  pollutants?: CaseEnforcementConclusionPollutants[];
  facilities?: CaseEnforcementConclusionFacilities[];
  dollars?: CaseEnforcementConclusionDollars[];
  sep?: CaseEnforcementConclusionSep[];
  complyingActions?: CaseEnforcementConclusionComplyingActions[];
}

export interface CaseEnforcementType {
  activityId: string;
  caseNumber?: string;
  enfTypeCode: string;
  enfTypeDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CasePriorities {
  activityId: string;
  caseNumber?: string;
  priorityCode: number;
  priorityDesc?: string;
  fiscalYear?: number;
  caseEnforcement: CaseEnforcements;
}

export interface CasePollutants {
  activityId: string;
  caseNumber?: string;
  pollutantCode: number;
  pollutantDesc?: string;
  chemicalAbstractServiceNmbr?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseLawSections {
  activityId: string;
  caseNumber?: string;
  rankOrder?: number;
  statuteCode?: string;
  lawSectionCode: string;
  lawSectionDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseViolations {
  activityId: string;
  caseNumber?: string;
  violationTypeCode: string;
  rankOrder?: number;
  violationTypeDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseEnforcementConclusionPollutants {
  environmentalImpactId: string;
  enfConclusionId: string;
  activityId?: string;
  caseNumber?: string;
  compActionId?: string;
  sepId?: string;
  pollutantCode?: string;
  pollutantName?: string;
  averageAnnualValue?: number;
  pollutantUnitCode?: string;
  mediaCode?: string;
  mediaDesc?: string;
  sepOrCompFlag?: string;
  enforcementConclusion: CaseEnforcementConclusions;
}

export interface EpaInformalEnforcementActions {
  pgmSysId: string;
  registryId?: string;
  agency?: string;
  activityTypeCode?: string;
  enfTypeCode?: string;
  enfTypeDesc?: string;
  achievedDate?: Date;
  enfIdentifier?: string;
  statute?: string;
}

export interface CasePenalties {
  activityId: string;
  caseNumber?: string;
  fedPenalty?: number;
  stLclPenalty?: number;
  totalSep?: number;
  complianceActionCost?: number;
  federalCostRecoveryAmt?: number;
  stateLocalCostRecoveryAmt?: number;
  penaltyCollectedAmt?: number;
  caseEnforcement: CaseEnforcements;
}

export interface CaseFacilities {
  activityId: string;
  caseNumber?: string;
  registryId: string;
  facilityName?: string;
  locationAddress?: string;
  city?: string;
  stateCode?: string;
  zip?: string;
  primarySicCode?: string;
  primaryNaicsCode?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseDefendants {
  activityId: string;
  caseNumber?: string;
  defendantName: string;
  namedInComplaintFlag?: boolean;
  namedInSettlementFlag?: boolean;
  caseEnforcement: CaseEnforcements;
}

export interface CaseRelatedActivities {
  activityId: string;
  caseNumber?: string;
  activityStatusDate?: Date;
  activityTypeCode: string;
  activityTypeDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseEnforcementConclusionFacilities {
  enfConclusionId: string;
  activityId?: string;
  caseNumber?: string;
  icisFacilityInterestId?: string;
  facilityUin: string;
  facilityName?: string;
  facilityCity?: string;
  facilityState?: string;
  facilityZip?: string;
  enforcementConclusion: CaseEnforcementConclusions;
}

export interface CaseEnforcementConclusionDollars {
  enfConclusionId: string;
  activityId?: string;
  caseNumber?: string;
  stateLocalPenaltyAmt?: number;
  costRecoveryAmt?: number;
  fedPenalty?: number;
  complianceActionCost?: number;
  sepCost?: number;
  penaltyCollectedAmt?: number;
  enforcementConclusion: CaseEnforcementConclusions;
}

export interface IcisFecEpaInspections {
  activityId: string;
  registryId?: string;
  pgmSysAcrnm?: string;
  pgmSysId?: string;
  facilityName?: string;
  epaRegionCode?: string;
  activityTypeCode?: string;
  activityTypeDesc?: string;
  compMonitorUid?: string;
  compMonitorTypes?: string;
  statuteCode?: string;
  lawSectionCode?: string;
  actualBeginDate?: Date;
  actualEndDate?: Date;
  agency?: string;
  compMonitorAgencyTypes?: string;
}

export interface CaseRegionalDockets {
  activityId: string;
  caseNumber?: string;
  regionalDocketNmbr: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseReliefSought {
  activityId: string;
  caseNumber?: string;
  reliefCode: string;
  reliefDesc?: string;
  caseEnforcement: CaseEnforcements;
}

export interface CaseEnforcementConclusionSep {
  enfConclusionId: string;
  activityId?: string;
  caseNumber?: string;
  sepCategoryCode: string;
  sepCategoryDesc?: string;
  sepId?: string;
  sepText?: string;
  sepAmt?: number;
  enforcementConclusion: CaseEnforcementConclusions;
}

export interface CaseEnforcementConclusionComplyingActions {
  compActionId: string;
  enfConclusionId: string;
  activityId?: string;
  caseNumber?: string;
  compActionDescription?: string;
  compActionTypeCode?: string;
  compActionTypeDesc?: string;
  compActionCategoryTypeDesc?: string;
  enforcementConclusion: CaseEnforcementConclusions;
}

export interface CaseMilestones {
  activityId: string;
  caseNumber?: string;
  subActivityTypeCode: string;
  subActivityTypeDesc?: string;
  actualDate: Date;
  caseEnforcement: CaseEnforcements;
}

export interface EchoExporter {
  registryId: string;
  facName?: string;
  facStreet?: string;
  facCity?: string;
  facState?: string;
  facZip?: string;
  facCounty?: string;
  facFipsCode?: string;
  facEpaRegion?: string;
  facIndianCntryFlg?: boolean;
  facFederalFlg?: boolean;
  facUsMexBorderFlg?: boolean;
  facChesapeakeBayFlg?: boolean;
  facNaaFlag?: boolean;
  facLat?: number;
  facLong?: number;
  facMapIcon?: string;
  facCollectionMethod?: string;
  facReferencePoint?: string;
  facAccuracyMeters?: number;
  facDerivedTribes?: string;
  facDerivedHuc?: string;
  facDerivedWbd?: string;
  facDerivedStctyFips?: string;
  facDerivedZip?: string;
  facDerivedCd113?: string;
  facDerivedCb2010?: string;
  facPercentMinority?: number;
  facPopDen?: number;
  facMajorFlag?: boolean;
  facActiveFlag?: boolean;
  facMyrtkUniverse?: string;
  facInspectionCount?: number;
  facDateLastInspection?: Date;
  facDaysLastInspection?: number;
  facInformalCount?: number;
  facDateLastInformalAction?: Date;
  facFormalActionCount?: number;
  facDateLastFormalAction?: Date;
  facTotalPenalties?: number;
  facPenaltyCount?: number;
  facDateLastPenalty?: Date;
  facLastPenaltyAmt?: number;
  facQtrsWithNc?: number;
  facProgramsWithSnc?: number;
  facComplianceStatus?: string;
  facSncFlg?: boolean;
  fac3yrComplianceHistory?: EchoQuarterlyHistory[];
  airFlag?: boolean;
  npdesFlag?: boolean;
  sdwisFlag?: boolean;
  rcraFlag?: boolean;
  triFlag?: boolean;
  ghgFlag?: boolean;
  airIds?: string[];
  caaPermitTypes?: string[];
  caaNaics?: string[];
  caaSics?: string[];
  caaEvaluationCount?: number;
  caaDaysLastEvaluation?: number;
  caaInformalCount?: number;
  caaFormalActionCount?: number;
  caaDateLastFormalAction?: Date;
  caaPenalties?: number;
  caaLastPenaltyDate?: Date;
  caaLastPenaltyAmt?: number;
  caaQtrsWithNc?: number;
  caaComplianceStatus?: string;
  caaHpvFlag?: boolean;
  caa3yrComplQtrsHistory?: EchoQuarterlyHistory[];
  npdesIds?: string[];
  cwaPermitTypes?: string[];
  cwaComplianceTracking?: string;
  cwaNaics?: string[];
  cwaSics?: string[];
  cwaInspectionCount?: number;
  cwaDaysLastInspection?: number;
  cwaInformalCount?: number;
  cwaFormalActionCount?: number;
  cwaDateLastFormalAction?: Date;
  cwaPenalties?: number;
  cwaLastPenaltyDate?: Date;
  cwaLastPenaltyAmt?: number;
  cwaQtrsWithNc?: number;
  cwaComplianceStatus?: string;
  cwaSncFlag?: boolean;
  cwa13qtrsComplHistory?: EchoQuarterlyHistory[];
  cwa13qtrsEfflntExceedances?: number;
  cwa3yrQncrCodes?: string;
  rcraIds?: string[];
  rcraPermitTypes?: string[];
  rcraNaics?: string[];
  rcraInspectionCount?: number;
  rcraDaysLastEvaluation?: number;
  rcraInformalCount?: number;
  rcraFormalActionCount?: number;
  rcraDateLastFormalAction?: Date;
  rcraPenalties?: number;
  rcraLastPenaltyDate?: Date;
  rcraLastPenaltyAmt?: number;
  rcraQtrsWithNc?: number;
  rcraComplianceStatus?: string;
  rcraSncFlag?: boolean;
  rcra3yrComplQtrsHistory?: EchoQuarterlyHistory[];
  sdwaIds?: string[];
  sdwaSystemTypes?: string[];
  sdwaInformalCount?: number;
  sdwaFormalActionCount?: number;
  sdwaComplianceStatus?: string;
  sdwaSncFlag?: boolean;
  triIds?: string[];
  triReleasesTransfers?: number;
  triOnSiteReleases?: number;
  triOffSiteTransfers?: number;
  triReporterInPast?: boolean;
  fecCaseIds?: string[];
  fecNumberOfCases?: number;
  fecLastCaseDate?: Date;
  fecTotalPenalties?: number;
  ghgIds?: string[];
  ghgCo2Releases?: number;
  dfrUrl?: string;
  facSicCodes?: string[];
  facNaicsCodes?: string[];
  facDateLastInspectionEpa?: Date;
  facDateLastInspectionState?: Date;
  facDateLastFormalActEpa?: Date;
  facDateLastFormalActSt?: Date;
  facDateLastInformalActEpa?: Date;
  facDateLastInformalActSt?: Date;
  facFederalAgency?: string;
  triReporter?: boolean;
  facImpWaterFlg?: boolean;
  ejscreenFlagUs?: boolean;
  facilityMatchCandidates?: EchoFacilityMatchCandidate[];
  facilityEchoExporter?: undefined[];
}

export interface EchoFacilityMatchCandidate {
  id: string;
  tenant: Tenant;
  tenantId: string;
  facility: Facility;
  facilityId: string;
  registryId: string;
  echoExporter: EchoExporter;
  matchScore: number;
  job: Job;
  jobId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface NpdesFacility {
  icisFacilityInterestId: string;
  npdesId: string;
  facilityUin?: string;
  facilityTypeCode?: FacilityTypeCode;
  facilityName?: string;
  locationAddress?: string;
  supplementalAddressText?: string;
  city?: string;
  countyCode?: string;
  stateCode?: string;
  zip?: string;
  geocodeLatitude?: number;
  geocodeLongitude?: number;
  impairedWaters?: string;
  npdesQncrHistory?: NpdesQncrHistory[];
}

export interface NpdesPermit {
  activityId: string;
  externalPermitNmbr?: string;
  versionNmbr?: number;
  facilityTypeIndicator?: FacilityTypeIndicator;
  permitTypeCode?: PermitTypeCode;
  majorMinorStatusFlag?: MajorMinorStatusFlag;
  permitStatusCode?: PermitStatusCode;
  totalDesignFlowNmbr?: number;
  actualAverageFlowNmbr?: number;
  stateWaterBody?: string;
  stateWaterBodyName?: string;
  permitName?: string;
  agencyTypeCode?: AgencyTypeCode;
  originalIssueDate?: Date;
  issueDate?: Date;
  issuingAgency?: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  retirementDate?: Date;
  terminationDate?: Date;
  permitCompStatusFlag?: boolean;
  dmrNonReceiptFlag?: boolean;
  rncTrackingFlag?: boolean;
  masterExternalPermitNmbr?: string;
  tmdlInterfaceFlag?: boolean;
  edmrAuthorizationFlag?: boolean;
  pretreatmentIndicatorCode?: PretreatmentIndicatorCode;
  radWbdHuc12s?: string;
}

export interface NpdesQncrHistory {
  npdesId: string;
  yearqtr: string;
  hlrnc?: HlrncCode;
  nume90q?: number;
  numcvdt?: number;
  numsvcd?: number;
  numpsch?: number;
  numd8090q?: number;
  npdesFacility: NpdesFacility;
}

export interface NpdesCsViolation {
  id: string;
  npdesId: string;
  npdesViolationId: string;
  violationTypeCode: string;
  compScheduleEventId: string;
  compScheduleNmbr: string;
  violationCode: string;
  violationDesc: string;
  scheduleEventCode: string;
  scheduleEventDesc: string;
  scheduleDate: Date;
  actualDate?: Date;
  rncDetectionCode?: string;
  rncDetectionDesc?: string;
  rncDetectionDate?: Date;
  rncResolutionCode?: string;
  rncResolutionDesc?: string;
  rncResolutionDate?: Date;
  reportReceivedDate?: Date;
}

export interface NpdesEffViolation {
  npdesId: string;
  versionNmbr: string;
  activityId: string;
  npdesViolationId: string;
  monitoringPeriodEndDate: Date;
}

export interface NpdesPsViolation {
  id: string;
  npdesId: string;
  npdesViolationId: string;
  permScheduleEventId: string;
  violationTypeCode: string;
  violationCode: string;
  violationDesc: string;
  scheduleEventCode: string;
  scheduleEventDesc: string;
  scheduleDate: Date;
  rncDetectionCode?: string;
  rncDetectionDesc?: string;
  rncDetectionDate?: Date;
  rncResolutionCode?: string;
  rncResolutionDesc?: string;
  rncResolutionDate?: Date;
  actualDate?: Date;
  reportReceivedDate?: Date;
}

export interface NpdesSeViolation {
  id: string;
  npdesId: string;
  npdesViolationId: string;
  violationTypeCode: string;
  violationCode: string;
  violationDesc: string;
  singleEventViolationDate: Date;
  singleEventEndDate?: Date;
  singleEventViolationComment?: string;
  singleEventAgencyTypeCode?: string;
  rncDetectionCode?: string;
  rncDetectionDesc?: string;
  rncDetectionDate?: Date;
  rncResolutionCode?: string;
  rncResolutionDesc?: string;
  rncResolutionDate?: Date;
}

export interface EchoRcraFacility {
  idNumber: string;
  facilityName?: string;
  activityLocation?: string;
  fullEnforcement?: string;
  hreportUniverseRecord?: string;
  streetAddress?: string;
  cityName?: string;
  stateCode?: string;
  zipCode?: string;
  latitude83?: number;
  longitude83?: number;
  fedWasteGenerator?: string;
  transporter?: boolean;
  activeSite?: string;
  operatingTsdf?: string;
  echoRcraVioSncHistory?: EchoRcraVioSncHistory[];
}

export interface EchoRcraViolation {
  id: string;
  idNumber: string;
  activityLocation: string;
  violationType: string;
  violationTypeDesc: string;
  violDeterminedByAgency: string;
  dateViolationDetermined?: Date;
  actualRtcDate?: Date;
  scheduledComplianceDate?: Date;
}

export interface EchoRcraVioSncHistory {
  idNumber: string;
  activityLocation: string;
  yrmonth: string;
  vioFlag: boolean;
  sncFlag: boolean;
  echoRcraFacility: EchoRcraFacility;
}

export interface NationalContactFile {
  id: string;
  registryId: string;
  pgmSysAcrnm: string;
  pgmSysId: string;
  interestType: string;
  affiliationType: string;
  startDate?: string;
  endDate?: string;
  fullName?: string;
  title?: string;
  phoneNumber?: string;
  alternatePhone?: string;
  faxNumber?: string;
  emailAddress?: string;
  mailingAddress?: string;
  supplementalAddress?: string;
  cityName?: string;
  stateCode?: string;
  stateName?: string;
  postalCode?: string;
  countryName?: string;
}

export interface InsightsCohort {
  id: string;
  tenantId: string;
  tenant: Tenant;
  name: string;
  nameSearches?: string[];
  domainSearches?: string[];
}

export interface HdHandler {
  handlerId: string;
  activityLocation: string;
  sourceType: RcraInfoSourceType;
  seqNumber: number;
  receiveDate?: Date;
  handlerName?: string;
  nonNotifier?: RcraInfoNonNotifier;
  acknowledgeDate?: Date;
  acknowledgeFlag?: boolean;
  accessibility?: RcraInfoAccessibility;
  locationStreetNo?: string;
  locationStreet1?: string;
  locationStreet2?: string;
  locationCity?: string;
  locationState?: string;
  locationZip?: string;
  locationCountry?: string;
  countyCode?: string;
  locationLatitude?: number;
  locationLongitude?: number;
  locationGisPrimary?: boolean;
  locationGisOrigin?: string;
  stateDistrictOwner?: string;
  stateDistrict?: string;
  landType?: RcraInfoLandType;
  mailStreetNo?: string;
  mailStreet1?: string;
  mailStreet2?: string;
  mailCity?: string;
  mailState?: string;
  mailZip?: string;
  mailCountry?: string;
  contactFirstName?: string;
  contactMiddleInitial?: string;
  contactLastName?: string;
  contactStreetNo?: string;
  contactStreet1?: string;
  contactStreet2?: string;
  contactCity?: string;
  contactState?: string;
  contactZip?: string;
  contactCountry?: string;
  contactPhone?: string;
  contactPhoneExt?: string;
  contactFax?: string;
  contactEmailAddress?: string;
  contactTitle?: string;
  contactLanguage?: string;
  fedWasteGeneratorOwner?: string;
  fedWasteGenerator?: RcraInfoFederalWasteGeneratorStatus;
  stateWasteGeneratorOwner?: string;
  stateWasteGenerator?: string;
  shortTermGenerator?: boolean;
  importerActivity?: boolean;
  mixedWasteGenerator?: boolean;
  transporter?: boolean;
  transferFacility?: boolean;
  tsdActivity?: boolean;
  recyclerActivity?: boolean;
  onsiteBurnerExemption?: boolean;
  furnaceExemption?: boolean;
  undergroundInjectionActivity?: boolean;
  offSiteReceipt?: boolean;
  universalWasteDestFacility?: boolean;
  usedOilTransporter?: boolean;
  usedOilTransferFacility?: boolean;
  usedOilProcessor?: boolean;
  usedOilRefiner?: boolean;
  usedOilBurner?: boolean;
  usedOilMarketBurner?: boolean;
  usedOilSpecMarketer?: boolean;
  subpartKCollege?: boolean;
  subpartKHospital?: boolean;
  subpartKNonprofit?: boolean;
  subpartKWithdrawal?: boolean;
  includeInNationalReport?: boolean;
  reportCycle?: string;
  brExempt?: string;
  cdxTransactionId?: string;
  userId?: string;
  lastChange?: string;
  notes?: string;
  publicNotes?: string;
  shortTermGeneratorNotes?: string;
  lqhuw?: boolean;
  dataOrigin?: RcraInfoDataOrigin;
  createdByUserId?: string;
  createdDate?: string;
  recognizedTraderImporter?: boolean;
  recognizedTraderExporter?: boolean;
  slabImporter?: boolean;
  slabExporter?: boolean;
  currentRecord?: boolean;
  recyclerActivityNonstorage?: boolean;
  manifestBroker?: boolean;
  subpartPHealthcare?: boolean;
  subpartPReverseDistributor?: boolean;
  subpartPWithdrawal?: boolean;
  ownerName?: string;
  operatorName?: string;
  hdOwnerOperators?: HdOwnerOperator[];
}

export interface HdOwnerOperator {
  handlerId: string;
  activityLocation: string;
  sourceType: RcraInfoSourceType;
  seqNumber: number;
  ownerOperatorSeq: number;
  ownerOperatorIndicator?: RcraInfoOwnerOperatorIndicator;
  ownerOperatorType?: RcraInfoLandType;
  ownerOperatorName?: string;
  dateBecameCurrent?: Date;
  dateEndedCurrent?: Date;
  streetNo?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  phoneExt?: string;
  fax?: string;
  email?: string;
  publicNotes?: string;
  hdHandler?: HdHandler;
}

export interface WasteLine {
  manifestId: string;
  manifestTrackingNumber: string;
  wasteLineNumber: string;
  dotHazardous?: boolean;
  dotIdNumberDescription?: string;
  dotPrintedInformation?: string;
  nonHazWasteDescription?: string;
  containerNumber?: number;
  containerTypeCode?: string;
  containerTypeDescription?: string;
  disposalManagementCode?: string;
  quantity?: number;
  qtyUnitOfMeasureCode?: string;
  qtyUnitOfMeasureDesc?: string;
  quantityTons?: number;
  quantityAcuteTons?: number;
  quantityNonAcuteTons?: number;
  quantityKg?: number;
  quantityAcuteKg?: number;
  quantityNonAcuteKg?: number;
  managementMethodCode?: string;
  managementMethodDescription?: string;
  wasteResidue?: boolean;
  quantityDiscrepancy?: boolean;
  wasteTypeDiscrepancy?: boolean;
  brDensity?: string;
  brDensityUomCode?: string;
  brDensityUomDescription?: string;
  brFormCode?: string;
  brFormCodeDescription?: string;
  brSourceCode?: string;
  brSourceCodeDescription?: string;
  brWasteMinCode?: string;
  brWasteMinDescription?: string;
  consentNumber?: string;
  epaWaste?: boolean;
  quantityHazKg?: number;
  quantityHazTons?: number;
  quantityNonHazKg?: number;
  quantityNonHazTons?: number;
  federalWasteCodes?: string[];
  stateWasteCodes?: string[];
  vendorProfileNumber?: string;
  vendorProfileDescription?: string;
  vendorComments?: string;
  vendorTSDFApprovalCode?: string;
  vendorDisposalMethod?: string;
  vendorWasteTypeDescription?: string;
  vendorWasteTypeCategory?: string;
  vendorBillUnit?: string;
  vendorBillQuantity?: number;
  vendorUnitPrice?: number;
  vendorSequenceID?: string;
  vendorDisposalDescription?: string;
  vendorFederal?: string;
  vendorStateHazLbs?: string;
  vendorUWLbs?: string;
  vendorServiceYearMonth?: string;
  wasteStreamHashKey?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  wasteManifest: WasteManifest;
}

export interface WasteManifest {
  id: string;
  manifestTrackingNumber: string;
  manifestSource: WasteManifestSource;
  tenantId?: string;
  updatedDate?: Date;
  shippedDate?: Date;
  receivedDate?: Date;
  status?: string;
  submissionType?: string;
  originType?: string;
  generatorId?: string;
  generatorName?: string;
  generatorMailStreetNo?: string;
  generatorMailStreet1?: string;
  generatorMailStreet2?: string;
  generatorMailCity?: string;
  generatorMailZip?: string;
  generatorMailState?: string;
  generatorLocationStreetNo?: string;
  generatorLocationStreet1?: string;
  generatorLocationStreet2?: string;
  generatorLocationCity?: string;
  generatorLocationZip?: string;
  generatorLocationState?: string;
  generatorContactCompanyName?: string;
  desFacilityId?: string;
  desFacilityName?: string;
  desFacMailStreetNo?: string;
  desFacMailStreet1?: string;
  desFacMailStreet2?: string;
  desFacMailCity?: string;
  desFacMailZip?: string;
  desFacMailState?: string;
  desFacLocationStreetNo?: string;
  desFacLocationStreet1?: string;
  desFacLocationStreet2?: string;
  desFacLocationCity?: string;
  desFacLocationZip?: string;
  desFacLocationState?: string;
  desFacContactCompanyName?: string;
  manifestResidue?: boolean;
  rejection?: boolean;
  totalQuantityAcuteKg?: number;
  totalQuantityAcuteTons?: number;
  totalQuantityHazKg?: number;
  totalQuantityHazTons?: number;
  totalQuantityNonAcuteKg?: number;
  totalQuantityNonAcuteTons?: number;
  totalQuantityNonHazKg?: number;
  totalQuantityNonHazTons?: number;
  totalQuantityKg?: number;
  totalQuantityTons?: number;
  brokerId?: string;
  vendorOtherSource?: string;
  vendorSiteId?: string;
  vendorCompanyID?: string;
  vendorProfitCtrID?: string;
  vendorCustomerID?: string;
  vendorCustomerName?: string;
  vendorWorkOrderId?: string;
  vendorGeneratorID?: string;
  vendorGeneratorSiteCode?: string;
  vendorGeneratorSiteType?: string;
  vendorGeneratorSublocationCode?: string;
  vendorPurchaseOrder?: string;
  vendorInvoiceCode?: string;
  vendorInvoiceDate?: Date;
  vendorResourceType?: string;
  vendorTSDFCode?: string;
  vendorBillTotal?: number;
  vendorDisposalDescription?: string;
  vendorFederal?: string;
  vendorStateHazLbs?: string;
  vendorUWLbs?: string;
  vendorServiceYearMonth?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  wasteLines?: WasteLine[];
  wasteTransporters?: WasteTransporter[];
  tenant?: Tenant;
  eManifestProcessed?: ProcessedManifest[];
  vendorManifestProcessed?: ProcessedManifest[];
}

export interface WasteTransporter {
  manifestId: string;
  manifestTrackingNumber: string;
  transporterLineNumber: string;
  transporterId?: string;
  transporterName?: string;
  vendorAddressLine1?: string;
  vendorAddressLine2?: string;
  vendorCity?: string;
  vendorState?: string;
  vendorZip?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  wasteManifest: WasteManifest;
}

export interface FacilityModelIssue {
  id: string;
  tenantId: string;
  facilityId: string;
  reportId?: string;
  reportingYear: number;
  schemaPath: string;
  modelIssueId: string;
  facility: Facility;
  modelIssue: ModelIssue;
  report?: TierIIReport;
}

export interface ModelIssue {
  id: string;
  tenantId: string;
  modelName: string;
  modelId: string;
  message: string;
  propertyKey: string;
  dataName?: string;
  validatedAt: Date;
  isRevalidating: boolean;
  facilityModelIssues?: FacilityModelIssue[];
}

export interface JobLog {
  id: string;
  jobId: string;
  status: JobStatus;
  taskArn?: string;
  taskDefinitionArn?: string;
  errorMessage?: string;
  stackTrace?: string;
  cloudWatchLogStream?: string;
  screenshotKey?: string;
  htmlKey?: string;
  job: Job;
  createdAt: Date;
  updatedAt: Date;
}

export interface JobStepsDone {
  id: string;
  jobId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  job: Job;
}

export interface Job {
  id: string;
  task: JobTask;
  status: JobStatus;
  parameters: any;
  taskArn?: string;
  taskDefinitionArn?: string;
  action?: WebAutomationAction;
  tenantId?: string;
  facilityId?: string;
  reportId?: string;
  documentId?: string;
  requestId?: string;
  encampRequestId?: string;
  credentialUsername?: string;
  stateOrTerritory?: string;
  forceClassicSubmissionStrategy?: boolean;
  evidenceOfCompletionActions?: WebAutomationCollectEvidenceOfCompletionActions[];
  stepError?: string;
  reportingYear?: number;
  extractionKey?: string;
  logs?: JobLog[];
  stepsDone?: JobStepsDone[];
  activity?: Activity;
  tenant?: Tenant;
  document?: Document;
  facility?: Facility;
  payments?: Payment[];
  echoFacilityMatchCandidates?: EchoFacilityMatchCandidate[];
  staffReportKey?: string;
  totalIssues?: number;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  promotedAt?: Date;
  promotedBy?: string;
  activityId?: string;
}

export interface JobManagerConfig {
  id: string;
  task: JobTask;
  maxConcurrency: number;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
}

export interface LeadAgency {
  id: number;
  address: string;
  name: string;
  additionalComments?: string;
  attn: string;
  city: string;
  consolidated: string;
  phone: string;
  state: string;
  zip: string;
  email?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Lepc {
  id: string;
  name: string;
  state?: string;
  city?: string;
  county?: string;
  zip?: string;
  mailingAddress?: string;
  mailingAddressLine2?: string;
  contactName?: string;
  email?: string;
  phone?: string;
  feeStructure?: string;
  latitude?: number;
  longitude?: number;
  leadAgency?: string;
  paymentMethod?: string;
  t2sValue?: string;
  submissionNotes?: string;
  requirementDetails?: string;
  additionalComments?: string;
  additionaRequirements?: string;
  additionalFees?: string;
  isEmailed: boolean;
  isCurrent1AsOf2021: boolean;
  isMailed: boolean;
  deactivated: boolean;
  isStateSubmits: boolean;
  needsT2sFile: boolean;
  isSubmittedViaPortal: boolean;
  needsXmlZipFile: boolean;
  portalId?: string;
  portalName?: string;
  preferUncertified: boolean;
  facilities?: Facility[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface LinearWebhookEvent {
  id: string;
  linearId: string;
  action: string;
  createdAt: Date;
  data?: any;
  updatedFrom?: any;
  url?: string;
  type: string;
  organizationId: string;
  webhookTimestamp: number;
}

export interface LobAddress {
  id: string;
  recipient: string;
  primaryLine: string;
  secondaryLine?: string;
  city: string;
  state: string;
  zipCode: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface LobMail {
  id: string;
  lobId: string;
  type: LobMailType;
  status?: LobMailStatus;
  lobUrl?: string;
  data?: any;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  activities?: Activity[];
  payments?: Payment[];
}

export interface Lock {
  id: string;
  type: LockType;
  resourceId: string;
  holderId: string;
  expiresAt: Date;
  description?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface MergeRecord {
  id: string;
  tenantId?: string;
  tenant?: Tenant;
  winningId: string;
  losingId: string;
  losingData?: any;
  tableName: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Payment {
  id: string;
  tenantId: string;
  tenant: Tenant;
  facilities?: FacilityPayment[];
  status: PaymentStatus;
  paymentMethod?: PaymentMethod;
  amount?: number;
  description?: string;
  memo?: string;
  payee?: string;
  paidAt: Date;
  recordedByEmail?: string;
  documentId?: string;
  document?: Document;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  paymentInvoices?: PaymentInvoice[];
  tierIIReports?: TierIIReportPayment[];
  activityId?: string;
  activity?: Activity;
  lobMailId?: string;
  lobMail?: LobMail;
  jobId?: string;
  job?: Job;
  payeeType?: string;
  company?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface PaymentInvoice {
  id: string;
  payee?: string;
  invoiceNumber?: string;
  invoiceDocumentId?: string;
  invoiceDocument?: Document;
  dateDue?: Date;
  amount?: number;
  description?: string;
  paymentId: string;
  Payment: Payment;
}

export interface Person {
  id: string;
  tenantId: string;
  first?: string;
  last?: string;
  email?: string;
  title?: string;
  company?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phones?: any;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  createdAtViaMerge?: Date;
  user?: User;
  tenant: Tenant;
  facilityContacts?: FacilityContact[];
  tierIIReports?: TierIIReport[];
  tierIIReportAssignments?: TierIIReportAssignee[];
}

export interface PoliceDepartment {
  id: number;
  address: string;
  name: string;
  additionalComments?: string;
  attn?: string;
  city: string;
  state?: string;
  zip?: string;
  email?: string;
  contactName?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface PortalCapacity {
  id: string;
  name: string;
  capacity: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Product {
  id: string;
  name: string;
  alternateId?: string;
  reference?: string;
  productChemicals?: ProductChemical[];
  productTenants?: ProductTenant[];
  facilityProducts?: FacilityProduct[];
  facilityProductMeasurements?: FacilityProductMeasurement[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ProductChemical {
  id: string;
  productId: string;
  chemicalId: string;
  amount: number;
  unit: UnitType;
  storageType?: string;
  storageTypeDescription?: string;
  pressure?: Pressure;
  temperature?: Temperature;
  otherPressureValue?: string;
  overrides?: ProductChemicalOverride[];
  product: Product;
  chemical: Chemical;
}

export interface ProductChemicalOverride {
  id: string;
  productChemicalId: string;
  jurisdiction: string;
  storageType?: string;
  storageTypeDescription?: string;
  pressure?: Pressure;
  otherPressureValue?: string;
  temperature?: Temperature;
  ProductChemical: ProductChemical;
}

export interface ProductTenant {
  id: string;
  productId: string;
  tenantId: string;
  product: Product;
  tenant: Tenant;
}

export interface Role {
  id: string;
  name: string;
  permissions?: Permission[];
  tenantId: string;
  tenant: Tenant;
  userTenants?: UserTenant[];
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
}

export interface SendGridEmail {
  id: string;
  messageId: string;
  to: string;
  from: string;
  replyTo?: string;
  subject: string;
  templateId?: string;
  sendDate: Date;
}

export interface Snapshot {
  id: string;
  type: SnapshotType;
  sourceId: string;
  content: any;
  dataModelVersion: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface StorageLocation {
  id: string;
  facilityId: string;
  Facility: Facility;
  description?: string;
  CA_mapNumber?: string;
  CA_gridNumber?: string;
  OR_insideOutside?: InsideOutside;
  OR_storageBuilding?: string;
  OR_storageFloor?: string;
  OR_storageArea?: string;
  OR_storageRoom?: string;
  OR_storageQuadrant?: StorageQuadrant;
  latitude?: number;
  longitude?: number;
  sourceUpdatedAt: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  facilityChemicalStorageLocations?: FacilityChemicalStorageLocation[];
  facilityProductStorageLocations?: FacilityProductStorageLocation[];
  facilityChemicalMeasurements?: FacilityChemicalMeasurement[];
  facilityProductMeasurements?: FacilityProductMeasurement[];
  reportingFacilityChemicalStorageLocations?: ReportingFacilityChemicalStorageLocation[];
}

export interface Tag {
  id: string;
  name: string;
  description?: string;
  tenantId: string;
  tenant: Tenant;
  icon?: TagIcon;
  isDisabled: boolean;
  type: TagType;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  createdBy: string;
  facilityTags?: FacilityTag[];
  taskTags?: TaskTag[];
  documentTags?: DocumentTag[];
}

export interface Task {
  id: string;
  seriesId: string;
  title: string;
  description?: string;
  completedAt?: Date;
  subtasks?: SubTask[];
  shouldNotify: boolean;
  completedBy?: User;
  completedById?: string;
  assignee?: User;
  assigneeId?: string;
  watchers?: User[];
  facilities?: Facility[];
  documents?: Document[];
  links?: string[];
  taskTags?: TaskTag[];
  tenantId: string;
  tenant: Tenant;
  frequency: Frequency;
  interval: number;
  endDate: Date;
  startDate: Date;
  excludedDates?: Date[];
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
}

export interface SubTask {
  id: string;
  taskId: string;
  task: Task;
  title: string;
  completedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
}

export interface TaskTag {
  id: string;
  tagId: string;
  taskId: string;
  tag: Tag;
  task: Task;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Tenant {
  id: string;
  name: string;
  partnerTenantId?: string;
  partnerTenant?: Tenant;
  partnersTenants?: Tenant[];
  salesforceId?: string;
  accountType: AccountType;
  isDemo: boolean;
  isPartner: boolean;
  isDisabled: boolean;
  externalId?: string;
  roleIdToDefaultForNewUsers?: string;
  ssoMappingName?: string;
  ssoDomains?: string[];
  signsAllReportPages?: boolean;
  selfSignedCertification?: boolean;
  tierIIReports?: TierIIReport[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  Document?: Document[];
  UserTenant?: UserTenant[];
  EmulatedRole?: EmulatedRole[];
  roles?: Role[];
  chemicalTenants?: ChemicalTenant[];
  products?: ProductTenant[];
  credentials?: Credential[];
  facilities?: Facility[];
  jobs?: Job[];
  persons?: Person[];
  payments?: Payment[];
  facilityAlternateIds?: FacilityAlternateId[];
  insightsCohorts?: InsightsCohort[];
  tasks?: Task[];
  tags?: Tag[];
  mergeRecords?: MergeRecord[];
  echoFacilityMatchCandidates?: EchoFacilityMatchCandidate[];
  WasteManifest?: WasteManifest[];
  ProcessedManifests?: ProcessedManifest[];
}

export interface TierIIReport {
  id: string;
  organizationStatus: TierIIReportOrgStatus;
  encampStatus: TierIIReportEncampStatus;
  productCount?: number;
  staffAssignee?: string;
  submissionMeasures?: TierIIReportSubmissionMeasure[];
  facilityId: string;
  facility: Facility;
  reportingYear: number;
  workflows?: Workflow[];
  currentWorkflowId?: string;
  currentWorkflow?: Workflow;
  tenantId: string;
  tenant: Tenant;
  documents?: Document[];
  verifierPersonId?: string;
  verifier?: Person;
  verifierFullName?: string;
  verifierTitle?: string;
  verifiedAt?: Date;
  isVerified?: boolean;
  verifiedByEncamp?: boolean;
  comments?: Comment[];
  reportKind: TierIIReportKind;
  submittedAt?: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  payments?: TierIIReportPayment[];
  notesToRegulators?: string;
  department?: string;
  touchedSteps?: TierIIReportStep[];
  validationErrors?: TierIIReportValidation;
  tags?: string[];
  reviewCompletedByUserId?: string;
  reviewCompletedByUser?: User;
  filedBy?: TierIIReportFiledBy;
  assignees?: TierIIReportAssignee[];
  issues?: FacilityModelIssue[];
}

export interface TierIIReportAssignee {
  id: string;
  tierIIReportId: string;
  tierIIReport: TierIIReport;
  person: Person;
  personId: string;
  assignmentType: AssignmentType;
  isComplete?: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  facilityTierIIReport?: undefined;
}

export interface TierIIReportPayment {
  payment: Payment;
  paymentId: string;
  tierIIReport: TierIIReport;
  tierIIReportId: string;
  amount?: number;
}

export interface TierIIReportSubmissionMeasure {
  id: string;
  index: number;
  name: string;
  description?: string;
  startedAt?: Date;
  completedAt?: Date;
  notes?: any;
  records?: any;
  tierIIReportId: string;
  TierIIReport: TierIIReport;
}

export interface TierIIReportValidation {
  id: string;
  errors?: string[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  tierIIReport: TierIIReport;
  tierIIReportId: string;
}

export interface User {
  id: string;
  email: string;
  type: UserType;
  invitedAt?: Date;
  invitationExpiresAt?: Date;
  status: UserStatus;
  personId?: string;
  person?: Person;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  facilityTierIIReportsUpdated?: undefined[];
  facilityTierIIReportsCreated?: undefined[];
  UserCognito?: UserCognito[];
  UserTenant?: UserTenant[];
  EmulatedRole?: EmulatedRole[];
  Activity?: Activity[];
  comments?: Comment[];
  facilities?: UserFacility[];
  tierIIReportsReviewsCompleted?: TierIIReport[];
  assignedTasks?: Task[];
  completedTasks?: Task[];
  watchedTasks?: Task[];
  verifiedChemicals?: Chemical[];
}

export interface UserCognito {
  id: string;
  userId: string;
  cognitoSub: string;
  user: User;
}

export interface UserFacility {
  id: string;
  facilityId: string;
  facility: Facility;
  userId: string;
  user: User;
}

export interface UserTenant {
  id: string;
  tenantId: string;
  tenant: Tenant;
  userId: string;
  user: User;
  roleId?: string;
  role?: Role;
}

export interface FacilityWasteStream {
  id: string;
  facilityId: string;
  facility: Facility;
  facilityWasteStreamAmounts?: FacilityWasteStreamAmount[];
  wasteStreamHashKey: string;
  dotDescription: string;
  classification?: WasteClassification;
  federalWasteCodes?: string[];
  stateWasteCodes?: string[];
  shipmentsYearToDate?: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface FacilityWasteStreamAmount {
  id: string;
  facilityWasteStreamId: string;
  facilityWasteStream: FacilityWasteStream;
  month: Date;
  amountInPounds: number;
  originalUnit?: EManifestWasteUnit;
  originalUnitAmount?: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface IngestSummary {
  id: string;
  s3Key: string;
  facilitiesUpdated: number;
  rcraCodesCreated: number;
  wasteTransportersCreated: number;
  wasteDisposalFacilitiesCreated: number;
  wasteStreamsCreated: number;
  wasteStreamsUpdated: number;
  manifestsCreated: number;
  manifestsUpdated: number;
  manifestLineItemsCreated: number;
  manifestLineItemsUpdated: number;
  owner: string;
  createdAt: Date;
  createdBy: string;
}

export interface StateWasteCode {
  id: string;
  state: string;
  value: string;
  definition: string;
  classification: StateWasteClassification;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Activity {
  id: string;
  type: ActivityType;
  status: ActivityStatus;
  executionPlan: ExecutionPlanType;
  description: string;
  output?: any;
  reportDetailsExtended?: any;
  championId?: string;
  nonChampionIds?: string[];
  cohortFacilityIds?: string[];
  assigneeId?: string;
  assignee?: User;
  workflowId: string;
  workflow: Workflow;
  jobId?: string;
  job?: Job;
  lobMailId?: string;
  lobMail?: LobMail;
  logs?: ActivityLog[];
  inputs?: ActivityUserInput[];
  message?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  currentActivityWorkflow?: Workflow;
  comments?: Comment[];
  documents?: ActivityDocument[];
  payments?: Payment[];
  tier2SubmitValidationStatus?: Tier2SubmitValidationStatus;
  tier2SubmitValidationErrors?: string[];
  tier2SubmitValidationWarnings?: string[];
}

export interface ActivityLog {
  id: string;
  activityId: string;
  activity: Activity;
  status: ActivityStatus;
  message?: string;
  errorType?: string;
  stackTrace?: string;
  time: Date;
}

export interface ActivityUserInput {
  id: string;
  activityId: string;
  activity: Activity;
  label: string;
  shortName: string;
  type: ActivityUserInputType;
  value?: string;
  required: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ActivityDocument {
  activityId: string;
  activity: Activity;
  documentId: string;
  document: Document;
}

export interface Workflow {
  id: string;
  type: WorkflowType;
  name?: string;
  description?: string;
  status: WorkflowStatus;
  completedAt?: Date;
  version: number;
  graph: any;
  history: any;
  activities?: Activity[];
  currentActivityId?: string;
  currentActivity?: Activity;
  tierIIReportId: string;
  tierIIReport: TierIIReport;
  currentTierIIReportWorkflow?: TierIIReport;
  comments?: Comment[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface WorkflowManagerConfig {
  id: string;
  type: WorkflowManagerConfigType;
  value: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface ZipCode {
  id: string;
  stateFips: string;
  stateFullName: string;
  state: string;
  zip: string;
  county: string;
  city?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export enum ProgramArea {
  EPCRA = 'EPCRA',
  RCRA = 'RCRA',
}

export enum PureOrMixture {
  PURE = 'PURE',
  MIXTURE = 'MIXTURE',
}

export enum StateOfMatter {
  SOLID = 'SOLID',
  LIQUID = 'LIQUID',
  GAS = 'GAS',
}

export enum DensityUnits {
  POUNDS_PER_CUBIC_FOOT = 'POUNDS_PER_CUBIC_FOOT',
  POUNDS_PER_GALLON = 'POUNDS_PER_GALLON',
  POUNDS_PER_LITER = 'POUNDS_PER_LITER',
  POUNDS_PER_BARREL = 'POUNDS_PER_BARREL',
  KILOGRAMS_PER_GALLON = 'KILOGRAMS_PER_GALLON',
  KILOGRAMS_PER_LITER = 'KILOGRAMS_PER_LITER',
  KILOGRAMS_PER_CUBIC_FOOT = 'KILOGRAMS_PER_CUBIC_FOOT',
  KILOGRAMS_PER_BARREL = 'KILOGRAMS_PER_BARREL',
  TONS_PER_GALLON = 'TONS_PER_GALLON',
  TONS_PER_LITER = 'TONS_PER_LITER',
  TONS_PER_CUBIC_FOOT = 'TONS_PER_CUBIC_FOOT',
  TONS_PER_BARREL = 'TONS_PER_BARREL',
  METRIC_TONS_PER_GALLON = 'METRIC_TONS_PER_GALLON',
  METRIC_TONS_PER_LITER = 'METRIC_TONS_PER_LITER',
  METRIC_TONS_PER_CUBIC_FOOT = 'METRIC_TONS_PER_CUBIC_FOOT',
  METRIC_TONS_PER_BARREL = 'METRIC_TONS_PER_BARREL',
}

export enum EhsForm {
  MOLTEN = 'MOLTEN',
  SOLUTION = 'SOLUTION',
  SOLID_PARTICLE_SIZE_LESS_THAN_100_MICRON = 'SOLID_PARTICLE_SIZE_LESS_THAN_100_MICRON',
  NONE = 'NONE',
}

export enum PhysicalHazard {
  EXPLOSIVE = 'EXPLOSIVE',
  FLAMMABLE = 'FLAMMABLE',
  OXIDIZER = 'OXIDIZER',
  SELF_REACTIVE = 'SELF_REACTIVE',
  PYROPHORIC_SOLID_OR_LIQUID = 'PYROPHORIC_SOLID_OR_LIQUID',
  PYROPHORIC_GAS = 'PYROPHORIC_GAS',
  SELF_HEATING = 'SELF_HEATING',
  ORGANIC_PEROXIDE = 'ORGANIC_PEROXIDE',
  CORROSIVE_TO_METAL = 'CORROSIVE_TO_METAL',
  COMPRESSED_GAS = 'COMPRESSED_GAS',
  FLAMMABLE_WITH_WATER = 'FLAMMABLE_WITH_WATER',
  COMBUSTIBLE_DUST = 'COMBUSTIBLE_DUST',
  UNCLASSIFIED_PHYSICAL_HAZARD = 'UNCLASSIFIED_PHYSICAL_HAZARD',
}

export enum HealthHazard {
  ACUTE_TOXICITY = 'ACUTE_TOXICITY',
  SKIN_IRRITANT = 'SKIN_IRRITANT',
  EYE_IRRITANT = 'EYE_IRRITANT',
  CAUSES_RESPIRATORY_OR_SKIN_SENSITIZATION = 'CAUSES_RESPIRATORY_OR_SKIN_SENSITIZATION',
  GERM_CELL_MUTAGENIC = 'GERM_CELL_MUTAGENIC',
  CARCINOGENIC = 'CARCINOGENIC',
  REPRODUCTIVE_TOXICITY = 'REPRODUCTIVE_TOXICITY',
  SPECIFIC_TARGET_ORGAN_TOXICITY = 'SPECIFIC_TARGET_ORGAN_TOXICITY',
  ASPIRATION_HAZARD = 'ASPIRATION_HAZARD',
  SIMPLE_ASPHYXIANT = 'SIMPLE_ASPHYXIANT',
  HAZARD_NOT_OTHERWISE_CLASSIFIED_HEALTH = 'HAZARD_NOT_OTHERWISE_CLASSIFIED_HEALTH',
}

export enum OregonHazard {
  RADIOACTIVE = 'RADIOACTIVE',
  POISONOUS_GAS = 'POISONOUS_GAS',
  POISONOUS_MATERIAL = 'POISONOUS_MATERIAL',
  INFECTIOUS_OR_ETIOLOGIC = 'INFECTIOUS_OR_ETIOLOGIC',
}

export enum InternationalFireCodeHazard {
  COMBUSTIBLE = 'COMBUSTIBLE',
  CORROSIVE = 'CORROSIVE',
  FLAMMABLE = 'FLAMMABLE',
  INERT = 'INERT',
  OXIDIZING = 'OXIDIZING',
  RADIOACTIVE = 'RADIOACTIVE',
  UNSTABLE = 'UNSTABLE',
  EXPLOSIVE = 'EXPLOSIVE',
  HIGHLY_TOXIC = 'HIGHLY_TOXIC',
  ORGANIC_PEROXIDES = 'ORGANIC_PEROXIDES',
  PYROPHORIC = 'PYROPHORIC',
  TOXIC = 'TOXIC',
  WATER_REACTIVE = 'WATER_REACTIVE',
}

export enum UnitType {
  POUNDS = 'POUNDS',
  GALLONS = 'GALLONS',
  CUBIC_FEET = 'CUBIC_FEET',
  TONS = 'TONS',
  KILOGRAMS = 'KILOGRAMS',
  METRIC_TONS = 'METRIC_TONS',
  LITERS = 'LITERS',
  BARRELS = 'BARRELS',
}

export enum WeightOrVolume {
  WEIGHT = 'WEIGHT',
  VOLUME = 'VOLUME',
}

export enum CacheRootModel {
  FACILITY = 'FACILITY',
  CHEMICAL = 'CHEMICAL',
  PERSON = 'PERSON',
}

export enum CredentialKind {
  RCRA_COUNTY = 'RCRA_COUNTY',
  RCRA_STATE = 'RCRA_STATE',
  RCRA_FEDERAL = 'RCRA_FEDERAL',
  RCRA_PAYMENT = 'RCRA_PAYMENT',
  TIERII = 'TIERII',
  LEPC = 'LEPC',
}

export enum CredentialVerificationStatus {
  NOT_ATTEMPTED = 'NOT_ATTEMPTED',
  CURRENTLY_VERIFYING = 'CURRENTLY_VERIFYING',
  GOOD = 'GOOD',
  INVALID_ACCESSID_OR_PASSWORD = 'INVALID_ACCESSID_OR_PASSWORD',
  BAD_USERNAME = 'BAD_USERNAME',
  BAD_PASSWORD = 'BAD_PASSWORD',
  BAD_SECURITY_QUESTION_ANSWER = 'BAD_SECURITY_QUESTION_ANSWER',
  EXPIRED_PASSWORD = 'EXPIRED_PASSWORD',
  IN_USE = 'IN_USE',
  REQUIRES_NEW_PASSWORD = 'REQUIRES_NEW_PASSWORD',
  LOCKED = 'LOCKED',
  UNKNOWN_NON_VERIFIABLE = 'UNKNOWN_NON_VERIFIABLE',
  UNKNOWN_ERROR_NEEDS_HUMAN = 'UNKNOWN_ERROR_NEEDS_HUMAN',
}

export enum DocumentType {
  ASTDocumentation = 'ASTDocumentation',
  Certification = 'Certification',
  Check = 'Check',
  CoverLetter = 'CoverLetter',
  DikeDescription = 'DikeDescription',
  EmergencyResponsePlan = 'EmergencyResponsePlan',
  EPCRA302 = 'EPCRA302',
  EPCRA304 = 'EPCRA304',
  EPCRA311 = 'EPCRA311',
  EPCRA312 = 'EPCRA312',
  EPCRA313 = 'EPCRA313',
  FeeCalculation = 'FeeCalculation',
  Fees = 'Fees',
  Form = 'Form',
  HazardousWasteContingencyPlan = 'HazardousWasteContingencyPlan',
  HazardousMaterialsContingencyPlanOrQuickGuide = 'HazardousMaterialsContingencyPlanOrQuickGuide',
  HazardousMaterialsInventoryStatement = 'HazardousMaterialsInventoryStatement',
  HazardousMaterialsManagementPlan = 'HazardousMaterialsManagementPlan',
  Invoice = 'Invoice',
  Other = 'Other',
  P2Plan = 'P2Plan',
  P2Update = 'P2Update',
  Permit = 'Permit',
  Proof = 'Proof',
  RCRAAnnual = 'RCRAAnnual',
  RCRAFeePaymentOrInvoice = 'RCRAFeePaymentOrInvoice',
  RCRAManifest = 'RCRAManifest',
  RCRAUpdateNotification = 'RCRAUpdateNotification',
  Receipt = 'Receipt',
  ReferenceImage = 'ReferenceImage',
  Report = 'Report',
  RevisionOrUpdate = 'RevisionOrUpdate',
  SafeguardMeasures = 'SafeguardMeasures',
  SafetyDataSheet = 'SafetyDataSheet',
  Screenshot = 'Screenshot',
  SiteCoordinateAbbreviation = 'SiteCoordinateAbbreviation',
  SitePlan = 'SitePlan',
  Submission = 'Submission',
  TrainingPlan = 'TrainingPlan',
  USTDocumentation = 'USTDocumentation',
}

export enum PeriodInterval {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum RegulatoryType {
  RCRA_FEDERAL_GENERATOR = 'RCRA_FEDERAL_GENERATOR',
}

export enum RegulatoryStatus {
  LARGE_QUANTITY_GENERATOR = 'LARGE_QUANTITY_GENERATOR',
  SMALL_QUANTITY_GENERATOR = 'SMALL_QUANTITY_GENERATOR',
  VERY_SMALL_QUANTITY_GENERATOR = 'VERY_SMALL_QUANTITY_GENERATOR',
  NOT_A_GENERATOR = 'NOT_A_GENERATOR',
  UNDETERMINED = 'UNDETERMINED',
  PENDING = 'PENDING',
}

export enum FacilityStatus {
  PLANNED = 'PLANNED',
  OPEN = 'OPEN',
  INACTIVE = 'INACTIVE',
  CLOSED = 'CLOSED',
}

export enum CollectionMode {
  AGGREGATE = 'AGGREGATE',
  MEASUREMENT = 'MEASUREMENT',
}

export enum ContactReportRole {
  EPCRA_CHEMICAL_CARRIER = 'EPCRA_CHEMICAL_CARRIER',
  EPCRA_EMERGENCY_CONTACT = 'EPCRA_EMERGENCY_CONTACT',
  EPCRA_EMERGENCY_COORDINATOR = 'EPCRA_EMERGENCY_COORDINATOR',
  EPCRA_OWNER_OPERATOR = 'EPCRA_OWNER_OPERATOR',
  EPCRA_TIER_II_CONTACT = 'EPCRA_TIER_II_CONTACT',
  EPCRA_BILLING = 'EPCRA_BILLING',
  GENERAL = 'GENERAL',
  RCRA_OWNER = 'RCRA_OWNER',
  RCRA_OPERATOR = 'RCRA_OPERATOR',
  RCRA_SITE_CONTACT = 'RCRA_SITE_CONTACT',
}

export enum ThresholdReason {
  Federal = 'Federal',
  State = 'State',
  FacilityEHSTotal = 'FacilityEHSTotal',
}

export enum ThresholdResultType {
  SECTION_302_TPQ_HIGH = 'SECTION_302_TPQ_HIGH',
  SECTION_302_TPQ_LOW = 'SECTION_302_TPQ_LOW',
  SECTION_302 = 'SECTION_302',
  SECTION_312_TPQ_HIGH = 'SECTION_312_TPQ_HIGH',
  SECTION_312_TPQ_LOW = 'SECTION_312_TPQ_LOW',
  SECTION_312 = 'SECTION_312',
  AZ_GILBERT = 'AZ_GILBERT',
  CA_LEPCS_WASTE = 'CA_LEPCS_WASTE',
  CA_LIVERMORE_PLEASANTON_ACUTE_TOXICITY_GASES = 'CA_LIVERMORE_PLEASANTON_ACUTE_TOXICITY_GASES',
  CA_LIVERMORE_PLEASANTON_FIRE_HAZARD = 'CA_LIVERMORE_PLEASANTON_FIRE_HAZARD',
  CA_PASADENA_ALWAYS_REPORTING = 'CA_PASADENA_ALWAYS_REPORTING',
  CA_STATE = 'CA_STATE',
  DE_STATE = 'DE_STATE',
  LA_STATE = 'LA_STATE',
  MD_MONTGOMERY = 'MD_MONTGOMERY',
  MO_EXPLOSIVES = 'MO_EXPLOSIVES',
  NC_COUNTY = 'NC_COUNTY',
  NJ_EHS = 'NJ_EHS',
  NV_COMBUSTIBLE_LIQUIDS = 'NV_COMBUSTIBLE_LIQUIDS',
  NV_CORROSIVE_GASES = 'NV_CORROSIVE_GASES',
  NV_CORROSIVE_LIQUIDS = 'NV_CORROSIVE_LIQUIDS',
  NV_CORROSIVE_SOLIDS = 'NV_CORROSIVE_SOLIDS',
  NV_FLAMMABLE_GASES = 'NV_FLAMMABLE_GASES',
  NV_FLAMMABLE_LIQUIDS = 'NV_FLAMMABLE_LIQUIDS',
  NV_FLAMMABLE_SOLIDS = 'NV_FLAMMABLE_SOLIDS',
  NV_HIGHLY_TOXIC = 'NV_HIGHLY_TOXIC',
  NV_ORGANIC_PEROXIDE_LIQUIDS = 'NV_ORGANIC_PEROXIDE_LIQUIDS',
  NV_ORGANIC_PEROXIDE_SOLIDS = 'NV_ORGANIC_PEROXIDE_SOLIDS',
  NV_OXIDIZING_GASES = 'NV_OXIDIZING_GASES',
  NV_OXIDIZING_LIQUIDS = 'NV_OXIDIZING_LIQUIDS',
  NV_OXIDIZING_SOLIDS = 'NV_OXIDIZING_SOLIDS',
  NV_PYROPHORIC = 'NV_PYROPHORIC',
  NV_TOXIC_GASES = 'NV_TOXIC_GASES',
  NV_TOXIC_LIQUIDS = 'NV_TOXIC_LIQUIDS',
  NV_TOXIC_SOLIDS = 'NV_TOXIC_SOLIDS',
  NV_UNSTABLE_LIQUIDS = 'NV_UNSTABLE_LIQUIDS',
  NV_UNSTABLE_SOLIDS = 'NV_UNSTABLE_SOLIDS',
  NV_WATER_REACTIVE_LIQUIDS = 'NV_WATER_REACTIVE_LIQUIDS',
  NV_WATER_REACTIVE_SOLIDS = 'NV_WATER_REACTIVE_SOLIDS',
  NY_NYC = 'NY_NYC',
  OR_RADIOACTIVE = 'OR_RADIOACTIVE',
  OR_STATE = 'OR_STATE',
  OR_TOXICS_EXPLOSIVES = 'OR_TOXICS_EXPLOSIVES',
  VT_CARCINOGENIC = 'VT_CARCINOGENIC',
  VT_EXPLOSIVES = 'VT_EXPLOSIVES',
  VT_STATE = 'VT_STATE',
}

export enum ThresholdResultJurisdictionType {
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  LOCAL = 'LOCAL',
}

export enum EchoQuarterlyHistory {
  SIGNIFICANT_NONCOMPLIANCE = 'SIGNIFICANT_NONCOMPLIANCE',
  VIOLATION = 'VIOLATION',
  NO_VIOLATION = 'NO_VIOLATION',
  UNKNOWN = 'UNKNOWN',
  INACTIVE = 'INACTIVE',
}

export enum FacilityTypeIndicator {
  PUBLICLY_OWNED_TREATMENT_WORKS = 'PUBLICLY_OWNED_TREATMENT_WORKS',
  NON_PUBLICLY_OWNED_TREATMENT_WORKS = 'NON_PUBLICLY_OWNED_TREATMENT_WORKS',
  FEDERAL_FACILITY = 'FEDERAL_FACILITY',
}

export enum FacilityTypeCode {
  COUNTY_GOVERNMENT = 'COUNTY_GOVERNMENT',
  CORPORATION = 'CORPORATION',
  MUNICIPALITY = 'MUNICIPALITY',
  DISTRICT = 'DISTRICT',
  FEDERAL_FACILITY = 'FEDERAL_FACILITY',
  GOVERNMENT_OWNED_CONTRACTOR_OPERATED = 'GOVERNMENT_OWNED_CONTRACTOR_OPERATED',
  INDIVIDUAL = 'INDIVIDUAL',
  MUNICIPAL_OR_WATER_DISTRICT = 'MUNICIPAL_OR_WATER_DISTRICT',
  MIXED_OWNERSHIP = 'MIXED_OWNERSHIP',
  NON_GOVERNMENT = 'NON_GOVERNMENT',
  PRIVATELY_OWNED_FACILITY = 'PRIVATELY_OWNED_FACILITY',
  SCHOOL_DISTRICT = 'SCHOOL_DISTRICT',
  STATE_GOVERNMENT = 'STATE_GOVERNMENT',
  TRIBAL_GOVERNMENT = 'TRIBAL_GOVERNMENT',
  UNKNOWN = 'UNKNOWN',
}

export enum MajorMinorStatusFlag {
  MAJOR = 'MAJOR',
  NON_MAJOR = 'NON_MAJOR',
}

export enum PermitStatusCode {
  EFFECTIVE = 'EFFECTIVE',
  ADMINISTRATIVELY_CONTINUED = 'ADMINISTRATIVELY_CONTINUED',
  EXPIRED = 'EXPIRED',
  NOT_NEEDED = 'NOT_NEEDED',
  PENDING = 'PENDING',
  RETIRED = 'RETIRED',
  TERMINATED = 'TERMINATED',
}

export enum PermitTypeCode {
  NPDES_INDIVIDUAL_PERMIT = 'NPDES_INDIVIDUAL_PERMIT',
  NPDES_MASTER_GENERAL_PERMIT = 'NPDES_MASTER_GENERAL_PERMIT',
  GENERAL_PERMIT_COVERED_FACILITY = 'GENERAL_PERMIT_COVERED_FACILITY',
  STATE_ISSUED_MASTER_GENERAL_PERMIT_NON_NPDES = 'STATE_ISSUED_MASTER_GENERAL_PERMIT_NON_NPDES',
  INDIVIDUAL_IU_PERMIT_NON_NPDES = 'INDIVIDUAL_IU_PERMIT_NON_NPDES',
  INDIVIDUAL_STATE_ISSUED_PERMIT_NON_NPDES = 'INDIVIDUAL_STATE_ISSUED_PERMIT_NON_NPDES',
  ASSOCIATED_PERMIT_RECORD = 'ASSOCIATED_PERMIT_RECORD',
  UNPERMITTED_FACILITY = 'UNPERMITTED_FACILITY',
}

export enum AgencyTypeCode {
  STATE = 'STATE',
  MUNICIPAL = 'MUNICIPAL',
  COUNTY = 'COUNTY',
  REGIONAL = 'REGIONAL',
  US_EPA = 'US_EPA',
  EPA_CONTRACTOR = 'EPA_CONTRACTOR',
  OTHER_EPA = 'OTHER_EPA',
  INTERSTATE = 'INTERSTATE',
  LOCAL = 'LOCAL',
  OTHER_FEDERAL = 'OTHER_FEDERAL',
  STATE_CONTRACTOR = 'STATE_CONTRACTOR',
  OTHER_STATE = 'OTHER_STATE',
  TRIBAL = 'TRIBAL',
  UNKNOWN_FROM_CONVERSION = 'UNKNOWN_FROM_CONVERSION',
  SENIOR_ENVIRONMENTAL_EMPLOYMENT_SEE_INSPECTOR = 'SENIOR_ENVIRONMENTAL_EMPLOYMENT_SEE_INSPECTOR',
  OTHER = 'OTHER',
  CONTRACTOR = 'CONTRACTOR',
}

export enum PretreatmentIndicatorCode {
  COVERED = 'COVERED',
  EPA = 'EPA',
  REQUIRED_AND_DEVELOPING = 'REQUIRED_AND_DEVELOPING',
  STATE = 'STATE',
  APPROVED = 'APPROVED',
}

export enum HlrncCode {
  SNC_COMPLIANCE_PERMIT_SCHEDULE_VIOLATIONS = 'SNC_COMPLIANCE_PERMIT_SCHEDULE_VIOLATIONS',
  SNC_EFFLUENT_MONTHLY_AVERAGE_LIMIT = 'SNC_EFFLUENT_MONTHLY_AVERAGE_LIMIT',
  SNC_EFFLUENT_NON_MONTHLY_AVERAGE_LIMIT = 'SNC_EFFLUENT_NON_MONTHLY_AVERAGE_LIMIT',
  SNC_COMPLIANCE_PERMIT_SCHEDULE_REPORTING = 'SNC_COMPLIANCE_PERMIT_SCHEDULE_REPORTING',
  SNC_FAILURE_TO_REPORT_DMR_NOT_RECEIVED = 'SNC_FAILURE_TO_REPORT_DMR_NOT_RECEIVED',
  FAILURE_TO_REPORT_DMR_NOT_RECEIVED = 'FAILURE_TO_REPORT_DMR_NOT_RECEIVED',
  REPORTABLE_NONCOMPLIANCE = 'REPORTABLE_NONCOMPLIANCE',
  RESOLVED_PENDING = 'RESOLVED_PENDING',
  RESOLVED_PENDING_MANUAL = 'RESOLVED_PENDING_MANUAL',
  RESOLVED = 'RESOLVED',
  NON_RNC_VIOLATIONS = 'NON_RNC_VIOLATIONS',
  UNDETERMINED = 'UNDETERMINED',
}

export enum RcraInfoSourceType {
  PART_A = 'PART_A',
  REPORT_UPDATE_WITH_NOTIFICATION = 'REPORT_UPDATE_WITH_NOTIFICATION',
  DEACTIVATION = 'DEACTIVATION',
  EMERGENCY = 'EMERGENCY',
  IMPLEMENTER = 'IMPLEMENTER',
  ELECTRONIC_MANIFEST_BROKER = 'ELECTRONIC_MANIFEST_BROKER',
  NOTIFICATION = 'NOTIFICATION',
  REPORT = 'REPORT',
  TEMPORARY = 'TEMPORARY',
}

export enum RcraInfoNonNotifier {
  NON_NOTIFIER_DETERMINED_TO_BE_EXEMPT = 'NON_NOTIFIER_DETERMINED_TO_BE_EXEMPT',
  FORMER_NON_NOTIFIER = 'FORMER_NON_NOTIFIER',
  NON_NOTIFIER = 'NON_NOTIFIER',
}

export enum RcraInfoAccessibility {
  SITE_IN_BANKRUPTCY = 'SITE_IN_BANKRUPTCY',
  RESPONSIBILITIES_TRANSFERRED_TO_CERCLA = 'RESPONSIBILITIES_TRANSFERRED_TO_CERCLA',
  RESPONSIBLE_PARTIES_HAVE_FLED_THE_COUNTRY = 'RESPONSIBLE_PARTIES_HAVE_FLED_THE_COUNTRY',
  SITE_IN_LITIGATION = 'SITE_IN_LITIGATION',
}

export enum RcraInfoLandType {
  COUNTY = 'COUNTY',
  DISTRICT = 'DISTRICT',
  FEDERAL = 'FEDERAL',
  TRIBAL = 'TRIBAL',
  MUNICIPAL = 'MUNICIPAL',
  OTHER = 'OTHER',
  PRIVATE = 'PRIVATE',
  STATE = 'STATE',
}

export enum RcraInfoFederalWasteGeneratorStatus {
  LARGE_QUANTITY_GENERATOR = 'LARGE_QUANTITY_GENERATOR',
  SMALL_QUANTITY_GENERATOR = 'SMALL_QUANTITY_GENERATOR',
  VERY_SMALL_QUANTITY_GENERATOR = 'VERY_SMALL_QUANTITY_GENERATOR',
  NOT_A_GENERATOR = 'NOT_A_GENERATOR',
  PENDING = 'PENDING',
  UNDETERMINED = 'UNDETERMINED',
}

export enum RcraInfoDataOrigin {
  RCRAINFO_INDUSTRY_APPLICATION = 'RCRAINFO_INDUSTRY_APPLICATION',
  RCRAINFO_BR_LOAD_UTILITY = 'RCRAINFO_BR_LOAD_UTILITY',
  DATA_ENTRY = 'DATA_ENTRY',
  EMERGENCY = 'EMERGENCY',
  EMANIFEST = 'EMANIFEST',
  MANUAL_LOAD = 'MANUAL_LOAD',
  MYRCRAID = 'MYRCRAID',
  TRANSLATION = 'TRANSLATION',
  UTILITY = 'UTILITY',
}

export enum RcraInfoOwnerOperatorIndicator {
  CURRENT_OWNER = 'CURRENT_OWNER',
  CURRENT_OPERATOR = 'CURRENT_OPERATOR',
  PREVIOUS_OWNER = 'PREVIOUS_OWNER',
  PREVIOUS_OPERATOR = 'PREVIOUS_OPERATOR',
}

export enum WasteManifestSource {
  eManifest = 'eManifest',
  CleanEarth = 'CleanEarth',
  CleanHarbors = 'CleanHarbors',
  Republic = 'Republic',
  UsEcology = 'UsEcology',
  WM = 'WM',
  Other = 'Other',
}

export enum JobTask {
  DATA_PIPELINE = 'DATA_PIPELINE',
  EMAIL_DIGESTS = 'EMAIL_DIGESTS',
  FILE_EXTRACTION = 'FILE_EXTRACTION',
  INSIGHTS_CONNECTOR = 'INSIGHTS_CONNECTOR',
  SEND_CHECK = 'SEND_CHECK',
  STAFF_REPORT = 'STAFF_REPORT',
  PROCESS_MANIFESTS = 'PROCESS_MANIFESTS',
  WEB_AUTOMATION = 'WEB_AUTOMATION',
  CHEMICAL_AGGREGATION = 'CHEMICAL_AGGREGATION',
  PROMOTE_DRAFT_REPORT = 'PROMOTE_DRAFT_REPORT',
  ONE_SCHEMA_FILE_UPLOAD = 'ONE_SCHEMA_FILE_UPLOAD',
  EXPORT_DATA = 'EXPORT_DATA',
}

export enum JobStatus {
  QUEUED = 'QUEUED',
  PROVISIONING_ECS_TASK = 'PROVISIONING_ECS_TASK',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  FAILED_MAY_AUTO_RETRY = 'FAILED_MAY_AUTO_RETRY',
  FAILED_NEEDS_HUMAN = 'FAILED_NEEDS_HUMAN',
  FAILED_QUALITY_CONTROL_CHECK = 'FAILED_QUALITY_CONTROL_CHECK',
  FAILED_PROVISIONING_ECS_TASK = 'FAILED_PROVISIONING_ECS_TASK',
  AWAITING_MANUAL_INTERVENTION = 'AWAITING_MANUAL_INTERVENTION',
  SHOULD_CANCEL = 'SHOULD_CANCEL',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  PROMOTED = 'PROMOTED',
  SENT = 'SENT',
  PENDING = 'PENDING',
  PROVISIONING = 'PROVISIONING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum WebAutomationAction {
  acquireLock = 'acquireLock',
  closeDraftReport = 'closeDraftReport',
  collectEvidenceOfCompletion = 'collectEvidenceOfCompletion',
  downloadReport = 'downloadReport',
  deleteLock = 'deleteLock',
  extractChemicalDatabase = 'extractChemicalDatabase',
  extractData = 'extractData',
  extractStateIDs = 'extractStateIDs',
  extractHazardousMaterialsPermit = 'extractHazardousMaterialsPermit',
  getFacilitiesList = 'getFacilitiesList',
  inputData = 'inputData',
  inputDataRevision = 'inputDataRevision',
  inputDataLepc = 'inputDataLepc',
  rcra870012 = 'rcra870012',
  rcra870013 = 'rcra870013',
  rcraEvq = 'rcraEvq',
  scrapeDistrictInfo = 'scrapeDistrictInfo',
  verifyCredentials = 'verifyCredentials',
  verify870012Submission = 'verify870012Submission',
}

export enum WebAutomationCollectEvidenceOfCompletionActions {
  completion = 'completion',
  chemicalCount = 'chemicalCount',
}

export enum LobMailType {
  CHECK = 'CHECK',
  LETTER = 'LETTER',
}

export enum LobMailStatus {
  MAILED = 'MAILED',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_LOCAL_AREA = 'IN_LOCAL_AREA',
  PROCESSED_FOR_DELIVERY = 'PROCESSED_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
  RE_ROUTED = 'RE_ROUTED',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  INTERNATIONAL_EXIT = 'INTERNATIONAL_EXIT',
  ISSUE = 'ISSUE',
  PICKUP_AVAILABLE = 'PICKUP_AVAILABLE',
}

export enum LockType {
  Credential = 'Credential',
  Job = 'Job',
  Report = 'Report',
  BulkMirror = 'BulkMirror',
  FacilityForWasteManifest = 'FacilityForWasteManifest',
  WorkflowManager = 'WorkflowManager',
}

export enum PaymentStatus {
  AWAITING_INVOICE = 'AWAITING_INVOICE',
  PENDING = 'PENDING',
  PAID = 'PAID',
  NO_PAYMENT = 'NO_PAYMENT',
  CANCELLED = 'CANCELLED',
  VOID = 'VOID',
  QUEUED_FOR_AUTOMATION = 'QUEUED_FOR_AUTOMATION',
  FAILURE = 'FAILURE',
}

export enum PaymentMethod {
  CHECK = 'CHECK',
  CREDIT_CARD = 'CREDIT_CARD',
  ACH = 'ACH',
  NO_PAYMENT = 'NO_PAYMENT',
}

export enum Permission {
  RouteEPCRA = 'RouteEPCRA',
  RouteRCRA = 'RouteRCRA',
  RouteFacilities = 'RouteFacilities',
  RouteCalendar = 'RouteCalendar',
  RouteContacts = 'RouteContacts',
  RouteDocuments = 'RouteDocuments',
  RouteEPCRAOverview = 'RouteEPCRAOverview',
  RouteEPCRATierII = 'RouteEPCRATierII',
  RouteEPCRAReports = 'RouteEPCRAReports',
  RouteEPCRAProducts = 'RouteEPCRAProducts',
  RouteEPCRACredentials = 'RouteEPCRACredentials',
  RouteInsights = 'RouteInsights',
  ReadOrgEventSequences = 'ReadOrgEventSequences',
  WriteOrgEventSequences = 'WriteOrgEventSequences',
  ReadAllFacility = 'ReadAllFacility',
  ReadAllFacilityMinimal = 'ReadAllFacilityMinimal',
  WriteAllFacility = 'WriteAllFacility',
  CreateFacility = 'CreateFacility',
  EditOwnFacilityPermissions = 'EditOwnFacilityPermissions',
  EditOwnFacilityPermissionsUnlimited = 'EditOwnFacilityPermissionsUnlimited',
  ReadAllUserTenant = 'ReadAllUserTenant',
  WriteAllUserTenant = 'WriteAllUserTenant',
  CreateUserTenant = 'CreateUserTenant',
  ReadUserTenantRole = 'ReadUserTenantRole',
  WriteUserTenantRole = 'WriteUserTenantRole',
  ReadRole = 'ReadRole',
  WriteRole = 'WriteRole',
  ReadPerson = 'ReadPerson',
  WritePerson = 'WritePerson',
  ReadFacilityContact = 'ReadFacilityContact',
  WriteFacilityContact = 'WriteFacilityContact',
  ReadSystemAuditLog = 'ReadSystemAuditLog',
  ReadUserActivityLog = 'ReadUserActivityLog',
  CreateDataCollectionTask = 'CreateDataCollectionTask',
  WriteDataCollectionTask = 'WriteDataCollectionTask',
  FulfillDataCollectionTask = 'FulfillDataCollectionTask',
  WriteDataCollectionTaskTemplate = 'WriteDataCollectionTaskTemplate',
  WriteAllCalendarEvent = 'WriteAllCalendarEvent',
  VerifyTierIIReport = 'VerifyTierIIReport',
}

export enum SnapshotType {
  TIER_II_REPORT = 'TIER_II_REPORT',
}

export enum InsideOutside {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

export enum StorageQuadrant {
  N = 'N',
  S = 'S',
  E = 'E',
  W = 'W',
  NE = 'NE',
  SE = 'SE',
  NW = 'NW',
  SW = 'SW',
  C = 'C',
  V = 'V',
}

export enum Pressure {
  AMBIENT_PRESSURE = 'AMBIENT_PRESSURE',
  GREATER_THAN_AMBIENT_PRESSURE = 'GREATER_THAN_AMBIENT_PRESSURE',
  LESS_THAN_AMBIENT_PRESSURE = 'LESS_THAN_AMBIENT_PRESSURE',
  OTHER = 'OTHER',
}

export enum Temperature {
  AMBIENT_TEMPERATURE = 'AMBIENT_TEMPERATURE',
  CRYOGENIC_CONDITIONS = 'CRYOGENIC_CONDITIONS',
  GREATER_THAN_AMBIENT_TEMPERATURE = 'GREATER_THAN_AMBIENT_TEMPERATURE',
  LESS_THAN_AMBIENT_TEMPERATURE_BUT_NOT_CRYOGENIC = 'LESS_THAN_AMBIENT_TEMPERATURE_BUT_NOT_CRYOGENIC',
}

export enum TagType {
  Facility = 'Facility',
  Document = 'Document',
  Task = 'Task',
}

export enum TagIcon {
  BADGE_CHECK = 'BADGE_CHECK',
  FOLDER_OPEN = 'FOLDER_OPEN',
  RADIATION_ALT = 'RADIATION_ALT',
  RECYCLE = 'RECYCLE',
  TRASH = 'TRASH',
  USER_HARD_HAT = 'USER_HARD_HAT',
  WATER = 'WATER',
  WIND = 'WIND',
}

export enum Frequency {
  ONCE = 'ONCE',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum AccountType {
  CUSTOMER = 'CUSTOMER',
  DEMO = 'DEMO',
  PROSPECT = 'PROSPECT',
}

export enum TierIIReportKind {
  ANNUAL = 'ANNUAL',
  INITIAL = 'INITIAL',
  REVISION = 'REVISION',
  UPDATE = 'UPDATE',
}

export enum TierIIReportOrgStatus {
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  NOT_REPORTING = 'NOT_REPORTING',
  NOT_STARTED = 'NOT_STARTED',
  VERIFIED = 'VERIFIED',
}

export enum TierIIReportEncampStatus {
  AWAITING_VERIFICATION = 'AWAITING_VERIFICATION',
  NOT_REPORTING = 'NOT_REPORTING',
  PROCESSING = 'PROCESSING',
  FILING_COMPLETE = 'FILING_COMPLETE',
}

export enum TierIIReportStep {
  CHEMICALS = 'CHEMICALS',
  CHEMICAL_SUMMARY = 'CHEMICAL_SUMMARY',
  CONTACTS = 'CONTACTS',
  DOCUMENTS = 'DOCUMENTS',
  FACILITY = 'FACILITY',
  OTHER_CHEMICALS = 'OTHER_CHEMICALS',
  PRODUCTS = 'PRODUCTS',
  REGULATORY = 'REGULATORY',
  REPORT_OVERVIEW = 'REPORT_OVERVIEW',
  REVIEW = 'REVIEW',
  STATE_INFORMATION = 'STATE_INFORMATION',
  VERIFY_AND_SUBMIT = 'VERIFY_AND_SUBMIT',
}

export enum TierIIReportFiledBy {
  ENCAMP = 'ENCAMP',
  OTHER = 'OTHER',
}

export enum AssignmentType {
  ASSIGNEE = 'ASSIGNEE',
  REVIEWER = 'REVIEWER',
}

export enum UserType {
  STAFF_ADMIN = 'STAFF_ADMIN',
  STAFF_EMPLOYEE = 'STAFF_EMPLOYEE',
  STAFF_TEMP = 'STAFF_TEMP',
  CUSTOMER = 'CUSTOMER',
}

export enum UserStatus {
  NOT_INVITED = 'NOT_INVITED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export enum WasteClassification {
  RCRA_ACUTELY_HAZARDOUS = 'RCRA_ACUTELY_HAZARDOUS',
  RCRA_HAZARDOUS = 'RCRA_HAZARDOUS',
  STATE_REGULATED = 'STATE_REGULATED',
  UNIVERSAL = 'UNIVERSAL',
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  NONHAZARDOUS = 'NONHAZARDOUS',
}

export enum EManifestWasteUnit {
  G = 'G',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  P = 'P',
  T = 'T',
  Y = 'Y',
}

export enum StateWasteClassification {
  HAZARDOUS = 'HAZARDOUS',
  NONHAZARDOUS = 'NONHAZARDOUS',
  ACUTELY_HAZARDOUS = 'ACUTELY_HAZARDOUS',
}

export enum ActivityType {
  AddDocument = 'AddDocument',
  CollectEvidence = 'CollectEvidence',
  CollectEvidenceOfCompletion = 'CollectEvidenceOfCompletion',
  CreatePdf = 'CreatePdf',
  CreateZip = 'CreateZip',
  FillPdf = 'FillPdf',
  Geppetto = 'Geppetto',
  Lob = 'Lob',
  Presubmission = 'Presubmission',
  SendEmail = 'SendEmail',
  SignReportPages = 'SignReportPages',
  Staff = 'Staff',
  Tier2Submit = 'Tier2Submit',
  Tier2SubmitValidation = 'Tier2SubmitValidation',
  WaitForAllAltIdReports = 'WaitForAllAltIdReports',
  WaitForAllCNReports = 'WaitForAllCNReports',
  WaitForAllCredentialReports = 'WaitForAllCredentialReports',
  WaitForAllStateReports = 'WaitForAllStateReports',
  WaitForAllFD = 'WaitForAllFD',
  WaitForAllLEPC = 'WaitForAllLEPC',
}

export enum ExecutionPlanType {
  Human = 'Human',
  Automation = 'Automation',
}

export enum ActivityStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Error = 'Error',
  StaffCompleted = 'StaffCompleted',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Queued = 'Queued',
}

export enum Tier2SubmitValidationStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  HasWarnings = 'HasWarnings',
  PendingValidation = 'PendingValidation',
  ServiceError = 'ServiceError',
}

export enum ActivityUserInputType {
  Boolean = 'Boolean',
}

export enum WorkflowType {
  TierIIReportSubmission = 'TierIIReportSubmission',
}

export enum WorkflowStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Error = 'Error',
  Completed = 'Completed',
  Canceled = 'Canceled',
}

export enum WorkflowManagerConfigType {
  ALLOW_ENVIRONMENT = 'ALLOW_ENVIRONMENT',
  ALLOW_STATE = 'ALLOW_STATE',
  ALLOW_FACILITY = 'ALLOW_FACILITY',
  DENY_TENANT = 'DENY_TENANT',
  DENY_FACILITY = 'DENY_FACILITY',
  STATE_BYPASS_VALIDATION = 'STATE_BYPASS_VALIDATION',
  FACILITY_BYPASS_VALIDATION = 'FACILITY_BYPASS_VALIDATION',
  MAX_CONCURRENCY = 'MAX_CONCURRENCY',
  MIN_CONDUCT_EXECUTION_TIME = 'MIN_CONDUCT_EXECUTION_TIME',
}
