import { useSuspenseQuery } from "@apollo/client";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { gql } from "generated-graphql/gql";
import { useTenant } from "hooks/useTenant";
import { FilterSelections } from "../FilterSelections";
import { useOverviewState } from "../OverviewContext";
import {
  allowedViolationsBarChartTypes,
  ViolationsBarChart,
  ViolationsBarChartType,
} from "./ViolationsBarChart";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useQueryParams } from "hooks/useQueryParams";

const INSIGHTS_VIOLATIONS_QUERY = gql(`
  query InsightViolations($tenantId: ID!, $filter: InsightsFilter) {
    insightsViolations(tenantId: $tenantId, filter: $filter) {
      totalViolations
      percentageFacilitiesWithViolation
      programAreas
      violationsByProgramArea {
        name
        totalViolations
      }
      violationsByQuarter {
        quarter
        violationsByProgramArea {
          name
          totalViolations
        }
      }
      violationsByFacility {
        frsId
        name
        totalViolations
      }
    }
  }
`);

const graphVariations: Record<
  ViolationsBarChartType,
  { label: string; selectLabel: string }
> = {
  byProgramArea: {
    label: "Number of violations by program area",
    selectLabel: "Program area totals",
  },
  byQuarter: {
    label: "Number of violations by quarter",
    selectLabel: "Quarterly totals",
  },
  byQuarterProgramArea: {
    label: "Number of violations by quarter and program area",
    selectLabel: "Quarterly by program area",
  },
  byFacility: {
    label: "Facilities with most violations",
    selectLabel: "Facilities with most violations",
  },
};

type QueryParamFilters = {
  violationGraphType: ViolationsBarChartType;
};

export function ViolationsTile() {
  return (
    <DashboardTile xs={12}>
      <ViolationsTileContent />
    </DashboardTile>
  );
}

function ViolationsTileContent() {
  const theme = useTheme();
  const { filters, setFilters } = useQueryParams<QueryParamFilters>();
  const {
    overviewState: { programArea, naicsCode, naicsType },
  } = useOverviewState();
  const { tenantId } = useTenant();

  const violationGraphType = allowedViolationsBarChartTypes.includes(
    filters.violationGraphType as any
  )
    ? (filters.violationGraphType as ViolationsBarChartType)
    : ("byProgramArea" as ViolationsBarChartType);

  const { data } = useSuspenseQuery(INSIGHTS_VIOLATIONS_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: {
        programArea,
        naicsCode: naicsCode?.toString(),
      },
    },
  });

  return (
    <>
      <DashboardTile.Header
        title="Violations"
        infoTooltip={<ViolationsTooltip timePeriod="3 years" />}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FilterSelections
            facilityType={naicsType}
            programArea={programArea}
          />
          <FormControl sx={{ width: theme.spacing(32.5) }}>
            <InputLabel id="view-by">View by</InputLabel>
            <Select
              sx={{ height: theme.spacing(5) }}
              fullWidth
              labelId="view-by"
              label="View by"
              value={violationGraphType}
              onChange={(event) => {
                setFilters((f) => {
                  f.violationGraphType = event.target
                    .value as ViolationsBarChartType;
                });
              }}
            >
              {Object.entries(graphVariations).map(([key, { selectLabel }]) => (
                <MenuItem key={key} value={key}>
                  {selectLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DashboardTile.Header>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={2}
        sx={{ flexGrow: 1 }}
      >
        <DashboardTile.ContentArea sx={{ flex: { xs: "1", lg: "0 0 auto" } }}>
          <Stack
            pl={{ xs: 0, lg: 2 }}
            gap={2}
            direction={{ xs: "row", lg: "column" }}
          >
            <StatDisplay
              label="Total violations"
              value={data?.insightsViolations?.totalViolations}
            />
            <StatDisplay
              label="Facilities with a violation"
              value={`${data?.insightsViolations?.percentageFacilitiesWithViolation}%`}
            />
          </Stack>
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea sx={{ flex: { xs: "1", lg: "0 0 1fr" } }}>
          <Stack direction="row" alignItems="center">
            <Typography>{graphVariations[violationGraphType].label}</Typography>
            <Tooltip
              title={
                <ViolationsGraphTooltip
                  label={graphVariations[violationGraphType].label}
                  timePeriod="3 years"
                />
              }
            >
              <IconButton size="small">
                <InfoOutlined color="disabled" />
              </IconButton>
            </Tooltip>
          </Stack>

          <Stack height={theme.spacing(34)}>
            <ViolationsBarChart
              graphType={violationGraphType}
              data={data?.insightsViolations}
            />
          </Stack>
        </DashboardTile.ContentArea>
      </Stack>
    </>
  );
}

function ViolationsTooltip({ timePeriod }: { timePeriod: string }) {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Program area totals
        </Typography>{" "}
        represents the total number of violations your facilities have received
        over the last {timePeriod}, broken down by the program areas found in
        EPA cases.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly totals
        </Typography>{" "}
        represents the total number of violations your facilities have received
        during each quarter over the last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly by program area
        </Typography>{" "}
        represents the number of violations your facilities have received during
        each quarter over the last {timePeriod}, broken down by the program
        areas found in EPA cases.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Facilities with most violations
        </Typography>{" "}
        represents the facilities who have received the highest number of
        violations over the last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        *You can view the facility details that correspond to each chart by
        clicking on it.
      </Typography>
    </Stack>
  );
}

function ViolationsGraphTooltip({
  label,
  timePeriod,
}: {
  label: string;
  timePeriod: string;
}) {
  switch (label) {
    case "Number of violations by program area":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Program area totals
            </Typography>{" "}
            represents the total number of violations your facilities have
            received over the last {timePeriod}, broken down by the program
            areas found in EPA cases.
          </Typography>

          <Typography variant="caption">
            If there is a violation against multiple program areas, you'll see
            that violation represented for each corresponding program area total
            (e.g. the violation would be added to both air and water totals).
            The violation is only assessed once when calculating the 'total
            violation' count.
          </Typography>
        </Stack>
      );
      break;
    case "Number of violations by quarter":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly totals
            </Typography>{" "}
            represents the total number of violations your facilities have
            received during each quarter over the last {timePeriod}, regardless
            of their program area or facility.
          </Typography>
        </Stack>
      );
      break;
    case "Number of violations by quarter and program area":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly by program area
            </Typography>{" "}
            represents the number of violations your facilities have received
            during each quarter over the last {timePeriod}, broken down by the
            program areas found in EPA cases.
          </Typography>
          <Typography variant="caption">
            If there is a violation against multiple program areas, it will be
            added to the 'multiple programs' section of the chart.
          </Typography>
        </Stack>
      );
      break;
    case "Facilities with most violations":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Facilities with most violations
            </Typography>{" "}
            represents the facilities that have received the highest number of
            violations over the last {timePeriod}.
          </Typography>
          <Typography variant="caption">
            If there is a violation against multiple facilities, you'll see that
            violation represented for each corresponding facility (e.g. the
            violation would be assessed to both facility X and Y).
          </Typography>
        </Stack>
      );
      break;
    default:
      return null;
  }
}
