import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortDirection,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { CustomGridColDef } from "components/DataGrid";
import { produce } from "immer";
import invariant from "invariant";
import { omit } from "lodash";
import { FilterUpdateFunction } from "providers/queryParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorageState } from "./useLocalStorageState";
import { useQueryParams } from "./useQueryParams";

export type FilterKey = {
  key: string;
  header: string;
  filterKeyType?: FilterKeyType;
  HelpTooltip?: React.ComponentType;
  enumValues?: string[];
  enumPresentationFunction?: (enumValue: string) => string;
};

export type FilterKeyType =
  | "text"
  | "time"
  | "enum"
  | "boolean"
  | "number"
  | "facility";

export type OmnisearchGridColDef<
  Row extends GridValidRowModel = Record<string, any>
> = CustomGridColDef<Row> & {
  filterKeyType?: FilterKeyType;
  enumValues?: string[];
  enumPresentationFunction?: (enumValue: string) => string;
  HelpTooltip?: React.ComponentType;
};

type UseOmnisearchInput<Row extends GridValidRowModel = Record<string, any>> = {
  persistenceKey?: string;
  isURLDriven?: boolean;
  excludeFilters?: string[];
  additionalFilters?: FilterKey[];
  // TODO: Setting this means that the grid cannot be "unsorted", as going empty resets to this
  initialSortModel?: GridSortModel;
  initialPageSize?: number;
  columns?: OmnisearchGridColDef<Row>[];
  // TODO: Setting this means that the grid cannot be "unfiltered", as going empty resets to this
  initialFilters?: { field: string; value: string | boolean }[];
};

type OmnisearchGridSettings = {
  omnisearch: string;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
  columnVisibilityModel: GridColumnVisibilityModel;
  columnWidthModel: Record<string, number>;
};

type OmnisearchQueryParams = {
  omnisearch: string;
  page: string;
  pageSize: string;
  sort: string;
  columnVisibility: string;
  columnWidth: string;
};

export function useOmnisearchDatagrid<
  Row extends GridValidRowModel = Record<string, any>
>({
  persistenceKey,
  isURLDriven = true,
  columns: columnsInput,
  additionalFilters = [],
  excludeFilters = [],
  initialPageSize,
  initialSortModel,
  initialFilters = [],
}: UseOmnisearchInput<Row> = {}) {
  const [initialLoad, setInitialLoad] = useState(true);

  const { filters: queryParamFilters, setFilters: setQueryParamFilters } =
    useQueryParams<OmnisearchQueryParams>();
  const [internalStateFilters, setInternalStateFilters] = useState({
    columnVisibility: "",
    columnWidth: "",
    omnisearch: "",
    sort: stringifySort(initialSortModel) ?? "",
    page: 0,
    pageSize: initialPageSize ?? 25,
  });

  const filters = useMemo(
    () =>
      isURLDriven
        ? {
            ...queryParamFilters,
            omnisearch: queryParamFilters.omnisearch?.length
              ? decodeURIComponent(ensureString(queryParamFilters.omnisearch))
              : ensureString(
                  initialFilters
                    ?.map((f) => `${f.field}:"${f.value}"`)
                    .join(" ")
                ),
          }
        : internalStateFilters,
    [internalStateFilters, isURLDriven, queryParamFilters, initialFilters]
  );

  const setFilters = useCallback(
    (updateFn: FilterUpdateFunction<OmnisearchQueryParams>) => {
      if (isURLDriven) {
        return setQueryParamFilters(updateFn);
      } else {
        return setInternalStateFilters((f) => produce(f, updateFn));
      }
    },
    [isURLDriven, setQueryParamFilters]
  );

  const location = useLocation();

  const state = useMemo<OmnisearchGridSettings>(() => {
    return {
      omnisearch: filters.omnisearch ?? "",
      paginationModel: {
        page: filters?.page ? Number(filters.page) : 0,
        pageSize: filters?.pageSize
          ? Number(filters.pageSize)
          : initialPageSize ?? 25,
      },
      sortModel: parseSort(filters.sort) ?? initialSortModel ?? [],
      columnVisibilityModel: filters.columnVisibility
        ? JSON.parse(filters.columnVisibility)
        : {},
      columnWidthModel: filters.columnWidth
        ? JSON.parse(filters.columnWidth)
        : {},
    };
  }, [filters, initialSortModel, initialPageSize]);

  const [persistedGridSettings, setPersistedGridSettings] =
    useLocalStorageState<Partial<OmnisearchGridSettings>>(
      persistenceKey ? `grid-settings-${persistenceKey}` : undefined,
      undefined
    );

  const noOtherFiltersSet = useMemo(() => {
    return (
      !Object.hasOwn(filters, "pageSize") &&
      !Object.hasOwn(filters, "sort") &&
      !Object.hasOwn(filters, "visibility") &&
      !Object.hasOwn(filters, "columnWidth")
    );
  }, [filters]);

  // On initial load, if we've opted into datagrid persistence, and if no other filters are set (ex: from a shared link),
  // initialize url with saved settings. Otherwise, just use the existing url.
  useEffect(() => {
    if (initialLoad && noOtherFiltersSet && persistenceKey) {
      setFilters((f) => {
        f.pageSize =
          persistedGridSettings?.paginationModel?.pageSize?.toString() ??
          undefined;
        f.sort = stringifySort(persistedGridSettings?.sortModel);
        f.columnVisibility = stringifyObject(
          persistedGridSettings?.columnVisibilityModel
        );
        f.columnWidth = stringifyObject(
          persistedGridSettings?.columnWidthModel
        );
      });
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    noOtherFiltersSet,
    persistenceKey,
    persistedGridSettings,
    setFilters,
  ]);

  const columns = useMemo(
    () =>
      columnsInput?.map<GridColDef<Row>>(
        (col) =>
          ({
            ...omit(col, [
              "omnisearchKey",
              "omnisearchFilterKeyType",
              "omnisearchEnumValues",
              "omnisearchHelpTooltip",
              "width",
              "flex",
            ]),

            width: state.columnWidthModel[col.field] ?? col.width,
            flex: !state.columnWidthModel[col.field] ? col.flex : undefined,
          } as GridColDef<Row>)
      ) ?? [],
    [columnsInput, state.columnWidthModel]
  );

  const columnFilterKeys = useMemo(() => {
    return columnsInput
      ?.filter(
        (col) => !excludeFilters.includes(col.field) && col.type !== "actions"
      )
      .reduce<FilterKey[]>(
        (colFilterKeys, col) => [
          ...colFilterKeys,
          {
            header: col.headerName ?? col.field,
            key: col.field,
            filterKeyType: col.filterKeyType,
            enumValues: col.enumValues,
            HelpTooltip: col.HelpTooltip,
            enumPresentationFunction: col.enumPresentationFunction,
          },
        ],
        []
      )
      .concat(additionalFilters);
  }, [additionalFilters, columnsInput, excludeFilters]);

  const getOmnisearchValue = useCallback(
    (key: string) => {
      const { kvpValues } = breakApartOmnisearch(filters.omnisearch ?? "");
      const kvp = kvpValues.find(([k]) => k === key);
      return kvp?.[1];
    },
    [filters.omnisearch]
  );

  const createUrlFromOmnisearchKeyValues = useCallback(
    (keyVals: [string, string][]) => {
      let omnisearch = "";
      for (const [key, value] of keyVals) {
        omnisearch = addOrUpdateKeyInOmnisearch(omnisearch, key, value);
      }
      return `${window.location.protocol}//${window.location.host}${
        location.pathname
      }?${
        omnisearch.length ? `omnisearch=${encodeURIComponent(omnisearch)}` : ""
      }`;
    },
    [location.pathname]
  );

  const setOmnisearch = useCallback(
    (search: string) =>
      setFilters((f) => {
        f.omnisearch = ensureString(search);
      }),
    [setFilters]
  );

  const editOmnisearchKeyValues = useCallback(
    (keyVals: [string, string][]) => {
      const newSearch = keyVals.reduce(
        (updatedSearch, [key, val]) =>
          addOrUpdateKeyInOmnisearch(updatedSearch, key, val),
        ""
      );
      return setOmnisearch(newSearch);
    },
    [setOmnisearch]
  );

  const removeOmnisearchKeyValue = useCallback(
    (key: string) =>
      setFilters((f) => {
        f.omnisearch = removeKeyInOmnisearch(f.omnisearch ?? "", key);
      }),
    [setFilters]
  );

  const setPaginationModel = useCallback(
    (model: GridPaginationModel) =>
      setFilters((f) => {
        f.page = String(model.page);
        f.pageSize = String(model.pageSize);
      }),
    [setFilters]
  );

  const setSortModel = useCallback(
    (model: GridSortModel) =>
      setFilters((f) => {
        f.sort = stringifySort(model);
      }),
    [setFilters]
  );

  const setColumnVisibilityModel = useCallback(
    (model: GridColumnVisibilityModel) => {
      const newState: GridColumnVisibilityModel = {};
      Object.keys(model).forEach((key) => {
        if (!model[key]) {
          newState[key] = model[key];
        }
      });
      setFilters((f) => {
        f.columnVisibility = stringifyObject(newState);
      });
    },
    [setFilters]
  );

  const setColumnWidthModel = useCallback(
    ({ colDef }: { colDef: GridColDef }) => {
      const newState = {
        ...state.columnWidthModel,
        [colDef.field]: colDef.width,
      };
      setFilters((f) => {
        f.columnWidth = stringifyObject(newState);
      });
    },
    [setFilters, state.columnWidthModel]
  );

  const resetColumnSettings = useCallback(() => {
    setFilters((f) => {
      delete f.columnWidth;
      delete f.columnVisibility;
    });
  }, [setFilters]);

  // Saves some grid settings into localStorage: pageSize, sort, column width, column visibility. Excludes omnisearch, and page number
  const persistGridSettings = useCallback(() => {
    invariant(
      persistenceKey,
      "Cannot persist grid settings: No persistenceKey specified."
    );
    setPersistedGridSettings({
      paginationModel: {
        pageSize: state.paginationModel.pageSize,
        page: 0,
      },
      sortModel: state.sortModel,
      columnWidthModel: state.columnWidthModel,
      columnVisibilityModel: state.columnVisibilityModel,
    });
  }, [persistenceKey, setPersistedGridSettings, state]);

  return useMemo(
    () => ({
      ...state,
      columns,
      getOmnisearchValue,
      createUrlFromOmnisearchKeyValues,
      setOmnisearch,
      editOmnisearchKeyValues,
      removeOmnisearchKeyValue,
      setPaginationModel,
      setSortModel,
      setColumnVisibilityModel,
      setColumnWidthModel,
      resetColumnSettings,
      persistGridSettings,
      columnFilterKeys,
    }),
    [
      state,
      columns,
      getOmnisearchValue,
      createUrlFromOmnisearchKeyValues,
      setOmnisearch,
      editOmnisearchKeyValues,
      removeOmnisearchKeyValue,
      setPaginationModel,
      setSortModel,
      setColumnVisibilityModel,
      setColumnWidthModel,
      resetColumnSettings,
      persistGridSettings,
      columnFilterKeys,
    ]
  );
}

function parseSort(sortString?: string): GridSortModel | undefined {
  if (sortString) {
    const [field, sort] = sortString.split("_");
    return [
      {
        field,
        sort: sort as GridSortDirection,
      },
    ];
  }
}

function stringifySort(sortModel?: GridSortModel): string | undefined {
  if (sortModel?.[0]) {
    return `${sortModel[0].field}_${sortModel[0].sort}`;
  }
}

function stringifyObject(object?: Record<string, any>): string | undefined {
  return Object.keys(object ?? {}).length ? JSON.stringify(object) : undefined;
}

function addOrUpdateKeyInOmnisearch(
  omnisearch: string,
  key: string,
  value: string
) {
  const { nonKvpValues, kvpValues } = breakApartOmnisearch(omnisearch);

  const filteredKvpValues = kvpValues.filter(([k]) => k !== key); // remove the old value if it exists, we're updating

  filteredKvpValues.push([key, value]); // add the new value
  return `${nonKvpValues} ${filteredKvpValues
    .map(([k, v]) => `${k}:${v}`)
    .join(" ")}`.trim();
}

function removeKeyInOmnisearch(omnisearch: string, key: string) {
  const { nonKvpValues, kvpValues } = breakApartOmnisearch(omnisearch);
  const filteredKvpValues = kvpValues.filter(([k]) => k !== key); // remove the old value if it exists
  return `${nonKvpValues} ${filteredKvpValues
    .map(([k, v]) => `${k}:${v}`)
    .join(" ")}`.trim();
}

function breakApartOmnisearch(omnisearch: string) {
  const values = omnisearch.split(" ").filter((v) => v.length > 0);
  const nonKvpValues = values.filter((v) => !v.includes(":")).join(" "); // get the non kvp values, just stuff we're searching for

  const kvpValues = values // this is all of the keyvalue pairs except the one we're updating / adding
    .filter((v) => v.includes(":"))
    .map((v) => v.split(":"));

  return { nonKvpValues, kvpValues };
}

function ensureString(value: string | undefined): string {
  return value ? String(value) : "";
}
