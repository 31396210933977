import { Box, useMediaQuery, useTheme } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import {
  AllSeriesType,
  ChartsItemContentProps,
  ChartsTooltip,
  DefaultizedPieValueType,
  PieValueType,
  ResponsiveChartContainer,
  ResponsiveChartContainerProps,
} from "@mui/x-charts";
import { PiePlot, PiePlotProps } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { useMemo } from "react";
import { PriorityChartColors } from "util/insights";
import ChartLegend, { LegendConfig } from "./ChartLegend";
import ChartTooltip from "./ChartTooltip";

const SERIES_ID = "seriesId";

const StyledText = styled("text")(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize,
}));

function PieCenterLabel({
  label,
  subLabel,
  aboveLabel,
}: {
  label?: string;
  subLabel?: string;
  aboveLabel?: string;
}) {
  const { width, height, left, top } = useDrawingArea();

  const offsetX = 0;
  const offsetY = 12;
  const centerX = left + width / 2;
  const centerY = top + height / 2;

  const renderMultilineText = (text: string, fontSize: number, y: number) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <tspan
        key={index}
        x={centerX + offsetX}
        y={y + index * (fontSize * 1.2)}
        textAnchor="middle"
      >
        {line}
      </tspan>
    ));
  };

  return (
    <>
      {aboveLabel && (
        <StyledText
          fontSize={12}
          x={centerX + offsetX}
          y={centerY - 2 * offsetY}
        >
          {renderMultilineText(aboveLabel, 12, centerY - 2 * offsetY)}
        </StyledText>
      )}
      {label && (
        <StyledText
          fontSize={24}
          x={centerX + offsetX}
          y={centerY - (aboveLabel ? 0 : subLabel ? offsetY : 0)}
        >
          {renderMultilineText(
            label,
            24,
            centerY - (aboveLabel ? 0 : subLabel ? offsetY : 0)
          )}
        </StyledText>
      )}
      {subLabel && (
        <StyledText
          fontSize={12}
          x={centerX + offsetX}
          y={centerY + (aboveLabel ? 2 * offsetY : offsetY)}
        >
          {renderMultilineText(
            subLabel,
            12,
            centerY + (aboveLabel ? 2 * offsetY : offsetY)
          )}
        </StyledText>
      )}
    </>
  );
}

function ItemTooltip(
  params: ChartsItemContentProps<"pie"> & { showTitle?: boolean }
) {
  const data = params.series.data?.[params.itemData?.dataIndex];

  if (!data) {
    return null;
  }

  const label = data.label ?? "";

  const title = typeof label === "function" ? label("legend") : label;
  const value = typeof label === "function" ? label("tooltip") : label;

  return (
    <ChartTooltip
      title={params.showTitle ? title : undefined}
      labels={[
        {
          color: params.getColor(params.itemData.dataIndex),
          value: value,
        },
      ]}
    />
  );
}

export type LabelLocation = "tooltip" | "legend" | "arc";

type DonutChartProps = {
  seriesData: PieValueType[];
  seriesOverrides?: Partial<AllSeriesType<"pie">>;
  selectedIndex?: number | null;
  label?: string;
  subLabel?: string;
  aboveLabel?: string;
  containerHeight: string;
  onItemClick?: PiePlotProps["onItemClick"];
  onLegendItemClick?: (item: DefaultizedPieValueType) => void;
  legend?: LegendConfig;
  legendSx?: SxProps;
  showLegend?: boolean;
  showTooltip?: boolean;
  responsiveContainerProps?: Partial<ResponsiveChartContainerProps>;
  colors?: string[];
  showTooltipTitle?: boolean;
};

export default function DonutChart({
  seriesData,
  seriesOverrides,
  selectedIndex,
  label,
  subLabel,
  aboveLabel,
  containerHeight,
  onItemClick,
  onLegendItemClick,
  legend,
  legendSx,
  showLegend = true,
  showTooltip = true,
  showTooltipTitle = true,
  colors = PriorityChartColors,
  responsiveContainerProps,
}: DonutChartProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const highlightedItem = useMemo(() => {
    if (selectedIndex === null || selectedIndex === undefined) {
      return null;
    }

    return {
      dataIndex: selectedIndex,
      seriesId: SERIES_ID,
    };
  }, [selectedIndex]);

  return (
    <Box
      display="flex"
      flexDirection={
        isSmallScreen
          ? "column"
          : legend?.position === "above"
          ? "column-reverse"
          : legend?.position === "right"
          ? "row"
          : legend?.position === "below"
          ? "column"
          : legend?.position === "left"
          ? "row-reverse"
          : "row"
      }
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height={containerHeight}
        width={containerHeight}
        minWidth={containerHeight}
      >
        <ResponsiveChartContainer
          series={[
            {
              id: SERIES_ID,
              type: "pie",
              data: seriesData,
              innerRadius: 65,
              outerRadius: 120,
              highlightScope: { faded: "global", highlighted: "item" },
              ...seriesOverrides,
            },
          ]}
          colors={colors}
          highlightedItem={highlightedItem}
          {...responsiveContainerProps}
        >
          <PiePlot onItemClick={onItemClick} />
          {showTooltip && (
            <ChartsTooltip
              trigger="item"
              slots={{
                itemContent: (params: ChartsItemContentProps<"pie">) => (
                  <ItemTooltip {...params} showTitle={showTooltipTitle} />
                ),
              }}
            />
          )}
          <PieCenterLabel
            label={label}
            subLabel={subLabel}
            aboveLabel={aboveLabel}
          />
        </ResponsiveChartContainer>
      </Box>
      {showLegend && (
        <ChartLegend
          config={legend}
          items={seriesData.map((d, index) => ({
            color: d.color ?? colors[index],
            value:
              (typeof d.label === "function" ? d.label("legend") : d.label) ??
              "",
          }))}
          onItemClick={(index) =>
            onLegendItemClick &&
            onLegendItemClick(seriesData[index] as DefaultizedPieValueType)
          }
          selectedIndex={selectedIndex}
          sx={legendSx}
        />
      )}
    </Box>
  );
}
