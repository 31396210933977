import { GoogleMap, LoadScriptNext, MarkerF } from "@react-google-maps/api";

const defaultLat = 39.8702318;
const defaultLng = -86.1488095;
const defaultZoom = 11;

export default function BasicMap(props: {
  latitude?: number | undefined | null;
  longitude?: number | undefined | null;
  zoom?: number | undefined | null;
  static?: boolean;
  onMarkerDragEnd?: (e: google.maps.MapMouseEvent) => void;
}) {
  const lat = props.latitude ?? defaultLat;
  const lng = props.longitude ?? defaultLng;
  const zoom = props.zoom ?? defaultZoom;

  return (
    <LoadScriptNext
      id="script-loader"
      googleMapsApiKey="AIzaSyDqHtySrYhBtTx6crOmWKHjA0ePGc5_BV0"
    >
      <GoogleMap
        id="facility-map"
        center={{
          lat: lat ?? defaultLat,
          lng: lng ?? defaultLng,
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        mapContainerClassName="rounded-lg"
        zoom={zoom}
        options={{
          gestureHandling: props.static ? "none" : null,
          disableDefaultUI: true,
          keyboardShortcuts: props.static ? false : null,
        }}
      >
        {/*
          It would be nice to be able to use an AdvancedMarker instead but the react-google-maps-api library hasn't implemented it as of now.
          https://github.com/JustFly1984/react-google-maps-api/issues/3250
        */}
        <MarkerF
          position={{ lat, lng }}
          draggable={!props.static}
          onDragEnd={props.onMarkerDragEnd}
        />
      </GoogleMap>
    </LoadScriptNext>
  );
}
