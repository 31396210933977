import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { Npdes, Row as NpdesRow } from "routes/Customer/Insights/Npdes/Npdes";
import { NpdesDetail } from "routes/Customer/Insights/Npdes/NpdesDetail";
import { HasNoInsightsId } from "../Insights/HasNoInsightsId";
import { ComparisonFields, InsightsLinker } from "./InsightsLinker";
import { useFacility } from "./useFacility";

export function NpdesProfile() {
  const { data: facility } = useFacility();
  const facilityId = facility?.facility?.id;

  const npdesId = findAlternateId(
    facility?.facility?.facilityAlternateIds ?? [],
    ["NPDES"],
    true
  );

  if (npdesId?.hasNoId === true) {
    return <HasNoInsightsId type={"NPDES"} />;
  }

  const mapper = (rowData: NpdesRow): ComparisonFields => ({
    name: rowData["facilityName"] ?? "",
    streetAddress: `${rowData["locationAddress"]}`,
    streetAddress2: "",
    city: rowData["city"] ?? "",
    state: rowData["stateCode"] ?? "",
    zip: rowData["zip"] ?? "",
    id: rowData["id"] ?? "",
  });

  return (
    <InsightsLinker
      componentIfLinked={NpdesDetail}
      componentIfUnlinked={Npdes}
      comparisonMapper={mapper}
      facilityId={facilityId || ""}
      type="NPDES"
    />
  );
}
