import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback } from "react";
import { FacilityChemicalStorageLocationInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityChemicalStorageLocation";
import { transform as transformFc } from "hooks/transform/transformFacilityChemical";
import { FacilityChemicalDetailFormType } from "../../Inventory/Facility/FacilityChemicalAddEditDialog";

const VALIDATE_FACILITY_CHEMICAL_STORAGE_LOCATION_INPUT = gql(`
  query ValidateFacilityChemicalStorageLocationInput($input: FacilityChemicalStorageLocationInput!, $facilityId: String!, $facilityChemicalId: String!, $facilityChemical: FacilityChemicalInput) {
    facilityChemicalStorageLocationInputValidator(input: $input, facilityId: $facilityId, facilityChemicalId: $facilityChemicalId, facilityChemical: $facilityChemical) {
      ...issue
    }
  }
`);

export function useFacilityChemicalStorageLocationInputValidation(
  facilityId: string,
  facilityChemicalId: string,
  facilityChemical?: FacilityChemicalDetailFormType
) {
  const [query] = useLazyQuery(
    VALIDATE_FACILITY_CHEMICAL_STORAGE_LOCATION_INPUT,
    {
      fetchPolicy: "no-cache",
    }
  );

  return useCallback(
    async (input: FacilityChemicalStorageLocationInput) => {
      const { data: result } = await query({
        variables: {
          input: transform(input),
          facilityId,
          facilityChemicalId,
          facilityChemical: facilityChemical
            ? transformFc(facilityChemical)
            : undefined,
        },
      });
      return result?.facilityChemicalStorageLocationInputValidator ?? [];
    },
    [query, facilityId, facilityChemicalId, facilityChemical]
  );
}
