import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { gql } from "generated-graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import invariant from "invariant";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CohortSelector from "routes/Customer/Insights/Benchmark/CohortSelector";
import IndustrySelector from "routes/Customer/Insights/Benchmark/IndustrySelector";

const ECHO_OVERVIEW = gql(`
  query EchoOverview($tenantId: String!, $filterTenantId: String, $naicsCode: String, $cohortIds: [String!]) {
    echoOverview(tenantId: $tenantId, filterTenantId: $filterTenantId, naicsCode: $naicsCode, cohortIds: $cohortIds) {
      stats {
        totalFacilityCount
        activeFacilityCount
        airFacilityCount
        airSignificantViolatorCount
        airViolatorCount
        airComplianceStatusCounts {
          countKey
          count
        }
        airPermitTypeCounts {
          countKey
          count
        }
        waterFacilityCount
        waterViolatorCount
        waterSignificantViolatorCount
        waterComplianceStatusCounts {
          countKey
          count
        }
        waterPermitTypeCounts {
          countKey
          count
        }
        wasteFacilityCount
        wasteViolatorCount
        wasteSignificantViolatorCount
        wasteComplianceStatusCounts {
          countKey
          count
        }
        wastePermitTypeCounts {
          countKey
          count
        }
      }
      history {
        quarter
        program
        significantNoncompliance
        violation
        noViolation
        unknown
      }
    }
  }
`);

const FACILITIES = gql(`
  query FacilityCount($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
    }
  }
`);

function LoadingState() {
  return (
    <Stack p={3} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={400} />
        </Grid>
      </Grid>
    </Stack>
  );
}

function ErrorState({ message }: { message: string }) {
  return <Typography>Error: {message}</Typography>;
}

function EmptyState() {
  return <Typography>No data available</Typography>;
}

function PercentageCell({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}) {
  const percentage =
    denominator > 0 ? ((numerator / denominator) * 100).toFixed(2) + "%" : "-";
  return <TableCell>{percentage}</TableCell>;
}

export function Benchmark() {
  useBreadcrumb({ label: "Benchmark" });
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");
  const [comparisonType, setComparisonType] = useState<"industry" | "cohort">(
    "industry"
  );
  const [naicsCode, setNaicsCode] = useState("");
  const [selectedCohortIds, setSelectedCohortIds] = useState<string[]>([]);
  const theme = useTheme();

  const { data: facilitiesData } = useQuery(FACILITIES, {
    variables: {
      search: `tenantId:${tenantId}`,
      page: 1,
      pageSize: 1, // We only need the count
    },
  });

  const tenantFacilitiesCount = facilitiesData?.facilities?.count ?? 0;

  const { data, loading, error } = useQuery(ECHO_OVERVIEW, {
    variables: {
      tenantId: tenantId || "",
      filterTenantId: tenantId || "",
    },
  });

  const {
    data: sectorData,
    loading: sectorLoading,
    refetch: refetchSectorOverview,
  } = useQuery(ECHO_OVERVIEW, {
    variables: {
      naicsCode: comparisonType === "industry" ? naicsCode || "" : "",
      cohortIds: comparisonType === "cohort" ? selectedCohortIds || [] : [],
      tenantId: tenantId || "",
    },
  });

  const handleComparisonSubmit = (value: string | string[]) => {
    if (comparisonType === "industry") {
      setNaicsCode(value as string);
    } else {
      setSelectedCohortIds(value as string[]);
    }
    refetchSectorOverview();
  };

  const mainOverviewStats = data?.echoOverview?.stats ?? {};
  const {
    airFacilityCount: mainAirFacilityCount,
    airViolatorCount: mainAirViolatorCount,
    airSignificantViolatorCount: mainAirSignificantViolatorCount,
    waterFacilityCount: mainWaterFacilityCount,
    waterViolatorCount: mainWaterViolatorCount,
    waterSignificantViolatorCount: mainWaterSignificantViolatorCount,
    wasteFacilityCount: mainWasteFacilityCount,
    wasteViolatorCount: mainWasteViolatorCount,
    wasteSignificantViolatorCount: mainWasteSignificantViolatorCount,
  } = mainOverviewStats as {
    totalFacilityCount?: number;
    activeFacilityCount?: number;
    airFacilityCount?: number;
    airViolatorCount?: number;
    airSignificantViolatorCount?: number;
    waterFacilityCount?: number;
    waterViolatorCount?: number;
    waterSignificantViolatorCount?: number;
    wasteFacilityCount?: number;
    wasteViolatorCount?: number;
    wasteSignificantViolatorCount?: number;
  };

  const sectorOverviewStats = sectorData?.echoOverview?.stats ?? {};
  const {
    totalFacilityCount: sectorTotalFacilityCount,
    airFacilityCount: sectorAirFacilityCount,
    airViolatorCount: sectorAirViolatorCount,
    airSignificantViolatorCount: sectorAirSignificantViolatorCount,
    waterFacilityCount: sectorWaterFacilityCount,
    waterViolatorCount: sectorWaterViolatorCount,
    waterSignificantViolatorCount: sectorWaterSignificantViolatorCount,
    wasteFacilityCount: sectorWasteFacilityCount,
    wasteViolatorCount: sectorWasteViolatorCount,
    wasteSignificantViolatorCount: sectorWasteSignificantViolatorCount,
  } = sectorOverviewStats;

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error.message} />;
  }

  if (!data) {
    return <EmptyState />;
  }

  return (
    <Stack p={3}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="h6">
                Total Tenant Facilities: {tenantFacilitiesCount}
              </Typography>
              <Typography variant="h6">
                Active ECHO Facilities: {mainOverviewStats.activeFacilityCount}
              </Typography>
            </Box>
            {sectorOverviewStats.totalFacilityCount &&
            sectorOverviewStats.totalFacilityCount > 0 ? (
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="h6">
                  {comparisonType === "cohort"
                    ? "Active Cohort Facilities"
                    : "Active Industry Facilities"}
                  : {sectorOverviewStats.activeFacilityCount}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <ToggleButtonGroup
              value={comparisonType}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setComparisonType(newValue);
                }
              }}
              sx={{ mr: 2 }}
            >
              <ToggleButton value="industry">Industry</ToggleButton>
              <ToggleButton value="cohort">Cohort</ToggleButton>
            </ToggleButtonGroup>
            {comparisonType === "industry" ? (
              <IndustrySelector
                onComparisonSubmit={handleComparisonSubmit}
                loading={sectorLoading}
                selectedNaicsCode={naicsCode}
              />
            ) : (
              <CohortSelector
                tenantId={tenantId}
                onComparisonSubmit={handleComparisonSubmit}
                loading={sectorLoading}
                selectedCohortIds={selectedCohortIds}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ border: `${theme.palette.divider} solid 1px` }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Facility Type</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>Percent</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort %</TableCell>
                  ) : (
                    <TableCell>Industry %</TableCell>
                  )}
                  <TableCell>Total Violations</TableCell>
                  <TableCell>Violation %</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort Violation %</TableCell>
                  ) : (
                    <TableCell>Industry Violation %</TableCell>
                  )}
                  <TableCell>Significant Violations</TableCell>
                  <TableCell>Significant Violation %</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort Significant Violation %</TableCell>
                  ) : (
                    <TableCell>Industry Significant Violation %</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Air Facilities</TableCell>
                  <TableCell>{mainAirFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />
                  <TableCell>{mainAirViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirViolatorCount ?? 0}
                    denominator={mainAirFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirViolatorCount ?? 0}
                    denominator={sectorAirFacilityCount ?? 0}
                  />
                  <TableCell>{mainAirSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirSignificantViolatorCount ?? 0}
                    denominator={mainAirFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirSignificantViolatorCount ?? 0}
                    denominator={sectorAirFacilityCount ?? 0}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Water Facilities</TableCell>
                  <TableCell>{mainWaterFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />

                  <TableCell>{mainWaterViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterViolatorCount ?? 0}
                    denominator={mainWaterFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterViolatorCount ?? 0}
                    denominator={sectorWaterFacilityCount ?? 0}
                  />
                  <TableCell>{mainWaterSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterSignificantViolatorCount ?? 0}
                    denominator={mainWaterFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterSignificantViolatorCount ?? 0}
                    denominator={sectorWaterFacilityCount ?? 0}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Waste Facilities</TableCell>
                  <TableCell>{mainWasteFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />
                  <TableCell>{mainWasteViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteViolatorCount ?? 0}
                    denominator={mainWasteFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteViolatorCount ?? 0}
                    denominator={sectorWasteFacilityCount ?? 0}
                  />
                  <TableCell>{mainWasteSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteSignificantViolatorCount ?? 0}
                    denominator={mainWasteFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteSignificantViolatorCount ?? 0}
                    denominator={sectorWasteFacilityCount ?? 0}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Stack>
  );
}
