import {
  Box,
  GridProps,
  Stack,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSuspenseQuery } from "@apollo/client";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { gql } from "generated-graphql";
import { useTenant } from "hooks/useTenant";
import formatCurrency from "util/formatCurrency";
import { formatNumberWithAbbreviation } from "util/formatNumber";

const ENCAMP_STATS_QUERY = gql(`
  query EncampStats($tenantId: ID!) {
    encampStats(tenantId: $tenantId) {
      treesPlanted
      hoursSaved
      totalPaymentsManaged
      totalPaymentsMade
    }
  }
`);

export function EncampStatsTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <EncampStatsContent />
    </DashboardTile>
  );
}

function EncampStatsContent() {
  const { tenantId } = useTenant();
  const { data } = useSuspenseQuery(ENCAMP_STATS_QUERY, {
    variables: { tenantId: tenantId ?? "" },
  });

  const encampStats = data?.encampStats;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const valueProps: TypographyProps = {
    variant: isXs ? "h4" : isSm ? "h5" : "h4",
  };

  return (
    <>
      <DashboardTile.Header
        title="Encamp Stats"
        infoTooltip={<EncampStatsTooltip />}
      ></DashboardTile.Header>
      <DashboardTile.ContentArea>
        <Stack
          direction={{ xs: "column", sm: "row", xl: "column" }}
          justifyContent={{
            xs: "flex-start",
            sm: "space-around",
            xl: "flex-start",
          }}
        >
          <StatDisplay
            label="Trees planted"
            value={
              <Box component="span">
                🌲 {formatNumberWithAbbreviation(encampStats?.treesPlanted)}
              </Box>
            }
            valueProps={valueProps}
            center={!isXl}
          />
          <StatDisplay
            label="Hours saved"
            value={
              <Box component="span">
                ⏰ {formatNumberWithAbbreviation(encampStats?.hoursSaved)}
              </Box>
            }
            valueProps={valueProps}
            center={!isXl}
          />
          <StatDisplay
            label="Total payments managed"
            value={
              <Box component="span">
                💸{" "}
                {formatNumberWithAbbreviation(
                  encampStats?.totalPaymentsManaged
                )}
              </Box>
            }
            valueProps={valueProps}
            center={!isXl}
          />
          <StatDisplay
            label="Total payments made"
            value={
              <Box component="span">
                💰 {formatCurrency(encampStats?.totalPaymentsMade)}
              </Box>
            }
            valueProps={valueProps}
            center={!isXl}
          />
        </Stack>
      </DashboardTile.ContentArea>
    </>
  );
}

function EncampStatsTooltip() {
  return (
    <Typography variant="caption">
      <Typography component="span" variant="caption" fontWeight={800}>
        Encamp Stats
      </Typography>{" "}
      shows you some of your tangible benefits of being an Encamp customer.
    </Typography>
  );
}
