import { Grid, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { useThemeMode } from "hooks/useThemeMode";

export type HorizontalBarChartDatum<T> = {
  label: string;
  value: T;
  total: number;
  percentage: number;
  color: string;
};

type HorizontalBarChartProps<T> = {
  title: string;
  data: HorizontalBarChartDatum<T>[];
  value?: T;
  onClick: (d: T) => void;
  barLabelGrid?: number;
};

type BarProps<T = any> = {
  d: HorizontalBarChartDatum<T>;
  color: string;
  highlighted?: boolean;
  barLabelGrid?: number;
};

type PercentageLabelProps = {
  total?: number;
  percentage: number;
  sx?: SxProps;
};

export function HorizontalBarChart<T = any>(props: HorizontalBarChartProps<T>) {
  const theme = useTheme();
  const applyHighlighting = props.data.reduce(
    (prev, d) => prev || d.value === props.value,
    false
  );
  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography>{props.title}</Typography>
      </Grid>
      <Grid item container xs={12}>
        {props.data.map((d, i) => (
          <Grid
            item
            container
            key={i}
            xs={12}
            marginTop={theme.spacing(2)}
            sx={{ "&:hover": { opacity: 0.7, cursor: "pointer" } }}
            onClick={() => {
              props.onClick(d.value);
            }}
          >
            <Bar
              barLabelGrid={props.barLabelGrid}
              d={d}
              color={d.color}
              highlighted={
                applyHighlighting ? d.value === props.value : undefined
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function Bar<T = any>({
  barLabelGrid = 1,
  d,
  color,
  highlighted,
}: BarProps<T>) {
  const theme = useTheme();
  const { themeMode } = useThemeMode();
  const invertLabelPosition = d.percentage >= 80;
  const muted = highlighted !== undefined && !highlighted;
  const barGrid = 12 - barLabelGrid;
  return (
    <>
      <Grid item xs={barLabelGrid} alignSelf={"center"}>
        <Typography sx={{ fontSize: theme.spacing(1.5) }}>{d.label}</Typography>
      </Grid>
      <Grid item xs={barGrid}>
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{
            backgroundColor:
              themeMode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
            borderRadius: 0.5,
            marginLeft: 1,
          }}
        >
          <Stack
            sx={{
              width: `${d.percentage}%`,
              transition: "width 0.3s ease-in-out",
              backgroundColor: !muted
                ? color
                : themeMode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
              borderRadius: theme.spacing(0.5),
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              height: theme.spacing(7),
            }}
            direction={"row"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            {invertLabelPosition && (
              <PercentageLabel
                percentage={d.percentage}
                total={d.total}
                sx={{
                  color: !muted
                    ? "white"
                    : themeMode === "light"
                    ? theme.palette.grey[500]
                    : theme.palette.grey[400],
                }}
              />
            )}
          </Stack>
          {!invertLabelPosition && (
            <PercentageLabel total={d.total} percentage={d.percentage} />
          )}
        </Stack>
      </Grid>
    </>
  );
}

function PercentageLabel(props: PercentageLabelProps) {
  const { total, percentage, sx } = props;
  const theme = useTheme();
  const label = total
    ? `${total.toLocaleString()} (${percentage}%)`
    : `${percentage}%`;
  return (
    <Typography
      sx={{
        marginX: 1,
        fontSize: "1rem",
        color: theme.palette.grey[500],
        ...sx,
      }}
    >
      {label}
    </Typography>
  );
}
