import { Stack, Typography, useTheme } from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { Issue } from "generated-graphql/graphql";
import { isEqual } from "lodash";
import { Component, Ref } from "react";
import { DataGridCell } from "./DataGridCell";

export type CustomGridColDef<
  Row extends GridValidRowModel = Record<string, any>
> = GridColDef<Row> & {
  topAligned?: boolean;
};

// Light wrapper of MUI's DataGridPremium component where we can specify global styles that should apply to all tables
// any default props can and should be overridable.
export class DataGrid<Row extends GridValidRowModel> extends Component<
  Omit<DataGridPremiumProps<Row>, "columns"> & {
    innerRef?: Ref<HTMLDivElement>;
    noRowsMessage?: string;
    columns: CustomGridColDef<Row>[];
  }
> {
  private memoizedColumns: CustomGridColDef<Row>[] = [];
  private prevColumnsInput: CustomGridColDef<Row>[] = [];
  render() {
    const {
      innerRef,
      noRowsMessage,
      columns: columnsInput,
      ...props
    } = this.props;
    const rowMinimumHeight = 38;

    const hasTopAlignedColumn = columnsInput.some((col) => col.topAligned);

    if (!isEqual(this.prevColumnsInput, columnsInput)) {
      this.memoizedColumns = columnsInput.map((column) => {
        if (column.topAligned) {
          return {
            ...column,
            renderCell: (params) => (
              <DataGridCell>
                {column.renderCell
                  ? column.renderCell(params)
                  : params.value ?? params.row}
              </DataGridCell>
            ),
          };
        }
        return column;
      });
      this.prevColumnsInput = columnsInput;
    }

    const columns = this.memoizedColumns;

    return (
      <DataGridPremium
        ref={innerRef}
        rowHeight={38}
        pageSizeOptions={[10, 25, 50, 100, 200, 300, 400, 500]}
        disableColumnFilter
        disableRowGrouping
        disableAggregation
        slots={
          noRowsMessage
            ? {
                noRowsOverlay: () => (
                  <div style={{ padding: "2rem", textAlign: "center" }}>
                    {noRowsMessage}
                  </div>
                ),
              }
            : undefined
        }
        {...props}
        columns={columns}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "40px",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-cellContent": {
            minHeight: `${rowMinimumHeight}px`,
            alignContent: "center",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:not(.actions)":
            {
              py: hasTopAlignedColumn ? "10px" : "inherit",
            },
          ...(props.sx ?? {}),
        }}
      />
    );
  }
}

/**
 * Component to render a cell with a primary and secondary (smaller) text value
 */
export const CellWithSecondaryText = ({
  primaryValue,
  secondaryValue,
}: {
  primaryValue: string | null | undefined;
  secondaryValue: string | null | undefined;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" width="100%" sx={{ py: theme.spacing(1) }}>
      <Typography variant="body2" noWrap>
        {primaryValue}
      </Typography>
      <Typography variant="caption" noWrap sx={{ fontSize: "10px" }}>
        {secondaryValue}
      </Typography>
    </Stack>
  );
};

export const IssueMessageCell = ({ issue }: { issue: Issue }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        color: theme.palette.error.main,
        whiteSpace: "normal",
        wordWrap: "break-word",
      }}
      variant="body2"
    >
      {issue.message}
    </Typography>
  );
};
