import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { convertAbbreviationToStateName } from "encamp-shared/src/utils/states";
import { DynamicField } from "generated-graphql/graphql";
import { groupBy } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DynamicField as DynamicFieldComponent } from "./DynamicField";

type StateFieldContext = "facility" | "facilityChemical";

export type StateFieldsFormProps = {
  fields: DynamicField[];
  // The context in which we are rendering this form
  context?: StateFieldContext;
  openJurisdictions?: string[];
  namePrefix: string;
  readOnly?: boolean;
  sx?: SxProps<Theme>;
};

export function StateFieldsForm(props: StateFieldsFormProps) {
  const { fields, openJurisdictions, namePrefix, context } = props;
  // The index of the field is tied to the form itself. we need to record the
  // position and use this index for the form input
  const fieldsWithIndex = fields.map((f, index) => ({
    ...f,
    originalIndex: index,
  }));

  const jurisdictions = [
    ...new Set(fieldsWithIndex.map((f) => f.jurisdiction)),
  ].sort();
  const fieldGroups = groupBy(fieldsWithIndex, "jurisdiction");

  const theme = useTheme();
  const form = useFormContext();

  if (context === "facilityChemical") {
    return (
      <>
        {jurisdictions.map((jurisdiction) => {
          const fields = Object.values(fieldGroups[jurisdiction]);
          const groupedFields = groupBy(
            fields,
            (field) => field.group || "default"
          );

          return (
            <Fragment key={jurisdiction}>
              <Typography variant="h6" marginBottom={theme.spacing(1)}>
                Jurisdiction-specific Fields for{" "}
                {convertAbbreviationToStateName(jurisdiction) ?? jurisdiction}
              </Typography>
              <Box>
                {Object.entries(groupedFields).map(([group, groupFields]) => {
                  const groupErrorMessage =
                    form.formState.errors.stateFields?.message;

                  return (
                    <Fragment key={`${jurisdiction}.${group}`}>
                      {group !== "default" && (
                        <Fragment>
                          <Typography variant="subtitle2">{group}</Typography>
                          <Typography
                            variant="subtitle2"
                            color="error"
                            sx={{ minHeight: "24px" }}
                          >
                            {groupErrorMessage
                              ? groupErrorMessage.toString()
                              : ""}
                          </Typography>
                        </Fragment>
                      )}
                      <Grid
                        container
                        columnSpacing={theme.spacing(3)}
                        rowSpacing={theme.spacing(1)}
                      >
                        {groupFields.map((field) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            key={`${field.jurisdiction}.${field.key}`}
                          >
                            <DynamicFieldComponent
                              sx={{ mb: theme.spacing(1) }}
                              namePrefix={`${namePrefix}.${field.originalIndex}`}
                              control={form.control}
                              dynamicField={field}
                              disabled={props.readOnly}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Fragment>
                  );
                })}
              </Box>
            </Fragment>
          );
        })}
      </>
    );
  }

  if (jurisdictions.length === 1 || context === "facility") {
    return (
      <Box sx={props.sx}>
        {jurisdictions.map((jurisdiction) =>
          Object.values(fieldGroups[jurisdiction]).map((field) => (
            <DynamicFieldComponent
              sx={{ mb: theme.spacing(1) }}
              key={`${field.jurisdiction}.${field.key}`}
              namePrefix={`${namePrefix}.${field.originalIndex}`}
              control={form.control}
              dynamicField={field}
              disabled={props.readOnly}
            />
          ))
        )}
      </Box>
    );
  }
  return (
    <Grid
      container
      columnSpacing={theme.spacing(3)}
      rowSpacing={theme.spacing(1)}
    >
      {jurisdictions.map((jurisdiction) => (
        <Grid item key={jurisdiction} xs={12}>
          <CollapsibleCard
            title={jurisdiction}
            // Open the card if specifically asked or if there are field values
            openState={
              openJurisdictions?.includes(jurisdiction) ||
              fieldGroups[jurisdiction].some(
                (f) => f.value != null && f.value !== false
              )
            }
          >
            <CardContent
              sx={{
                px: theme.spacing(3),
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {Object.values(fieldGroups[jurisdiction]).map((field) => (
                <DynamicFieldComponent
                  sx={{ mb: theme.spacing(1) }}
                  key={`${field.jurisdiction}.${field.key}`}
                  namePrefix={`${namePrefix}.${field.originalIndex}`}
                  control={form.control}
                  dynamicField={field}
                  disabled={props.readOnly}
                />
              ))}
            </CardContent>
          </CollapsibleCard>
        </Grid>
      ))}
    </Grid>
  );
}

const CollapsibleCard = ({
  title,
  openState,
  children,
}: {
  title: string;
  openState?: boolean;
  children: React.ReactNode;
}) => {
  const [sectionOpen, setSectionOpen] = useState(openState);
  const toggleSectionOpen = useCallback(() => {
    setSectionOpen((prevState) => !prevState);
  }, []);

  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title={
          <Typography color="GrayText" variant="h6">
            {title}
          </Typography>
        }
        onClick={toggleSectionOpen}
        sx={{
          cursor: "pointer",
          ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          color: theme.palette.error,
        }}
        action={
          <IconButton>
            {sectionOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={sectionOpen}>{children}</Collapse>
    </Card>
  );
};
