import React, {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { Box, TextField, Button, Grid, Chip } from "@mui/material";
import { useBreadcrumb } from "../../../hooks/useBreadcrumbs";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DemoOrganizationInput } from "generated-graphql/graphql";
import { useAlerts } from "components/Alerts/AlertProvider";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { DataGrid } from "components/DataGrid";

interface ListWithAddRemoveProps {
  label: string;
  items: string[];
  setItems: (items: string[]) => void;
}

const ListWithAddRemove: React.FC<ListWithAddRemoveProps> = ({
  label,
  items,
  setItems,
}) => {
  const [newItem, setNewItem] = useState<string>("");

  const handleAddItems = (input: string) => {
    const trimmedLines = input
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");

    if (trimmedLines.length > 0) {
      setItems([...items, ...trimmedLines]);
      setNewItem("");
    }
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewItem(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleAddItems(newItem);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    handleAddItems(pastedText);
  };

  return (
    <Box>
      <TextField
        label={label}
        value={newItem}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
        placeholder="Add new item(s)"
        fullWidth
        margin="normal"
        multiline
        rows={2}
        size="small"
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {items.map((item, index) => (
          <Chip
            key={index}
            label={item}
            onDelete={() => handleRemoveItem(index)}
            size="small"
          />
        ))}
      </Box>
    </Box>
  );
};

const DEMO_ACCOUNT_CANDIDATE_SEARCH = gql`
  query DemoAccountCandidateSearch($input: DemoOrganizationInput!) {
    demoAccountCandidates(input: $input) {
      items {
        registryId
        rcraIds
        naics
        name
        streetAddress
        city
        zip
        state
        county
        latitude
        longitude
        emails
        ownerName
        operatorName
      }
      count
    }
  }
`;

const CREATE_DEMO_ORGANIZATION = gql`
  mutation CreateDemoOrganization($input: DemoOrganizationInput!) {
    createDemoOrganization(input: $input)
  }
`;

export function DemoOrganizationCreator() {
  useBreadcrumb({
    label: "Create Demo Organization",
    to: "/staff/demoOrganizationCreator",
  });
  const alerts = useAlerts();

  const [demoOrgState, setDemoOrgState] = useState<DemoOrganizationInput>({
    includeNameTerms: [],
    excludeNameTerms: [],
    includeDomains: [],
    excludeDomains: [],
    includeEpaIds: [],
    excludeEpaIds: [],
    includeRegistryIds: [],
    excludeRegistryIds: [],
    tenantName: "",
    salesforceId: "",
  });
  const anySearchCriteria = useMemo(() => {
    return (
      demoOrgState.includeNameTerms.length > 0 ||
      demoOrgState.excludeNameTerms.length > 0 ||
      demoOrgState.includeDomains.length > 0 ||
      demoOrgState.excludeDomains.length > 0 ||
      demoOrgState.includeEpaIds.length > 0 ||
      demoOrgState.excludeEpaIds.length > 0 ||
      demoOrgState.includeRegistryIds.length > 0 ||
      demoOrgState.excludeRegistryIds.length > 0
    );
  }, [demoOrgState]);

  const [
    createDemoOrganization,
    {
      loading: createDemoOrganizationLoading,
      called: createDemoOrganizationCalled,
    },
  ] = useMutation(CREATE_DEMO_ORGANIZATION);

  const [demoAccountCandidateSearch, { loading, data }] = useLazyQuery(
    DEMO_ACCOUNT_CANDIDATE_SEARCH,
    {
      variables: {
        input: demoOrgState,
      },
    }
  );

  const onCreateDemoOrganization = useCallback(() => {
    createDemoOrganization({
      variables: {
        input: demoOrgState,
      },
      onCompleted: () => {
        alerts.success("Successfully created demo organization");
      },
      onError: (e) => {
        alerts.error("Error creating demo organization", e);
      },
    });
  }, [createDemoOrganization, demoOrgState, alerts]);

  const handleSearch = useCallback(() => {
    demoAccountCandidateSearch({
      variables: {
        input: demoOrgState,
      },
    });
  }, [demoAccountCandidateSearch, demoOrgState]);

  const updateDemoOrgState = useCallback(
    (update: Partial<DemoOrganizationInput>) => {
      setDemoOrgState((prev) => ({ ...prev, ...update }));
    },
    []
  );

  const columns: GridColDef[] = [
    { field: "registryId", headerName: "Registry ID", flex: 1 },
    {
      field: "rcraIds",
      headerName: "RCRA ID",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? params.value.join(", ") : "N/A",
    },
    { field: "name", headerName: "Facility Name", flex: 2 },
    {
      field: "emails",
      headerName: "Emails",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? params.value.join(", ") : "N/A",
    },
    { field: "ownerName", headerName: "Owner Name", flex: 1 },
    { field: "operatorName", headerName: "Operator Name", flex: 1 },
    {
      field: "naics",
      headerName: "NAICS",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? params.value : "N/A",
    },
    { field: "streetAddress", headerName: "Street Address", flex: 2 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "zip", headerName: "Zip", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "county", headerName: "County", flex: 1 },
    {
      field: "latitude",
      headerName: "Latitude",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value !== null ? params.value : "N/A",
    },
    {
      field: "longitude",
      headerName: "Longitude",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value !== null ? params.value : "N/A",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Include Name Terms"
              items={demoOrgState.includeNameTerms}
              setItems={(newValue) =>
                updateDemoOrgState({ includeNameTerms: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Exclude Name Terms"
              items={demoOrgState.excludeNameTerms}
              setItems={(newValue) =>
                updateDemoOrgState({ excludeNameTerms: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Include Domains"
              items={demoOrgState.includeDomains}
              setItems={(newValue) =>
                updateDemoOrgState({ includeDomains: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Exclude Domains"
              items={demoOrgState.excludeDomains}
              setItems={(newValue) =>
                updateDemoOrgState({ excludeDomains: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Include EPA IDs"
              items={demoOrgState.includeEpaIds}
              setItems={(newValue) =>
                updateDemoOrgState({ includeEpaIds: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Exclude EPA IDs"
              items={demoOrgState.excludeEpaIds}
              setItems={(newValue) =>
                updateDemoOrgState({ excludeEpaIds: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Include Registry IDs"
              items={demoOrgState.includeRegistryIds}
              setItems={(newValue) =>
                updateDemoOrgState({ includeRegistryIds: newValue })
              }
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <ListWithAddRemove
              label="Exclude Registry IDs"
              items={demoOrgState.excludeRegistryIds}
              setItems={(newValue) =>
                updateDemoOrgState({ excludeRegistryIds: newValue })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Button
            variant="contained"
            onClick={() => handleSearch()}
            disabled={loading || !anySearchCriteria}
          >
            {loading ? "Searching..." : "Run Search"}
          </Button>
          <TextField
            label="New Tenant Name"
            value={demoOrgState.tenantName}
            onChange={(e) => updateDemoOrgState({ tenantName: e.target.value })}
            size="small"
          />
          <TextField
            label="Salesforce ID"
            value={demoOrgState.salesforceId}
            onChange={(e) =>
              updateDemoOrgState({ salesforceId: e.target.value })
            }
            size="small"
          />
          <Button
            variant="contained"
            onClick={onCreateDemoOrganization}
            disabled={
              createDemoOrganizationLoading ||
              !demoOrgState.tenantName ||
              data?.demoAccountCandidates?.count === 0 ||
              createDemoOrganizationCalled
            }
          >
            Create Account
          </Button>
        </Box>

        <Box sx={{ width: "100%", minHeight: 400 }}>
          <DataGrid
            rows={data?.demoAccountCandidates?.items || []}
            columns={columns}
            pagination
            pageSizeOptions={[10, 50, 100, 250, 500]}
            paginationMode="client"
            rowCount={data?.demoAccountCandidates?.count || 0}
            loading={loading}
            getRowId={(row) =>
              row.registryId ??
              `${row.name}-${row.streetAddress}-${row.city}-${row.zip}`
            }
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
}
