import { Stack, Typography } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { ExportButton, ExportFileType } from "components/ExportButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  FacilityWasteStreamsQuery,
  FacilityWasteStreamsQueryVariables,
  WasteClassification,
} from "generated-graphql/graphql";
import { useParams } from "react-router-dom";
import { formatNumber } from "util/formatNumber";

const FACILITY_WASTE_STREAMS = gql(`
  query FacilityWasteStreams(
    $facilityId: ID!,
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facility(id: $facilityId) {
      wasteStreams(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          dotDescription
          federalWasteCodes
          stateWasteCodes
          classification
          shipmentsYearToDate
        }
        count
      }
    }
  }
`);

export const WasteStreamsTile = () => {
  return (
    <DashboardTile xs={12}>
      <WasteStreamsTileContent />
    </DashboardTile>
  );
};

const WasteStreamsTileContent = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  return (
    <>
      <DashboardTile.Header
        title="Waste Streams"
        infoTooltip={
          <Stack spacing={2}>
            <Typography variant="caption">
              Waste streams are created based on the unique combination of DOT
              Description and Federal and State Waste Codes.
            </Typography>
            <Typography variant="caption">
              Waste streams are currently created based on eManifest data.
            </Typography>
          </Stack>
        }
      />
      <DashboardTile.ContentArea>
        <OmnisearchDataGrid<
          FacilityWasteStreamsQuery,
          FacilityWasteStreamsQueryVariables,
          NonNullable<
            NonNullable<FacilityWasteStreamsQuery["facility"]>["wasteStreams"]
          >["items"][number]
        >
          columns={[
            {
              field: "dotDescription",
              headerName: "DOT Description",
              flex: 1,
              minWidth: 100,
            },
            {
              field: "wasteCodes",
              headerName: "Waste Codes",
              flex: 0.5,
              minWidth: 100,
              maxWidth: 200,
              valueGetter({ row }) {
                const stateWasteCodes = row.stateWasteCodes || [];
                const federalWasteCodes = row.federalWasteCodes || [];
                return [...stateWasteCodes, ...federalWasteCodes].join(", ");
              },
            },
            {
              field: "classification",
              headerName: "Classification",
              flex: 0.5,
              maxWidth: 150,
              valueFormatter: ({ value }) =>
                prettyPrintEnumValue(value)?.replace("Rcra", "RCRA"),
              filterKeyType: "enum",
              enumValues: Object.values(WasteClassification),
            },

            {
              field: "shipmentsYearToDate",
              headerName: `Shipments (YTD)`,
              flex: 0.5,
              maxWidth: 150,
              valueFormatter: ({ value }) =>
                value ? `${formatNumber(value)} lbs` : "",
            },
          ]}
          dataQuery={FACILITY_WASTE_STREAMS}
          getItems={(data) => data.facility?.wasteStreams?.items ?? []}
          getCount={(data) => data.facility?.wasteStreams?.count ?? 0}
          dataQueryVariables={{ facilityId: facilityId ?? "" }}
          initialPageSize={10}
          showFavorites={true}
          withPadding={false}
          noDataMessage={"No Waste Streams found for this facility."}
          initialSortModel={[{ field: "dotDescription", sort: "asc" }]}
          excludeFilterColumns={["wasteCodes", "generated"]}
          isRowSelectable={() => false}
          disableRowSelectionOnClick={true}
          sx={{
            ".MuiDataGrid-row": {
              cursor: "default !important",
            },
          }}
          additionalFilterColumns={[
            {
              filterKeyType: "text",
              key: "stateWasteCode",
              header: "State Waste Code",
            },
            {
              filterKeyType: "text",
              key: "federalWasteCode",
              header: "Federal Waste Code",
            },
          ]}
          commandButtons={[
            <ExportButton
              key="export"
              fileType={ExportFileType.EXCEL}
              endpoint={`/download/wasteStreams?facilityId=${facilityId}`}
              disabled={!facilityId}
            />,
          ]}
        />
      </DashboardTile.ContentArea>
    </>
  );
};
