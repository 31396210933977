import {
  FormControl,
  GridProps,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { useQueryParams } from "hooks/useQueryParams";
import { AnomaliesDataGrid } from "./AnomaliesDataGrid";
import { ProblematicReportsDataGrid } from "./ProblematicReportsDataGrid";
import { ProlificIssuesDataGrid } from "./ProlificIssuesDataGrid";

export function IssuesAndAnomaliesTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <IssuesAndAnomaliesContent />
    </DashboardTile>
  );
}

const GRID_TYPES = {
  ISSUES: { value: "issues", label: "Prolific issues" },
  REPORTS: { value: "reports", label: "Problematic reports" },
  ANOMALIES: { value: "anomalies", label: "Anomalies" },
} as const;

type GridTypeKey = keyof typeof GRID_TYPES;
type GridTypeValue = (typeof GRID_TYPES)[GridTypeKey]["value"];

function IssuesAndAnomaliesContent() {
  const theme = useTheme();
  const { filters, setFilters } = useQueryParams<{ gridType: string }>();

  const gridType: GridTypeValue = Object.values(GRID_TYPES)
    .map<string>((type) => type.value)
    .includes(filters.gridType ?? "")
    ? (filters.gridType as GridTypeValue)
    : "issues";

  return (
    <>
      <DashboardTile.Header title="Issues & Anomalies">
        <FormControl sx={{ width: theme.spacing(32.5) }}>
          <InputLabel id="view-by">View by</InputLabel>
          <Select
            sx={{ height: theme.spacing(5) }}
            fullWidth
            labelId="view-by"
            label="View by"
            value={gridType}
            onChange={(e) =>
              setFilters((f) => {
                f.gridType = e.target.value;
              })
            }
          >
            {Object.entries(GRID_TYPES).map(([key, { value, label }]) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DashboardTile.Header>
      <DashboardTile.ContentArea>
        {gridType === "issues" && <ProlificIssuesDataGrid />}
        {gridType === "reports" && <ProblematicReportsDataGrid />}
        {gridType === "anomalies" && <AnomaliesDataGrid />}
      </DashboardTile.ContentArea>
    </>
  );
}
