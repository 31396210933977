import Warning from "@mui/icons-material/Warning";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { FacilityAlternateIdKnownKind } from "encamp-shared/src/facility/alternateId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { gql } from "generated-graphql";
import {
  RcraInfoFederalWasteGeneratorStatus,
  WasteFacilitiesQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import {
  OmnisearchGridColDef,
  useOmnisearchDatagrid,
} from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useNavigate } from "react-router-dom";
import { wasteGeneratorStatusToLabel } from "util/constants";
import { getRegulatoryStatusDiscrepancies } from "../utils";
import { LongPollingExportButton } from "components/LongPollingExportButton";
import { useMemo } from "react";
import { ExportFileType } from "components/ExportButton";
import { formatAddress } from "encamp-shared/src/utils/address";

const WASTE_FACILITIES = gql(`
  query WasteFacilities(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        city
        state
        zip
        facilityAlternateIds {
          id
          type
          value
          hasNoId
        }
        regulatoryStatus {
          rcraInfo
          rcraInfoHighestStatusThisYear
          eManifest
          vendor
        }
      }
      count
    }
  }
`);

export const WasteFacilities = () => {
  const navigate = useNavigate();
  const { tenantId } = useTenant();
  const { omnisearch } = useOmnisearchDatagrid();

  const filename = useMemo(
    () => `wasteFacilities-${new Date().toISOString()}.xlsx`,
    // Make sure to generate a new filename when the inputs change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [omnisearch, tenantId]
  );

  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Facilities",
    },
  ]);

  const columns: OmnisearchGridColDef<
    WasteFacilitiesQuery["facilities"]["items"][number]
  >[] = [
    {
      field: "epaId",
      headerName: "EPA ID",
      minWidth: 150,
      valueGetter({ row }) {
        return findAlternateId(row.facilityAlternateIds ?? [], [
          FacilityAlternateIdKnownKind.EPA,
        ])?.value;
      },
      sortable: false,
    },
    {
      field: "name",
      headerName: "Facility",
      filterKeyType: "facility",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      valueGetter({ row }) {
        return formatAddress(row.streetAddress1, row.city, row.state, row.zip);
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "highestGenStatus",
      headerName: "Highest Reported Generator Status",
      minWidth: 235,
      sortable: false,
      valueGetter({ row }) {
        if (
          !row.regulatoryStatus?.rcraInfoHighestStatusThisYear ||
          row.regulatoryStatus?.rcraInfoHighestStatusThisYear ===
            RcraInfoFederalWasteGeneratorStatus.Undetermined
        ) {
          return "";
        }

        return wasteGeneratorStatusToLabel(
          row.regulatoryStatus?.rcraInfoHighestStatusThisYear
        );
      },
    },
    {
      field: "issues",
      headerName: "Issues",
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        if (!row.regulatoryStatus) {
          return null;
        }

        const discrepancies = getRegulatoryStatusDiscrepancies(
          row.regulatoryStatus
        );

        if (discrepancies.length > 0) {
          return (
            <Tooltip
              title={
                <Stack gap={2}>
                  {discrepancies.map((d) => (
                    <Typography
                      variant="caption"
                      sx={{ whiteSpace: "pre-wrap" }}
                      key={d}
                    >
                      {d}
                    </Typography>
                  ))}
                </Stack>
              }
            >
              <Warning color="error" />
            </Tooltip>
          );
        }

        return null;
      },
    },
  ];

  const btns = [
    <LongPollingExportButton
      key="export"
      fileType={ExportFileType.EXCEL}
      endpoint={`/download/wasteFacilities?tenantId=${tenantId}&search=${omnisearch}&filename=${filename}`}
      fileName={filename}
      tenantId={tenantId ?? ""}
    />,
  ];

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        defaultSearch={`hasManifests:true tenantId:${tenantId}`}
        columns={columns}
        commandButtons={btns}
        dataQuery={WASTE_FACILITIES}
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        showFavorites
        isRowSelectable={() => false}
        noDataMessage={
          "No waste facilities have been added to this organization"
        }
        excludeFilterColumns={[
          "issues",
          "highestGenStatus",
          "address",
          "epaId",
        ]}
        additionalFilterColumns={[
          { header: "Street Address", key: "streetAddress1" },
          { header: "City", key: "city" },
          { header: "State", key: "state" },
          { header: "Zip", key: "zip" },
        ]}
        onRowClick={(row) => navigate(`${row.id}`)}
        initialSortModel={[{ field: "name", sort: "asc" }]}
      />
    </Box>
  );
};
