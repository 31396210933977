import { Box, Typography, Stack, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

type StatDisplayProps = {
  label: string;
  value: ReactNode;
  subtext?: string;
  valueProps?: TypographyProps;
  center?: boolean;
};

export const StatDisplay = ({
  label,
  value,
  subtext,
  valueProps,
  center,
}: StatDisplayProps) => {
  return (
    <Stack
      direction="column"
      py={1}
      alignItems={center ? "center" : "flex-start"}
    >
      <Typography variant="caption" color="text.secondary" noWrap>
        {label}
      </Typography>
      <Box display="flex" alignItems="baseline" flexWrap="wrap">
        <Typography
          variant="h4"
          color="text.primary"
          component="span"
          {...valueProps}
          sx={{
            flexShrink: 1,
            minWidth: 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...valueProps?.sx,
          }}
        >
          {value}
        </Typography>
        {subtext && (
          <Typography
            variant="caption"
            color="text.secondary"
            component="span"
            sx={{ ml: 1, fontWeight: "500", flexShrink: 0 }}
          >
            {subtext}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
