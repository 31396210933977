/**
 * Formats an address string in the format of "123 Main St, Springfield, IL 62704"
 */
export function formatAddress(
  streetAddress: string | null | undefined,
  city: string | null | undefined,
  state: string | null | undefined,
  zip: string | null | undefined
) {
  return [streetAddress, [city, state].filter(Boolean).join(", "), zip]
    .filter(Boolean)
    .join(" ");
}
