import { Issue } from "generated-graphql/graphql";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import {
  DefaultValues,
  FieldValues,
  Path,
  ResolverResult,
  UseFormHandleSubmit,
  useForm,
} from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

export function useValidatingForm<TFieldValues extends FieldValues>(
  data: TFieldValues | undefined,
  persistedErrors: Issue[] | undefined,
  validator: (input: TFieldValues) => Promise<Issue[]>
) {
  const [errors, setErrors] = useState<Record<string, any>>({});
  const asyncValidationResolver = useDebouncedCallback(
    async (data: TFieldValues): Promise<ResolverResult<TFieldValues>> => {
      const errors: Record<string, any> = {};
      if (data) {
        // Used for issues list in footer
        const validationResult = await validator(data);
        /**
         * Sorting the issues by key length ensures that shorter paths are
         * processed first which fixes a react hook form bug when longer paths
         * like "person.phones.0.number" are called before "person.phones"
         */
        validationResult.sort((a, b) => {
          const pathA = a?.key ?? "";
          const pathB = b?.key ?? "";
          if (pathA < pathB) return -1;
          if (pathA > pathB) return 1;
          return 0;
        });
        setValidationIssues(validationResult);

        const groupedErrors = groupErrorsByKey(
          updateIssuesKeys({
            data: data,
            issues: validationResult,
          })
        );

        Object.keys(groupedErrors).forEach((key) => {
          errors[key] = {
            type: "input",
            message: groupedErrors[key]
              .map((error) => error.message)
              .join("| "),
          };

          setError(key as Path<TFieldValues>, {
            type: "validation",
            message: groupedErrors[key]
              .map((error) => error.message)
              .join("| "),
          });
        });
      }

      setErrors(errors);
      return {
        values: Object.keys(errors).length > 0 ? {} : data, // Return data only if there are no errors
        errors: errors,
      };
    },
    500
  );

  const form = useForm<TFieldValues>({
    defaultValues: (data as DefaultValues<TFieldValues>) ?? undefined,
    resolver: (values) =>
      asyncValidationResolver(values) ?? { errors: {}, values },
    mode: "onChange",
  });

  const { reset, setError, clearErrors, handleSubmit, getValues } = form;

  const customHandleSubmit: UseFormHandleSubmit<TFieldValues> = (
    onSubmit,
    onInvalid
  ) =>
    handleSubmit(onSubmit, (errors, e) => {
      const data = getValues();

      if (onInvalid) {
        onInvalid(errors, e);
      }

      onSubmit(data, e);
    });

  useEffect(() => {
    clearErrors();
    /**
     * Sorting the issues by key ensures that shorter paths are
     * processed first which fixes a react hook form bug when longer paths
     * like "person.phones.0.number" are called before "person.phones"
     */
    const keys = Object.keys(errors).sort();
    for (const errorKey of keys) {
      setError(errorKey as Path<TFieldValues>, errors[errorKey]);
    }
  }, [errors, setError, clearErrors]);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const memoizedPersistedErrors = useDeepCompareMemoize(
    updateIssuesKeys({
      data: data ?? {},
      issues: persistedErrors ?? [],
    }) ?? []
  );

  const [validationIssues, setValidationIssues] = useState<Issue[]>([]);

  useEffect(() => {
    if (memoizedPersistedErrors) {
      clearErrors();
      setValidationIssues(memoizedPersistedErrors);

      // Displaying issues on initial form load
      const groupedErrors = groupErrorsByKey(memoizedPersistedErrors);
      /**
       * Sorting the issues by key ensures that shorter paths are
       * processed first which fixes a react hook form bug when longer paths
       * like "person.phones.0.number" are called before "person.phones"
       */
      const keys = Object.keys(groupedErrors).sort();
      for (const key of keys) {
        setError(key as Path<TFieldValues>, {
          type: "persisted",
          message: groupedErrors[key].map((error) => error.message).join("| "),
        });
      }
    }
  }, [clearErrors, memoizedPersistedErrors, setError, data]);

  return {
    ...form,
    handleSubmit: customHandleSubmit,
    issues: validationIssues,
  };
}

export function findPath<TFieldValues extends FieldValues>({
  object,
  modelId,
  issueKey,
  currentPath,
}: {
  object: TFieldValues;
  modelId: string;
  issueKey: string;
  currentPath: string[];
}): string | null {
  for (const key of Object.keys(object)) {
    const newPath = [...currentPath, key];
    if (typeof object[key] === "object" && object[key] !== null) {
      if ("id" in object[key] && object[key].id === modelId) {
        return [...newPath, issueKey].join(".");
      }
      const result = findPath({
        object: object[key],
        modelId,
        issueKey,
        currentPath: newPath,
      });
      if (result) {
        return result;
      }
    }
  }
  return null;
}

/**
 * Construct issue keys according to the structure of form data
 * by recursively traversing the object for issue.modelId
 * and update issue.key to the corresponding path in data
 */
export function updateIssuesKeys<TFieldValues extends FieldValues>({
  data,
  issues,
}: {
  data: TFieldValues;
  issues: Issue[];
}): Issue[] {
  return issues.map((issue) => {
    if (issue.modelId == null) {
      return issue;
    }
    const path = findPath({
      object: data,
      modelId: issue.modelId ?? "",
      issueKey: issue.key ?? "",
      currentPath: [],
    });
    if (path) {
      return { ...issue, key: path };
    }

    return mapStateFieldKeys({
      data,
      issue,
    });
  });
}

function groupErrorsByKey(errors: Issue[]): Record<string, Issue[]> {
  return groupBy(errors, "key");
}

function mapStateFieldKeys<TFieldValues extends FieldValues>({
  data,
  issue,
}: {
  data: TFieldValues;
  issue: Issue;
}): Issue {
  // @TODO - can just check stateFields once we rename all these
  // tables and relationships to stateFields.
  const stateFieldArrayKeys = [
    "stateFields",
    "facilityChemicalStateFields", // on Facility/Chemical model
  ];

  // find the first stateFieldKey match in data
  const stateFieldsKey = stateFieldArrayKeys.find((key) => key in data);
  if (!stateFieldsKey) {
    return issue;
  }

  const pathIndex = data[stateFieldsKey].findIndex(
    (c: any) =>
      c.key === issue.key && issue.jurisdictions?.includes(c.jurisdiction)
  );
  if (pathIndex !== -1) {
    const newPath = `${stateFieldsKey}.${pathIndex}.value`;
    return { ...issue, key: newPath };
  }

  return issue;
}
