import { Stack } from "@mui/material";
import { orange, red } from "@mui/material/colors";
import { BarSeriesType } from "@mui/x-charts";
import { BarChart } from "components/Dashboard/BarChart";
import ChartLegend from "components/Dashboard/ChartLegend";
import { GetInsightsNonComplianceQuery } from "generated-graphql/graphql";
import { uniqBy } from "lodash";
import pluralize from "pluralize";
import { useMemo } from "react";
import {
  getPriorityChartColorByIndex,
  PriorityChartColors,
  ProgramAreaColors,
} from "util/insights";
import { useOverviewState } from "../OverviewContext";

export const allowedNonComplianceBarChartTypes = [
  "programAreaTotals",
  "quarterlyNonComplianceTotals",
  "quarterlyNonComplianceByProgramArea",
  "leastCompliantFacilities",
] as const;

export type NonComplianceBarChartType =
  (typeof allowedNonComplianceBarChartTypes)[number];

export type NonComplianceBarChartProps = {
  graphType: NonComplianceBarChartType;
  data: GetInsightsNonComplianceQuery["insightsNonCompliance"] | undefined;
};

export const NonComplianceBarChart = ({
  graphType,
  data,
}: NonComplianceBarChartProps) => {
  if (!data) return null;

  switch (graphType) {
    case "programAreaTotals":
      return <ProgramAreaTotals data={data.byProgramArea} />;
    case "quarterlyNonComplianceTotals":
      return (
        <QuarterlyNonComplianceTotals data={data.quarterlyBySignificance} />
      );
    case "quarterlyNonComplianceByProgramArea":
      return (
        <QuarterlyNonComplianceByProgramArea
          data={data.quarterlyByProgramArea}
        />
      );
    case "leastCompliantFacilities":
      return <LeastCompliantFacilities data={data.violationsByFacility} />;
    default:
      return null;
  }
};

const ProgramAreaTotals = ({
  data,
}: {
  data: GetInsightsNonComplianceQuery["insightsNonCompliance"]["byProgramArea"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  const hasNoData = data.reduce((acc, curr) => acc + curr.count, 0) === 0;
  return (
    <BarChart
      dataset={hasNoData ? [{ count: 0, name: "No data" }] : data}
      series={[
        {
          type: "bar",
          dataKey: "count",
          valueFormatter: (val) =>
            `${val ? val.toLocaleString() : "0"} ${pluralize(
              "facility",
              val ?? 0
            )}`,
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "name",
          colorMap: {
            type: "ordinal",
            values: Object.keys(ProgramAreaColors),
            colors: Object.values(ProgramAreaColors),
          },
          categoryGapRatio: 0.65,
        },
      ]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (val) => (val ? val.toString() : "0"),
        },
      ]}
      barLabel={({ value }) => (value ? value.toString() : "0")}
      barLabelColor="white"
      onItemClick={(event, item) => {
        drillDownToEcho((f) => ({
          ...f,
          programAreaNonCompliance: data[item.dataIndex].key,
        }));
      }}
    />
  );
};

const QuarterlyNonComplianceTotals = ({
  data,
}: {
  data: GetInsightsNonComplianceQuery["insightsNonCompliance"]["quarterlyBySignificance"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  const aggregatedData = useMemo(() => {
    const result: {
      quarter: string;
      Violation: number;
      Significant: number;
    }[] = [];

    data.forEach((item) => {
      const existing = result.find((r) => r.quarter === item.quarter);
      if (existing) {
        existing[item.group as "Violation" | "Significant"] = item.count;
      } else {
        result.push({
          quarter: item.quarter,
          Violation: item.group === "Violation" ? item.count : 0,
          Significant: item.group === "Significant" ? item.count : 0,
        });
      }
    });

    return result;
  }, [data]);

  return (
    <>
      <ChartLegend
        config={{ direction: "row", position: "above" }}
        items={[
          {
            color: orange[400],
            value: `Facilities with non-compliance`,
          },
          {
            color: red[400],
            value: `Facilities with significant non-compliance`,
          },
        ]}
        sx={{ paddingLeft: 6, position: "absolute" }}
      />
      <BarChart
        dataset={aggregatedData}
        series={[
          {
            type: "bar",
            dataKey: "Violation",
            id: "violation",
            stack: "compliance",
            color: orange[400],
            valueFormatter: (val) =>
              `Facilities with non-compliance (${val ? val.toString() : "0"})`,
          },
          {
            type: "bar",
            id: "significant",
            dataKey: "Significant",
            stack: "compliance",
            color: red[400],
            valueFormatter: (val) =>
              `Facilities with significant non-compliance (${
                val ? val.toString() : "0"
              })`,
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "quarter",
            categoryGapRatio: 0.2,
            valueFormatter(value, context) {
              if (context.location === "tick") {
                return value.split(" ")[0] === "Q1"
                  ? value.split(" ").join("\n")
                  : value.split(" ")[0];
              }
              return value;
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "linear",
            valueFormatter: (val) => (val ? val.toString() : "0"),
          },
        ]}
        onItemClick={(event, item) => {
          const significance =
            item.seriesId === "significant" ? "significant" : "noncompliance";
          drillDownToEcho((f) => ({
            ...f,
            hasHistoricalNonCompliance: significance,
          }));
        }}
        stackedTooltipTotalFormatter={(total) => `Total facilities: ${total}`}
      />
    </>
  );
};

const QuarterlyNonComplianceByProgramArea = ({
  data,
}: {
  data: GetInsightsNonComplianceQuery["insightsNonCompliance"]["quarterlyByProgramArea"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  const aggregatedData = useMemo(() => {
    const result: {
      quarter: string;
      Waste: number;
      Water: number;
      Air: number;
      TRI: number;
      "Multiple Programs": number;
    }[] = [];

    data.forEach((item) => {
      const existing = result.find((r) => r.quarter === item.quarter);
      if (existing) {
        existing[item.group as "Waste" | "Water" | "Air" | "TRI"] = item.count;
      } else {
        result.push({
          quarter: item.quarter,
          Waste: item.group === "Waste" ? item.count : 0,
          Water: item.group === "Water" ? item.count : 0,
          Air: item.group === "Air" ? item.count : 0,
          TRI: item.group === "TRI" ? item.count : 0,
          "Multiple Programs":
            item.group === "Multiple Programs" ? item.count : 0,
        });
      }
    });

    return result;
  }, [data]);

  const series: BarSeriesType[] = [
    "Waste",
    "Water",
    "Air",
    "TRI",
    "Multiple Programs",
  ].map((programArea) => ({
    type: "bar" as const,
    dataKey: programArea,
    id: programArea,
    stack: "programArea",
    color: ProgramAreaColors[programArea],
    valueFormatter: (val) =>
      programArea === "Multiple Programs"
        ? `Multi-program facilities (${val ? val.toLocaleString() : "0"})`
        : `${programArea} facilities (${val ? val.toString() : "0"})`,
  }));

  return (
    <>
      <ChartLegend
        config={{ direction: "row", position: "above" }}
        items={uniqBy(data, "group").map((d) => ({
          color: d.group ? ProgramAreaColors[d.group] : "",
          value: d.group ?? "",
        }))}
        sx={{ paddingLeft: 6, position: "absolute" }}
      />
      <BarChart
        dataset={aggregatedData}
        series={series}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "quarter",
            categoryGapRatio: 0.2,
            valueFormatter(value, context) {
              if (context.location === "tick") {
                return value.split(" ")[0] === "Q1"
                  ? value.split(" ").join("\n")
                  : value.split(" ")[0];
              }
              return value;
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "linear",
            valueFormatter: (val) => (val ? val.toString() : "0"),
          },
        ]}
        onItemClick={(event, item) => {
          drillDownToEcho((f) => ({
            ...f,
            hasHistoricalNonCompliance: true,
            programAreaNonCompliance:
              item.seriesId !== "Multiple Programs" ? item.seriesId : undefined,
          }));
        }}
        stackedTooltipTotalFormatter={(total) => `Total facilities: ${total}`}
      />
    </>
  );
};

const LeastCompliantFacilities = ({
  data,
}: {
  data: GetInsightsNonComplianceQuery["insightsNonCompliance"]["violationsByFacility"];
}) => {
  const { drillDownToEcho } = useOverviewState();
  return (
    <Stack display="flex" direction="row" height="100%">
      <BarChart
        dataset={data.length ? data : [{ count: 0, name: "No data" }]}
        series={[
          {
            type: "bar",
            dataKey: "count",
            valueFormatter: (val) =>
              `Quarters spent in non-compliance (${
                val ? val.toString() : "0"
              })`,
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            categoryGapRatio: 0.5,
            colorMap: {
              type: "ordinal",
              colors: PriorityChartColors,
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "linear",
            valueFormatter: (val) => (val ? val.toLocaleString() : "0"),
          },
        ]}
        barLabel={({ value }) => (value ? value.toLocaleString() : "0")}
        barLabelColor="white"
        indexAxisLabelProperty={data.length ? "name" : undefined}
        onItemClick={(event, item) => {
          drillDownToEcho(() => ({
            registryId: data[item.dataIndex].key,
          }));
        }}
      />
      <ChartLegend
        items={data.map((d, index) => ({
          color: getPriorityChartColorByIndex(index),
          value: `${index + 1}. ${d.name}`,
        }))}
        sx={{ flex: 1, maxWidth: "35%" }}
      />
    </Stack>
  );
};
