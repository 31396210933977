import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CatalogLink } from "components/CatalogLink";
import { ChemicalPicker } from "components/ChemicalPicker";
import { ConfirmDialog } from "components/ConfirmDialog";
import { CheckboxField } from "components/Forms/CheckboxField";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { StateFieldsForm } from "components/Forms/StateFieldsForm";
import { AmountCodeSelect } from "components/OverallStorage/AmountCodeSelect";
import { SaveButton } from "components/SaveButton";
import {
  FacilityChemicalStorageLocationInputWithIssues,
  StorageLocationsDataGrid,
} from "components/StorageLocationsDataGrid";
import { ThresholdChip } from "components/ThresholdChip";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  DynamicField,
  FacilityChemicalDetailsFragment,
  FacilityChemicalInput,
  FacilityChemicalStorageLocationInput,
  Issue,
  PreviousYearValuesQuery,
  ProgramArea,
  UnitType,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityChemical";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import invariant from "invariant";
import { omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { GetReportDetailsQueryName } from "routes/Customer/Chemicals/Report/useReport";
import { useFacilityChemicalInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useFacilityChemicalInputValidation";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";

gql(`
  fragment FacilityChemicalChemical on Chemical {
    id
    ...ChemicalPicker
    alternateId
    isEhs
  }
`);

gql(`
  fragment FacilityChemicalDetails on FacilityChemical {
      id
      reportingYear
      unit
      hasConfidentialStorageLocations
      isAlwaysReported
      facility {
        id
        name
        state
      }
      chemical {
        ...FacilityChemicalChemical
      }
      ...FacilityChemicalAggregate
      storageLocations {
        ...FacilityChemicalStorageLocationForm
      }
      issues {
        ...issue
      }
      stateFields {
        key
        value
        type
        jurisdiction
        inputType
        label
        tooltip
        selectOptions {
          label
          value
        }
        group
      }
      jurisdictions
  }
`);

const FACILITY_CHEMICAL_DETAILS = gql(`
  query FacilityChemicalDetails ($id: ID!) {
    facilityChemical(id: $id) {
      ...FacilityChemicalDetails
    }
  }
`);

const EDIT_FACILITY_CHEMICAL = gql(`
  mutation EditFacilityChemical($id: ID, $input: FacilityChemicalInput!, $reportId: ID) {
    upsertFacilityChemical(id: $id, input: $input, reportId: $reportId) {
      ...FacilityChemicalDetails
    }
  }
`);

const PREVIOUS_YEAR_DETAILS_QUERY =
  gql(`query PreviousYearValues($facilityId: ID!, $chemicalId: ID!, $reportingYear: Int!) {
  facilityChemicalByYear(facilityId: $facilityId, chemicalId: $chemicalId, reportingYear: $reportingYear) {
    maxAmount
    unit
  }
}`);

const IS_OVER_THRESHOLD_QUERY = gql(`
  query IsChemicalOverThreshold($input: FacilityChemicalInput!) {
    getFacilityChemicalThresholdData(input: $input) {
      isOverThreshold
    }
}`);

const FACILITY_CHEMICAL_STATE_FIELDS_QUERY = gql(`
  query GetFacilityChemicalStateFields($facilityId: ID!) {
    facilityChemicalStateFieldsForFacility(facilityId: $facilityId) {
      items {
        key
        value
        jurisdiction
        label
        tooltip
        type
        inputType
        selectOptions {
          label
          value
        }
        group
      }
    }
}`);

interface AddEditFacilityChemicalDialogProps {
  state: AddEditFacilityChemicalDialogState;
  reportId?: string;
  reportingYear: number;
  alreadySelectedChemicalIds?: string[];
  facilityId: string;
  onClose: () => void;
}

export type AddEditFacilityChemicalDialogState = {
  open: boolean;
  facilityChemicalId?: string;
};

export type FacilityChemicalDetailFormType = Omit<
  FacilityChemicalInput,
  "maxAmountCode" | "averageAmountCode"
> & {
  id?: string;
  maxAmountCode: string | number | null;
  averageAmountCode: string | number | null;
  stateFields: (DynamicField & { type: ProgramArea })[];
};

export type FacilityChemicalStorageLocationDetailFormType =
  FacilityChemicalStorageLocationInput & {
    id?: string;
  };

export const defaultAddEditFacilityChemicalDialogState: AddEditFacilityChemicalDialogState =
  {
    open: false,
  };

export const AddEditFacilityChemicalDialog: React.FC<
  AddEditFacilityChemicalDialogProps
> = ({
  state,
  reportingYear,
  onClose,
  reportId,
  alreadySelectedChemicalIds = [],
  facilityId,
}) => {
  const { open, facilityChemicalId } = state;
  const isAddMode = !facilityChemicalId;
  const { tenantId } = useTenant();
  const theme = useTheme();
  const alerts = useAlerts();
  const { data: facility } = useFacility(facilityId);
  const [previousYearData, setPreviousYearData] =
    useState<PreviousYearValuesQuery | null>(null);
  invariant(facilityId, "Facility id is required.");

  const [editFacilityChemical, { loading: submitting }] = useMutation(
    EDIT_FACILITY_CHEMICAL
  );

  const { data, loading } = useQuery<{
    facilityChemical: FacilityChemicalDetailsFragment & {
      previousYearMaxAmount?: number;
      previousYearUnit?: UnitType;
    };
  }>(FACILITY_CHEMICAL_DETAILS, {
    variables: {
      id: facilityChemicalId ?? "",
    },
    fetchPolicy: "cache-and-network",
    skip: isAddMode,
  });

  const { data: stateFieldsData } = useQuery(
    FACILITY_CHEMICAL_STATE_FIELDS_QUERY,
    {
      variables: {
        facilityId,
      },
      skip: !isAddMode || !!data?.facilityChemical?.stateFields.length,
    }
  );

  const facilityState =
    data?.facilityChemical?.facility?.state ?? facility?.facility?.state;

  const stateFields: DynamicField[] = useMemo(() => {
    return (
      data?.facilityChemical?.stateFields ??
      stateFieldsData?.facilityChemicalStateFieldsForFacility?.items ??
      []
    );
  }, [data?.facilityChemical?.stateFields, stateFieldsData]);

  const facilityChemical = useMemo(() => {
    return open ? data?.facilityChemical : null;
  }, [data?.facilityChemical, open]);

  const defaultValues: FacilityChemicalDetailFormType = useMemo(() => {
    return {
      id: facilityChemical?.id ?? uuid(),
      averageAmount: facilityChemical?.averageAmount,
      averageAmountCode:
        facilityChemical?.averageAmountCode?.toString() ?? null,
      chemicalId: facilityChemical?.chemical?.id,
      storageLocations: [
        ...(
          facilityChemical?.storageLocations ?? []
        ).map<FacilityChemicalStorageLocationDetailFormType>((sl) => ({
          id: sl.id,
          maxAmount: sl.maxAmount ?? null,
          pressure: sl.pressure ?? null,
          otherPressureValue: sl.otherPressureValue ?? null,
          storageType: sl.storageType ?? null,
          storageTypeDescription: sl.storageTypeDescription ?? null,
          temperature: sl.temperature ?? null,
          unit: sl.unit ?? null,
          storageLocation: {
            id: sl.storageLocation?.id ?? "",
            description: sl.storageLocation?.description ?? "",
            facilityId,
            latitude: sl.storageLocation?.latitude ?? null,
            longitude: sl.storageLocation?.longitude ?? null,
            OR_insideOutside: sl.storageLocation?.OR_insideOutside ?? null,
            OR_storageArea: sl.storageLocation?.OR_storageArea ?? null,
            OR_storageBuilding: sl.storageLocation?.OR_storageBuilding ?? null,
            OR_storageFloor: sl.storageLocation?.OR_storageFloor ?? null,
            OR_storageQuadrant: sl.storageLocation?.OR_storageQuadrant ?? null,
            OR_storageRoom: sl.storageLocation?.OR_storageRoom ?? null,
          },
        })),
      ],
      daysOnSite: facilityChemical?.daysOnSite,
      facilityId,
      hasConfidentialStorageLocations:
        facilityChemical?.hasConfidentialStorageLocations ?? null,
      maxAmount: facilityChemical?.maxAmount,
      maxAmountCode: facilityChemical?.maxAmountCode?.toString() ?? null,
      maxAmountLargestContainer: facilityChemical?.maxAmountLargestContainer,
      reportingYear: facilityChemical?.reportingYear ?? reportingYear,
      unit: facilityChemical?.unit ?? UnitType.Pounds,
      isAlwaysReported: facilityChemical?.isAlwaysReported ?? false,
      facility: facilityChemical?.facility,
      stateFields:
        stateFields?.map((sf) => ({
          ...omit(sf, "selectOptions", "tooltip"),
          value: sf.value ?? null,
          type: ProgramArea.Epcra,
        })) ?? [],
      issues: facilityChemical?.issues,
    };
  }, [facilityChemical, facilityId, reportingYear, stateFields]);

  const form = useValidatingForm<FacilityChemicalDetailFormType>(
    defaultValues,
    facilityChemical?.issues ?? [],
    useFacilityChemicalInputValidation()
  );

  const { control, watch, handleSubmit, formState, issues, reset, getValues } =
    form;
  const {
    fields: storageLocations,
    append,
    replace,
    update,
  } = useFieldArray({
    control: control,
    name: "storageLocations",
    keyName: "_id",
  });

  const storageLocationsIssue = useMemo(() => {
    return issues.find((i) => i.key === "storageLocations");
  }, [issues]);

  const storageLocationsWithIssueCounts = useMemo(() => {
    return mapStorageLocationToIssueCount(storageLocations, issues);
  }, [issues, storageLocations]);

  const handleStorageLocationSubmit: SubmitHandler<FacilityChemicalStorageLocationInput> =
    useCallback(
      (data) => {
        const slIndex = storageLocations.findIndex((l) => l.id === data.id);
        if (slIndex >= 0) {
          update(slIndex, data);
        } else {
          append(data);
        }

        // Find all instances where the nested storageLocation id matches
        const matchingIndices = storageLocations
          .map((l, index) =>
            l.storageLocation.id === data.storageLocation.id ? index : -1
          )
          .filter((index) => index !== -1 && index !== slIndex);

        matchingIndices.forEach((index) => {
          // Update only the nested storageLocation while preserving other fields
          const updatedLocation = {
            ...storageLocations[index], // Keep other fields
            storageLocation: { ...data.storageLocation }, // Only update storageLocation part
          };
          update(index, updatedLocation); // Update each matching location
        });
      },
      [append, storageLocations, update]
    );

  const handleRemoveStorageLocation = useCallback(
    (storageLocationId: string) => {
      replace(storageLocations.filter((l) => l.id !== storageLocationId));
    },
    [replace, storageLocations]
  );

  const handleClose = useCallback(() => {
    reset();
    setPreviousYearData(null);
    onClose();
  }, [onClose, reset]);

  const hasPendingChanges = formState.isDirty && !formState.isSubmitSuccessful;

  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const onCancel = useCallback(() => {
    // If there are pending changes, verify the user wants to close the dialog
    if (hasPendingChanges) {
      setShowConfirmClose(true);
      return;
    }

    handleClose();
  }, [handleClose, reset, hasPendingChanges]);

  const onSubmit: SubmitHandler<FacilityChemicalDetailFormType> = async (
    data
  ) => {
    try {
      const input = transform(data);

      await editFacilityChemical({
        variables: {
          id: isAddMode ? undefined : facilityChemical?.id,
          reportId,
          input,
        },
        refetchQueries: ["FacilityChemicals"].concat(
          reportId // Include report details if we are in a report context
            ? [
                "TierIIReportReportingFacilityChemicals",
                GetReportDetailsQueryName,
              ]
            : []
        ),
      });
      handleClose();
      alerts.success("Successfully saved facility chemical");
    } catch (err) {
      alerts.error("An error occurred while saving facility chemical", err);
    }
  };

  const formData = watch();

  const { data: isOverThresholdData, refetch: refetchThresholdData } = useQuery(
    IS_OVER_THRESHOLD_QUERY,
    {
      variables: {
        input: omit(
          transform({ ...formData, storageLocations: [] }),
          "stateFields"
        ),
      },
      skip: !open || !formData.chemicalId,
    }
  );

  const { refetch: refetchPreviousYearData } = useQuery(
    PREVIOUS_YEAR_DETAILS_QUERY,
    {
      variables: {
        facilityId,
        chemicalId:
          data?.facilityChemical.chemical?.id ?? formData.chemicalId ?? "",
        reportingYear: reportingYear - 1,
      },
      skip: !formData.chemicalId && !data?.facilityChemical.chemical?.id,
      onCompleted: (data) => setPreviousYearData(data),
    }
  );

  const hasPreviousYearData = useMemo(() => {
    return (
      previousYearData?.facilityChemicalByYear?.maxAmount &&
      previousYearData?.facilityChemicalByYear?.unit
    );
  }, [
    previousYearData?.facilityChemicalByYear?.maxAmount,
    previousYearData?.facilityChemicalByYear?.unit,
  ]);

  useEffect(() => {
    if (open && (formData.chemicalId || data?.facilityChemical.chemical?.id)) {
      refetchPreviousYearData().then((result) =>
        setPreviousYearData(result.data)
      );
    }
  }, [
    data?.facilityChemical.chemical?.id,
    formData.chemicalId,
    open,
    refetchPreviousYearData,
  ]);

  const isOverThreshold = useMemo(() => {
    return isOverThresholdData?.getFacilityChemicalThresholdData
      ?.isOverThreshold;
  }, [isOverThresholdData?.getFacilityChemicalThresholdData?.isOverThreshold]);

  useEffect(() => {
    if (open && formData.chemicalId) {
      refetchThresholdData();
    }
  }, [open, formData, refetchThresholdData]);

  if (!open) return <></>;

  return (
    <>
      <FormProvider {...form}>
        <form key={facilityChemicalId} onSubmit={handleSubmit(onSubmit)}>
          <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="add-edit-facility-chemical-dialog-title"
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle
              id="add-edit-facility-chemical-dialog-title"
              sx={{ paddingBottom: 0 }}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {isAddMode ? "Add" : "Edit"} Chemical Inventory{" "}
                  <ThresholdChip isOverThreshold={isOverThreshold ?? false} />
                </div>
                <CheckboxField
                  fullWidth={false}
                  name="isAlwaysReported"
                  label="Voluntarily report this chemical"
                  noErrorDisplay
                />
              </Stack>
            </DialogTitle>
            <DialogContent>
              {loading ? (
                <Skelly />
              ) : (
                <>
                  <Grid container spacing={2} marginTop={theme.spacing(0.01)}>
                    {/* yep 0.01, the chemical picker is ever so slightly cutoff when a chemical is selected here */}
                    <Grid item xs={12}>
                      <Controller
                        name="chemicalId"
                        control={control}
                        render={({ field, fieldState }) => (
                          <ChemicalPicker
                            {...field}
                            {...fieldState}
                            value={facilityChemical?.chemical}
                            defaultSearchTerm={`tenantId:${tenantId}`}
                            disabled={!isAddMode}
                            disabledChemicalsById={alreadySelectedChemicalIds}
                            disabledOptionLabel="You have already added this chemical to this facility"
                            onChange={(chem) => {
                              field.onChange(chem?.id);
                            }}
                            isOptionDisabled={(chemical) =>
                              !!chemical.facilityChemicals?.length
                            }
                            facilityId={facilityId}
                            reportingYear={reportingYear}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Stack direction={"column"} spacing={1.5}>
                        <FormSelect
                          name="unit"
                          control={control}
                          label="Units"
                          selectItems={Object.values(UnitType).map((unit) => ({
                            display: prettyPrintEnumValue(unit),
                            value: unit,
                          }))}
                        />
                        <FormTextField
                          name="maxAmount"
                          label="Max Daily Amount *"
                          control={control}
                          sx={{
                            marginBottom: theme.spacing(2), // this is due to the extra space needed for the helper text
                          }}
                          helperText={
                            hasPreviousYearData
                              ? `Last Year: ${
                                  previousYearData?.facilityChemicalByYear
                                    ?.maxAmount ?? ""
                                } ${prettyPrintEnumValue(
                                  previousYearData?.facilityChemicalByYear
                                    ?.unit ?? ""
                                )}`
                              : "Chemical not present at this facility last year"
                          }
                        />
                        <AmountCodeSelect
                          state={facilityState}
                          name="maxAmountCode"
                          label="Max Amount Code"
                          amount={watch("maxAmount")}
                          disabled={getValues("maxAmount") != null}
                        />
                        <FormTextField
                          name="averageAmount"
                          label="Avg Daily Amount *"
                          control={control}
                        />
                        <AmountCodeSelect
                          state={facilityState}
                          name="averageAmountCode"
                          label="Avg Daily Amount Code"
                          amount={watch("averageAmount")}
                          disabled={getValues("averageAmount") != null}
                        />
                        <FormTextField
                          name="maxAmountLargestContainer"
                          label="Max Amount in Largest Container"
                          control={control}
                        />
                        <FormTextField
                          name="daysOnSite"
                          label="Days on Site"
                          control={control}
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                    >
                      <div
                        id="storage-location-data-grid-portal"
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      ></div>
                      <RadioGroupField
                        name="hasConfidentialStorageLocations"
                        label="This chemical has confidential storage locations"
                        control={control}
                        radioOptions={[
                          {
                            label: "Yes",
                            value: true,
                          },
                          {
                            label: "No",
                            value: false,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  {stateFields.length !== 0 && (
                    <>
                      <StateFieldsForm
                        namePrefix="stateFields"
                        openJurisdictions={
                          facilityChemical?.jurisdictions ?? []
                        }
                        context={"facilityChemical"}
                        fields={stateFields}
                        sx={{ p: theme.spacing(3) }}
                      />
                    </>
                  )}
                </>
              )}
              <CatalogLink
                id={facilityChemical?.chemical?.id ?? ""}
                type="Chemical"
                name={facilityChemical?.chemical?.name ?? ""}
                issues={facilityChemical?.issues ?? []}
              />
            </DialogContent>
            <DialogActions>
              <Stack direction="row" gap={theme.spacing(1)}>
                <IssueListButton
                  issues={form.issues ?? facilityChemical?.issues ?? []}
                />
                <Button
                  variant="outlined"
                  disabled={submitting || loading}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <SaveButton
                  loading={loading || submitting}
                  onClick={handleSubmit(onSubmit)}
                  disabled={hasCriticalIssues(issues)}
                />
              </Stack>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
      {open && !loading ? (
        /* NOTE: This component is rendered using a React Portal so that we do not
      have nested forms in the React component hierarchy. With nested forms,
      react-hook-form will submit both forms at the same time which we do not want. */
        <StorageLocationsDataGrid
          facilityId={defaultValues.facilityId || ""}
          facilityChemicalId={defaultValues.id ?? ""}
          storageLocations={storageLocationsWithIssueCounts}
          sx={{ mb: theme.spacing(4), flex: 1 }}
          disabled={false}
          handleRemoveStorageLocation={handleRemoveStorageLocation}
          handleStorageLocationSubmit={handleStorageLocationSubmit}
          issue={storageLocationsIssue}
          displayIssueCountColumn
          facilityChemical={formData}
        />
      ) : (
        ""
      )}
      <UnfinishedChangesPrompt when={hasPendingChanges} />
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={handleClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg={"You have unsaved changes."}
      />
    </>
  );
};

function mapStorageLocationToIssueCount(
  storageLocations: FacilityChemicalStorageLocationInput[],
  allIssues: Issue[]
): FacilityChemicalStorageLocationInputWithIssues[] {
  return storageLocations.map((sl) => {
    const issues = allIssues.filter(
      (i) => i.modelId === sl.id || i.modelId === sl.storageLocation?.id
    );
    const issueCount = issues.length;
    return { ...sl, issueCount, issues };
  });
}

function Skelly() {
  return (
    <Grid container spacing={2} marginTop={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={68} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={68} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
        <Skeleton variant="rectangular" height={68} sx={{ marginTop: 2 }} />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <div
          id="storage-location-data-grid-portal"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="rectangular" height="100%" />
        </div>
        <Skeleton variant="rectangular" height={60} sx={{ marginTop: 2 }} />
      </Grid>
    </Grid>
  );
}
