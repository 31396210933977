import { FeatureFlagKey, useFeatureFlags } from "hooks/useFeatureFlags";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

export function HasFlag({
  flag,
  fallback,
  children,
}: PropsWithChildren<{ flag: FeatureFlagKey; fallback?: string }>) {
  const { featureFlags, loading } = useFeatureFlags();

  if (loading) {
    return <></>;
  } else if (featureFlags?.[flag]) {
    return <>{children}</>;
  } else {
    return <Navigate to={fallback ?? "../not-found"} replace />;
  }
}
