import { Skeleton, Stack } from "@mui/material";
import { TierIiReportStep } from "generated-graphql/graphql";
import { ProductsTable } from "../../ProductsTable";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { useProductIssues, useReport } from "../useReport";
import { useTouchReportMutation } from "../useTouchReportMutation";

export function ProductsReportStep() {
  const { data } = useReport();
  const issues = useProductIssues();

  const facilityId = data?.tierIIReport.facility.id ?? "";
  const reportId = data?.tierIIReport.id ?? "";
  const { handleSave, loading } = useTouchReportMutation(
    reportId,
    data?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.Products
  );

  if (!data?.tierIIReport.facility) {
    return <Skeleton />;
  }

  return (
    <>
      <Stack
        sx={{
          flexDirection: "column",
          height: "100%",
        }}
      >
        <ProductsTable facilityId={facilityId} reportId={reportId} />
      </Stack>
      <ReportFloatingBar
        loading={loading}
        onSaveClick={handleSave}
        issues={issues}
        disabledWithAnyIssues
        saveText="Mark as Complete"
      />
    </>
  );
}
