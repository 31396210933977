export const alternateIdTypes: Record<string, string> = {
  CERS: "CERS Facility",
  CERS_BUSINESS: "CERS Business",
  CLEAN_HARBORS: "Clean Harbors",
  CLEAN_EARTH: "Clean Earth",
  REPUBLIC: "Republic",
  US_ECOLOGY: "US Ecology",
  OTHER_VENDOR: "Other Waste Vendor",
  EPA: "EPA ID",
  FRS: "FRS ID",
  ICIS: "ICIS ID",
  ISO_CERTIFICATION: "ISO Certification Number",
  NPDES: "NPDES ID",
  RCRAINFO: "RCRAInfo",
  RMP: "RMP ID",
  SAFETY_KLEEN: "Safety Kleen",
  STEERS_CN: "STEERS CN",
  STEERS_RN: "STEERS RN",
  TIER2_AZ: "Tier 2 Portal ID (AZ)",
  TIER2_KS: "Tier 2 Portal ID (KS)",
  TIER2_LA: "Tier 2 Portal ID (LA)",
  TIER2_MANAGER: "Tier 2 Portal ID (T2M)",
  TIER2_MO: "Tier 2 Portal ID (MO)",
  TIER2_MO_COMPANY: "Tier 2 Portal ID (MO Company)",
  TIER2_NJ: "Tier 2 Portal ID (NJ)",
  TIER2_SD: "Tier 2 Portal ID (SD)",
  TIER2_WA: "Tier 2 Portal ID (WA)",
  TRI: "TRI ID",
};
