import {
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  orange,
  pink,
  purple,
  yellow,
} from "@mui/material/colors";

export const ProgramAreaLabelMap: { [area: string]: string } = {
  NPDES: "Water",
  RCRA: "Waste",
  AIR: "Air",
  TRI: "TRI",
  Default: "Other",
};

export const ProgramAreaColors: { [area: string]: string } = {
  Water: cyan[400],
  Waste: pink[300],
  Air: deepPurple[200],
  TRI: orange[400],
  Other: "#666",
  Chemicals: indigo[400],
  "Multiple Programs": blue[400],
};

/** Helper function to generate quarters for the past 3 years. */
export function generateQuarters(): string[] {
  const quarters = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

  for (let year = currentYear - 3; year <= currentYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      quarters.push(`Q${quarter} ${year}`);
      if (year === currentYear && quarter === currentQuarter) break;
    }
  }

  return quarters;
}

export const PriorityChartColors = [
  blue[400],
  purple[300],
  yellow[600],
  pink[200],
  cyan[200],
  deepOrange[300],
  green[300],
  indigo[400],
  orange[300],
  pink[400],
];

export function getPriorityChartColorByIndex(index: number) {
  const remainder = index % PriorityChartColors.length;

  return PriorityChartColors[remainder];
}

export const PriorityChartColorsPurple = [
  indigo[400],
  deepPurple[200],
  indigo[200],
  deepPurple[500],
  deepPurple[100],
  indigo[300],
  indigo[700],
  deepPurple[200],
  deepPurple[400],
  indigo[100],
];
