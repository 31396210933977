import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Clear from "@mui/icons-material/Clear";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { EditFacilityContactDialog } from "components/Contacts/EditFacilityContactDialog";
import { DataGrid, IssueMessageCell } from "components/DataGrid";
import { IssueCount } from "components/IssueCount";
import { gql } from "generated-graphql";
import {
  ContactReportRole,
  FacilityContactsFragment,
  GetPersonQuery,
  Issue,
  Permission,
} from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { contactRoleToLabel } from "util/constants";
import { formatPhoneNumberAndType } from "util/phoneNumbers";
import { NoRowsOverlay } from "../Chemicals/Inventory/Facility/NoRowsOverlay";

const UPSERT_FACILITY_CONTACT = gql(`
  mutation UpsertFacilityContact($data: FacilityContactInput!) {
    upsertFacilityContact(data: $data) {
      id
    }
  }
`);

const DELETE_FACILITY_CONTACT = gql(`
  mutation DeleteFacilityContact($facilityContactId: ID!) {
    deleteFacilityContact(facilityContactId: $facilityContactId)
  }
`);

export type Row = NonNullable<FacilityContactsFragment["contacts"]>[number];

type FacilityContactIssueRow = Issue & GridValidRowModel;

type DataGridRow = Row | FacilityContactIssueRow;

const isFacilityContactIssueRow = (
  row: DataGridRow
): row is FacilityContactIssueRow => {
  return (row as FacilityContactIssueRow).key !== undefined;
};

export const FacilityContacts = ({
  loading,
  facilityId,
  facilityContacts,
  getRowIssues,
  facilityContactIssues,
  refetchQueries,
}: {
  loading: boolean;
  facilityId: string;
  facilityContacts: FacilityContactsFragment["contacts"];
  getRowIssues: (row: Row) => Issue[];
  facilityContactIssues: Issue[];
  refetchQueries: InternalRefetchQueriesInclude;
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenantId = "" } = useParams();

  const [upsertFacilityContact] = useMutation(UPSERT_FACILITY_CONTACT, {
    refetchQueries,
  });

  const [deleteFacilityContact] = useMutation(DELETE_FACILITY_CONTACT, {
    refetchQueries,
  });

  const upsert = useCallback(
    async (
      person: GetPersonQuery["person"],
      roles: ContactReportRole[],
      success: string
    ) => {
      if (!person) {
        return;
      }
      upsertFacilityContact({
        variables: {
          data: {
            person: {
              id: person.id,
              tenantId,
              first: person.first,
              last: person.last,
              email: person.email,
              title: person.title,
              company: person.company,
              phones: person.phones?.map((p) => ({
                number: p.number,
                type: p.type,
              })),
              streetAddress1: person.streetAddress1,
              streetAddress2: person.streetAddress2,
              city: person.city,
              state: person.state,
              zip: person.zip,
              country: person.country,
            },
            facilityId,
            reportingRoles: roles,
          },
        },
        onCompleted() {
          alerts.success(success);
        },
      });
    },
    [upsertFacilityContact, tenantId, facilityId, alerts]
  );

  const deleteContact = useCallback(
    async (facilityContactId: string) => {
      deleteFacilityContact({
        variables: { facilityContactId },
        onCompleted() {
          alerts.success("Contact removed successfully!");
        },
        onError(err) {
          alerts.error(err.message);
        },
      });
    },
    [deleteFacilityContact]
  );

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editRow, setEditRow] = useState<Row | undefined | null>(undefined);
  const [addingContact, setAddingContact] = useState<ContactReportRole>();
  const [rowToDelete, setRowToDelete] = useState<Row | undefined>(undefined);

  const openEditDialog = useCallback(
    ({ row, contact }: { row?: Row; contact?: ContactReportRole }) => {
      setIsEditDialogOpen(true);
      if (row) {
        setEditRow(row);
      } else {
        setEditRow(null);
      }
      if (contact) {
        setAddingContact(contact);
      }
    },
    [setIsEditDialogOpen, setEditRow, setAddingContact]
  );

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
    setEditRow(undefined);
    setAddingContact(undefined);
  }, [setIsEditDialogOpen, setEditRow, setAddingContact]);

  const { hasPermissionForFacility } = useAuthorization();

  const readOnly = !hasPermissionForFacility(facilityId, [
    Permission.WriteFacilityContact,
  ]);

  const rows: DataGridRow[] = [
    ...(facilityContacts ?? []),
    ...facilityContactIssues.map((i) => ({
      ...i,
      id: i.message,
      metadata: i.metadata,
    })),
  ];

  const columns: GridColDef<DataGridRow>[] = useMemo(
    () => [
      {
        field: "role",
        headerName: "Role",
        flex: 1,
        colSpan: ({ row }) => (isFacilityContactIssueRow(row) ? 4 : undefined),
        renderCell: ({ row }) => {
          if (isFacilityContactIssueRow(row)) {
            return (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                alignItems="start"
              >
                <IssueMessageCell issue={row} />
              </Box>
            );
          }

          return (
            <Box display="flex" flexDirection="column" height="100%">
              {row.reportingRoles?.map((role) => (
                <Typography
                  variant="body2"
                  key={role}
                  noWrap
                  alignItems="start"
                >
                  {contactRoleToLabel(role)}
                </Typography>
              ))}
            </Box>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }) =>
          `${row.person?.first ?? ""} ${row.person?.last ?? ""}`.trim(),
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1.25,
        renderCell: ({ row }) => {
          if (!isFacilityContactIssueRow(row))
            return (
              <Box display="flex" flexDirection="column" height="100%">
                {row.person.phones?.map((phone, index) => (
                  <Typography
                    variant="body2"
                    key={index}
                    noWrap
                    alignItems="start"
                  >
                    {formatPhoneNumberAndType(phone)}
                  </Typography>
                ))}
              </Box>
            );
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.5,
        topAligned: true,
        valueGetter: ({ row }) => row.person.email ?? "",
      },
      {
        field: "issues",
        headerName: "Issues",
        headerAlign: "center",
        align: "center",
        topAligned: true,
        sortable: false,
        renderCell({ row }) {
          const issueCount = isFacilityContactIssueRow(row)
            ? undefined
            : getRowIssues(row).length;

          return <IssueCount issueCount={issueCount} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        cellClassName: "actions",
        flex: 0.5,
        align: "right",
        renderCell: ({ row }) => {
          if (isFacilityContactIssueRow(row)) {
            return (
              <Box display="flex" height="100%" alignItems="start">
                {!readOnly && (
                  <Button
                    key="add"
                    size="small"
                    onClick={() => {
                      openEditDialog({
                        contact: row.metadata
                          ?.requiredReportingRole as ContactReportRole,
                      });
                    }}
                    startIcon={<Add fontSize="small" />}
                  >
                    <Box>Add Contact</Box>
                  </Button>
                )}
              </Box>
            );
          }
          return (
            <Box display="flex" height="100%" alignItems="center">
              <GridActionsCellItem
                key={readOnly ? "view" : "edit"}
                label={readOnly ? "View" : "Edit"}
                icon={readOnly ? <Visibility /> : <Edit />}
                onClick={() => {
                  openEditDialog({ row });
                }}
              />
              {!readOnly && (
                <GridActionsCellItem
                  key="delete"
                  label="Delete"
                  icon={<Clear />}
                  onClick={() => setRowToDelete(row)}
                />
              )}
            </Box>
          );
        },
      },
    ],
    [getRowIssues, readOnly]
  );

  return (
    <>
      <Stack
        gap={theme.spacing(2)}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        {loading ? (
          <>
            <Skeleton variant="rectangular" sx={{ mt: theme.spacing(4) }} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        ) : (
          <Stack spacing={theme.spacing(1)}>
            <Stack direction="row" justifyContent="end" alignItems="end">
              {!readOnly && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    openEditDialog({});
                  }}
                >
                  Add Contact
                </Button>
              )}
            </Stack>
            <DataGrid<DataGridRow>
              disableRowSelectionOnClick
              columns={columns}
              initialState={{
                sorting: { sortModel: [{ field: "name", sort: "desc" }] },
              }}
              rows={rows}
              hideFooter
              slots={{
                noRowsOverlay: (props) => (
                  <NoRowsOverlay
                    {...props}
                    message="No contacts"
                    buttonText="Add contact"
                    onButtonClick={() => setEditRow(null)}
                  />
                ),
              }}
              onRowClick={({ row }) => {
                if (isFacilityContactIssueRow(row)) {
                  openEditDialog({
                    contact: row.metadata
                      ?.requiredReportingRole as ContactReportRole,
                  });
                } else {
                  openEditDialog({ row });
                }
              }}
              autoHeight
              getRowHeight={() => "auto"}
              sx={{
                "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within":
                  { outline: "none" },
                "& .MuiDataGrid-row:hover": { cursor: "pointer" },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "4px",
                  alignItems: "start",
                },
              }}
            />
          </Stack>
        )}
      </Stack>

      {isEditDialogOpen && editRow !== undefined && (
        <EditFacilityContactDialog
          onClose={closeEditDialog}
          onSave={async (roles, person) => {
            await upsert(person, roles, "Contact saved successfully!");
            closeEditDialog();
          }}
          data={editRow}
          contacts={facilityContacts ?? []}
          addingContact={addingContact}
          setEditRow={setEditRow}
          initialIssues={editRow ? getRowIssues(editRow) : []}
          facilityId={facilityId}
        />
      )}

      {rowToDelete && (
        <ConfirmDialog
          open
          onClose={() => setRowToDelete(undefined)}
          onConfirm={async () => {
            await deleteContact(rowToDelete.id);
            setRowToDelete(undefined);
          }}
          msg={`You are removing ${rowToDelete.person.first} ${rowToDelete.person.last} from the chemicals contact list at this facility.`}
        />
      )}
    </>
  );
};
