import SaveIcon from "@mui/icons-material/Save";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { Issue } from "generated-graphql/graphql";
import { useMemo } from "react";
import { hasCriticalIssues } from "util/forms";

type ReportFloatingSaveBarProps = {
  onSaveClick?: () => void;
  onCancel?: () => void;
  loading?: boolean;
  saveDisabled?: boolean;
  saving?: boolean;
  issues: Issue[];
  disabledWithAnyIssues?: boolean;
  saveText?: string;
  overrideSaveTooltip?: string;
  noIcon?: boolean;
  hideCancel?: boolean;
};

export const ReportFloatingBar = ({
  onSaveClick,
  onCancel,
  saveDisabled,
  loading = false,
  saving = false,
  issues,
  disabledWithAnyIssues,
  saveText,
  overrideSaveTooltip,
  noIcon,
  hideCancel = false,
}: ReportFloatingSaveBarProps) => {
  const disabledFromIssues = useMemo(
    () =>
      disabledWithAnyIssues ? issues?.length > 0 : hasCriticalIssues(issues),
    [issues, disabledWithAnyIssues]
  );
  const tooltip = useMemo(
    () =>
      overrideSaveTooltip
        ? overrideSaveTooltip
        : disabledFromIssues
        ? "Resolve compliance issues to proceed."
        : undefined,
    [overrideSaveTooltip, disabledFromIssues]
  );
  const _saveDisabled = useMemo(
    () => loading || (saveDisabled ?? disabledFromIssues),
    [saveDisabled, loading, disabledFromIssues]
  );
  return (
    <FloatingSaveBar
      hideCancel={hideCancel}
      onSaveClick={onSaveClick}
      onCancel={onCancel}
      saving={saving}
      saveText={saveText ?? "Save"}
      saveDisabled={_saveDisabled}
      saveStartIcon={!noIcon && <SaveIcon />}
      issues={issues}
      saveTooltipTitle={tooltip}
    />
  );
};
