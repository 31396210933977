import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";

export function SubSection(props: {
  title?: string;
  tooltip?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();
  return (
    <Box
      marginTop={theme.spacing(3)}
      paddingBottom={theme.spacing(3)}
      marginLeft={theme.spacing(3)}
      marginRight={theme.spacing(3)}
      style={props.style}
    >
      {props.title && (
        <SubSectionHeader title={props.title} tooltip={props.tooltip} />
      )}
      {props.children}
    </Box>
  );
}

export function SubSectionHeader(props: { title: string; tooltip?: string }) {
  const theme = useTheme();
  return (
    <Typography
      variant="body1"
      fontWeight={"bold"}
      height={theme.spacing(3)}
      marginBottom={theme.spacing(2.5)}
      display="flex"
      alignItems="center"
    >
      {props.title}
      {props.tooltip && (
        <Tooltip title={props.tooltip}>
          <HelpOutlineOutlined
            fontSize="small"
            style={{ marginLeft: theme.spacing(1) }}
          />
        </Tooltip>
      )}
    </Typography>
  );
}
