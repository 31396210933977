import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { SaveButton } from "components/SaveButton";
import { SkeletonFormGroup } from "components/Skeleton";
import { gql } from "generated-graphql";
import { ProductInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformProduct";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { compact } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { hasCriticalIssues } from "util/forms";
import { v4 } from "uuid";
import { useProductInputValidation } from "../../Report/validationHooks/useProductInputValidation";
import ProductChemicalDataGrid, {
  ProductChemicalRow,
} from "./ProductChemicalDataGrid";
import ProductChemicalForm from "./ProductChemicalForm";

const UPSERT_PRODUCT_MUTATION = gql(`
  mutation UpsertProduct($id: ID!, $input: ProductInput!) {
    upsertProduct(id: $id, input: $input) {
      id
      name
    }
  }
`);

const PRODUCT_DETAILS_QUERY = gql(`
  query ProductDetailQuery($productId: ID!) {
    productWithFacilityCount(productId: $productId) {
      facilityCount
      product {
        id
        name
        alternateId
        reference
        productChemicals {
          ...ProductChemicalForm
        }
        issues {
          ...issue
        }
      }
    }
  }
`);

export type ProductFormState = Omit<ProductInput, "productChemicals"> & {
  productChemicals: ProductChemicalRow[];
};

type ProductDetailProps = {
  open: boolean;
  onClose: () => void;
  productId: string;
};

export function ProductDetailForm(props: ProductDetailProps) {
  const { open, onClose } = props;
  const { tenantId } = useTenant();
  invariant(tenantId);
  const alerts = useAlerts();
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [selectedProductChemical, setSelectedProductChemical] = useState<
    ProductChemicalRow | undefined
  >();
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const creatingNewProduct = !props.productId;
  const navigate = useNavigate();
  const productId = useMemo(
    () => (creatingNewProduct ? v4() : props.productId),
    [creatingNewProduct, props.productId]
  );

  const { data, loading } = useQuery(PRODUCT_DETAILS_QUERY, {
    variables: { productId: productId ?? "" },
    skip: !productId?.length || creatingNewProduct,
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      console.error(error);
      alerts.error("Error fetching product details");
      onClose();
      navigate("../products", { replace: true });
    },
  });

  const [upsertProduct, { loading: upsertLoading }] = useMutation(
    UPSERT_PRODUCT_MUTATION,
    {
      refetchQueries: ["TenantCatalogProducts", PRODUCT_DETAILS_QUERY],
    }
  );

  const product: ProductFormState = useMemo(() => {
    const product = data?.productWithFacilityCount.product;

    return {
      name: product?.name ?? "",
      alternateId: product?.alternateId ?? "",
      reference: product?.reference ?? "",
      productChemicals:
        product?.productChemicals?.map((pc) => ({
          ...pc,
          overrides: pc.overrides ?? [],
          issues: product.issues.filter((i) => {
            return (
              (i.modelName === "ProductChemical" && i.modelId === pc.id) ||
              (i.modelName === "Chemical" && pc.chemicalId === i.modelId) ||
              (i.modelName === "ChemicalStateField" &&
                pc.chemicalId === i.modelId)
            );
          }),
        })) ?? [],
    };
  }, [data?.productWithFacilityCount.product]);

  const form = useValidatingForm<ProductFormState>(
    product,
    data?.productWithFacilityCount.product.issues,
    useProductInputValidation(productId)
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isSubmitting },
    issues,
  } = form;

  const productChemicals = watch("productChemicals", []);

  const hasPendingChanges = isDirty && !isSubmitting;
  const onCancel = useCallback(() => {
    // If there are pending changes, verify the user wants to close the dialog
    // can't use UnfinishedChangesPrompt because user isn't navigating away
    if (hasPendingChanges) {
      setShowConfirmClose(true);
      return;
    }

    onClose();
  }, [onClose, hasPendingChanges]);

  const { append, update } = useFieldArray<ProductFormState>({
    control,
    name: "productChemicals",
  });

  const productName = creatingNewProduct ? "New Product" : watch("name") ?? "";

  const usedChemicalIds = useMemo(
    () => compact(productChemicals.map((pc) => pc.chemicalId)),
    [productChemicals]
  );

  const onSubmit: SubmitHandler<ProductFormState> = useCallback(
    async (data) => {
      // associate current tenant to product so that we create
      // at least one ProductTenant
      if (creatingNewProduct) data.tenantIds = [tenantId];

      try {
        await upsertProduct({
          variables: {
            id: productId,
            input: transform(data),
          },
        });
        alerts.success("Saved product successfully");
        onClose();
      } catch (ex) {
        console.error(ex);
        alerts.error("Error saving product.", ex);
      }
    },
    [creatingNewProduct, tenantId, upsertProduct, productId, alerts, onClose]
  );

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="md">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {!creatingNewProduct ? "Edit" : "Add"} Product
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <SkeletonFormGroup count={4} />
            ) : (
              <>
                <Box sx={{ marginBottom: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <FormTextField
                          sx={{ pt: 2 }}
                          textFieldProps={{ autoFocus: true, required: true }}
                          label="Name"
                          name="name"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <FormTextField
                          name="alternateId"
                          label="Alternate ID"
                          sx={{ pt: 2 }}
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <FormTextField
                          name="reference"
                          label="Reference"
                          sx={{ pt: 2 }}
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <ProductChemicalDataGrid
                  productName={productName}
                  selectedProductChemical={selectedProductChemical}
                  setSelectedProductChemical={setSelectedProductChemical}
                  setOpenAddEditModal={setOpenAddEditModal}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <IssueListButton issues={issues} />
            <FormControl>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <SaveButton
                loading={upsertLoading}
                disabled={hasCriticalIssues(issues) || loading}
              />
            </FormControl>
          </DialogActions>
        </form>
      </FormProvider>
      {openAddEditModal && (
        <ProductChemicalForm
          productName={productName}
          productId={productId}
          usedChemicalIds={usedChemicalIds}
          open={openAddEditModal}
          onClose={() => {
            setOpenAddEditModal(false);
            setSelectedProductChemical(undefined);
          }}
          onProductChemicalSaved={(productChemical) => {
            // check if we are adding a new productChemical
            // or updating an existing one
            const productChemicalIndex = productChemicals.findIndex(
              (p) => p.id === productChemical.id
            );

            if (productChemicalIndex === -1) {
              append(productChemical);
              return;
            }

            update(productChemicalIndex, productChemical);
          }}
          productChemical={selectedProductChemical}
          creatingNewProduct={creatingNewProduct}
        />
      )}
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={onClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg={"You have unsaved changes."}
      />
    </Dialog>
  );
}
