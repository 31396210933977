import { useMutation, useQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { IssueCount } from "components/IssueCount";
import { CatalogLink } from "components/CatalogLink";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import { FacilityChemicalMeasurementsDetailsQuery } from "generated-graphql/graphql";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatChemicalName } from "util/chemicalName";
import { prettyPrintDateMed } from "util/dates";
import { prettyPrintQuantity } from "util/unit";
import { CHEMICAL_DETAIL_FOR_FACILITY_QUERY } from "../../Catalog/Chemical/schema";
import {
  ChemicalMeasurementSeed,
  FacilityChemicalMeasurementForm,
  UPSERT_FACILITY_CHEMICAL_MEASUREMENT,
} from "../../FacilityChemicalMeasurementForm";
import { FACILITY_CHEMICAL_MEASUREMENTS_DETAILS } from "./schema";
import { omit } from "lodash";

import { v4 as uuid } from "uuid";
import { transform } from "hooks/transform/transformFacilityChemicalMeasurement";

type FacilityChemicalMeasurementDetailsProps = {
  facilityId: string;
  chemicalId: string;
  reportId?: string;
};

const DELETE_FACILITY_CHEMICAL_MEASUREMENT_MUTATION = gql(`
  mutation DeleteFacilityChemicalMeasurement($id: ID!) {
    deleteFacilityChemicalMeasurement(id: $id) {
      id
    }
  }
`);

type Row =
  FacilityChemicalMeasurementsDetailsQuery["facilityChemicalMeasurements"]["items"][0];

export function FacilityChemicalMeasurementDetailsTable({
  facilityId,
  chemicalId,
  reportId,
}: FacilityChemicalMeasurementDetailsProps) {
  const theme = useTheme();
  const reportingYear = useSelectedReportingYear();
  const [rowToDelete, setRowToDelete] = useState<Row | undefined>(undefined);

  const [editingMeasurementId, setEditingMeasurementId] = useState<
    string | undefined
  >(undefined);
  const [measurementSeed, setMeasurementSeed] = useState<
    ChemicalMeasurementSeed | undefined
  >();

  const { data: chemicalData, loading } = useQuery(
    CHEMICAL_DETAIL_FOR_FACILITY_QUERY,
    {
      variables: { chemicalId, facilityId },
      fetchPolicy: "cache-and-network",
      skip: !chemicalId?.length,
    }
  );
  const navigate = useNavigate();

  const onQuickAdd = useCallback(
    (row: Row) => {
      setMeasurementSeed({
        chemicalId: row.chemicalId,
        facilityId,
        amount: row.amount,
        unit: row.unit,
        pressure: row.pressure,
        otherPressureValue: row.otherPressureValue,
        temperature: row.temperature,
        storageType: row.storageType,
        storageTypeDescription: row.storageTypeDescription,
        storageLocationId: row.storageLocationId,
        storageLocation: row.storageLocation
          ? {
              id: row.storageLocation.id,
              description: row.storageLocation.description ?? "",
              facilityId,
            }
          : undefined,
      });
    },
    [facilityId]
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    DELETE_FACILITY_CHEMICAL_MEASUREMENT_MUTATION,
    {
      refetchQueries: [FACILITY_CHEMICAL_MEASUREMENTS_DETAILS],
    }
  );

  const [upsertFacilityChemicalMeasurementMutation, { loading: upserting }] =
    useMutation(UPSERT_FACILITY_CHEMICAL_MEASUREMENT, {
      refetchQueries: [FACILITY_CHEMICAL_MEASUREMENTS_DETAILS],
    });

  const columns: GridColDef<Row>[] = [
    {
      field: "location",
      headerName: "Storage Location",
      flex: 1,
      valueGetter(params) {
        return params.row.storageLocation?.description;
      },
    },
    {
      field: "measuredAtUtc",
      headerName: "Measurement Date",
      flex: 1,
      valueGetter(params) {
        const measurementYear = params.row.measuredAtUtc.slice(0, 4);
        return `${prettyPrintDateMed(params.row.measuredAtUtc)}${
          measurementYear < reportingYear ? " (carry-over)" : ""
        }`;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter(params) {
        return prettyPrintQuantity(params.row.amount, params.row.unit);
      },
    },
    {
      field: "storageType",
      headerName: "Storage Type",
      flex: 1,
    },
    {
      field: "issues",
      headerName: "Issues",
      flex: 0.5,
      renderCell: ({ row: { issueCount } }) => (
        <IssueCount issueCount={issueCount} />
      ),
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.8,
      minWidth: 135,
      align: "right",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key="edit"
            label="Edit"
            icon={<Edit />}
            onClick={() => setEditingMeasurementId(row.id)}
          />,
          <GridActionsCellItem
            key="delete"
            label="Delete"
            icon={<Delete />}
            onClick={() => setRowToDelete(row)}
          />,
          <GridActionsCellItem
            key="add"
            label="Add"
            icon={<Add />}
            onClick={() => onQuickAdd(row)}
          />,
        ];
      },
    },
  ];

  const defaultSearch = useMemo(
    () =>
      `facilityId:${facilityId} chemicalId:${chemicalId} reportingYear:${reportingYear}`,
    [chemicalId, facilityId, reportingYear]
  );

  const headerSecondPart = useMemo(() => {
    if (!chemicalData) return undefined;
    return formatChemicalName(
      chemicalData?.chemicalWithAssociatedDataForFacility?.chemical
    );
  }, [chemicalData]);
  return (
    <>
      <Box mb={theme.spacing(3)} sx={{ width: "100%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent={"center"}
          sx={{ mb: 3 }}
        >
          <Typography variant="h5">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                Daily Measurements {headerSecondPart ? " for " : ""}
                <i>{headerSecondPart ? headerSecondPart : ""}</i>
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <OmnisearchDataGrid
        dataQuery={FACILITY_CHEMICAL_MEASUREMENTS_DETAILS}
        columns={columns}
        withPadding={false}
        defaultSearch={defaultSearch}
        initialSortModel={[
          { field: "location", sort: "desc" },
          { field: "measuredAtUtc", sort: "desc" },
        ]}
        getItems={(data) => data.facilityChemicalMeasurements.items}
        getCount={(data) => data.facilityChemicalMeasurements.count}
        commandButtons={[
          !reportId ? (
            <TierIIYearPicker
              key="yearPicker"
              selectProps={{
                size: "small",
              }}
            />
          ) : undefined,
          <Button
            key="add"
            onClick={() => setMeasurementSeed({ chemicalId, facilityId })}
            variant="contained"
            sx={{ width: { xs: "100%", sm: "auto" } }}
            size="small"
          >
            Add Measurement
          </Button>,
        ]}
        noDataOnButtonClick={() =>
          setMeasurementSeed({ chemicalId, facilityId })
        }
        noDataMessage="No chemical measurements have been added to this facility yet."
        noDataButtonText="Add Measurement"
        onRowClick={(params) => setEditingMeasurementId(params.row.id)}
      />
      <CatalogLink
        id={
          chemicalData?.chemicalWithAssociatedDataForFacility?.chemical.id ?? ""
        }
        type="Chemical"
        name={
          chemicalData?.chemicalWithAssociatedDataForFacility?.chemical.name ??
          ""
        }
        issues={
          chemicalData?.chemicalWithAssociatedDataForFacility?.chemical
            .issues ?? []
        }
      />
      <FloatingSaveBar
        hideCancel={true}
        saveStartIcon={<ArrowForward />}
        saveText="Return to Chemicals"
        onSaveClick={() => {
          navigate(`..?ry=${reportingYear}`);
        }}
      />
      {rowToDelete &&
        new Date(rowToDelete.measuredAtUtc).getUTCFullYear() ==
          reportingYear && (
          <ConfirmDialog
            open
            onClose={() => setRowToDelete(undefined)}
            onConfirm={async () => {
              await deleteMutation({ variables: { id: rowToDelete.id } });
              setRowToDelete(undefined);
            }}
            msg={`You are deleting the ${
              rowToDelete?.chemical?.name
            } measurement from ${prettyPrintDateMed(
              rowToDelete?.measuredAtUtc
            )} from this facility.`}
            loading={deleting}
          />
        )}

      {rowToDelete &&
        new Date(rowToDelete.measuredAtUtc).getUTCFullYear() !==
          reportingYear && (
          <ConfirmDialog
            open
            onClose={() => setRowToDelete(undefined)}
            onCancel={async () => {
              await deleteMutation({ variables: { id: rowToDelete.id } });
              setRowToDelete(undefined);
            }}
            onConfirm={async () => {
              await upsertFacilityChemicalMeasurementMutation({
                variables: {
                  id: uuid(),
                  input: transform({
                    ...omit(
                      rowToDelete,
                      "id",
                      "chemical",
                      "facility",
                      "issueCount",
                      "issues",
                      "storageLocation"
                    ),
                    facilityId,
                    chemicalId,
                    amount: 0,
                    measuredAtUtc: `${reportingYear}-01-01`,
                  }),
                },
              });
              setRowToDelete(undefined);
            }}
            cancelText={`Delete Measurement`}
            confirmText={`Reset to 0 on January 1, ${reportingYear}`}
            msg={`You are deleting the ${
              rowToDelete?.chemical?.name
            } measurement from ${prettyPrintDateMed(
              rowToDelete?.measuredAtUtc
            )} from this facility. This measurement is from a previous reporting year. Would you like to delete this measurement or reset the amount to 0 on January 1, ${reportingYear}?`}
            loading={deleting || upserting}
          />
        )}

      {(editingMeasurementId || measurementSeed) && (
        <FacilityChemicalMeasurementForm
          measurementId={editingMeasurementId}
          measurementSeed={measurementSeed}
          inputChemical={
            chemicalData?.chemicalWithAssociatedDataForFacility?.chemical
          }
          facilityId={facilityId}
          onClose={() => {
            setEditingMeasurementId(undefined);
            setMeasurementSeed(undefined);
          }}
          open={true}
        />
      )}
    </>
  );
}
