import AccountCircle from "@mui/icons-material/AccountCircle";
import Brightness4 from "@mui/icons-material/Brightness4";
import Brightness7 from "@mui/icons-material/Brightness7";
import HelpOutline from "@mui/icons-material/HelpOutline";
import HelpOutlineRounded from "@mui/icons-material/HelpOutlineRounded";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/icons-material/Menu";
import WidthFull from "@mui/icons-material/WidthFull";
import WidthNormal from "@mui/icons-material/WidthNormal";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu as MuiMenu,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { Permission } from "generated-graphql/graphql";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useScreenWidth } from "hooks/useScreenWidth";
import { useTenant } from "hooks/useTenant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditUserDialog } from "routes/Customer/Users/EditUserDialog";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useAlerts } from "./Alerts/AlertProvider";
import { AppBar } from "./AppBar";
import { EmulatePermissionsDialog } from "./EmulatePermissionsDialog";
import NavBreadcrumbs from "./NavBreadcrumbs";
import { TenantPickerDialog } from "./TenantPickerDialog";

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: "rgb(253 223 185)",
      color: "rgb(251 138 0)",
      fontSize: "1rem",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function TopNavBar({
  handleThemeToggle,
  onMenuClick,
}: {
  handleThemeToggle: () => void;
  onMenuClick: () => void;
}) {
  const theme = useTheme();
  const { featureFlags } = useFeatureFlags();
  const { isStaff, isOriginalUserStaff, isEmulating, user, isPartner } =
    useCurrentUser();
  const { tenant, loading: loadingTenant } = useTenant();
  const [tenantPickerOpen, setTenantPickerOpen] = useState(false);
  const [emulateDialogOpen, setEmulateDialogOpen] = useState(false);

  // This state determines whether the User Profile dialog is open
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  // This state determines whether the User Profile dialog is open, but in the context of
  // field users adding facilities. Only used if facility-self-assignment FF is enabled.
  const [initialAddFacilitiesDialogOpen, setInitialAddFacilitiesDialogOpen] =
    useState(false);
  const themeMode = theme.palette.mode;
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const themeToggleTitle = `Switch to ${
    themeMode === "dark" ? "light" : "dark"
  } theme`;
  const { screenWidth, toggleScreenWidth } = useScreenWidth();
  const screenToggleTitle = `Switch to ${
    screenWidth === "full" ? "fixed" : "full"
  } width`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const alerts = useAlerts();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = useCallback(async () => {
    localStorage.setItem("logout", "true");
    await Auth.signOut();
    navigate("/");
    window.location.pathname = "/";
  }, [navigate]);

  const OrgName = useMemo(() => {
    const tenantName = loadingTenant
      ? "Loading..."
      : tenant?.name ?? "No current organization";
    return (
      <>
        <span>{tenantName}</span>{" "}
        {(isStaff || isPartner) && (
          <span>
            (
            <span
              style={{ cursor: "pointer", color: theme.palette.primary.main }}
              onClick={() => setTenantPickerOpen(true)}
            >
              change org
            </span>
            )
          </span>
        )}
      </>
    );
  }, [
    isStaff,
    tenant?.name,
    theme.palette.primary.main,
    isPartner,
    loadingTenant,
  ]);

  const open = Boolean(anchorEl);
  const UserInfo = () => (
    <Stack
      direction="column"
      alignItems={isLargeScreen ? "flex-end" : "flex-start"}
    >
      <Typography
        variant="body2"
        component="div"
        sx={{
          mt: theme.spacing(0.5),
          fontWeight: "bold",
        }}
      >
        {user?.person?.first || user?.person?.last
          ? [user?.person?.first, user?.person?.last].filter(hasValue).join(" ")
          : user?.email}
        {isEmulating && " (as emulated user)"}
      </Typography>
      <Typography variant="caption" sx={{ textAlign: "right" }}>
        {OrgName}
      </Typography>
    </Stack>
  );

  useEffect(() => {
    const userTenant = user?.UserTenant.find(
      (userTenant) => userTenant.tenantId === tenant?.id
    );
    const permissions = userTenant?.role?.permissions ?? [];

    if (
      user?.facilities.length === 0 &&
      (featureFlags?.["facility-self-assignment"] ?? false) &&
      !(
        permissions.includes(Permission.ReadAllFacility) ||
        permissions.includes(Permission.WriteAllFacility)
      ) &&
      !isStaff &&
      !editUserDialogOpen &&
      !initialAddFacilitiesDialogOpen
    ) {
      setInitialAddFacilitiesDialogOpen(true);
    }
  }, [
    user?.facilities.length,
    featureFlags,
    isStaff,
    user?.UserTenant,
    tenant?.id,
    editUserDialogOpen,
    initialAddFacilitiesDialogOpen,
  ]);

  return (
    <>
      <AppBar>
        <Toolbar sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", display: "flex" }}
          >
            {!isLargeScreen && (
              <IconButton
                onClick={onMenuClick}
                sx={{
                  padding: 0,
                }}
              >
                <Menu sx={{ fontSize: "3rem" }} />
              </IconButton>
            )}
            <NavBreadcrumbs />
            <Stack direction="row" alignItems="center">
              {isLargeScreen && <UserInfo />}
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ marginLeft: theme.spacing(1), padding: 0 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  {...stringAvatar(
                    `${user?.person?.first} ${user?.person?.last}`
                  )}
                />
              </IconButton>
              {isLargeScreen && (
                <IconButton
                  component="a"
                  href="http://support.encamp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ padding: 0, marginLeft: theme.spacing(1) }}
                >
                  <HelpOutlineRounded
                    sx={{
                      fontSize: "2rem",
                      color: "rgb(251 138 0)",
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <MuiMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isLargeScreen && (
          <MenuItem divider disabled>
            <UserInfo />
          </MenuItem>
        )}
        <MenuItem
          component="a"
          href="https://encamp.com/legal/security-statement/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Security
        </MenuItem>
        <MenuItem
          component="a"
          href="https://encamp.com/legal/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </MenuItem>
        <MenuItem
          component="a"
          href="https://encamp.com/legal/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </MenuItem>
        {isOriginalUserStaff && [
          <Divider key="divider-1" />,
          <MenuItem
            key="toggle-theme"
            onClick={() => {
              handleThemeToggle();
              handleClose();
            }}
          >
            <ListItemIcon>
              {themeMode === "dark" ? <Brightness7 /> : <Brightness4 />}
            </ListItemIcon>
            {themeToggleTitle}
          </MenuItem>,
          <MenuItem
            key="toggle-width"
            onClick={() => {
              toggleScreenWidth();
              handleClose();
            }}
          >
            <ListItemIcon>
              {screenWidth === "full" ? <WidthNormal /> : <WidthFull />}
            </ListItemIcon>
            {screenToggleTitle}
          </MenuItem>,
          <MenuItem
            key="emulate-permissions"
            onClick={() => {
              setEmulateDialogOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Menu />
            </ListItemIcon>
            Emulate Permissions
          </MenuItem>,
        ]}
        <Divider />
        {!isLargeScreen && (
          <MenuItem
            component="a"
            href="http://support.encamp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <HelpOutline fontSize="small" />
            </ListItemIcon>
            Help
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setEditUserDialogOpen(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          User Profile
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </MuiMenu>
      <TenantPickerDialog
        open={tenantPickerOpen}
        onClose={() => {
          setTenantPickerOpen(false);
        }}
        onTenantSelected={(tenant) => {
          navigate(`/o/${tenant.id}/facilities`);
          alerts.success("Successfully changed organization");
          setTenantPickerOpen(false);
        }}
      />
      <EmulatePermissionsDialog
        open={emulateDialogOpen}
        tenant={tenant}
        onClose={() => setEmulateDialogOpen(false)}
      />
      {editUserDialogOpen && (
        <EditUserDialog
          tenantId={tenant?.id ?? ""}
          initialUserId={user?.id}
          open={editUserDialogOpen}
          onClose={() => setEditUserDialogOpen(false)}
        />
      )}
      {initialAddFacilitiesDialogOpen && (
        <EditUserDialog
          tenantId={tenant?.id ?? ""}
          initialUserId={user?.id}
          open={initialAddFacilitiesDialogOpen}
          introText="Click the Associate Facilities button to add facilities to your user."
          onClose={() => setInitialAddFacilitiesDialogOpen(false)}
        />
      )}
    </>
  );
}
