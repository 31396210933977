import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { Suspense } from "react";
import { FacilityMixTile } from "./FacilityMix/FacilityMixTile";
import { NonComplianceTile } from "./NonCompliance/NonComplianceTile";
import { OverviewContext } from "./OverviewContext";
import { ChartTileSkeleton, FacilityMixSkeleton } from "./OverviewSkeleton";
import { PenaltiesTile } from "./Penalties/PenaltiesTile";
import { ViolationsTile } from "./Violations/ViolationsTile";

export function Overview() {
  useBreadcrumb({ label: "Overview" });
  return (
    <DashboardLayout>
      <Suspense>
        <OverviewContext>
          <Suspense fallback={<FacilityMixSkeleton />}>
            <FacilityMixTile />
          </Suspense>
          <Suspense fallback={<ChartTileSkeleton />}>
            <PenaltiesTile />
          </Suspense>
          <Suspense fallback={<ChartTileSkeleton />}>
            <ViolationsTile />
          </Suspense>
          <Suspense fallback={<ChartTileSkeleton />}>
            <NonComplianceTile />
          </Suspense>
        </OverviewContext>
      </Suspense>
    </DashboardLayout>
  );
}
