import { Grid, Skeleton, Stack } from "@mui/material";

export function LoadingState() {
  return (
    <Stack p={3} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={400} />
        </Grid>
      </Grid>
    </Stack>
  );
}
