import { Skeleton, Stack, useTheme } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";

export function WasteFacilitySkeleton() {
  const theme = useTheme();
  return (
    <>
      <DashboardTile xs={7}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
          <Stack direction="row" spacing={2}>
            <Skeleton
              variant="rectangular"
              height={theme.spacing(25)}
              sx={{ flex: 1 }}
            />
            <Skeleton
              variant="rectangular"
              height={theme.spacing(25)}
              sx={{ flex: 2 }}
            />
          </Stack>
        </Stack>
      </DashboardTile>
      <DashboardTile xs={5}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
          <Skeleton variant="rectangular" height={theme.spacing(8)} />
          <Skeleton variant="rectangular" height={theme.spacing(6)} />
          <Skeleton variant="rectangular" height={theme.spacing(6)} />
        </Stack>
      </DashboardTile>

      <DashboardTile xs={12}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
          <Skeleton variant="rectangular" height={theme.spacing(38)} />
        </Stack>
      </DashboardTile>

      <DashboardTile xs={12}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
          <Skeleton variant="rectangular" height={theme.spacing(38)} />
        </Stack>
      </DashboardTile>
    </>
  );
}
