import { Box, Button, FormControl, Stack } from "@mui/material";
import { StorageLocationLatLong } from "components/StorageLocationLatLong";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  FacilityProductStorageLocationInput,
  InsideOutside,
  StorageQuadrant,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useFacilityProductStorageLocationInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useFacilityProductStorageLocationInputValidation";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { hasCriticalIssues } from "util/forms";
import { getStorageQuadrantLabel } from "util/storage";
import { v4 as uuidv4 } from "uuid";
import { FormSelect } from "../../../../../components/Forms/FormSelect";
import { FormTextField } from "../../../../../components/Forms/FormTextField";
import { IssueListButton } from "../../../../../components/Forms/IssueListButton";
import { SaveButton } from "../../../../../components/SaveButton";
import { StorageLocationPicker } from "../../../../../components/StorageLocationPicker";
import { useReport } from "../../Report/useReport";

type FacilityProductStorageLocationFormProps = {
  storageLocation: FacilityProductStorageLocationInput | null;
  onSave: (storageLocation: FacilityProductStorageLocationInput) => void;
  handleClose: () => void;
  facilityId: string;
  isEditMode: boolean;
};

export const FacilityProductStorageLocationForm: React.FC<
  FacilityProductStorageLocationFormProps
> = ({ onSave, storageLocation, handleClose, facilityId, isEditMode }) => {
  const { data: reportData } = useReport();
  const { data: facilityData } = useFacility();

  const facility =  facilityData?.facility ?? reportData?.tierIIReport.facility;
  const facilityState =
    facility?.state;

  const isOregonFacility = facilityState === "OR";
  const isCaliforniaFacility = facilityState === "CA";

  const defaultValues: FacilityProductStorageLocationInput = useMemo(
    () => ({
      id: storageLocation?.id ?? uuidv4(),
      maxQuantity: storageLocation?.maxQuantity ?? null,
      storageLocation: storageLocation?.storageLocation
        ? {
            ...storageLocation.storageLocation,
            latitude:
              storageLocation.storageLocation.latitude ??
              facility?.latitude,
            longitude:
              storageLocation.storageLocation.longitude ??
              facility?.longitude,
          }
        : {
            description: "",
            facilityId,
            id: "",
            OR_insideOutside: null,
            OR_storageArea: "",
            OR_storageBuilding: "",
            OR_storageQuadrant: null,
            OR_storageFloor: "",
            OR_storageRoom: "",
            latitude: facility?.latitude,
            longitude: facility?.longitude,
          },
    }),
    [
      storageLocation,
      facilityId,
      facility?.latitude,
      facility?.longitude,
    ]
  );

  const form = useValidatingForm<FacilityProductStorageLocationInput>(
    defaultValues,
    [],
    useFacilityProductStorageLocationInputValidation(facilityId)
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
    trigger,
    issues,
  } = form;

  useEffect(() => {
    // Since we're not in the context of a real react-hook-form, we need to manually trigger the validation when the form opens
    if (isEditMode) {
      trigger();
    }
  }, [trigger, isEditMode]);

  const onSubmit = useCallback(
    (input: FacilityProductStorageLocationInput) => onSave(input),
    [onSave]
  );

  return (
    <Box>
      <Stack direction="column">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box paddingX={3} paddingTop={2}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="storageLocation.description"
                  render={({ field, fieldState }) => (
                    <StorageLocationPicker
                      {...fieldState}
                      facilityId={facilityId}
                      description={field.value}
                      required
                      onSelectLocation={(location) => {
                        if (location == null) return;
                        setValue(
                          "storageLocation",
                          {
                            ...location,
                            description: location.description ?? "",
                            latitude:
                              location.latitude ??
                              facility?.latitude,
                            longitude:
                              location.longitude ??
                              facility?.longitude,
                          },
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {isOregonFacility && (
              <>
                <Box paddingX={3} paddingTop={2}>
                  <FormSelect
                    name="storageLocation.OR_insideOutside"
                    label="Inside/Outside *"
                    selectItems={Object.values(InsideOutside).map(
                      (insideOutside) => ({
                        display: prettyPrintEnumValue(insideOutside),
                        value: insideOutside,
                      })
                    )}
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Storage Building *"
                    name="storageLocation.OR_storageBuilding"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Storage Floor"
                    name="storageLocation.OR_storageFloor"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormSelect
                    name="storageLocation.OR_storageQuadrant"
                    label="Storage Quadrant *"
                    selectItems={Object.values(StorageQuadrant).map(
                      (storageQuadrant) => ({
                        display: getStorageQuadrantLabel(storageQuadrant),
                        value: storageQuadrant,
                      })
                    )}
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Storage Room"
                    name="storageLocation.OR_storageRoom"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Storage Area *"
                    name="storageLocation.OR_storageArea"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
              </>
            )}
            {isCaliforniaFacility && (
              <>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Grid Number"
                    name="storageLocation.CA_gridNumber"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
                <Box paddingX={3} paddingTop={2}>
                  <FormTextField
                    label="Map Number"
                    name="storageLocation.CA_mapNumber"
                    control={control}
                    // disabled={disabled}
                  />
                </Box>
              </>
            )}
            <Box paddingX={3} paddingTop={2}>
              <FormTextField
                intOnly
                label="Max Quantity"
                control={control}
                name="maxQuantity"
              />
            </Box>

            <Box px={3} pt={2}>
              <StorageLocationLatLong facilityState={facilityState} />
            </Box>

            <Stack
              padding={1.5}
              direction="row"
              justifyContent="flex-end"
              gap={1}
            >
              <IssueListButton issues={issues} />
              <Box>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <SaveButton
                  loading={isSubmitting}
                  disabled={hasCriticalIssues(issues)}
                />
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Box>
  );
};
