import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback, useEffect, useState } from "react";

const CHECK_FOR_FILE = gql(`query CheckForFile($key: String!) {
    checkForFile(key: $key) {
      exists
      signedUrl
    }
  }`);

export const useLongPollingForFile = ({
  tenantId,
  filename,
}: {
  tenantId: string;
  filename: string;
}) => {
  const [polling, setPolling] = useState(false);
  const [signedUrl, setSignedUrl] = useState<undefined | string>(undefined);

  const [checkForFile, { data, startPolling, stopPolling }] = useLazyQuery(
    CHECK_FOR_FILE,
    {
      fetchPolicy: "network-only",
      variables: { key: `spreadsheet-exports/${tenantId}/${filename}` },
    }
  );

  useEffect(() => {
    if (polling) {
      checkForFile();
      startPolling(1000);
    } else {
      stopPolling();
      setSignedUrl(undefined);
    }

    return () => {
      stopPolling();
    };
  }, [polling, checkForFile, startPolling, stopPolling]);

  useEffect(() => {
    if (data?.checkForFile?.signedUrl) {
      setSignedUrl(data.checkForFile.signedUrl);
      setPolling(false);
    }
  }, [data]);

  return { polling, setPolling, signedUrl };
};
