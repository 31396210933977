import { StorageQuadrant } from "../generated-graphql/graphql";

export function getStorageQuadrantLabel(enumValue: StorageQuadrant): string {
  switch (enumValue) {
    case StorageQuadrant.N:
      return "N";
    case StorageQuadrant.S:
      return "S";
    case StorageQuadrant.E:
      return "E";
    case StorageQuadrant.W:
      return "W";
    case StorageQuadrant.Ne:
      return "NE";
    case StorageQuadrant.Se:
      return "SE";
    case StorageQuadrant.Nw:
      return "NW";
    case StorageQuadrant.Sw:
      return "SW";
    case StorageQuadrant.C:
      return "C (Center)";
    case StorageQuadrant.V:
      return "V (Various)";
  }
}
