import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import { Button, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import {
  FacilityChemicalInput,
  FacilityChemicalStorageLocationInput,
  Issue,
} from "generated-graphql/graphql";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { SubmitHandler } from "react-hook-form";
import { FacilityChemicalStorageLocationDialog } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemicalStorageLocationDialog";
import { NoRowsOverlay } from "routes/Customer/Chemicals/Inventory/Facility/NoRowsOverlay";
import { prettyPrintQuantity } from "util/unit";
import { ConfirmDialog } from "./ConfirmDialog";
import { DataGrid } from "./DataGrid";
import { ErrorDisplay } from "./Forms/ErrorDisplay";
import { IssueCount } from "./IssueCount";
import { FacilityChemicalDetailFormType } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemicalAddEditDialog";

export type FacilityChemicalStorageLocationInputWithIssues =
  FacilityChemicalStorageLocationInput & {
    issues: Issue[];
    issueCount: number;
  };
interface StorageLocationDataGridProps {
  facilityId: string;
  facilityChemicalId: string;
  storageLocations: FacilityChemicalStorageLocationInputWithIssues[];
  sx?: SxProps;
  disabled?: boolean;
  submitting?: boolean;
  handleStorageLocationSubmit: SubmitHandler<FacilityChemicalStorageLocationInput>;
  handleRemoveStorageLocation?: (storageLocationId: string) => void;
  displayIssueCountColumn?: boolean;
  issue?: Issue;
  facilityChemical?: FacilityChemicalDetailFormType;
}

export function StorageLocationsDataGrid({
  facilityId,
  facilityChemicalId,
  storageLocations,
  disabled = false,
  submitting = false,
  handleStorageLocationSubmit,
  handleRemoveStorageLocation,
  displayIssueCountColumn: displayIssues = false,
  issue,
  facilityChemical,
}: PropsWithChildren<StorageLocationDataGridProps>) {
  const theme = useTheme();
  const [dialog, setDialog] = useState<
    "storageLocation" | "deleteStorageLocation" | null
  >(null);
  const [selectedLocation, setSelectedLocation] =
    useState<FacilityChemicalStorageLocationInputWithIssues | null>(null);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  // this is to display the issue for the storage location list itself
  const storageLocationListIssue = useMemo(() => {
    if (issue) {
      return { type: "validate", message: issue?.message ?? "" };
    }

    return null;
  }, [issue]);
  /*  sometimes the portal element is not available immediately and then this never loaded
   with the implementation before, this guarantees that it is loaded properly */
  useEffect(() => {
    const portal = document.getElementById("storage-location-data-grid-portal");
    if (portal) {
      setPortalElement(portal);
    } else {
      const intervalId = setInterval(() => {
        const portalCheck = document.getElementById(
          "storage-location-data-grid-portal"
        );
        if (portalCheck) {
          setPortalElement(portalCheck);
          clearInterval(intervalId);
        }
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, []);

  const storageLocationColumns: GridColDef<FacilityChemicalStorageLocationInputWithIssues>[] =
    useMemo(() => {
      const columns: GridColDef<FacilityChemicalStorageLocationInputWithIssues>[] =
        [
          {
            field: "location",
            headerName: "Location",
            flex: 1,
            valueGetter(params) {
              return params.row.storageLocation?.description;
            },
          },
          {
            field: "storageType",
            headerName: "Storage Type",
            flex: 1,
            valueGetter(params) {
              return params.row.storageType;
            },
          },
          {
            field: "maxAmount",
            headerName: "Max Amount",
            flex: 1,
            valueGetter(params) {
              return prettyPrintQuantity(params.row.maxAmount, params.row.unit);
            },
          },
        ];

      if (displayIssues) {
        columns.push({
          field: "issues",
          headerName: "Issues",
          flex: 1,
          renderCell: ({ row: { issueCount } }) => (
            <IssueCount issueCount={issueCount} />
          ),
          sortable: false,
        });
      }

      if (disabled) {
        columns.push({
          field: "actions",
          type: "actions",
          getActions: (params) => [
            <GridActionsCellItem
              onClick={() => {
                setSelectedLocation(params.row);
                setDialog("storageLocation");
              }}
              key={1}
              label="View"
              icon={<Visibility />}
            />,
          ],
        });
      } else {
        columns.push({
          field: "actions",
          type: "actions",
          getActions: (params) => [
            <GridActionsCellItem
              onClick={() => {
                setSelectedLocation(params.row);
                setDialog("storageLocation");
              }}
              key={1}
              label="Edit"
              icon={<Edit />}
            />,
            <GridActionsCellItem
              onClick={() => {
                setSelectedLocation(params.row);
                setDialog("deleteStorageLocation");
              }}
              key={2}
              label="Delete"
              icon={<Delete />}
            />,
          ],
        });
      }
      return columns;
    }, [disabled, displayIssues]);

  const handleClose = useCallback(() => {
    setSelectedLocation(null);
    setDialog(null);
  }, []);

  if (!portalElement) {
    return <></>;
  }

  return (
    <>
      {createPortal(
        <>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              marginBottom: theme.spacing(1),
            }}
          >
            <Typography variant="h6">Storage Locations</Typography>
            <Button
              onClick={() => {
                setDialog("storageLocation");
                setSelectedLocation(null);
              }}
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                maxWidth: { xs: "100%", sm: "250px" },
              }}
            >
              Add Storage Location
            </Button>
          </Stack>
          <DataGrid
            rows={storageLocations ?? []}
            columns={storageLocationColumns}
            onRowClick={(params) => {
              setSelectedLocation(params.row);
              setDialog("storageLocation");
            }}
            slots={{
              noRowsOverlay: (props) => (
                <NoRowsOverlay
                  {...props}
                  message="No storage locations for this chemical."
                  buttonText="Add Storage Location"
                  onButtonClick={() => {
                    setDialog("storageLocation");
                    setSelectedLocation(null);
                  }}
                />
              ),
            }}
            sx={{
              marginBottom: storageLocationListIssue ? "0px" : "2rem",
              minHeight: "200px",
              "& .MuiDataGrid-virtualScroller": {
                minHeight: "100px",
              },
              height: "100%",
            }}
          />
          {storageLocationListIssue && (
            <ErrorDisplay error={storageLocationListIssue} />
          )}
          <ConfirmDialog
            open={dialog === "deleteStorageLocation"}
            onClose={handleClose}
            onConfirm={() => {
              handleRemoveStorageLocation &&
                handleRemoveStorageLocation(selectedLocation?.id ?? "");
              handleClose();
            }}
            msg={`Are you sure you want to remove storage location ${selectedLocation?.storageLocation.description} from this facility chemical?`}
          />

          <FacilityChemicalStorageLocationDialog
            facilityId={facilityId}
            facilityChemicalId={facilityChemicalId}
            open={dialog === "storageLocation"}
            onClose={handleClose}
            loading={submitting}
            initialValues={selectedLocation}
            onSubmit={(data) => {
              handleStorageLocationSubmit(data);
              handleClose();
            }}
            disabled={disabled}
            facilityChemical={facilityChemical}
          />
        </>,
        portalElement
      )}
    </>
  );
}
