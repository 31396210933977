import { Skeleton, Stack, useTheme } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";

export function OverviewSkeleton() {
  return (
    <>
      <FacilityMixSkeleton />
      <ChartTileSkeleton />
      <ChartTileSkeleton />
      <ChartTileSkeleton />
    </>
  );
}

export function FacilityMixSkeleton() {
  const theme = useTheme();
  return (
    <DashboardTile xs={12}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={theme.spacing(4)} />
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Skeleton
            variant="rectangular"
            height={theme.spacing(40)}
            sx={{ flexGrow: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={theme.spacing(40)}
            sx={{ flexGrow: 1 }}
          />
        </Stack>
        <Skeleton variant="rectangular" height={theme.spacing(6)} />
      </Stack>
    </DashboardTile>
  );
}

export function ChartTileSkeleton() {
  const theme = useTheme();
  return (
    <DashboardTile xs={12}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={theme.spacing(4)} />
        <Stack direction="row" spacing={2}>
          <Skeleton
            variant="rectangular"
            height={theme.spacing(38)}
            sx={{ width: "25%" }}
          />
          <Skeleton
            variant="rectangular"
            height={theme.spacing(38)}
            sx={{ width: "75%" }}
          />
        </Stack>
      </Stack>
    </DashboardTile>
  );
}
