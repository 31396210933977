import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { TierIiReportKind } from "generated-graphql/graphql";

export function ReportKindPicker(props: {
  reportKind: TierIiReportKind;
  setReportKind: (reportKind: TierIiReportKind) => void;
}) {
  const theme = useTheme();
  return (
    <FormControl sx={{ width: theme.spacing(32.5) }}>
      <InputLabel id="view-by">View by</InputLabel>
      <Select
        sx={{ height: theme.spacing(5) }}
        fullWidth
        labelId="view-by"
        label="View by"
        value={props.reportKind}
        onChange={(event) => {
          props.setReportKind(event.target.value as TierIiReportKind);
        }}
      >
        <MenuItem value={TierIiReportKind.Annual}>Annual Reports</MenuItem>
        <MenuItem value={TierIiReportKind.Initial}>
          Initial Notification Reports
        </MenuItem>
      </Select>
    </FormControl>
  );
}
